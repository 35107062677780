import { TransactionHistory } from './apiTypes';
import { TransitionName } from './constants';
import { Accept, Decline, LineItem } from './types';

export const hasInitialisedTransaction = (transactionHistory: TransactionHistory): boolean => !!transactionHistory.data.length;

export const getDeclinePayload = (transactionId: string): Decline => ({
    transactionId,
    transition: TransitionName.DECLINE,
    params: {},
});

export const getAcceptPayload = (transactionId: string): Accept => ({
    transactionId,
    transition: TransitionName.ACCEPT,
    params: {},
});

export const cancelledStates = [
    TransitionName.CANCEL,
    TransitionName.EXPIRE,
    TransitionName.CANCEL_NO_REFUND,
    TransitionName.DECLINE,
    TransitionName.CUSTOMER_CANCEL,
    TransitionName.CUSTOMER_CANCEL_AFTER_ACCEPT,
    TransitionName.OPERATOR_DECLINE,
    TransitionName.OPERATOR_DECLINE_NO_REFUND,
    TransitionName.PROVIDER_CANCEL_AFTER_ACCEPT,
    TransitionName.CUSTOMER_DECLINE_BEFORE_CONFIRM_PAYMENT,
];

export const isTransactionCancelled = (lastTransition: TransitionName): boolean => {
    return cancelledStates.includes(lastTransition);
};

export const filterCustomerLineItems = (lineItems: LineItem[]) => lineItems.filter((item) => item.includeFor.includes('customer'));
export const filterProviderLineItems = (lineItems: LineItem[]) => lineItems.filter((item) => item.includeFor.includes('provider'));
