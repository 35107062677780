import React, { useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';

import { useListing } from '../../queries/useListing';
import { ContentLoader } from '../../components/ContentLoader/ContentLoader';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { ListingDetails } from './ListingDetails';
import { ListingFooter } from './ListingFooter';
import { useCurrentUser } from '../../user/hooks/useUser';
import { createBookingDraft, removeFromOngoing, selectOngoingBooking } from '../../store/bookingReducer';
import { useConfirm } from '../../context/confirm';
import { useTranslation } from 'react-i18next';
import { convertToMidnightUTC, formatDistanceNow, formatShortMonthAndDate, getDurationInDays } from '../../helpers/dateAndTimeHelpers';
import { assertIsDefined, noop } from '../../helpers/commonHelpers';
import { endOfDay } from 'date-fns';
import { DateRange } from '../../components/DateRangePicker/DateRangePicker.types';
import { useBreakpoint } from '../../hooks/useBreakpoint';
import { ListingControlsCard } from './ListingControlsCard';
import { rem } from '@mantine/core';
import { useCapacitorStripe } from '@capacitor-community/stripe/dist/esm/react/provider';
import { AnimatedOutlet } from '../../animations/components/AnimatedOutlet';
import { useSafeNavigate } from '../../hooks/useSafeNavigate';
import { getListingTitle } from './ViewListing.helpers';
import useLocationParams from '../../hooks/useLocationParams';
import { declineTransaction } from '../UserProfile/Payments/paymentApi';
import { useQueryClient } from '@tanstack/react-query';

const getInitialRange = (start?: string, end?: string): DateRange => {
    if (!start || !end) {
        return [null, null];
    }

    const startDate = new Date(start);
    const endDate = new Date(end);

    return [startDate, endDate];
};

/**
 * This whole view should get refactorinoed
 */
const ViewListing: React.FC<{ own?: boolean }> = ({ own = false }) => {
    const { id: listingId } = useParams() as { id: string };
    const { start, end } = useLocationParams();
    const [selectedRange, setSelectedRange] = useState<DateRange>(getInitialRange(start, end));

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { confirm } = useConfirm();
    const ongoingBooking = useAppSelector((s) => selectOngoingBooking(s, listingId));
    const queryClient = useQueryClient();

    const { pathname } = useLocation();
    const { data: user } = useCurrentUser();
    const navigate = useSafeNavigate();

    const { data: listingData, status, isRefetching, error } = useListing(listingId, { own });

    const [showProfile, setShowProfile] = useState(false);
    const [showDateRangePicker, setShowDateRangePicker] = useState(false);

    const { isGooglePayAvailable, isApplePayAvailable } = useCapacitorStripe();

    const isOwnListing = useMemo(() => {
        if (!listingData?.author || !user) {
            return false;
        }

        return listingData.author.id === user.id;
    }, [listingData, user]);

    const handleRentClick = async () => {
        const [startDate, endDate] = selectedRange;

        if (!startDate || !endDate) {
            return;
        }

        const handleCreateBooking = () => {
            assertIsDefined(listingData, 'Listing data should be defined at this point');

            const payload = {
                brand: listingData.publicData.brand,
                category: listingData.publicData.category,
            };
            const listingTitle = getListingTitle(payload, { lng: 'en' });
            const bookingStart = convertToMidnightUTC(startDate).toISOString();
            const bookingEnd = convertToMidnightUTC(endDate).toISOString();
            const productLabel = `${listingTitle} - Rental x ${getDurationInDays(bookingStart, bookingEnd)} days`;

            const getDefaultPaymentMethod = () => {
                if (isGooglePayAvailable) {
                    return 'google_pay';
                }
                if (isApplePayAvailable) {
                    return 'apple_pay';
                }
                return 'card';
            };

            dispatch(
                createBookingDraft({
                    bookingStart,
                    bookingEnd,
                    listingId,
                    productLabel,
                    paymentMethod: getDefaultPaymentMethod(),
                }),
            );

            navigate(`${pathname}/checkout`);
        };

        if (ongoingBooking) {
            // User is starting an another booking for an item they have already booked earlier on.
            // TODO: Should we ask the user whether this is correct?
            if (ongoingBooking.status === 'payment-confirmed') {
                dispatch(removeFromOngoing({ ...ongoingBooking }));
                handleCreateBooking();
            } else {
                // If payment is not confirmed and there is an ongoing booking, the item is still reserved for the
                // user and we should offer the possibility to continue that transaction instead of starting a new one.
                const duration = formatDistanceNow(ongoingBooking.expiresAt);
                const range = `${formatShortMonthAndDate(ongoingBooking.range.bookingStart)} - ${formatShortMonthAndDate(
                    ongoingBooking.range.bookingEnd,
                )}`;

                const handleDeclineBooking = async () => {
                    try {
                        await declineTransaction(ongoingBooking.transaction.id.uuid);
                    } catch (err) {
                        console.error('Error declining transaction, was the transaction cancelled from the Flex console already? ', err);
                    }

                    queryClient.invalidateQueries(['timeslots', { listingId }]);
                    queryClient.invalidateQueries(['listing', { listingId }]);
                    queryClient.invalidateQueries(['delivery-options']);

                    dispatch(removeFromOngoing({ ...ongoingBooking }));
                };

                confirm({
                    title: t('confirmAnotherBookingTitle'),
                    message: t('confirmAnotherBookingBody', { duration, range }),
                    cancelText: t('cancelBooking'),
                })
                    .then(() => {
                        navigate(`${pathname}/checkout`);
                    })
                    .catch(() => {
                        handleDeclineBooking()
                            .then(noop)
                            .catch((err) => {
                                console.error('Error declining transaction', err);
                            });
                    });
            }
        } else {
            handleCreateBooking();
        }
    };

    useEffect(() => {
        setShowProfile(false);
    }, [pathname, listingId]);

    useEffect(() => {
        if (!selectedRange) return;

        const [startDate, endDate] = selectedRange;

        if (!startDate || !endDate) return;

        const startDateString = startDate.toISOString();
        const endDateString = endDate.toISOString();

        const url = `${pathname}?start=${startDateString}&end=${endDateString}${window.location.hash}`;

        navigate(url, { replace: true });
    }, [selectedRange]);

    const isDesktop = useBreakpoint('sm');
    const showListingControls = isDesktop;
    const showFooter = !showListingControls && listingData;

    return (
        <ContentLoader status={status} error={error} style={{ zIndex: 1, position: 'relative' }}>
            {listingData && (
                <ListingDetails showProfile={showProfile} setShowProfile={setShowProfile} listing={listingData} isOwnListing={isOwnListing}>
                    {showListingControls && (
                        <ListingControlsCard
                            listing={listingData}
                            isLoading={isRefetching || isRefetching}
                            selectedRange={selectedRange}
                            onRangeSelected={setSelectedRange}
                            isOwnListing={isOwnListing}
                            onRentClick={handleRentClick}
                            showDateRangePicker={showDateRangePicker}
                            setShowDateRangePicker={setShowDateRangePicker}
                            sxProps={{ width: rem(450), margin: '10px 0 0 30px', ...(!isOwnListing && { height: rem(230) }) }}
                        />
                    )}
                </ListingDetails>
            )}

            {showFooter && (
                <ListingFooter
                    listing={listingData}
                    isLoading={isRefetching || isRefetching}
                    selectedRange={selectedRange}
                    onRangeSelected={setSelectedRange}
                    isOwnListing={isOwnListing}
                    onRentClick={handleRentClick}
                    showDateRangePicker={showDateRangePicker}
                    setShowDateRangePicker={setShowDateRangePicker}
                />
            )}
        </ContentLoader>
    );
};

export const ListingLayout: React.FC = () => {
    const isDesktop = useBreakpoint('sm');

    return <>{isDesktop ? <Outlet /> : <AnimatedOutlet />}</>;
};

export default ViewListing;
