import {
    ERROR_CODE_TRANSACTION_LISTING_NOT_FOUND,
    ERROR_CODE_MISSING_STRIPE_ACCOUNT,
    ERROR_CODE_TRANSACTION_BOOKING_TIME_NOT_AVAILABLE,
    ERROR_CODE_PAYMENT_FAILED,
    STRIPE_ERROR_CODE_INSUFFICIENT_CAPABILITIES,
} from './constants';
import { SharetribeError } from './types';

const responseAPIErrors = (error: SharetribeError) => (error && error.data && error.data.errors ? error.data.errors : []);

export const hasErrorWithCode = (error: SharetribeError, code: string) => responseAPIErrors(error).some((apiError) => apiError.code === code);

export const hasErrorWithStripeCode = (error: SharetribeError, code: string) =>
    responseAPIErrors(error).some((apiError) => apiError.meta?.stripeCode === code);

export const isSharetribeError = (error: any): error is SharetribeError =>
    error && typeof error === 'object' && 'data' in error && 'errors' in error.data;

/**
 * Check if the given API error (from `sdk.transaction.initiate()` or
 * `sdk.transaction.initiateSpeculative()`) is due to the listing
 * being closed or deleted.
 */
export const isTransactionInitiateListingNotFoundError = (error: SharetribeError) =>
    hasErrorWithCode(error, ERROR_CODE_TRANSACTION_LISTING_NOT_FOUND);

/**
 * Check if the given API error (from `sdk.transaction.initiate()` or
 * `sdk.transaction.initiateSpeculative()`) is due to missign Stripe
 * connection from the listing author.
 */
export const isTransactionInitiateMissingStripeAccountError = (error: SharetribeError) => hasErrorWithCode(error, ERROR_CODE_MISSING_STRIPE_ACCOUNT);

/**
 * Check if the given API error (from `sdk.transaction.initiate()` or
 * `sdk.transaction.initiateSpeculative()`) is due to selected booking
 * time already being booked.
 */
export const isTransactionInitiateBookingTimeNotAvailableError = (error: SharetribeError) =>
    hasErrorWithCode(error, ERROR_CODE_TRANSACTION_BOOKING_TIME_NOT_AVAILABLE);

export const isPaymentFailedError = (error: SharetribeError) => hasErrorWithCode(error, ERROR_CODE_PAYMENT_FAILED);

export const isTransactionInitiateOwnerMissingCapabilitiesError = (error: SharetribeError) =>
    hasErrorWithStripeCode(error, STRIPE_ERROR_CODE_INSUFFICIENT_CAPABILITIES);
