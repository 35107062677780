import React, { useRef, useState } from 'react';
import { StepBackButton, StepContent, StepContentContainer } from '../UserOnboarding.components';
import { Avatar, Button, Fab, Typography } from '@mui/material';
import { useUploadAndUpdateProfileImage } from '../../../user/hooks/useUpdateProfileImage';
import { ActionSheet, ActionSheetButtonStyle } from '@capacitor/action-sheet';
import { pickPhoto, presentPhotoPrompt, takePhoto } from '../../../helpers/camera';
import { assertNever, sendToSentry } from '../../../helpers/commonHelpers';
import { ImageCropper } from '../../ImageCropper/ImageCropper';
import { ArrowBack, Crop as CropIcon, Preview } from '@mui/icons-material';
import { motion } from 'framer-motion';
import { StaggerList, StaggerListElement } from '../../../animations/components/Stagger';
import Profiles from '../../../assets/images/onboarding/Profiles.png';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { BaseStepProps } from '../UserOnboarding.types';
import { User } from '../../../types/apiTypes';
import { toast } from 'react-toastify';
import { isSafari } from 'react-device-detect';

const showActions = async () => {
    const result = await ActionSheet.showActions({
        title: 'Photo Options',
        message: 'Select an option to perform',
        options: [
            {
                title: 'Take photo',
                icon: 'camera',
            },
            {
                title: 'Pick from gallery',
                icon: 'image',
            },
            {
                title: 'Cancel',
                style: ActionSheetButtonStyle.Cancel,
                icon: 'close',
            },
        ],
    });

    return result.index as 0 | 1 | 2;
};

interface AddProfilePictureStepProps extends BaseStepProps {
    user: User;
    existingUser: boolean;
}

export const AddProfilePictureStep: React.FC<AddProfilePictureStepProps> = ({
    onComplete,
    onGoBack,
    loading,
    user,
    existingUser,
    hasPreviousStep,
}) => {
    const [editMode, setEditMode] = useState(!user.profileImage);
    const [preview, setPreview] = useState(false);
    const [imageUrl, setImageUrl] = useState<string | undefined>();

    const cropperRef = useRef<{ getCroppedImageBlob: () => Promise<Blob | null | undefined> }>(null);

    const { t } = useTranslation();
    const { mutate: uploadAndUpdateProfileImage, isLoading } = useUploadAndUpdateProfileImage(() => onComplete());

    const handleConfirm = () => {
        const update = async () => {
            // Fetch the cropped image from canvas
            const handleImageError = (message: string) => {
                const toastMsg = t('imageDownloadError');
                toast.error(toastMsg);

                sendToSentry(new Error(message), { type: 'client', message });
            };

            if (!cropperRef.current) {
                handleImageError('No canvas element found');

                return;
            }

            cropperRef.current
                .getCroppedImageBlob()
                .then((blob) => {
                    if (!blob) {
                        handleImageError('No image generated');
                    } else {
                        // Upload the image to Sharetribe and set as profile picture
                        uploadAndUpdateProfileImage(blob);
                    }
                })
                .catch((err: string) => {
                    handleImageError(`Error generating image from canvas: ${err}`);
                });
        };

        update();
    };

    const handleShowActions = async () => {
        const handleResult = async (result: 0 | 1 | 2) => {
            if (result === 0) {
                return takePhoto();
            }
            if (result === 1) {
                return pickPhoto();
            }
            if (result === 2) {
                return;
            }

            assertNever(result);
        };

        let photo;

        // Safari works in mysterious ways, workaround for the photo prompt not appearing.
        if (isSafari) {
            photo = await presentPhotoPrompt();
        } else {
            const result = await showActions();
            photo = await handleResult(result);
        }

        if (photo) {
            setPreview(false);
            setEditMode(true);
            setImageUrl(photo);
        }
    };

    if (!editMode) {
        return (
            <StepContent>
                <StepContentContainer>
                    <Typography variant="h6">{t('yourProfilePicture')}</Typography>
                    <Avatar src={user.profileImage} sx={{ height: '200px', width: '200px' }} />
                </StepContentContainer>
                <Button variant="contained" onClick={onComplete}>
                    {t('confirm')}
                </Button>
                <Button variant="text" onClick={handleShowActions}>
                    {t('changePicture')}
                </Button>

                {hasPreviousStep && (
                    <StepBackButton onClick={onGoBack}>
                        <ArrowBack />
                    </StepBackButton>
                )}
            </StepContent>
        );
    }

    return (
        <StepContent>
            <StepContentContainer style={{ position: 'relative' }}>
                {imageUrl ? (
                    <>
                        <ImageCropper ref={cropperRef} src={imageUrl} preview={preview} maxHeight={300} />

                        <motion.div layout style={{ position: 'absolute', bottom: '50px' }}>
                            {preview ? (
                                <Fab variant="extended" onClick={() => setPreview(!preview)} size="small">
                                    <motion.div layout="position" style={{ display: 'flex', alignItems: 'center' }}>
                                        <CropIcon sx={{ mr: 1 }} />
                                        <>{t('crop')}</>
                                    </motion.div>
                                </Fab>
                            ) : (
                                <Fab variant="extended" onClick={() => setPreview(!preview)} size="small">
                                    <motion.div layout="position" style={{ display: 'flex', alignItems: 'center' }}>
                                        <Preview sx={{ mr: 1 }} />
                                        <>{t('preview')}</>
                                    </motion.div>
                                </Fab>
                            )}
                        </motion.div>
                    </>
                ) : (
                    <StaggerList
                        custom={{ stagger: 0.3 }}
                        style={{ display: 'flex', overflow: 'hidden', flexDirection: 'column', justifyContent: 'space-evenly' }}
                    >
                        <StaggerListElement style={{ maxHeight: '70%' }} onClick={handleShowActions}>
                            <img
                                src={Profiles}
                                style={{
                                    height: '100%',
                                    width: '100%',
                                    borderRadius: '20px',
                                    objectFit: 'contain',
                                }}
                            />
                        </StaggerListElement>

                        <StaggerListElement style={{ flex: '1 0 auto' }}>
                            <Typography variant="h6" fontWeight="bold">
                                {existingUser ? t('profileStepExistingUserTitle') : t('profileStepTitle')}
                            </Typography>
                        </StaggerListElement>

                        <StaggerListElement>
                            <Typography variant="subtitle2">
                                {existingUser ? t('profileStepExistingUserDescription') : t('profileStepDescription')}
                            </Typography>
                        </StaggerListElement>
                    </StaggerList>
                )}
            </StepContentContainer>

            {imageUrl ? (
                <>
                    <LoadingButton variant="contained" onClick={handleConfirm} loading={isLoading || loading}>
                        {t('confirm')}
                    </LoadingButton>
                    <Button variant="text" onClick={handleShowActions}>
                        {t('changePicture')}
                    </Button>
                </>
            ) : (
                <>
                    <Button variant="contained" onClick={handleShowActions}>
                        {t('addPicture')}
                    </Button>
                    <Button variant="text" onClick={() => onComplete()}>
                        {t('later')}
                    </Button>
                </>
            )}

            {hasPreviousStep && (
                <StepBackButton onClick={onGoBack}>
                    <ArrowBack />
                </StepBackButton>
            )}
        </StepContent>
    );
};
