import { useStripe } from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { assertIsDefined } from '../../../helpers/commonHelpers';
import { getApiClient } from '../../../services/sharetribe/apiClients';
import { StripeAccount } from '../../../transactions/apiTypes';
import { useCurrentUser } from '../../../user/hooks/useUser';
import { StripeConnectFormData } from '../Stripe/StripeConnectForm.types';

export const useCreateStripeAccount = (onSuccess?: () => void) => {
    const { t } = useTranslation();
    const { data: user } = useCurrentUser();
    const stripeConnected = user?.stripeConnected;
    const queryClient = useQueryClient();
    const stripe = useStripe();

    const createStripeAccountFn = async (formData: StripeConnectFormData) => {
        if (!stripe || !user || stripeConnected) {
            throw new Error('Stripe is already connected, this function should not be called.');
        }

        // To stay PCI Compliant, account tokens are created client-side

        const { token, error } = await stripe.createToken('account', {
            business_type: 'individual',
            individual: {
                first_name: user.profile.firstName,
                last_name: user.profile.lastName,
                email: user.email,
            },
            tos_shown_and_accepted: true,
        });

        if (error) {
            throw new Error(error.message);
        }

        assertIsDefined(token, 'Account token is not defined');

        const accountToken = token.id;
        const url = '/account';
        const { data } = await getApiClient('stripe').post<StripeAccount>(url, { ...formData, accountToken });

        return data;
    };

    return useMutation(createStripeAccountFn, {
        onSuccess: (stripeAccount) => {
            queryClient.setQueryData(['stripe-account'], () => stripeAccount);

            if (onSuccess) {
                onSuccess();
            }
        },
        onError: (err: any) => {
            if (err.message) {
                const msg = t(err.message);
                toast.error(msg);
            } else {
                const msg = t('unexpectedError');
                toast.error(msg);
            }
        },
    });
};
