import i18next, { TOptions } from 'i18next';
import { Listing, ExtendedListing, ListingPreview } from '../../types/apiTypes';
import { capitalizeString, getSizeLabel } from '../../helpers/commonHelpers';

export const isListingPreview = (listing: Listing | ExtendedListing | ListingPreview): listing is ListingPreview => 'preview' in listing;
export const isExtendedListing = (listing: Listing | ExtendedListing | ListingPreview): listing is ExtendedListing =>
    'author' in listing && !('preview' in listing);

export const getListingTitle = <T extends { brand?: string; category?: string; size?: string }>(listingData: T, tOptions?: TOptions): string => {
    return [listingData.brand, listingData.category, getSizeLabel(listingData.size)]
        .filter(Boolean)
        .map((attribute) => capitalizeString(i18next.t(attribute || '', tOptions)))
        .join(', ');
};
