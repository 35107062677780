import React, { useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, useTheme } from '@mui/material';
import { Spoiler } from '@mantine/core';

interface ShowMoreProps {
    value: React.ReactNode;
    maxHeight?: number;
    rows?: number | null;
    typographyProps?: React.ComponentProps<typeof Typography>;
}

export const ShowMore: React.FC<ShowMoreProps> = ({ maxHeight = 125, rows, value, typographyProps }) => {
    const { t } = useTranslation();
    const theme = useTheme();

    const [contentMaxHeight, setContentMaxHeight] = useState(rows ? 0 : maxHeight);
    const hiddenDiv = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        if (rows && hiddenDiv.current) {
            const rects = hiddenDiv.current.getClientRects();
            let height = 0;
            for (let i = 0; i < Math.min(rects.length, rows); i++) {
                height += rects[i].height;
            }
            setContentMaxHeight(height);
        }
    }, [value, rows]);

    if (!value) {
        return null;
    }

    const spoilerProps = {
        maxHeight: contentMaxHeight,
        showLabel: t('showMore'),
        hideLabel: t('showLess'),
        transitionDuration: 500,
    };

    const typography = (
        <Typography variant="caption" sx={{ fontSize: '0.85rem' }} {...typographyProps}>
            {value}
        </Typography>
    );

    return (
        <Box
            sx={{
                width: '100%',
                '& .mantine-Spoiler-control': {
                    color: theme.palette.text.primary,
                    opacity: 0.5,
                    fontSize: '12px',
                },
            }}
        >
            <div style={{ position: 'fixed', visibility: 'hidden', whiteSpace: 'pre-wrap' }}>
                <Typography ref={hiddenDiv} variant="caption" sx={{ fontSize: '0.85rem' }} {...typographyProps}>
                    {value}
                </Typography>
            </div>

            {rows && maxHeight && <Spoiler {...spoilerProps}>{typography}</Spoiler>}
            {!rows && <Spoiler {...spoilerProps}>{typography}</Spoiler>}
        </Box>
    );
};
