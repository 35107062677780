import { Box, Button, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { StepBackButton, StepContent, StepContentContainer } from '../LenderOnboarding.components';
import { BaseStepProps } from '../LenderOnboarding.types';
import { useTranslation } from 'react-i18next';
import { StaggerList, StaggerListElement } from '../../../animations/components/Stagger';
import { User } from '../../../types/apiTypes';
import { useEffect, useState } from 'react';
import { ArrowBack, Notifications } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { PushNotifications } from '@capacitor/push-notifications';
import { useConfirm } from '../../../context/confirm';
import { NativeSettings, AndroidSettings, IOSSettings } from 'capacitor-native-settings';
import { noop } from '../../../helpers/commonHelpers';
import { useAppActiveState } from '../../../hooks/useAppActiveState';

type PermissionState = 'granted' | 'denied' | null;

interface LendingDescriptionStepProps extends BaseStepProps {
    user: User;
}

export const NotificationPermissionsStep: React.FC<LendingDescriptionStepProps> = ({ user, onComplete, loading, onGoBack, hasPreviousStep }) => {
    const [permissionState, setPermissionState] = useState<PermissionState>(null);
    const [clickTarget, setClickTarget] = useState<'allowNotifications' | 'later' | 'continue'>();
    const { t } = useTranslation();

    const { confirm } = useConfirm();

    const requestPermissionsAndNotify = async () => {
        let permStatus = await PushNotifications.checkPermissions();

        if (permStatus.receive === 'prompt' || permStatus.receive === 'prompt-with-rationale') {
            permStatus = await PushNotifications.requestPermissions();
        }

        if (permStatus.receive === 'denied' || permStatus.receive === 'prompt-with-rationale') {
            setPermissionState('denied');

            const title = t('notificationsDeniedModalTitle');
            const message = t('notificationsDeniedModalBody');
            const confirmText = t('goToSettings');
            const cancelText = t('dismiss');

            confirm({ title, message, confirmText, cancelText })
                .then(() => {
                    NativeSettings.open({
                        optionAndroid: AndroidSettings.ApplicationDetails,
                        optionIOS: IOSSettings.App,
                    });
                })
                .catch(noop);
        } else {
            onComplete(true);
        }
    };

    const initPermissionState = async () => {
        const { receive } = await PushNotifications.checkPermissions();

        if (receive === 'granted') {
            setPermissionState('granted');
        } else if (receive === 'denied') {
            setPermissionState('denied');
        }
    };

    const isActive = useAppActiveState();

    useEffect(() => {
        initPermissionState();
    }, [isActive]);

    return (
        <StepContent>
            <StepContentContainer>
                <StaggerList
                    custom={{ stagger: 0.3, delay: 0.3 }}
                    style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}
                >
                    <StaggerListElement>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '150px' }}>
                            <Notifications sx={{ fontSize: '80px', color: '#C8642E' }} />
                        </Box>
                    </StaggerListElement>

                    <StaggerListElement>
                        <Typography variant="h5" fontWeight="bold">
                            {t('notificationPermissionsStepTitle')}
                        </Typography>
                    </StaggerListElement>

                    <StaggerListElement>
                        <Typography variant="subtitle1">{t('notificationPermissionsStepDescription')}</Typography>
                    </StaggerListElement>
                </StaggerList>
            </StepContentContainer>

            {permissionState === 'granted' ? (
                <>
                    <LoadingButton
                        variant="contained"
                        loading={clickTarget === 'continue' && loading}
                        onClick={() => {
                            setClickTarget('continue');
                            onComplete(true);
                        }}
                    >
                        {t('continue')}
                    </LoadingButton>
                </>
            ) : (
                <>
                    <LoadingButton
                        variant="contained"
                        loading={clickTarget === 'allowNotifications' && loading}
                        onClick={() => {
                            setClickTarget('allowNotifications');
                            requestPermissionsAndNotify();
                        }}
                    >
                        {permissionState === null ? t('allowNotifications') : t('notificationSettings')}
                    </LoadingButton>

                    <LoadingButton
                        loading={clickTarget === 'later' && loading}
                        onClick={() => {
                            setClickTarget('later');
                            onComplete(false);
                        }}
                    >
                        {t('continue')}
                    </LoadingButton>
                </>
            )}

            {hasPreviousStep && (
                <StepBackButton onClick={onGoBack}>
                    <ArrowBack />
                </StepBackButton>
            )}
        </StepContent>
    );
};
