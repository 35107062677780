import { Box, FormHelperText, Typography } from '@mui/material';
import WoltLogo from '../../assets/images/wolt-logo.png';
import MatkahuoltoLogo from '../../assets/images/matkahuolto-logo.png';
import { LocalShipping, LocationCity, People } from '@mui/icons-material';
import { FormField, FormSelectItem, SelectItem } from '../../components/FormRenderer/FormRenderer.types';
import { TFunction, Trans, useTranslation } from 'react-i18next';
import { useFormContext, useWatch } from 'react-hook-form';
import { useDrawer } from '../../context/drawer';
import { useEffect, useState } from 'react';
import { usePrevious } from '../../hooks/usePrevious';
import { isEmpty, isEqual, pick } from 'lodash';
import { formatDateAndTimeRange, formatDateAndTimeLong } from '../../helpers/dateAndTimeHelpers';
import { Timeslot } from '../ViewListing/ListingFooter.helpers';
import { formatPrice } from '../../helpers/commonHelpers';
import { Booking, BookingDraft } from '../../store/bookingReducer';
import { getAvailabilityHoursString, getPriceForDeliveryMethod, isDeliveryMethodEnabled } from '../../helpers/delivery';
import { getAppCountryCallingCode } from '../../countryConfigs';
import { match } from 'ts-pattern';
import { FormField as DeliveryFormField, DeliveryOption } from '../../types/delivery';
import { isDate } from 'date-fns';
import { PickupPoint } from '../../queries/usePickupPoints';

interface RenderSettings {
    renderPrefix: boolean;
    renderSuffix: boolean;
}

export const renderSelectItem = (item: SelectItem, renderSettings: RenderSettings = { renderPrefix: true, renderSuffix: true }) => {
    const { renderPrefix, renderSuffix } = renderSettings;

    let gridTemplateColumns = '60px 4fr 50px';

    if (!renderPrefix) {
        gridTemplateColumns = '4fr 50px';
    }

    if (!renderSuffix) {
        gridTemplateColumns = '60px 4fr';
    }

    if (!renderPrefix && !renderSuffix) {
        gridTemplateColumns = '4fr';
    }

    return (
        <Box
            sx={{
                width: '100%',
                display: 'grid',
                gridTemplateColumns,
                alignItems: 'center',
                gap: '10px',
            }}
        >
            {renderPrefix && <span style={{ height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{item.prefix}</span>}

            <span style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <Typography variant="body1" sx={{ textAlign: 'start' }}>
                    {item.title}
                </Typography>
                <Typography variant="caption" sx={{ opacity: 0.7 }}>
                    {item.content}
                </Typography>
            </span>
            {renderSuffix && <span style={{ textAlign: 'end' }}>{item.suffix}</span>}
        </Box>
    );
};

export const renderDeliveryMethodValue = () => {
    return function DeliveryMethodValueComponent() {
        const { t } = useTranslation();
        const {
            watch,
            formState: { errors },
        } = useFormContext();
        const value = watch('deliveryMethod');

        const showHelperText = errors && !isEmpty(errors.deliveryMethod);

        if (!value) {
            return (
                <>
                    <Typography variant="caption" sx={{ opacity: 0.6 }}>
                        {t('selectDeliveryMethod')}
                    </Typography>

                    {showHelperText && (
                        <FormHelperText error sx={{ mt: 0 }}>
                            {t('missingDeliveryMethod')}
                        </FormHelperText>
                    )}
                </>
            );
        }

        return (
            <Box sx={{ display: 'flex', alignItems: 'center', height: '30px' }}>
                <Typography variant="body2">{t(`${value}Delivery`)}</Typography>

                {showHelperText && (
                    <FormHelperText error sx={{ mt: 0 }}>
                        {t('missingDeliveryMethod')}
                    </FormHelperText>
                )}
            </Box>
        );
    };
};

export const renderReturnMethodValue = () => {
    return function DeliveryMethodValueComponent() {
        const { t } = useTranslation();
        const {
            watch,
            formState: { errors },
        } = useFormContext();
        const value = watch('returnMethod');

        const showHelperText = errors && !isEmpty(errors.returnMethod);

        if (!value) {
            return (
                <>
                    <Typography variant="caption" sx={{ opacity: 0.6 }}>
                        {t('selectReturnMethod')}
                    </Typography>

                    {showHelperText && (
                        <FormHelperText error sx={{ mt: 0 }}>
                            {t('missingReturnMethod')}
                        </FormHelperText>
                    )}
                </>
            );
        }

        return (
            <Box sx={{ display: 'flex', alignItems: 'center', height: '30px' }}>
                <Typography variant="body2">{t(`${value}Return`)}</Typography>

                {showHelperText && (
                    <FormHelperText error sx={{ mt: 0 }}>
                        {t('missingReturnMethod')}
                    </FormHelperText>
                )}
            </Box>
        );
    };
};

export const renderPickupPointValue = (pickupPoints: PickupPoint[]) => {
    return function PickupPointValueComponent() {
        const { t } = useTranslation();
        const {
            watch,
            formState: { errors },
        } = useFormContext();
        const value = watch('pickupPoint');

        const showHelperText = errors && !isEmpty(errors.pickupPoint);

        if (!value) {
            return (
                <>
                    <Typography variant="caption" sx={{ opacity: 0.6 }}>
                        {t('selectPickupPoint')}
                    </Typography>

                    {showHelperText && (
                        <FormHelperText error sx={{ mt: 0 }}>
                            {t('missingPickupPoint')}
                        </FormHelperText>
                    )}
                </>
            );
        }

        const formatSelectedPickupPoint = () => {
            const pickupPoint = pickupPoints.find((point) => point.Id === parseInt(value));

            if (!pickupPoint) {
                return '';
            }

            return `${pickupPoint.Name}\n${pickupPoint.StreetAddress}, ${String(pickupPoint.PostalCode).padStart(5, '0')} ${pickupPoint.City}`;
        };

        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', minHeight: '30px', gap: '10px' }}>
                <Typography variant="body2" sx={{ textAlign: 'start' }}>
                    {formatSelectedPickupPoint()}
                </Typography>

                {showHelperText && (
                    <FormHelperText error sx={{ mt: 0 }}>
                        {t('missingPickupPoint')}
                    </FormHelperText>
                )}
            </Box>
        );
    };
};

export function renderDateTimeValue(controlName: string): () => React.ReactNode;
export function renderDateTimeValue(controlName: string, d: Date | null, interval: number): () => React.ReactNode;
export function renderDateTimeValue(controlName: string, d?: Date | null, interval?: number): () => React.ReactNode {
    return function DeliveryTimeValueComponent() {
        const { t } = useTranslation();
        const {
            control,
            formState: { errors },
        } = useFormContext();

        const value = useWatch({ control, name: controlName });

        const showHelperText = errors && !isEmpty(errors.deliveryTime);
        const formatString = (str: string) => `${str}${controlName.charAt(0).toUpperCase() + controlName.slice(1)}`;

        if (!value || !d) {
            return (
                <>
                    <Typography variant="caption" sx={{ opacity: 0.6 }}>
                        {t(formatString('select'))}
                    </Typography>

                    {showHelperText && (
                        <FormHelperText error sx={{ mt: 0 }}>
                            {t(formatString('missing'))}
                        </FormHelperText>
                    )}
                </>
            );
        }

        const getDisplayDate = () => {
            if (!d || !interval) {
                return formatDateAndTimeLong(value);
            }

            const displayDate = new Date(d);

            displayDate.setHours(value.getHours());
            displayDate.setMinutes(value.getMinutes());

            const formattedDate = formatDateAndTimeRange(displayDate, interval);
            return formattedDate;
        };

        const displayDate = getDisplayDate();

        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', minHeight: '30px', gap: '10px' }}>
                <Typography variant="body2" sx={{ textAlign: 'start' }}>
                    {displayDate}
                </Typography>
                {showHelperText && (
                    <FormHelperText error sx={{ mt: 0 }}>
                        {t(formatString('missing'))}
                    </FormHelperText>
                )}
            </Box>
        );
    };
}

export const renderFormSectionValue = ({
    placeholder,
    fieldName,
    summaryFields,
}: {
    placeholder: string;
    fieldName: string;
    summaryFields: DeliveryFormField[];
}) => {
    return function DeliveryDetailsValueComponent() {
        const { t } = useTranslation();
        const {
            control,
            formState: { errors },
        } = useFormContext();
        const { openDrawersState } = useDrawer();
        const value = useWatch({ control, name: fieldName });

        const [committedValue, setCommittedValue] = useState(value);
        const [tempValue, setTempValue] = useState(value);

        const deliveryDetailsDrawerOpen = openDrawersState[fieldName];
        const previousOpen = usePrevious(deliveryDetailsDrawerOpen);

        useEffect(() => {
            if (deliveryDetailsDrawerOpen) {
                setTempValue(value);
            } else if (previousOpen && !isEqual(tempValue, committedValue)) {
                setCommittedValue(tempValue);
            }
        }, [deliveryDetailsDrawerOpen, value, previousOpen]);

        const showHelperText = errors && !isEmpty(errors.deliveryDetails);

        const allValuesEmpty =
            !committedValue ||
            (!isDate(committedValue) &&
                Object.entries(committedValue)
                    .filter(([key]) => {
                        const shouldShowInSummary = summaryFields?.find((field) => field.controlName === key)?.summary === 'show';

                        return shouldShowInSummary;
                    })
                    .every(([key, val]) => {
                        if (key === 'phone') {
                            const countryCallingCode = getAppCountryCallingCode();
                            return val === countryCallingCode || !val;
                        }

                        return !val;
                    }));

        if (allValuesEmpty) {
            return (
                <>
                    <Typography variant="caption" sx={{ opacity: 0.6 }}>
                        {placeholder}
                    </Typography>

                    {showHelperText && (
                        <FormHelperText error sx={{ mt: 0 }}>
                            {t('missingOrInvalidDetails')}
                        </FormHelperText>
                    )}
                </>
            );
        }

        const summaryString = summaryFields
            ? summaryFields
                  .filter((field) => field.summary === 'show')
                  .map((sf) => committedValue[sf.controlName])
                  .join(', ')
            : value;

        const standaloneString = summaryFields
            ? summaryFields
                  .filter((field) => field.summary === 'standalone')
                  .map((sf) => committedValue[sf.controlName])
                  .join('\n')
            : '';

        return (
            <>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', minHeight: '30px', gap: '10px' }}>
                    {summaryString && (
                        <Typography variant="body2" sx={{ textAlign: 'start' }}>
                            {summaryString}
                        </Typography>
                    )}

                    {standaloneString && <Typography variant="body2">{standaloneString}</Typography>}
                </Box>
                {showHelperText && (
                    <FormHelperText error sx={{ mt: 0 }}>
                        {t('missingOrInvalidDetails')}
                    </FormHelperText>
                )}
            </>
        );
    };
};
export type BookingDetails = {
    booking: BookingDraft | Booking;
    listingDeliveryOptions: string[];
    deliveryMethod: string;
    returnMethod: string;
    timeslots: Timeslot[] | undefined;
};

export const getDeliveryMethodFormFields = (
    t: TFunction,
    parentControlName: string,
    deliveryMethod: string,
    listingDeliveryOptions: string[],
    deliveryOptions: DeliveryOption[],
): FormField[] => {
    const allDeliveryOptions = deliveryOptions.sort((a, b) => a.sortOrder - b.sortOrder).map((el) => el.type);
    const enabledDeliveryOptions = allDeliveryOptions.filter((option) => listingDeliveryOptions.includes(option));
    const availableDeliveryOptions = enabledDeliveryOptions.filter((option) => isDeliveryMethodEnabled(deliveryOptions, option));

    const isDeliveryMethodEnabledButNotAvailable = (option: DeliveryOption) => {
        return enabledDeliveryOptions.includes(option.type) && !availableDeliveryOptions.includes(option.type);
    };

    let selectItems: FormSelectItem[] = [];

    const createSelectItem = ({ value, prefix }: { value: DeliveryOption['type']; prefix: React.ReactNode }) =>
        ({
            type: 'item',
            value,
            title: t(`${value}Delivery`),
            content: t(`${value}DeliveryDescription`),
            disabled: !availableDeliveryOptions.includes(value),
            prefix,
            suffix: (
                <Typography variant="body2" fontWeight="bold" textAlign="end">
                    {formatPrice(getPriceForDeliveryMethod(deliveryOptions, value))}
                </Typography>
            ),
        } as const);

    for (const option of enabledDeliveryOptions) {
        const prefix = match(option)
            .with('faceToFace', () => <People />)
            .with('showroom', () => <LocationCity />)
            .with('uber', () => <LocalShipping />)
            .with('wolt', () => <img src={WoltLogo} alt="Wolt Logo" style={{ height: '15px' }} />)
            .with('matkahuolto', () => <img src={MatkahuoltoLogo} alt="Matkahuolto logo" style={{ height: '50px' }} />)
            .exhaustive();

        selectItems.push(createSelectItem({ value: option, prefix }));
    }

    selectItems = selectItems.sort((a, b) => {
        if (a.type === 'alert' || b.type === 'alert') {
            return 0;
        }

        if (a.disabled && !b.disabled) {
            return 1;
        }
        if (!a.disabled && b.disabled) {
            return -1;
        }
        return 0;
    });

    const atLeastOneOptionDisabled = allDeliveryOptions.some((option) => !availableDeliveryOptions.includes(option));

    if (atLeastOneOptionDisabled) {
        const getAlertText = () => {
            const showAlertForDeliveryOptions = deliveryOptions.filter((option) => option.showAlertIfDisabled);
            // Options enabled on listing level, but not for the selected dates
            const showLimitedOptionsAlert = showAlertForDeliveryOptions.some(isDeliveryMethodEnabledButNotAvailable);

            if (showLimitedOptionsAlert) {
                const noOptionsEnabled = availableDeliveryOptions.length === 0;

                const i18nKey = noOptionsEnabled ? 'noOptionsAvailable' : 'limitedOptionsAvailable';

                return (
                    <Trans
                        i18nKey={i18nKey}
                        components={{ b: <b />, i: <i />, h3: <h3 style={{ marginTop: '0' }} />, h4: <h4 />, h6: <h6 /> }}
                        values={{ openingHours: getAvailabilityHoursString(deliveryOptions, deliveryMethod, t) }}
                    />
                );
            }
        };

        const alertText = getAlertText();

        if (alertText) {
            selectItems.push({
                type: 'alert',
                severity: 'info',
                maxHeight: 120,
                rows: 5,
                content: alertText,
            });
        }
    }

    return [
        {
            type: 'select',
            controlName: 'deliveryMethod',
            label: t('deliveryMethod'),
            closeDrawerOnSelect: true,
            parentControl: parentControlName,
            renderItem: renderSelectItem,
            registerOptions: { required: true },
            items: selectItems,
        },
    ];
};
