/* eslint-disable max-len */
import { ResourceLanguage } from 'i18next';
import { showroomAddressCity, showroomAddressPostalCode, showroomAddressStreet, showroomDisplayNameFull } from '../constants';

type SupportedLanguage = 'en' | 'fi';

export const translations: Record<string, Record<SupportedLanguage, string>> = {
    welcome: {
        en: 'Welcome!',
        fi: 'Tervetuloa',
    },
    helloUser: {
        en: 'Hello {{name}}.',
        fi: 'Hei {{name}}.',
    },
    chatWith: {
        en: 'Chat with {{name}}',
        fi: 'Keskustelu käyttäjän {{name}} kanssa',
    },
    loading: {
        en: 'Loading...',
        fi: 'Ladataan...',
    },
    noItems: {
        en: 'No items found',
        fi: 'Tuloksia ei löytynyt',
    },
    searchRobes: {
        en: 'Search ROBES',
        fi: 'Etsi Robesista',
    },
    searchWardrobes: {
        en: `Search for users' wardrobes`,
        fi: 'Etsi käyttäjien vaatekaappeja',
    },
    userProfile: {
        en: 'User profile',
        fi: 'Käyttäjän profiili',
    },
    noMoreItems: {
        en: 'No more items to load',
        fi: 'Ei enempää tuloksia',
    },
    outdatedVersionTitle: {
        en: 'Update your app',
        fi: 'Päivitä sovellus',
    },
    hideUiPrimary: {
        en: 'Hide interface on scroll',
        fi: 'Piilota käyttöliittymä selatessa',
    },
    hideUiSecondary: {
        en: 'Hides the topbar and footer when scrolling down on Browse-page',
        fi: 'Piilottaa ylä- ja alapalkin listaus-sivulla sivua selatessa',
    },
    allowMarketingPrimary: {
        en: 'Allow marketing notifications',
        fi: 'Salli markkinointi-ilmoitukset',
    },
    outdatedVersionDescription: {
        en: 'Please update to the latest version to continue using ROBES.',
        fi: 'Päivitä sovellus uusimpaan versioon jatkaaksesi ROBESin käyttöä.',
    },
    newVersionAvailableTitle: {
        en: `New ROBES version available!`,
        fi: `Uusi ROBES versio ladattavissa!`,
    },
    buildVersion: {
        en: 'Build version',
        fi: 'Sovelluksen versio',
    },
    newVersionAvailableDescription: {
        en: 'Press the button below to get access to the latest features and improvements.',
        fi: 'Paina allaolevaa painiketta saadaksesi käyttöösi uusimmat ominaisuudet ja parannukset.',
    },
    noMoreConversations: {
        en: 'No more conversations found',
        fi: 'Lisää keskusteluja ei löytynyt',
    },
    browseAll: {
        en: 'Browse all items',
        fi: 'Katso kaikki tuotteet',
    },
    beginningOfMessageHistory: {
        en: 'Beginning of your message history with {{name}}',
        fi: 'Viestihistoriasi alku käyttäjän {{name}} kanssa',
    },
    loadNextPage: {
        en: 'Next page',
        fi: 'Seuraava sivu',
    },
    messageReadTooltip: {
        en: `Message was read at: {{time}}`,
        fi: `Viesti luettu: {{time}}`,
    },
    email: {
        en: 'Email',
        fi: 'Sähköposti',
    },
    name: {
        en: 'Name',
        fi: 'Nimi',
    },
    password: {
        en: 'Password',
        fi: 'Salasana',
    },
    enterCurrentPassword: {
        en: 'Enter your password to confirm account deletion',
        fi: 'Syötä nykyinen salasanasi varmistaaksesi käyttäjän poiston',
    },
    createNewPassword: {
        en: 'Create new password',
        fi: 'Luo uusi salasana',
    },
    resendNewPasswordLink: {
        en: 'Resend password creation request',
        fi: 'Lähetä uusi salasanan luontipyyntö',
    },
    newPasswordLinkSent: {
        en: 'A link has been sent to your email for creating a new password. The link is valid for 1 hour.',
        fi: 'Linkki uuden salasanan luomista varten on lähetetty sähköpostiisi. Linkki on voimassa 1 tunnin ajan.',
    },
    iHaveNoRobesPassword: {
        en: 'I do not have a password for my ROBES account',
        fi: 'Minulla ei ole salasanaa ROBES-käyttäjätililleni',
    },
    iHaveNoRobesPasswordDescription: {
        en: 'If you have signed up using a 3rd party service (Google, Apple), then you need to create a new password to complete account deletion.',
        fi: 'Jos olet rekisteröitynyt käyttäen kolmannen osapuolen palvelua (Google, Apple), sinun tulee luoda uusi salasana käyttäjätilin poistamiseksi.',
    },
    currentPassword: {
        en: 'Current password',
        fi: 'Nykyinen salasana',
    },
    enterPassword: {
        en: 'Enter password',
        fi: 'Syötä salasana',
    },
    requestDeletionDialogTitle: {
        en: 'Request account deletion',
        fi: 'Pyydä käyttäjätilin poistoa',
    },
    iHaveNotSetAPassword: {
        en: 'I have not set a password for my account',
        fi: 'En ole asettanut salasanaa käyttäjätililleni',
    },
    ifNoPassword: {
        en: `If you have not set a password for your account, you can request a link that you can use for deleting your account.
        
        The link will be sent to your email.
        
        The link will say that you have requested a password reset, but the link will actually be used for deleting your account.
        
        After clicking the link, you will be asked for one final confirmation before your account is deleted.`,
        fi: `Jos et ole asettanut salasanaa käyttäjätilillesi, voit pyytää linkin, jota voit käyttää käyttäjätilisi poistamiseen.
        
        Linkki lähetetään sähköpostiisi.
        
        Linkissä lukee, että olet pyytänyt salasanan palautusta, mutta linkkiä käytetään oikeasti käyttäjätilisi poistamiseen.
        
        Linkkiä klikattuasi sinulta kysytään vielä yksi vahvistus ennen kuin käyttäjätilisi poistetaan lopullisesti.`,
    },
    request: {
        en: 'Request',
        fi: 'Lähetä',
    },
    confirmPassword: {
        en: 'Confirm password',
        fi: 'Vahvista salasana',
    },
    newPassword: {
        en: 'New password',
        fi: 'Uusi salasana',
    },
    confirmNewPassword: {
        en: 'Confirm new password',
        fi: 'Vahvista uusi salasana',
    },
    firstname: {
        en: 'First name',
        fi: 'Etunimi',
    },
    lastname: {
        en: 'Last name',
        fi: 'Sukunimi',
    },
    currency: {
        en: 'Currency',
        fi: 'Valuutta',
    },
    dob: {
        en: 'Date of birth',
        fi: 'Syntymäaika',
    },
    postalCode: {
        en: 'Postal code',
        fi: 'Postinumero',
    },
    zipCode: {
        en: 'ZIP code',
        fi: 'Postinumero',
    },
    city: {
        en: 'City',
        fi: 'Kaupunki',
    },
    state: {
        en: 'State',
        fi: 'Osavaltio',
    },
    street: {
        en: 'Street',
        fi: 'Katuosoite',
    },
    street2: {
        en: 'Apartment, Suite, Unit etc.',
        fi: 'Asunto, kerros, huoneisto jne.',
    },
    phoneNumber: {
        en: 'Phone number',
        fi: 'Puhelinnumero',
    },
    forgotPassword: {
        en: 'Forgot your password?',
        fi: 'Unohditko salasanasi?',
    },
    closeApp: {
        en: 'Close app?',
        fi: 'Suljetaanko sovellus?',
    },
    logout: {
        en: 'Log out',
        fi: 'Kirjaudu ulos',
    },
    privacyPolicy: {
        en: 'Privacy Policy',
        fi: 'Yksityisyyskäytäntö',
    },
    identification: {
        en: 'Identity verification',
        fi: 'Tunnistautuminen',
    },
    signicatPolicyConsent: {
        en: 'By proceeding, you will be redirected to the verification service provided by Signicat. More information in our <button>privacy policy</button>.',
        fi: 'Jatkaessasi sinut ohjataan Signicatin tarjoamaan vahvistuspalveluun. Lisää tietoa aiheesta löydät <button>palveluehdoistamme</button>.',
    },
    stripePolicyConsent: {
        en: 'By proceeding, you will be redirected to the verification service provided by Stripe. More information in our <button>privacy policy</button>.',
        fi: 'Jatkaessasi sinut ohjataan Stripen tarjoamaan vahvistuspalveluun. Lisää tietoa aiheesta löydät <button>palveluehdoistamme</button>.',
    },
    stripe_identity_requires_input_document_unverified_other: {
        en: 'The document you uploaded was not accepted by Stripe. Please try again.',
        fi: 'Stripe ei hyväksynyt lataamaasi dokumenttia. Yritä uudelleen.',
    },
    stripe_identity_requires_input_document_expired: {
        en: 'The document you uploaded has expired. Please try again.',
        fi: 'Lataamasi dokumentti on vanhentunut. Yritä uudelleen.',
    },
    stripe_identity_requires_input_unknown: {
        en: 'Identification failed for an unknown reason, please try again or if this issue persists, contact support.',
        fi: 'Tunnistautuminen epäonnistui tuntemattomasta syystä, yritä uudelleen tai jos ongelma jatkuu, ota yhteyttä asiakaspalveluun.',
    },
    stripeIdentityRequiresInput: {
        en: `<strong>Identification requires additional information for the following reason:</strong> <br/><br/>{{reason}}`,
        fi: `<strong>Tunnistautuminen vaatii lisätietoja seuvaavasta syystä:</strong> <br/><br/>{{reason}}`,
    },
    stripeTakingLongerThanExpected: {
        en: 'Stripe is taking longer than expected to verify your identity. You can try to refresh the page or try again later. If this issue persists, please contact support.',
        fi: 'Stripen tunnistautuminen kestää odotettua kauemmin. Voit yrittää päivittää sivun tai yrittää myöhemmin uudelleen. Jos ongelma jatkuu, ota yhteyttä asiakaspalveluun.',
    },
    selectCountry: {
        en: 'Select country',
        fi: 'Valitse maa',
    },
    refresh: {
        en: 'Refresh',
        fi: 'Päivitä',
    },
    stripeIdentityPendingResponse: {
        en: 'Waiting for response from Stripe...',
        fi: 'Odotetaan vastausta Stripeltä...',
    },
    verifyIdentity: {
        en: 'Verify your identity',
        fi: 'Vahvista henkilöllisyytesi',
    },
    verifyIdentityShort: {
        en: 'Verify identity',
        fi: 'Vahvista henkilöllisyys',
    },
    verifyIdentityDescription: {
        en: 'Before you start renting, we kindly ask you to verify your identity to ensure a safe experience for you and our community 🧡',
        fi: 'Ennen kuin aloitat vuokraamisen, pyydämme sinua ystävällisesti vahvistamaan henkilöllisyytesi varmistaaksemme turvallisen kokemuksen sinulle ja yhteisöllemme 🧡',
    },
    verificationRequired: {
        en: 'Verify identity to proceed',
        fi: 'Vahvista henkillöllisyys jatkaaksesi',
    },
    verificationSuccess: {
        en: 'Identity verified!',
        fi: 'Henkilöllisyys tunnistettu!',
    },
    verificationError: {
        en: 'Something went wrong with the identification!',
        fi: 'Jokin meni pieleen tunnistautumisessa',
    },
    retryVerification: {
        en: 'Retry verification',
        fi: 'Yritä uudelleen',
    },
    chooseYourLocation: {
        en: 'Choose your location',
        fi: 'Valitse sijaintisi',
    },
    ageError: {
        en: '',
        fi: '',
    },
    verificationAborted: {
        en: `Identification was cancelled`,
        fi: `Tunnistautuminen keskeytettiin`,
    },
    loggedOut: {
        en: 'Logged out succesfully.',
        fi: 'Kirjauduttu ulos onnistuneesti.',
    },
    orIfThatDoesNotWork: {
        en: `Or if that doesn't work`,
        fi: 'Tai vaihtoehtoisesti',
    },
    loginExpired: {
        en: 'Login expired, please log in again.',
        fi: 'Kirjautuminen vanhentui, kirjaudu sisään uudelleen.',
    },
    invalidLogin: {
        en: 'Invalid login, please try logging in again.',
        fi: 'Virheellinen kirjautuminen, kirjaudu sisään uudestaan.',
    },
    tryAgain: {
        en: 'Try again',
        fi: 'Yritä uudelleen',
    },
    signupSuccess: {
        en: 'Signed up succesfully!',
        fi: 'Rekisteröinti onnistui!',
    },
    createNewAccount: {
        en: 'Create new account',
        fi: 'Luo uusi käyttäjä',
    },
    emailVerificationSuccess: {
        en: 'Email verification success!',
        fi: 'Sähköpostin todennus onnistui!',
    },
    verificationSuccessText: {
        en: 'Verification success! You can close this window or continue to the front page using the button below.',
        fi: 'Todennus onnistui! Voit sulkea tämän ikkunan tai jatkaa etusivulle allaolevasta linkistä.',
    },
    verificationSuccessTextMobile: {
        en: 'Verification success! You can continue to the front page using the button below.',
        fi: 'Todennus onnistui! Voit jatkaa etusivulle allaolevasta linkistä.',
    },
    verificationFailedText: {
        en: 'Verification failed, has the link expired? You can re-send the verification link from the application.',
        fi: 'Todennus epäonnistui, onko linkki vanhentunut? Voit lähettää todennuslinkin uudelleen sovelluksesta.',
    },
    verifyOtp: {
        en: 'Verification',
        fi: 'Todennus',
    },
    enterOtp: {
        en: 'If you have received an invitation code to the Robes closed beta to your email at <1>{{email}}</1>, enter it here.',
        fi: 'Jos olet saanut sähköpostiisi <1>{{email}}</1> Robesin suljetun betan kutsukoodin, syötä se tähän.',
    },
    enterOtpDetails: {
        en: 'If you have not yet received your invite code, join our <1> waitlist.</1> We will send you an invite before our public launch.',
        fi: 'Jos et ole vielä saanut kutsukoodiasi, liity <1> odotuslistallemme. </1> Lähetämme koodin sinulle ennen palvelun lanseerausta.',
    },
    emailVerificationPending: {
        en: `We have sent an email with a verification link to your email address, follow the link to verify your account.`,
        fi: 'Lähetimme sähköpostiisi linkin, jonka avulla voit vahvistaa käyttäjätunnuksesi.',
    },
    pricePrefix: {
        en: 'From',
        fi: 'Alk.',
    },
    pricingSummary: {
        en: 'Pricing summary',
        fi: 'Hinnoittelun yhteenveto',
    },
    otpSuccess: {
        en: 'Verification success, welcome to Robes!',
        fi: 'Todentaminen onnistui, tervetuloa Robesiin!',
    },
    otpError: {
        en: 'Verification error, check your invitation code',
        fi: 'Todennus epäonnistui, tarkista koodi',
    },
    discountFetchError: {
        en: 'Fetching pricing information failed, please try again later',
        fi: 'Hinnoittelutietojen haku epäonnistui, yritä myöhemmin uudelleen',
    },
    listingImageSizeError: {
        en: 'Upload failed: file size too big! Biggest supported file size is 16 MB.',
        fi: 'Kuvan lataus epäonnistui: kuvan tiedostokoko on liian suuri! Suurin sallittu kuvan tiedostokoko on 16 MB.',
    },
    didNotReceiveEmail: {
        en: 'Did not receive the email? Press <1> here </1> to re-send',
        fi: 'Etkö saanut sähköpostiamme? Paina <1> tästä </1> niin lähetämme sen uudelleen',
    },
    otpExpiredOrInvalid: {
        en: 'The code you provided is either invalid or expired, please try again.',
        fi: 'Syöttämäsi koodi on joko vanhentunut tai väärä, yritä uudestaan.',
    },
    emailResent: {
        en: 'Email sent',
        fi: 'Sähköposti lähetetty',
    },
    passwordResetRequestSent: {
        en: `We have sent an email with a password reset link to your email address.`,
        fi: 'Lähetimme sähköpostiisi linkin, jonka avulla voit palauttaa salasanasi.',
    },
    passwordResetSuccess: {
        en: 'Your password was reset succesfully. You may continue to the login page using the button below.',
        fi: 'Salasana vaihdettu onnistuneesti. Jatka kirjautumiseen allaolevasta painikkeesta.',
    },
    createPassword: {
        en: 'Create password',
        fi: 'Salasanan luonti',
    },
    createPasswordSuccess: {
        en: 'Your password was created succesfully.',
        fi: 'Salasana luotu onnistuneesti.',
    },
    enterEmailPrompt: {
        en: `Enter your <strong>email address</strong> that you used to register. We'll send you an email with a link to reset your password.`,
        fi: `Syötä <strong>sähköpostiosoitteesi</strong> jota käytät kirjautumiseesi. Lähetämme sinulle sähköpostiisi linkin jonka avulla voit luoda uuden salasanan.`,
    },
    unexpectedError: {
        en: 'Error, try again later',
        fi: 'Virhe, yritä myöhemmin uudelleen',
    },
    emailError: {
        en: 'Check email address. E.g. firstname.lastname@provider.com',
        fi: 'Tarkista sähköposti. Esim. etunimi.sukunimi@tarjoaja.fi',
    },
    firstnameError: {
        en: 'Check firstname. E.g. Matti',
        fi: 'Tarkista etunimi. Esim. Matti',
    },
    lastnameError: {
        en: 'Check lastname. E.g. Meikäläinen',
        fi: 'Tarkista etunimi. Esim. Meikäläinen',
    },
    dobError: {
        en: 'Check date of birth',
        fi: 'Tarkista syntymäaika',
    },
    phoneError: {
        en: 'Check phone number',
        fi: 'Tarkista puhelinnumero',
    },
    cityError: {
        en: 'Check city',
        fi: 'Tarkista kaupunki',
    },
    passwordError: {
        en: 'Check password. Must be at least 8 characters long.',
        fi: 'Tarkista salasana. Salasanan tulee olla vähintään 8 merkkiä pitkä.',
    },
    submitError: {
        en: 'Please check your input information and try again.',
        fi: 'Tarkista antamasi tiedot ja yritä uudestaan.',
    },
    confirmPasswordError: {
        en: 'Passwords do not match.',
        fi: 'Salasanat eivät täsmää.',
    },
    signUp: {
        en: 'Sign up',
        fi: 'Rekisteröidy',
    },
    signUpToRent: {
        en: 'Sign up to rent this item',
        fi: 'Rekisteröidy ja vuokraa',
    },
    signUpOrLogin: {
        en: 'Login or sign up',
        fi: 'Kirjaudu sisään tai rekisteröidy',
    },
    signUpCTADescription: {
        en: 'Join our community and rent clothes, bags, jewellery and shoes for any occasion.',
        fi: 'Liity yhteisöömme ja vuokraa vaatteita, laukkuja, koruja ja kenkiä arkeen ja juhlaan.',
    },
    userExistsError: {
        en: 'User already exists with this email. Try logging in.',
        fi: 'Tälle sähköpostille on jo luotu käyttäjätili. Kokeile kirjautua sisään.',
    },
    accountMissing: {
        en: "Don't have an account?",
        fi: 'Ei käyttäjätunnusta?',
    },
    signIn: {
        en: 'Sign in',
        fi: 'Kirjaudu sisään',
    },
    invalidCredentials: {
        en: 'Invalid credentials',
        fi: 'Virheelliset kirjautumistiedot',
    },
    profileFetchError: {
        en: 'Fetching profile failed, please try again later',
        fi: 'Profiilin haku epäonnistui, yritä myöhemmin uudelleen',
    },
    idpLoginError: {
        en: 'Login using {{provider}} failed. Try another login method.',
        fi: '{{provider}}-kirjautuminen epäonnistui. Valitse toinen kirjautumistapaa.',
    },
    continueWithGoogle: {
        en: 'Continue with Google',
        fi: 'Jatka Google-tunnuksilla',
    },
    continueWithApple: {
        en: 'Continue with Apple',
        fi: 'Jatka Apple-tunnuksilla',
    },
    continueWithEmail: {
        en: 'Continue with Email',
        fi: 'Jatka sähköpostiosoitteella',
    },
    continueWithoutSignUp: {
        en: 'Skip',
        fi: 'Ohita',
    },
    deleteAccount: {
        en: 'Delete Account',
        fi: 'Poista käyttäjätili',
    },
    deleteAccountFailed: {
        en: 'Failed to delete account. If this problem persists, please contact support.',
        fi: 'Käyttäjätilin poisto epäonnistui. Jos ongelma jatkuu, ota yhteyttä asiakaspalveluun.',
    },
    deleteAccountSuccess: {
        en: 'Account deleted succesfully.',
        fi: 'Käyttäjätili poistettu onnistuneesti.',
    },
    removalOfPersonalInformation: {
        en: 'Removal of personal information',
        fi: 'Henkilötietojen poisto',
    },
    or: {
        en: 'or',
        fi: 'tai',
    },
    mine: {
        en: 'Own',
        fi: 'Omat',
    },
    saved: {
        en: 'Saved',
        fi: 'Tallennetut',
    },
    favorites: {
        en: 'Favorites',
        fi: 'Suosikit',
    },
    goBack: {
        en: 'Go back',
        fi: 'Takaisin',
    },
    back: {
        en: 'Back',
        fi: 'Takaisin',
    },

    rentingPeriod: {
        en: 'Rental period',
        fi: 'Vuokra-aika',
    },
    selectRentingPeriod: {
        en: 'Select rental period',
        fi: 'Valitse vuokrausjakso',
    },
    coveredByFitGuarantee: {
        en: 'This listing is covered by Fit guarantee.',
        fi: 'Tämä tuote sisältää ilmaisen sovituksen.',
    },
    fitGuarantee: {
        en: 'Fit guarantee',
        fi: 'Sopivuustakuu',
    },
    woltDeliveryAvailableForListing: {
        en: 'delivery available for this listing',
        fi: 'toimitus saatavilla tälle tuotteelle',
    },
    woltDeliveryInfoTitle: {
        en: 'Home Delivery Info',
        fi: 'Wolt Toimitus',
    },
    woltDeliveryInfoHowItWorksTitle: {
        en: 'How it works',
        fi: 'Kuinka se toimii',
    },
    woltDeliveryInfoHowItWorksDescription: {
        en: 'Home delivery is available for this listing. You can order the item to your location.',
        fi: 'Kotiinkuljetus on saatavilla tälle tuotteelle. Voit tilata tuotteen omaan sijaintiisi.',
    },
    woltDeliveryInfoReturning: {
        en: 'Returning the item',
        fi: 'Tuotteen palautus',
    },
    woltDeliveryInfoReturningDescription: {
        en: 'The renter is responsible for returning the item to the ROBES showroom.',
        fi: 'Vuokraaja vastaa tuotteen palautuksesta ROBESin showroomille.',
    },
    postalCodeCheckerLabel: {
        en: 'Enter postal code to check Home delivery availability to your location',
        fi: 'Syötä postinumero tarkistaaksesi Wolt-toimituksen saatavuuden omaan sijaintiisi',
    },
    postalCodeNotAccepted: {
        en: 'Sorry, Home delivery is not available to this location yet!',
        fi: 'Valitettavasti Kotiinkuljetus ei ole vielä saatavilla tähän sijaintiin!',
    },
    postalCodeAccepted: {
        en: 'Home Delivery is available to this location!',
        fi: 'Kotiinkuljetus on saatavilla tähän sijaintiin!',
    },
    postalCodePlaceholder: {
        en: 'E.g 00210',
        fi: 'Esim. 00210',
    },
    minimumDays: {
        en: 'Minimum {{amount}} days',
        fi: 'Vähintään {{amount}} päivää',
    },
    minimumDaysRental: {
        en: 'Minimum rental length is {{ amount }} days.',
        fi: 'Minimivuokrausaika on {{ amount }} päivää.',
    },
    minRentalLength: {
        en: 'Minimum rental length is {{ amount }} days',
        fi: 'Pienin mahdollinen vuokra-aika on {{ amount }} päivää',
    },
    bookingBreakdown: {
        en: 'Booking breakdown',
        fi: 'Varauksen erittely',
    },
    transactionCancelled: {
        en: 'Transaction was cancelled',
        fi: 'Tapahtuma peruutettiin',
    },
    rentalLengthDiscount: {
        en: 'Discount (Rental length)',
        fi: 'Alennus (pituus)',
    },
    total: {
        en: 'Total',
        fi: 'Yhteensä',
    },
    noLoginFound: {
        en: 'Please login again',
        fi: 'Kirjaudu sisään uudestaan',
    },
    emailVerifiedSuccessfully: {
        en: 'Email verified successfully!',
        fi: 'Sähköposti vahvistettu onnistuneesti!',
    },

    // Existing lender onboarding

    existingLenderOnboardingFirstStepTitle: {
        en: 'New delivery options are available!',
        fi: 'Uusia toimitusvaihtoehtoja on tarjolla!',
    },
    existingLenderOnboardingFirstStepDescription: {
        en: `Take a minute to update your lender profile to get the most out of your wardrobe!`,
        fi: `Päivitä vuokraajaprofiilisi saadaksesi enemmän irti vaatekaapistasi!`,
    },
    newLenderOnboardingFirstStepTitle: {
        en: 'List your wardrobe and earn on your sense of fashion!',
        fi: 'Listaa vaatekaappisi ja ansaitse tyylitajullasi!',
    },
    newLenderOnboardingFirstStepDescription: {
        en: `Let's set up your lender profile and add your first items to get you started!`,
        fi: `Aloitetaan vuokraajaprofiilisi luominen ja ensimmäisten tuotteiden lisääminen!`,
    },
    lenderOnboardingAddContactDetailsTitle: {
        en: 'Add your contact details',
        fi: 'Lisää yhteystietosi',
    },
    lenderOnboardingDeliveryPreferencesDescription: {
        en: `<b>Note:</b> Your address, email and phone number will NOT be shared with other users without your permission.\n\nYour city will be shown in your profile and listings to help local users discover your wardrobe.`,
        fi: `<b>Huom:</b> Osoitteesi, sähköpostisi ja puhelinnumerosi eivät näy muille käyttäjille ilman lupaasi.\n\nKaupunkisi näytetään profiilissasi ja tuotteissasi auttaakseen paikallisia käyttäjiä löytämään vaatekaappisi.`,
    },
    lenderOnboardingDeliveryOptionsTitle: {
        en: 'Select delivery options offered to your renters',
        fi: 'Valitse toimitusvaihtoehdot, jotka tarjoat vuokraajillesi',
    },
    lenderOnboardingMatkahuoltoDescription: {
        en: 'Ship your item with Matkahuolto to over 2500 locations in Finland.',
        fi: 'Lähetä tuotteesi Matkahuollon kautta yli 2500 noutopisteeseen ympäri Suomen.',
    },
    lenderOnboardingDeliveryOptionsMatkahuolto: {
        en: 'Parcel delivery',
        fi: 'Pakettitoimitus',
    },
    lenderOnboardingDeliveryOptionsFaceToFace: {
        en: 'Meet with lender',
        fi: 'Tapaaminen vuokraajan kanssa',
    },
    lenderOnboardingDeliveryOptionsShowroomAndWolt: {
        en: 'Showroom pickup & home delivery',
        fi: 'Showroom-nouto & kotiinkuljetus',
    },
    lenderOnboardingDeliveryOptionsUber: {
        en: 'Uber delivery',
        fi: 'Uber-toimitus',
    },
    lenderOnboardingDeliveryOptionsPickAtLeastOne: {
        en: 'Pick at least one delivery option!',
        fi: 'Valitse vähintään yksi toimitusvaihtoehto!',
    },
    lenderOnboardingPickupPointsPickAtLeastOne: {
        en: 'Pick at least one service point!',
        fi: 'Valitse vähintään yksi palvelupiste!',
    },
    lenderOnboardingFaceToFaceDescription: {
        en: 'You and the renter agree on the time and place to pickup and drop-off the rental item.',
        fi: 'Sovitte vuokraajan kanssa noudon ja palautuksen ajankohdasta ja paikasta.',
    },
    lenderOnboardingShowroomAndWoltDescription: {
        en: `Drop off your item at the ROBES showroom, Fabianinkatu 4, and ROBES will handle delivery and return with the renter on your behalf. Once your item is returned, you can pick it up from the showroom. Home delivery from showroom to renter is handled by Wolt.`,
        fi: `Jätä tuotteesi ROBES Showroomille (Fabianinkatu 4) ennen vuokrauksen alkua. ROBES huolehtii toimituksesta ja palautuksesta vuokraajan kanssa puolestasi. Vuokrauksen jälkeen voit noutaa tuotteesi showroomilta. Lisäksi Wolt tarjoaa kotiinkuljetuksen showroomilta suoraan vuokraajalle.`,
    },
    lenderOnboardingUberDescription: {
        en: 'Ship your item with Uber to the renter.',
        fi: 'Lähetä tuotteesi Uberilla vuokraajalle.',
    },
    lenderOnboardingDeliveryOptionsShowroomAndWoltDisabled: {
        en: 'Currently available in Helsinki area only',
        fi: 'Tällä hetkellä saatavilla vain Helsingin alueella',
    },
    lenderOnboardingDeliveryOptionsTip: {
        en: `<b>Tip: </b>The more options you offer, the more likely your items are rented!`,
        fi: `<b>Vinkki: </b>Mitä enemmän toimitusvaihtoehtoja tarjoat, sitä todennäköisemmin tuotteesi vuokrataan!`,
    },
    lenderOnboardingPickupPointsTitle: {
        en: 'Select preferred Matkahuolto service point for parcel delivery returns',
        fi: 'Valitse Matkahuollon palvelupiste, johon haluat palautukset',
    },
    lenderOnboardingPickupPointsAddressDisclaimer: {
        en: 'For parcel delivery rentals, Matkahuolto will return your rental item to the selected service point after the rental ends.',
        fi: 'Pakettitoimituksissa Matkahuolto palauttaa vuokrauksen päätyttyä tuotteesi valitsemaasi palvelupisteeseen.',
    },
    lenderOnboardingStripeConnectTitle: {
        en: 'Setup your Stripe account to receive payments',
        fi: 'Luo Stripe-tili vastaanottaaksesi maksuja',
    },
    lenderOnboardingStripeConnectDescription: {
        en: 'You will be redirected to Stripe, the platform powering safe payments on ROBES.',
        fi: 'Sinut ohjataan Stripeen, globaaliin maksupalveluntarjoajaan joka mahdollistaa turvalliset maksut ROBESissa.',
    },
    lenderOnboardingStripeRedirectingText: {
        en: 'Preparing your account. You will be redirected to Stripe shortly.',
        fi: 'Tiliäsi valmistellaan. Sinut ohjataan Stripe-palveluun hetken kuluttua.',
    },
    lenderOnboardingFinishBodyText: {
        en: 'Happy rentals! ❤️',
        fi: 'Onnea vuokrauksiin! ❤️',
    },
    lenderOnboardingFinishButtonText: {
        en: 'Start listing items',
        fi: 'Aloita tuotteiden lisääminen',
    },

    /**
     * Toasts
     */
    msgSendError: {
        en: 'Message sending failed, please try again later',
        fi: 'Viestin lähetys epäonnistui, yritä myöhemmin uudelleen',
    },
    addFavoriteError: {
        en: 'Could not add to favorites, please try again later',
        fi: 'Suosikkeihin lisääminen epäonnistui, yritä myöhemmin uudelleen',
    },
    removeFavoriteError: {
        en: 'Could not remove from favorites, please try again later',
        fi: 'Suosikeista poistaminen epäonnistui, yritä myöhemmin uudelleen',
    },
    userCreated: {
        en: 'New user created! You can now log in to your account.',
        fi: 'Uusi käyttäjä luotu! Voit nyt kirjautua sisään sovellukseen.',
    },
    tosAgreementText: {
        en: 'By signing up, you agree to our <1> Terms of Service </1>',
        fi: 'Rekisteröitymällä hyväksyt <1> käyttöehtomme </1> ',
    },
    fastFashionStatement: {
        en: `We believe in quality fashion and welcome all high-end and premium brands. To ensure the best experience for everyone, we do not accept <button> fast fashion brands </button>. Unsure if your item meets our brand standards? No problem! Just submit it and we'll happily review it for you.`,
        fi: `Vaatekaappisi high-end ja laatuaarteet ovat toivottuja ja hyväksyttyjä alustallamme. Oletko epävarma, täyttääkö tuotteesi brändistandardimme? Ei hätää! Lisää tuote ja vahvistamme sen puolestasi. Huomioithan, että <button> pikamuotibrändejä </button> ei voi lisätä alustallemme.`,
    },
    removeFavorite: {
        en: 'Remove from favorites?',
        fi: 'Poista suosikeista?',
    },
    bannedBrands: {
        en: 'Banned Brands',
        fi: 'Kielletyt brändit',
    },
    bannedBrandsBodyText: {
        en: `We promote sharing all of your wardrobe quality treasures with the community, but please note that following brands are not accepted on our platform`,
        fi: `Lisää vaatekaappisi laatuvaatteet ja -asusteet profiiliisi muiden muodin ystävien käyttöön. Huomioithan, että alla mainittuja tai samantyylisiä brändejä ei voi lisätä alustallemme.`,
    },

    /**
     * Page titles
     */
    addListing: {
        en: 'Add new item',
        fi: 'Lisää uusi tuote',
    },
    profile: {
        en: 'Profile',
        fi: 'Profiili',
    },
    login: {
        en: 'Login',
        fi: 'Kirjautuminen',
    },
    checkout: {
        en: 'Checkout',
        fi: 'Kassa',
    },
    orderComplete: {
        en: 'Order complete',
        fi: 'Tilaus vahvistettu',
    },
    verifyEmail: {
        en: 'Confirm Email',
        fi: 'Vahvista Sähköposti',
    },
    renterProtection: {
        en: 'Renter protection',
        fi: 'Vuokraajan turva',
    },
    renterProtectionSectionTitle: {
        en: 'ROBES Renter protection',
        fi: 'ROBES Vuokraajan turva',
    },
    renterProtectionSectionDescription: {
        en: '<button>ROBES Renter protection</button> is included in the rental fee to ensure a worry-free rental.',
        fi: '<button>ROBES Vuokraajan turva</button> sisältyy jokaiseen vuokraukseen, jotta voit vuokrata huoletta.',
    },
    cancellationPolicySectionTitle: {
        en: 'Cancellation policy',
        fi: 'Peruutusehdot',
    },
    deliveryOptionsSectionTitle: {
        en: 'Delivery Options',
        fi: 'Toimitusvaihtoehdot',
    },
    cancellationPolicySectionDescription: {
        en: 'Cancel up to 72 hours before the rental start time without additional fees.',
        fi: 'Voit peruuttaa vuokrauksen 72 tuntia ennen vuokrausta ilman lisäkuluja.',
    },
    howRentingWorksSectionTitle: {
        en: 'How to rent?',
        fi: 'Miten vuokraus toimii?',
    },
    howRentingWorksSectionDescription: {
        en: `Read the <button>guide</button> on how renting works. \nAny questions? Ask the ROBES team via chat - we're here to help!`,
        fi: `Miten vuokraus toimii? Lue <button>ohjeet</button> \nKysyttävää?  Lähetä viestiä tiimillemme chatin kautta - olemme aina apunasi!`,
    },
    howRentingWorksSectionDescriptionNotLoggedIn: {
        en: `Read the <button>guide</button> on how renting works. \nAny questions? <signUp>Sign up</signUp> to chat with the ROBES team - we're here to help!`,
        fi: `Miten vuokraus toimii? Lue <button>ohjeet</button> \nKysyttävää? <signUp>Rekisteröidy</signUp> keskustellaksesi ROBES-tiimin kanssa — olemme aina apunasi!`,
    },
    chatWithSupport: {
        en: 'Chat with support',
        fi: 'Keskustele ROBESin kanssa',
    },
    passwordResetRequest: {
        en: 'Reset password',
        fi: 'Palauta salasana',
    },
    passwordReset: {
        en: 'Change password',
        fi: 'Vaihda salasana',
    },

    /**
     * Add listing view
     */
    itemName: {
        en: 'Item name',
        fi: 'Tuotteen nimi',
    },
    price: {
        en: 'Price',
        fi: 'Hinta',
    },
    itemNameError: {
        en: 'Item name is required.',
        fi: 'Tuotteen nimi vaaditaan.',
    },
    imageSizeError: {
        en: 'Maximum file size for image uploads is 20MB. Try again with a smaller picture.',
        fi: 'Suurin sallittu kuvan tiedostokoko on 20MB. Kokeile uudestaan pienemmällä kuvalla.',
    },
    'image-max-size-exceeded': {
        en: 'File size is too big',
        fi: 'Tiedosto on liian suuri',
    },
    'image-invalid-content': {
        en: 'Invalid file type. Only JPG and PNG are supported',
        fi: 'Virheellinen tiedostomuoto. Vain JPG ja PNG ovat sallittuja',
    },
    'image-unknown-error': {
        en: 'Unknown error, please try again',
        fi: 'Tuntematon virhe, yritä uudelleen',
    },
    previewNoImages: {
        en: 'No images uploaded yet',
        fi: 'Ei näytettäviä kuvia',
    },
    uploadNImages: {
        en: 'Add {{count}} more images to proceed',
        fi: 'Lisää vielä {{count}} kuvaa jatkaaksesi',
    },
    uploadOneMoreImage: {
        en: 'Add one more image to proceed',
        fi: 'Lisää vielä yksi kuva jatkaaksesi',
    },
    addListingError: {
        en: 'Could not save listing, please check your information and try again.',
        fi: 'Tuotteen tallennus epäonnistui, tarkista tiedot ja yritä uudelleen.',
    },
    required: {
        en: 'Required information',
        fi: 'Pakollinen tieto',
    },
    priceError: {
        en: 'Check price.',
        fi: 'Tarkista hinta.',
    },
    brand: {
        en: 'Brand',
        fi: 'Merkki',
    },
    brands: {
        en: 'Brands',
        fi: 'Merkit',
    },
    category: {
        en: 'Category',
        fi: 'Kategoria',
    },
    categories: {
        en: 'Categories',
        fi: 'Kategoriat',
    },
    material: {
        en: 'Material',
        fi: 'Materiaali',
    },
    collection: {
        en: 'Collection',
        fi: 'Kokoelma',
    },
    seeAll: {
        en: 'See all',
        fi: 'Näytä kaikki',
    },
    details: {
        en: 'Details',
        fi: 'Kuvaus',
    },
    submit: {
        en: 'Submit',
        fi: 'Lähetä',
    },
    saveChanges: {
        en: 'Save changes',
        fi: 'Tallenna muutokset',
    },
    edit: {
        en: 'Edit',
        fi: 'Muokkaa',
    },
    sizingOne: {
        en: 'Sizing 1',
        fi: 'Kokoluokka 1',
    },
    sizingTwo: {
        en: 'Sizing 2',
        fi: 'Kokoluokka 2',
    },
    preview: {
        en: 'Preview',
        fi: 'Esikatsele',
    },
    browse: {
        en: 'Browse',
        fi: 'Selaa',
    },
    size: {
        en: 'Size',
        fi: 'Koko',
    },
    clothingSizes: {
        en: 'Clothing',
        fi: 'Vaatteet',
    },
    shoeSizes: {
        en: 'Shoes',
        fi: 'Kengät',
    },
    color: {
        en: 'Color',
        fi: 'Väri',
    },
    sort: {
        en: 'Sort',
        fi: 'Lajittele',
    },
    layout: {
        en: 'Layout',
        fi: 'Näkymä',
    },
    inspiration: {
        en: 'Inspiration',
        fi: 'Inspiraatio',
    },
    all: {
        en: 'All',
        fi: 'Kaikki',
    },
    reset: {
        en: 'Reset',
        fi: 'Nollaa',
    },
    clear: {
        en: 'Clear',
        fi: 'Tyhjennä',
    },
    apply: {
        en: 'Apply',
        fi: 'Hyväksy',
    },
    showResults: {
        en: 'Show results',
        fi: 'Näytä tulokset',
    },
    verifyMyIdentity: {
        en: 'Verify my identity information',
        fi: 'Vahvista henkilöllisyystietoni',
    },
    termsOfService: {
        en: 'Terms of Service',
        fi: 'Palveluehdot',
    },
    addImages: {
        en: 'Add images',
        fi: 'Lisää kuvia',
    },
    uploadPhotos: {
        en: 'Upload photos',
        fi: 'Lataa kuvia',
    },
    coverImage: {
        en: 'Cover image',
        fi: 'Kansikuva',
    },
    clickAndDragToAdjustOrder: {
        en: 'Click and drag to adjust the order',
        fi: 'Klikkaa ja vedä muuttaaksesi järjestystä',
    },
    addNMoreImages: {
        en: 'Add {{count}} more image to proceed',
        fi: 'Lisää vielä {{count}} kuva jatkaaksesi',
    },
    addNMoreImages_plural: {
        en: 'Add {{count}} more images to proceed',
        fi: 'Lisää vielä {{count}} kuvaa jatkaaksesi',
    },
    imageHelperText: {
        en: `Make your items stand out by uploading {{ minImages }}-{{ maxImages }} clear and attractive images of your item. Good photos feature a person with good lighting to showcase its unique style. Let's make your items shine and get them rented in no time!`,
        fi: `Muotokuvasuunnassa ja hyvissä valaistusolosuhteissa otetut kuvat tuotteesta henkilön yllä toimivat parhaiten. Suosittelemme lisäämään {{ minImages }}-{{ maxImages }} kuvaa tuotteestasi. Hyvät kuvat auttavat yhteisömme jäseniä näkemään, kuinka upeasta tuotteesta on kyse!`,
    },
    confirmAnotherBookingTitle: {
        en: 'Ongoing booking',
        fi: 'Meneillään oleva bookkaus',
    },
    confirmAnotherBookingBody: {
        en: `It appears that you already have a booking for this item for dates {{ range }}. 
        
        The item is still reserved to you for the next {{ duration }}. Do you wish to continue your earlier booking?`,
        fi: `Näyttäisi siltä, että sinulla on jo olemassa oleva varaus tälle tuotteelle ajanjaksolle {{ range }}. 
        
        Tuote on varattu sinulle kyseiselle ajanjaksolle vielä seuraavat {{ duration }}. Haluatko jatkaa varausta?`,
    },
    whatAreYouLending: {
        en: 'What are you lending?',
        fi: 'Mitä olet vuokraamassa?',
    },
    addListingSuccessHeader: {
        en: 'Amazing news! 🧡',
        fi: 'Upeita uutisia, muodin ystävä! 🧡',
    },
    addListingSuccessSubheader: {
        en: 'You have successfully uploaded your item.',
        fi: 'Olet onnistuneesti ladannut tuotteesi ROBESiin.',
    },
    addListingSuccessBody: {
        en: 'Every new item listing goes through an approval process. Once the process is completed, you will be notified. Stay tuned fashionista!',
        fi: 'Jokainen uusi tuote hyväksytetään ennen julkaisua. Ilmoitamme sinulle kun tuotteesi on valmis julkaistavaksi tai jos ilmoitus tarvitsee lisätietoja. ',
    },
    addListingSuccessPrimaryAction: {
        en: 'Add another item',
        fi: 'Lisää toinen tuote',
    },
    goToWardrobe: {
        en: 'Go to wardrobe',
        fi: 'Vaatekaappiin',
    },
    exploreMore: {
        en: 'Explore more',
        fi: 'Löydä lisää tuotteita',
    },
    updateSuccess: {
        en: 'Updated succesfully',
        fi: 'Tuotteen tiedot päivitetty',
    },

    /**
     * Pricing model form
     */
    priceSectionTitle: {
        en: 'Pricing',
        fi: 'Hinnoittelu',
    },
    priceSectionSubtitle: {
        en: `Provide the item retail price to automatically generate daily price and discounts based on our recommendations.`,
        fi: `Syöttämällä tuotteen alkuperäisen hinnan voimme ehdottaa tuotteellesi sopivan päivittäisen, viikottaisen ja kuukausittaisen hinnan.`,
    },
    originalPrice: {
        en: 'Original price',
        fi: 'Alkuperäinen hinta',
    },
    dailyPrice: {
        en: 'Daily price',
        fi: 'Päivävuokrahinta',
    },
    week: {
        en: 'Week',
        fi: 'Viikko',
    },
    month: {
        en: 'Month',
        fi: 'Kuukausi',
    },
    noDiscount: {
        en: 'No discount',
        fi: 'Ei alennusta',
    },
    editPricing: {
        en: 'Edit pricing',
        fi: 'Muokkaa hintoja',
    },
    minimumRental: {
        en: 'Minimum rental ({{value}} days)',
        fi: 'Minimivuokra-aika (3 päivää)',
    },
    weeklyRental: {
        en: 'Weekly rental',
        fi: 'Viikkovuokra',
    },
    monthlyRental: {
        en: 'Monthly rental',
        fi: 'Kuukausivuokra',
    },
    oneSize: {
        en: 'One Size',
        fi: 'One Size',
    },
    weeklyPrice: {
        en: 'Weekly price',
        fi: 'Viikottainen hinta',
    },
    weeklyAndMonthlyPriceSectionTitle: {
        en: 'Weekly and monthly prices',
        fi: 'Viikko- ja kuukausihinnat',
    },
    monthlyPrice: {
        en: 'Monthly price',
        fi: 'Kuukausittainen hinta',
    },
    suggestion: {
        en: 'Suggestion: ',
        fi: 'Ehdotus: ',
    },

    /**
     * Listing view filters
     */
    showXResults: {
        en: 'Show {{amount}} results',
        fi: 'Näytä {{amount}} tulosta',
    },
    noResults: {
        en: 'No results, show all instead?',
        fi: 'Ei tuloksia, näytetäänkö kaikki?',
    },
    noSearchResultsFor: {
        en: 'No results for "{{ searchTerm }}"',
        fi: 'Ei tuloksia hakusanalle "{{ searchTerm }}"',
    },
    filters: {
        en: 'Filters',
        fi: 'Suodattimet',
    },
    allFilters: {
        en: 'Filters',
        fi: 'Suodattimet',
    },
    filter: {
        en: 'Filter',
        fi: 'Suodata',
    },
    searchBrands: {
        en: 'Search Brands',
        fi: 'Etsi Brändejä',
    },
    select: {
        en: 'Select',
        fi: 'Valitse',
    },
    new: {
        en: 'New',
        fi: 'Uusi',
    },

    /**
     * Listing view sorting
     */
    sortBy: {
        en: 'Sort by',
        fi: 'Järjestele tulokset',
    },
    newest: {
        en: 'Newest',
        fi: 'Uusimmat ensin',
    },
    priceHighestFirst: {
        en: 'Highest Price',
        fi: 'Kalleimmat ensin',
    },
    priceLowestFirst: {
        en: 'Lowest Price',
        fi: 'Edullisimmat ensin',
    },

    /**
     * Listing and user search
     */

    users: {
        en: 'Users',
        fi: 'Käyttäjät',
    },
    listings: {
        en: 'Listings',
        fi: 'Listaukset',
    },
    listing: {
        en: 'Listing',
        fi: 'Listaus',
    },
    editListing: {
        en: 'Edit listing',
        fi: 'Muokkaa listausta',
    },
    noSearchResults: {
        en: 'No results',
        fi: 'Ei tuloksia',
    },
    nothingHere: {
        en: 'Nothing here',
        fi: 'Ei tuloksia',
    },

    viewRentalDetails: {
        en: 'View rental details',
        fi: 'Varauksen tiedot',
    },
    transactionDetails: {
        en: 'Transaction details',
        fi: 'Varauksen tiedot',
    },
    cancelRentalRequest: {
        en: 'Cancel rental request',
        fi: 'Peruuta vuokrauspyyntö',
    },
    cancelRental: {
        en: 'Cancel rental',
        fi: 'Peruuta',
    },

    /*
     * Footer navigation
     */

    home: {
        en: 'Home',
        fi: 'Koti',
    },
    wardrobe: {
        en: 'Wardrobe',
        fi: 'Vaatekaappi',
    },
    add: {
        en: 'Add',
        fi: 'Lisää',
    },
    personalInformation: {
        en: 'Personal information',
        fi: 'Henkilökohtaiset tiedot',
    },

    /*
     * Settings
     */

    settings: {
        en: 'Settings',
        fi: 'Asetukset',
    },
    goToSettings: {
        en: 'Go to settings',
        fi: 'Siirry asetuksiin',
    },
    messages: {
        en: 'Messages',
        fi: 'Viestit',
    },
    darkMode: {
        en: 'Dark mode',
        fi: 'Tummennettu tila',
    },
    language: {
        en: 'Language',
        fi: 'Kieli',
    },
    preferredLanguage: {
        en: 'Preferred language',
        fi: 'Valitse kieli',
    },
    credits: {
        en: 'Credits',
        fi: 'Krediitit',
    },
    youHaveCredits: {
        en: 'You have {{ amount }} ROBES credits',
        fi: 'Sinulla on {{ amount }} ROBES-krediittiä',
    },
    creditsEnterCode: {
        en: 'Enter a promotional code to receive ROBES credits',
        fi: 'Syötä promokoodi saadaksesi ROBES-krediittejä',
    },
    creditsMinimumAmountDisclaimer: {
        en: 'Credits can be used as payment at checkout when renting for over {{ amount }}.',
        fi: 'Krediittejä voi käyttää maksuvälineenä, kun vuokraussumma on yli {{ amount }}.',
    },
    robesPromoCode: {
        en: 'ROBES promo code',
        fi: 'ROBES promokoodi',
    },
    redeemPromoCode: {
        en: 'Enter code to receive ROBES credits',
        fi: 'Syötä koodi saadaksesi ROBES-krediittejä',
    },
    codeAlreadyRedeemed: {
        en: 'Code has already been redeemed',
        fi: 'Koodi on jo käytetty',
    },
    codeExpired: {
        en: 'Code has expired',
        fi: 'Koodi on vanhentunut',
    },
    codeNotFound: {
        en: 'The code you entered is not valid',
        fi: 'Virheellinen koodi',
    },
    codeCannotUseOwn: {
        en: 'You cannot use your own code',
        fi: 'Et voi käyttää omaa koodiasi',
    },
    codeOnlyOnePerUser: {
        en: 'Code is already used',
        fi: 'Koodi on jo käytetty',
    },
    creditsAddSuccessTitle: {
        en: '<counter></counter> credits added \n to your account!',
        fi: '<counter></counter> krediittiä lisätty \n tilillesi!',
    },
    creditsAddSuccessBody: {
        en: 'Credits are valid to use until <b>{{ date }}</b>.\n We wish you a delightful rental experience!',
        fi: 'Krediitit ovat voimassa käytettäväksi asti <b>{{ date }}</b>.\n Toivotamme sinulle mukavia vuokraushetkiä!',
    },
    enterYourCode: {
        en: 'Enter promo code',
        fi: 'Syötä promokoodi',
    },
    invalidCodeError: {
        en: 'The code you entered is not valid',
        fi: 'Virheellinen koodi',
    },
    redeemCode: {
        en: 'Redeem code',
        fi: 'Lunasta koodi',
    },
    promoCodeDrawerBodyText: {
        en: 'If you have a promotional code, enter it here to receive ROBES credits.',
        fi: 'Jos sinulla on promokoodi, syötä se tähän saadaksesi ROBES-krediittejä.',
    },
    paymentsAndPayouts: {
        en: 'Payments and payouts',
        fi: 'Maksut',
    },
    payWith: {
        en: 'Pay with',
        fi: 'Maksutapa',
    },
    stripeUpToDate: {
        en: 'Payment information up to date!',
        fi: 'Maksutiedot ajantasalla!',
    },
    editPayoutDetails: {
        en: 'Edit payout details',
        fi: 'Muokkaa maksutietoja',
    },
    somethingWentWrongOurEnd: {
        en: 'Something went wrong on our end. Please try again later!',
        fi: 'Jokin meni vikaan meidän puolellamme. Yritä myöhemmin uudestaan!',
    },
    timeToAdd: {
        en: 'Time to add something to your wardrobe!',
        fi: 'Aika lisätä tuotteita vaatekaappiisi!',
    },
    addNewItems: {
        en: 'Add new items',
        fi: 'Lisää uusia tuotteita',
    },
    backToSettings: {
        en: 'Back to settings',
        fi: 'Takaisin asetuksiin',
    },
    stripeAcctSuccess: {
        en: 'Stripe account created succesfully!',
        fi: 'Stripe-käyttäjä luotu onnistuneesti!',
    },
    /**
     * Stripe connect form
     */
    'stripeConnectForm.iban.label': {
        en: 'IBAN',
        fi: 'IBAN',
    },
    'stripeConnectForm.institutionNumber.label': {
        en: 'Institution number',
        fi: 'Laitoksen numero',
    },
    'stripeConnectForm.accountNumber.label': {
        en: 'Account number',
        fi: 'Tilinumero',
    },
    'stripeConnectForm.routingNumber.label': {
        en: 'Routing number',
        fi: 'Reititysnumero',
    },
    'stripeConnectForm.sortCode.label': {
        en: 'Sort code',
        fi: 'Lajittelukoodi',
    },

    // Country codes

    FI: {
        en: 'Finland',
        fi: 'Suomi',
    },
    US: {
        en: 'United States',
        fi: 'Yhdysvallat',
    },

    fillAdditionalDetails: {
        en: '...but some additional verification is needed. Press below button to fill the missing details.',
        fi: '...mutta tarvitsemme vielä lisätietoja sinulta. Paina alempana olevasta nappulasta päästäksesi täyttämään puuttuvat tiedot.',
    },
    planToBeALender: {
        en: 'Plan to be a lender?',
        fi: 'Aiotko vuokrata vaatteitasi muille?',
    },
    lenderGuidanceText: {
        en: `In order to send you money, we need to know a bit more about you. Next, we will guide you through verifying your account. The details can be edited afterwards from the Payments And Payouts Settings.`,
        fi: `Jotta voimme lähettää sinulle rahaa, meidän on tiedettävä sinusta hieman enemmän. Seuraavaksi opastamme sinut Stripen sivuille, mistä pystyt lisäämään maksuihin tarvittavat tietosi. Tietoja voi muokata jälkikäteen Maksut ja maksut -asetuksista.`,
    },
    preferences: {
        en: 'Preferences',
        fi: 'Asetukset',
    },
    notifications: {
        en: 'Notifications',
        fi: 'Ilmoitukset',
    },
    contactUs: {
        en: 'Contact us',
        fi: 'Ota meihin yhteyttä',
    },
    displayName: {
        en: 'Display name',
        fi: 'Näyttönimi',
    },
    bio: {
        en: 'Bio',
        fi: 'Biografia',
    },
    bioPlaceholder: {
        en: 'You can talk about anything here: your musical preferences, what inspires you, favorite brand, etc.',
        fi: 'Voit kertoa mistä tahansa: musiikkimaustasi, inspiraation lähteistäsi tai esimerkiksi suosikkibrändeistäsi.',
    },
    chatPlaceholder: {
        en: 'Type something',
        fi: 'Kirjoita tähän',
    },
    chatDisabledPlaceholder: {
        en: 'Cannot send messages to deleted user',
        fi: 'Viestien lähetys estetty poistetulle käyttäjälle',
    },
    showMore: {
        en: 'Show more',
        fi: 'Näytä lisää',
    },
    showLess: {
        en: 'Show less',
        fi: 'Näytä vähemmän',
    },
    displayNameError: {
        en: 'Invalid display name',
        fi: 'Virheellinen näyttönimi',
    },
    maxLengthError: {
        en: 'Max length is {{ length }}',
        fi: 'Maksimipituus on {{ length }} merkkiä',
    },
    profileUpdatedMsg: {
        en: 'Profile updated',
        fi: 'Profiili päivitetty',
    },
    profilePicUpdatedMsg: {
        en: 'Profile picture updated',
        fi: 'Profiilikuva päivitetty',
    },
    payoutDetailsUpdatedMsg: {
        en: 'Payout details updated',
        fi: 'Maksutiedot päivitetty',
    },
    profileUpdateErrorMsg: {
        en: 'Updating profile failed',
        fi: 'Profiilin päivitys epäonnistui',
    },
    backToCredits: {
        en: 'Back to credits',
        fi: 'Takaisin',
    },
    compactMode: {
        en: 'Compact listing layout',
        fi: 'Tiivis listausnäkymä',
    },
    reducedMotion: {
        en: 'Reduced motion',
        fi: 'Vähemmän animaatioita',
    },

    /**
     * Onboarding
     */

    firstStepTitle: {
        en: 'Hi {{ name }}!',
        fi: 'Hei {{ name }}!',
    },
    firstStepDescription: {
        en: 'We are delighted to have you in our growing community of fashion lovers.',
        fi: 'Tervetuloa mukaan kasvavaan yhteisöömme, jossa laatu ja tyyli ovat kaiken keskiössä.',
    },
    firstStepSubtitle: {
        en: `Let's get you started!`,
        fi: `Aloitetaan!`,
    },

    rentingStepTitle: {
        en: 'Rent from the community',
        fi: 'Vuokraa ja inspiroidu',
    },
    rentingStepDescription: {
        en: 'Discover and rent high-end fashion affordably.',
        fi: 'Inspiroidu muiden tyyleistä ja vuokraa suosikkisi haluamallesi ajankohdalle.',
    },

    lendingStepTitle: {
        en: 'Cash in on your wardrobe',
        fi: 'Tienaa vaatekaapillasi',
    },
    lendingStepDescription: {
        en: 'Earn money by lending clothes, bags and accessories to others.',
        fi: 'Ansaitse rahaa vuokraamalla muille omia vaatteita ja asusteitasi.',
    },

    notificationPermissionsStepTitle: {
        en: 'Turn on notifications',
        fi: 'Ota ilmoitukset käyttöön',
    },
    notificationPermissionsStepDescription: {
        en: `Don't miss new rental requests, chat messages and reminders of upcoming rentals!`,
        fi: `Älä missaa uusia vuokrauspyyntöjä, chat-viestejä ja muistutuksia tulevista vuokrauksista!`,
    },
    allowNotifications: {
        en: 'Allow notifications',
        fi: 'Salli ilmoitukset',
    },
    notificationSettings: {
        en: 'Notification settings',
        fi: 'Ilmoitusasetukset',
    },
    notificationsDenied: {
        en: 'Notifications denied',
        fi: 'Ilmoitukset estetty',
    },
    notificationsDeniedModalTitle: {
        en: 'Notifications denied',
        fi: 'Ilmoitukset estetty',
    },
    notificationsDeniedModalBody: {
        en: 'You have denied notification permissions for ROBES. To enable them, go to your app settings via the button below.',
        fi: 'Olet estänyt ilmoitukset ROBES-sovellukselle. Voit sallia ne sovelluksen asetuksista alla olevasta napista.',
    },

    allowMarketingCheckboxText: {
        en: 'Allow marketing notifications of new arrivals, limited offers and much more',
        fi: 'Salli markkinointi-ilmoitukset pysyäksesi kartalla uusista tuotteista ja rajoitetuista tarjouksista',
    },

    interestedInLending: {
        en: 'I am interested in lending',
        fi: 'Olen kiinnostunut vuokraamaan omia vaatteitani muille',
    },

    profileStepTitle: {
        en: 'Build your profile',
        fi: 'Luo profiili',
    },
    profileStepExistingUserTitle: {
        en: `Let's update your profile`,
        fi: `Päivitetään profiilisi`,
    },
    profileStepDescription: {
        en: 'Let the ROBES community know the kind of fashionista you are!',
        fi: 'Esittele itsesi yhteisömme jäsenille. Lisää profiiliisi vapaavalintainen kuva itsestäsi.',
    },
    profileStepExistingUserDescription: {
        en: `To continue enjoying ROBES, please upload a profile picture.`,
        fi: `Lisää profiilikuva jatkaaksesi ROBESin käyttöä.`,
    },
    yourProfilePicture: {
        en: 'Your profile picture',
        fi: 'Profiilikuvasi',
    },

    bioStepTitle: {
        en: 'Add your bio',
        fi: 'Lisää bio',
    },
    bioStepDescription: {
        en: 'Tell us about yourself, your style and even your style icons! The floor is yours.',
        fi: 'Kerro itsestäsi, tyylistäsi ja vaikka tyyli-ikoneistasi! Sana on vapaa.',
    },

    finishStepTitle: {
        en: 'You are all set!',
        fi: 'Olet valmis!',
    },
    finishStepDescription: {
        en: 'Time to explore our collections! Updated daily and provided in full by our growing ROBES community.',
        fi: 'Tutustu valikoimaan, joka koostuu kaupungin tyylikkäimmistä vaatekaapeista.',
    },

    startExploring: {
        en: 'Start exploring',
        fi: 'Valmis!',
    },
    addPicture: {
        en: 'Add picture',
        fi: 'Lisää kuva',
    },
    changePicture: {
        en: 'Change picture',
        fi: 'Vaihda kuva',
    },
    addBio: {
        en: 'Add bio',
        fi: 'Lisää bio',
    },
    later: {
        en: 'Later',
        fi: 'Myöhemmin',
    },
    crop: {
        en: 'Crop',
        fi: 'Rajaa',
    },

    /**
     * View listing view
     */
    day: {
        en: 'day',
        fi: 'päivä',
    },
    dayAbbreviated: {
        en: 'day',
        fi: 'pv',
    },
    days: {
        en: 'days',
        fi: 'päivää',
    },
    availabilityToggleInfo: {
        en: 'Controls whether the listing will be visible to other users. Changing this setting does not affect any ongoing transactions.',
        fi: 'Määrittää, näkyykö listaus muille käyttäjille. Tämän asetuksen muuttaminen ei vaikuta käynnissä oleviin tapahtumiin.',
    },

    rent: {
        en: 'Rent',
        fi: 'Vuokraa',
    },
    allListings: {
        en: 'All listings',
        fi: 'Kaikki listaukset',
    },
    selectDatesForTotal: {
        en: 'Select dates to see prices',
        fi: 'Valitse päivämäärät nähdäksesi hinnat',
    },
    to: {
        en: 'To',
        fi: '-',
    },
    confirm: {
        en: 'Confirm',
        fi: 'Vahvista',
    },
    includeListingInfo: {
        en: `Automatically include product info in message`,
        fi: `Liitä tuotteen tiedot viestiin automaattisesti`,
    },
    available: {
        en: `Available`,
        fi: `Vuokrattavissa`,
    },
    closed: {
        en: 'Hidden',
        fi: 'Piilotettu',
    },
    dismiss: {
        en: 'Dismiss',
        fi: 'Ohita',
    },
    updateApp: {
        en: 'Update',
        fi: 'Päivitä',
    },

    /**
     * Checkout sections
     */

    deliveryDetailsTitle: {
        en: 'Delivery Details',
        fi: 'Toimitustiedot',
    },
    deliveryDetailsDescription: {
        en: 'Enter delivery details',
        fi: 'Syötä toimitustiedot',
    },
    'deliveryDetails.streetAddressLabel': {
        en: 'Street address',
        fi: 'Katuosoite',
    },
    'deliveryDetails.postalCodeLabel': {
        en: 'Postal code',
        fi: 'Postinumero',
    },
    'deliveryDetails.cityLabel': {
        en: 'City',
        fi: 'Kaupunki',
    },
    'deliveryDetails.emailLabel': {
        en: 'Email',
        fi: 'Sähköposti',
    },
    'deliveryDetails.phoneNumberLabel': {
        en: 'Phone number',
        fi: 'Puhelinnumero',
    },
    pickupPointLabel: {
        en: 'Pickup point',
        fi: 'Noutopiste',
    },
    pickupPointDescription: {
        en: 'Select a pickup point',
        fi: 'Valitse noutopiste',
    },
    deliveryDateLabel: {
        en: 'Delivery date',
        fi: 'Toimituspäivä',
    },
    deliveryTimeLabel: {
        en: 'Delivery time',
        fi: 'Toimitusaika',
    },
    returnDateLabel: {
        en: 'Return date',
        fi: 'Palautuspäivä',
    },
    returnDatePrefix: {
        en: 'Send the item back latest on',
        fi: 'Lähetä tuote takaisin viimeistään',
    },
    timeOfPickupLabel: {
        en: 'Time of pickup',
        fi: 'Noutoaika',
    },
    timeOfReturnLabel: {
        en: 'Time of return',
        fi: 'Palautusaika',
    },

    /**
     * Transactions
     */
    renter: {
        en: 'Renter',
        fi: 'Vuokraaja',
    },
    rental: {
        en: 'Rental',
        fi: 'Vuokraus',
    },
    lender: {
        en: 'Lender',
        fi: 'Lainaaja',
    },
    lending: {
        en: 'Lending',
        fi: 'Lainassa',
    },
    renting: {
        en: 'Renting',
        fi: 'Vuokralla',
    },
    over: {
        en: 'Over',
        fi: 'Yli',
    },
    allItems: {
        en: 'All Items',
        fi: 'Kaikki tuotteet',
    },
    results: {
        en: 'Results',
        fi: 'Tulosta',
    },
    startDate: {
        en: 'Start',
        fi: 'Aloitus',
    },
    endDate: {
        en: 'End',
        fi: 'Lopetus',
    },
    startLending: {
        en: `Start lending your clothes to others with our community. Once you have active rentals, you will see the relevant rental details on this page.`,
        fi: 'Aloita omien vaatteiden vuokraaminen muille. Kun olet vuokrannut vaatteesi toiselle, näet tällä sivulla vuokrausta koskevat tiedot.',
    },
    startRenting: {
        en: `Start renting clothes from our community. When you rent clothes from others, you will see the relevant rental details on this page.`,
        fi: `Aloita vuokraamaan vaatteita muilta yhteisömme jäseniltä. Kun vuokraat vaatteen toiselta, näet tällä sivulla vuokrausta koskevat tiedot.`,
    },
    uploadYourFirstItem: {
        en: `Add your first clothing items or accessories for rental on ROBES today and start earning! You can make items available for others to see or keep them hidden from other users. On this page you can see all your listed items.`,
        fi: `Lisää ensimmäinen vaatteesi tai asusteesi alustalle ja aloita tienaamaan vaatekaapillasi jo tänään! Voit lisätä tuotteita näkyville muille tai vain itsellesi. Tällä sivulla näet kaikki vaatteesi listaamasi vaatteet.`,
    },
    delivery: {
        en: `Delivery`,
        fi: `Toimitus`,
    },
    deliveryDetails: {
        en: `Receive your item by meeting the owner in person. The owner will contact you for pick-up details via chat. 

        More delivery options coming to ROBES soon!`,
        fi: `Nouto yhdessä sovitusta paikasta. Vuokraaja on sinuun yhteydessä chatin kautta ja voitte yhdessä sopia ajan ja paikan noudolle sekä palautukselle. 

        Lisää toimitusvaihtoehtoja on tulossa ROBES-palveluun pian, joten pysy kuulolla! 
        `,
    },
    deliveryDetailsLabel: {
        en: `Delivery details`,
        fi: `Toimitustiedot`,
    },
    'deliveryDetailsLabel-order': {
        en: `Delivery details`,
        fi: `Toimitustiedot`,
    },
    'deliveryDetailsLabel-sale': {
        en: 'Delivery instructions',
        fi: 'Toimitusohjeet',
    },
    'returnDetailsLabel-order': {
        en: 'Return instructions',
        fi: 'Palautusohjeet',
    },
    'returnDetailsLabel-sale': {
        en: `Return details`,
        fi: `Palautustiedot`,
    },
    checkoutSuccessTitle: {
        en: 'Rental request sent!',
        fi: 'Pyyntö lähetetty!',
    },
    checkoutSuccessBody: {
        en: 'The owner will respond to your request within 48 hours.',
        fi: 'Omistaja vastaa pyyntöösi 48 tunnin sisään',
    },

    logisticsSectionDeliveryDetails: {
        en: 'Delivery address',
        fi: 'Toimitusosoite',
    },
    deliveryTime: {
        en: 'Time of delivery',
        fi: 'Toimitusaika',
    },
    additionalInformation: {
        en: 'Additional information',
        fi: 'Lisätiedot',
    },
    deliveryOnDateSummary: {
        en: 'Delivery is <b>{{ daysDiffString }} before</b> rental starts',
        fi: 'Toimitus on <b>{{ daysDiffString }} ennen</b> vuokrauksen alkua',
    },
    pickupTime: {
        en: 'Time of pickup',
        fi: 'Noutoaika',
    },
    selectPickupTime: {
        en: 'Select pickup time',
        fi: 'Valitse noutoaika',
    },
    deliveryInformation: {
        en: 'Delivery information',
        fi: 'Toimitustiedot',
    },
    dropoff: {
        en: 'drop-off',
        fi: 'palautus',
    },
    showroomDropoff: {
        en: 'showroom drop-off',
        fi: 'showroom-palautus',
    },
    deliveryInformationDrawerHomeDelivery: {
        en: '<h1>Home Delivery</h1><h3>How it works</h3> Have your rental item delivered to your location by our courier partner Wolt. You return the item after the rental by dropping it off at our showroom. <h3>Tracking the delivery</h3> You will receive a delivery tracking link by SMS before the time of delivery. <h3>Availability</h3> Home deliveries are available in the Helsinki area within 10km distance from our showroom. Home deliveries can currently be ordered during <b>{{ openingHours }}</b>. <br /> <br /> You can check availaibility to your location by entering your postal code below.',
        fi: '<h1>Kotiinkuljetus</h1><h3>Miten se toimii</h3> Vuokraamasi tuote toimitetaan sinulle kuriirikumppanimme Woltin toimesta. Palautat tuotteen vuokra-ajan päätyttyä ROBESin Showroomille. <h3>Toimituksen seuranta</h3> Saat toimituksen seurantalinkin tekstiviestillä ennen toimitusaikaa. <h3>Saatavuus</h3> Kotiinkuljetukset ovat saatavilla Helsingin alueella 10km säteellä Showroomistamme. Kotiinkuljetuksia voi tilata tällä hetkellä <b>{{ openingHours }}</b>. <br /> <br /> Voit tarkistaa saatavuuden omalle alueellesi syöttämällä postinumerosi alla olevaan kenttään.',
    },
    deliveryInformationDrawerParcelDelivery: {
        en: '<h1>Parcel delivery</h1><h3>How it works</h3> Pickup and drop-off at your preferred Matkahuolto service point.<h3>Tracking the delivery</h3> You will receive a tracking code for your parcel after your rental request has been confirmed. <h3>Receiving the parcel</h3> You will receive an SMS notification when your rental item is ready for pickup. <h3>Returning the item</h3>Repackage your rental item, mark your Matkahuolto activation code on the package and drop it off at a Matkahuolto service point.',
        fi: '<h1>Pakettitoimitus</h1><h3>Miten se toimii</h3> Nouda ja palauta vuokraamasi tuote valitsemastasi Matkahuollon palvelupisteestä. <h3>Toimituksen seuranta</h3> Saat seurantakoodin paketillesi vuokrauspyyntösi hyväksymisen jälkeen. <h3>Paketin vastaanottaminen</h3> Saat tekstiviestin, kun vuokraamasi tuote on valmiina noudettavaksi. <h3>Tuotteen palautus</h3>Pakkaa vuokraamasi tuote uudelleen, merkitse Matkahuollon aktivointikoodi paketin päälle ja vie se Matkahuollon palvelupisteeseen.',
    },
    deliveryInformationDrawerShowroomDropoff: {
        en: `<h1>Showroom pickup</h1><h3>How it works</h3> Pick up and drop-off your rental item at ROBES showroom. <h3>📍  Location</h3> ${showroomAddressStreet} <br /> ${showroomAddressPostalCode} <br /> ${showroomAddressCity} <h3>📅 Opening hours</h3> {{ openingHours }} (or by appointment)`,
        fi: `<h1>Showroom-nouto</h1><h3>Miten se toimii</h3> Nouda ja palauta vuokraamasi tuote ROBES Showroomilta. <h3>📍  Osoite</h3> ${showroomAddressStreet} <br /> ${showroomAddressPostalCode} <br /> ${showroomAddressCity} <h3>📅 Aukioloajat</h3> {{ openingHours }} (tai sopimuksen mukaan)`,
    },
    deliveryInformationDrawerfaceToFaceDelivery: {
        en: '<h1>Meet with lender</h1><h3>How it works</h3> Receive your item by meeting the owner in person. The owner will contact you for pickup details via chat after accepting your rental request.',
        fi: '<h1>Tapaaminen lainaajan kanssa</h1><h3>Miten se toimii</h3> Nouda vuokraamasi tuote tapaamalla omistaja henkilökohtaisesti. Omistaja ottaa sinuun yhteyttä chatin kautta ja voitte yhdessä sopia ajan ja paikan noudolle sekä palautukselle.',
    },
    deliveryInformationDrawerFAQ: {
        en: '<h1>FAQ</h1><h3>Can I send my rented item back by return delivery?</h3> ROBES currently only offers one-way home deliveries. We are working on bringing return deliveries to our service. Stay tuned! <h3>What if the showroom is closed when my rental starts or ends?</h3>You will automatically be offered an earlier delivery or pickup date before your rental starts and a later drop-off date. <br /> <br /> <i>Example: If your rental starts on Saturday (outside of showroom opening hours) your home delivery or showroom pickup will be on Friday. </i> <br /> <br /> Any additional rental days in these cases are free of charge! <h3>Why are not all delivery methods available for a specific item?</h3> Home deliveries and showroom pickups are currently only available for owners and listings located in the Helsinki area. We are working on making more delivery options for other cities as well! <br /> <br />In addition, the owner decides what delivery methods they want to offer for their listings.',
        fi: '<h1>FAQ</h1><h3>Voinko palauttaa vuokraamani tuotteen kotiinkuljetuksella?</h3> ROBES tarjoaa tällä hetkellä vain yksisuuntaisia kotiinkuljetuksia. Työskentelemme palautuskuljetusten tuomiseksi palveluumme. Pysy kuulolla! <h3>Mitä teen, jos Showroom on suljettu vuokraukseni alkaessa tai päättyessä?</h3>Sinulle tarjotaan automaattisesti aikaisempaa toimitus- tai noutopäivää ennen vuokrasi alkamista, ja myöhäisempää palautuspäivää. <br /> <br /> <i>Esimerkki: Jos vuokrauksesi alkaa lauantaina (Showroomin ollessa suljettu), kotiinkuljetuksesi tai Showroom-noutosi on perjantaina. </i> <br /> <br /> Lisävuokrapäivät näissä tapauksissa ovat ilmaisia! <h3>Miksi kaikki toimitustavat eivät ole saatavilla tietylle tuotteelle?</h3> Kotiinkuljetukset ja Showroom-noudot ovat tällä hetkellä saatavilla vain Helsingissä sijaitseville tuotteille ja omistajille. Pyrimme tuomaan lisää toimitusvaihtoehtoja myös muihin kaupunkeihin! <br /> <br /> Lisäksi omistaja päättää, mitä toimitustapoja hän haluaa tarjota tuotteilleen.',
    },
    tryonOptionsSectionTitle: {
        en: 'Try before you rent',
        fi: 'Sovita ennen vuokrausta',
    },
    tryonSectionPresentInShowroomContent: {
        en: 'This item is available at <showroom>ROBES Showroom</showroom> for try-on without an appointment. Check the rental calendar for availability.',
        fi: 'Tämä tuote löytyy <showroom>ROBES Showroomilta</showroom> ja on sovitettavissa ilman ajanvarausta. Tarkista saatavuus vuokrauskalenterista.',
    },
    tryonSectionContent: {
        en: 'Item owner offers try-ons for this item. Contact the owner by chat to arrange a try-on.',
        fi: 'Tuotteen omistaja tarjoaa sovitusta tälle tuotteelle. Ota yhteyttä omistajaan chatin kautta sopiaaksesi sovituksesta.',
    },
    tryOnSectionContentDoesNotOfferTryon: {
        en: 'Ask the item owner by chat if they are willing to arrange a try-on.',
        fi: 'Kysy tuotteen omistajalta chatin avulla onko hän valmis järjestämään sovituksen tuotteelle.',
    },
    chatWithOwner: {
        en: 'Chat with owner',
        fi: 'Keskustele omistajan kanssa',
    },
    showroomDetails: {
        en: 'Showroom details',
        fi: 'Showroomin tiedot',
    },
    showroomLocation: {
        en: `📍 <b>Location</b> ${showroomDisplayNameFull}, entrance on street level`,
        fi: `📍 <b>Osoite</b> ${showroomDisplayNameFull}, sisäänkäynti katutasolla`,
    },
    showroomOpeningHours: {
        en: '📅 <b>Opening hours</b> {{ openingHours }}, or by appointment',
        fi: '📅 <b>Aukioloajat</b> {{ openingHours }}, tai sopimuksen mukaan',
    },
    itemReturn: {
        en: 'Return instructions',
        fi: 'Palautusohjeet',
    },
    showroomItemReturnDescription: {
        en: 'Renter returns item to ROBES Showroom on {{ renterReturnDate }}',
        fi: 'Vuokraaja palauttaa tuotteen ROBES Showroomille {{ renterReturnDate }}',
    },
    uberItemReturn: {
        en: 'Time of return',
        fi: 'Palautusaika',
    },
    returnSectionTitle: {
        en: 'Return',
        fi: 'Palautus',
    },
    returnAddress: {
        en: 'Return address',
        fi: 'Palautusosoite',
    },
    pickupAddress: {
        en: 'Pickup address',
        fi: 'Noutopiste',
    },
    robesShowroom: {
        en: 'ROBES Showroom',
        fi: 'ROBES Showroom',
    },
    showroomDelivery: {
        en: 'Showroom pickup',
        fi: 'Nouto Showroomilta',
    },
    showroomPickupAndHomeDelivery: {
        en: 'Showroom pickup & home delivery',
        fi: 'Showroom-nouto ja kotiinkuljetus',
    },
    returnMethod: {
        en: 'Return method',
        fi: 'Palautustapa',
    },
    selectReturnMethod: {
        en: 'Select return method',
        fi: 'Valitse palautustapa',
    },
    missingReturnMethod: {
        en: 'Missing return method',
        fi: 'Palautustapa puuttuu',
    },
    selectPickupPoint: {
        en: 'Select pickup point',
        fi: 'Valitse noutopiste',
    },
    missingPickupPoint: {
        en: 'Missing pickup point',
        fi: 'Noutopiste puuttuu',
    },
    pickupPointsQueryChanged: {
        en: 'Address has changed, please select a new pickup point',
        fi: 'Osoite on muuttunut, valitse uusi noutopiste',
    },
    returnTime: {
        en: 'Time of return',
        fi: 'Palautusaika',
    },
    selectReturnTime: {
        en: 'Select time of return',
        fi: 'Valitse palautusaika',
    },
    missingReturnTime: {
        en: 'Missing time of return',
        fi: 'Palautusaika puuttuu',
    },
    showroomReturn: {
        en: 'Showroom drop-off',
        fi: 'Showroom-palautus',
    },
    showroomDropoffTitle: {
        en: 'Showroom drop-off',
        fi: 'Showroom-palautus',
    },
    showroomDropoffDescription: {
        en: 'You return the item to ROBES Showroom',
        fi: 'Palauta tuote ROBESin Showroomille',
    },
    moreOptionsComingSoonAlert: {
        en: '<h3>More options coming soon</h3>\n\n<p>We are working on bringing return deliveries to ROBES. Stay tuned!</p>',
        fi: '<h3>Lisää palautusvaihtoehtoja tulossa</h3>\n\n<p>Pyrimme tuomaan palautuskuljetukset ROBESiin mahdollisimman pian. Pysy kuulolla!</p>',
    },
    note: {
        en: 'Note',
        fi: 'Huom',
    },
    important: {
        en: 'Important',
        fi: 'Tärkeää',
    },
    showroomAvailabilityExceptions: {
        en: '<h4><b>Note! Showroom is closed: </b></h4>{{availabilityExceptionsString}}',
        fi: '<h4><b>Huom! Showroom on kiinni: </b></h4>{{availabilityExceptionsString}}',
    },
    pickupDateNote: {
        en: 'Please note the date of pickup',
        fi: 'Huomioithan noutopäivän',
    },
    returnDateNote: {
        en: 'Please note the date of return',
        fi: 'Huomioithan palautuspäivän',
    },
    deliveryDateNote: {
        en: 'Please note the date of delivery',
        fi: 'Huomioithan toimituspäivän',
    },
    deliveryOnDate: {
        en: 'Your item will be delivered to you on <b>{{ date }}.</b> <br /> <br />ROBES currently provides Wolt deliveries {{ openingHourDates }}. Enjoy the additional rental days free of charge!',
        fi: 'Tuote toimitetaan sinulle <b>{{ date }}.</b> <br /> <br />ROBES tarjoaa tällä hetkellä Wolt-toimituksia {{ openingHourDates }}. Nauti lisävuokrapäivistä ilmaiseksi!',
    },
    pickupOnDate: {
        en: 'Your pickup is on <b>{{ date }}, {{ openingHoursForDate }}</b>. <availability></availability><br /> <br />Enjoy the additional rental days free of charge! <br /> <br /> Please contact ROBES support to agree on returns outside opening hours.',
        fi: 'Noutosi on <b>{{ date }}, {{ openingHoursForDate }}</b> showroomilta. <availability></availability><br /> <br />Nauti lisävuokrapäivistä ilmaiseksi! <br /> <br /> Ota yhteyttä ROBESin tukeen sopiaksesi palautuksesta aukioloaikojen ulkopuolella.',
    },
    pickupOnDateSummary: {
        en: 'Pickup is <b>{{ daysDiffString }} before</b> rental starts',
        fi: 'Nouto on <b>{{ daysDiffString }} ennen</b> vuokrauksen alkua',
    },
    returnOnDate: {
        en: 'Your return is on <b>{{ date }}</b>, {{ openingHoursForDate }} to ROBES Showroom. <availability></availability><br /> <br />Enjoy the additional rental days free of charge! <br /> <br /> Please contact ROBES support to agree on returns outside opening hours.',
        fi: 'Palautuksesi on <b>{{ date }}</b>, {{ openingHoursForDate }} showroomillemme. <availability></availability><br /> <br />Nauti lisävuokrapäivistä ilmaiseksi! <br /> <br /> Ota yhteyttä ROBESin tukeen sopiaksesi palautuksesta aukioloaikojen ulkopuolella.',
    },
    returnOnDateSummary: {
        en: 'Drop-off is <b>{{ daysDiffString }} after</b> rental ends',
        fi: 'Palautus on <b>{{ daysDiffString }} vuokrauksen päättymisen</b> jälkeen',
    },
    method: {
        en: 'Method',
        fi: 'Toimitustapa',
    },
    enterDeliveryDetails: {
        en: 'Enter delivery address',
        fi: 'Syötä toimitusosoite',
    },
    selectDeliveryTime: {
        en: 'Select delivery time',
        fi: 'Valitse toimitusaika',
    },
    deliveryTimeDescription: {
        en: `Please ensure you are available at the agreed location and delivery time.`,
        fi: `Varmista, että olet sovitussa paikassa kyseisenä aikana.`,
    },

    invalidFormat: {
        en: 'Invalid format',
        fi: 'Virheellinen muoto',
    },
    requiredField: {
        en: 'Required',
        fi: 'Pakollinen tieto',
    },

    invalidPhoneNumber: {
        en: 'Enter phone number in format +358 40 1234567',
        fi: 'Syötä puhelinnumero muodossa +358 40 1234567',
    },
    missingOrInvalidDetails: {
        en: 'Missing or invalid details',
        fi: 'Puuttuvia tai virheellisiä tietoja',
    },
    missingDeliveryTime: {
        en: 'Missing delivery time',
        fi: 'Toimitusaika puuttuu',
    },
    missingDeliveryMethod: {
        en: 'Missing delivery method',
        fi: 'Toimitustapa puuttuu',
    },

    parcelDelivery: {
        en: 'Parcel delivery',
        fi: 'Pakettitoimitus',
    },
    woltDelivery: {
        en: 'Home delivery',
        fi: 'Kotiinkuljetus',
    },
    woltDeliveryArea: {
        en: 'Helsinki area',
        fi: 'Helsingin alue',
    },
    woltDeliveryDescriptionWithReturn: {
        en: `<b>Receiving the item</b> \n\nA Wolt partner will deliver the rented item to your location at the given delivery time. You will receive an SMS with a delivery tracking link just before the time of arrival. \n\nPlease ensure you are available to receive the item as agreed.\n\n<b>Returning the item</b>\n\n Return the item by dropping it off to ROBES Showroom at <underline>${showroomAddressStreet}</underline> on <b>{{ renterReturnDate }}</b>`,
        fi: `<b>Tuotteen vastaanottaminen</b> \n\nWolt-lähetti toimittaa vuokraamasi tuotteen valitsemaasi osoitteeseen sovittuna toimitusaikana. Saat seurantalinkin tekstiviestinä ennen toimitusta. \n\nVarmistathan, että olet paikalla vastaanottamassa tuotetta sovittuna aikana.\n\n<b>Tuotteen palauttaminen</b>\n\n Olet vastuussa vuokraamasi tuotteen palauttamisesta ROBESin Showroomille osoitteeseen <underline>${showroomAddressStreet}</underline> <b>{{ renterReturnDate }}</b>`,
    },
    woltDeliveryNotAcceptedPostalCode: {
        en: 'Sorry, Home delivery is currently not available to this location. Specify another address or select another delivery method.',
        fi: 'Valitettavasti Kotiinkuljetus ei ole saatavilla tähän osoitteeseen. Valitse toinen toimitustapa tai toinen osoite.',
    },
    matkahuoltoDelivery: {
        en: 'Parcel delivery',
        fi: 'Matkahuolto',
    },
    matkahuoltoDeliveryDescription: {
        en: 'Parcel delivery to a Matkahuolto service point near you',
        fi: 'Pakettitoimitus Matkahuollon palvelupisteeseen lähellä sinua',
    },
    showRoomDeliveryDescriptionWithReturn: {
        en: `Pick up the item from our showroom at <underline>${showroomAddressStreet}</underline> <b>{{ renterDeliveryDate }}</b>.\n\nYou are responsible for returning the rented item to the showroom on <b>{{ renterReturnDate }}.</b>`,
        fi: `Nouda tuote showroomistamme osoitteesta <underline>${showroomAddressStreet}</underline> <b>{{ renterDeliveryDate }}</b>. Palautus showroomille <b>{{ renterReturnDate }}.</b>`,
    },
    faceToFaceDeliveryDescriptionLong: {
        en: 'Receive your item by meeting the owner in person. The owner will contact you for pick-up details via chat.',
        fi: 'Nouto yhdessä sovitusta paikasta. Vuokraaja on sinuun yhteydessä chatin kautta ja voitte yhdessä sopia ajan ja paikan noudolle sekä palautukselle.',
    },
    rentalMaxLengthAlert: {
        en: 'Rentals can be booked 3 months in advance',
        fi: 'Vuokrauksia voi varata 3 kuukautta etukäteen',
    },
    showroomPickupDescription: {
        en: `Pick up the rented item from ROBES Showroom at ${showroomAddressStreet} on {{ date }}`,
        fi: `Nouda vuokraamasi tuote ROBES Showroomista osoitteesta ${showroomAddressStreet} {{ date }}.`,
    },
    showroomAddress: {
        en: 'Showroom address',
        fi: 'Showroom osoite',
    },
    faceToFaceDelivery: {
        en: 'Meet with lender',
        fi: 'Sovittu tapaaminen',
    },
    faceToFaceDeliveryDescription: {
        en: 'Owner will contact you for pick-up details',
        fi: 'Omistaja ottaa sinuun yhteyttä noutoa varten',
    },
    uberDelivery: {
        en: 'Home delivery',
        fi: 'Kotiinkuljetus',
    },
    uberDeliveryDescription: {
        en: 'Delivery to your location',
        fi: 'Toimitus valitsemaasi osoitteeseen',
    },
    uberReturn: {
        en: 'Return delivery',
        fi: 'Palautus',
    },
    uberReturnDeliveryDescription: {
        en: 'Uber courier pickup from your address',
        fi: 'Uber-kuriirin nouto valitsemastasi osoitteesta',
    },
    free: {
        en: 'Free',
        fi: 'Ilmainen',
    },
    selectDeliveryMethod: {
        en: 'Select delivery method',
        fi: 'Valitse toimitustapa',
    },
    deliveryMethod: {
        en: 'Delivery method',
        fi: 'Toimitustapa',
    },
    deliveryAddress: {
        en: 'Delivery address',
        fi: 'Toimitusosoite',
    },
    rememberForLater: {
        en: 'Remember for later',
        fi: 'Tallenna tiedot',
    },
    woltDeliveryUnavailable: {
        en: 'Home delivery is not available for this listing.',
        fi: 'Kotiinkuljetus ei ole saatavilla tälle listaukselle.',
    },
    showroomDeliveryUnavailable: {
        en: 'Showroom pickup is not available for this listing.',
        fi: 'Showroom-nouto ei ole saatavilla tälle listaukselle.',
    },
    woltAndShowRoomUnavailableForListing: {
        en: 'Home delivery and showroom pickup are not available for this listing.',
        fi: 'Kotiinkuljetus ja showroom-nouto eivät ole saatavilla tälle tuotteelle.',
    },
    deliveryTimeNotAvailable: {
        en: `Wolt deliveries and ROBES showroom pick-ups are available during showroom opening hours {{ openingHours }}. Please select a later rental start date for more delivery methods.`,
        fi: `Wolt-toimitukset ja ROBES Showroom-noudot ovat saatavilla showroomin aukioloaikoina {{ openingHours }}. Valitse myöhempi vuokrausajankohta saadaksesi lisää toimitusvaihtoehtoja.`,
    },
    limitedOptionsAvailable: {
        en: '<h3><b>Limited delivery methods available</b></h3> Select another rental period to enable more delivery methods for this item. <h4>Why are delivery methods limited for this item?</h4>We may limit certain delivery methods managed by ROBES if we cannot ensure that the item can be delivered to you in time for your rental or returned and cleaned in time for an upcoming rental.\n\nIn this case, you can always select <i>Meet the lender</i> as the delivery method and chat with the owner to agree on a pickup directly from them.<b><h4>Tip</h4></b>We recommend booking your item of choice well in advance to ensure a more convenient delivery!\n\n',
        fi: '<h3><b>Rajoitetut toimitusvaihtoehdot</b></h3> Valitse toinen vuokrausaika mahdollistaaksesi lisää toimitusvaihtoehtoja tälle tuotteelle. <h4>Miksi toimitusvaihtoehdot ovat rajoitetut tälle tuotteelle?</h4>Voimme rajoittaa tiettyjä toimitustapoja, jos emme voi taata, että tuote voidaan toimittaa sinulle ajoissa vuokrausta varten tai palauttaa ja puhdistaa ajoissa seuraavaa vuokrausta varten.\n\nTässä tapauksessa voit aina valita <i>Sovittu tapaaminen</i> toimitustavaksi ja sopia noudosta suoraan omistajan kanssa chatin kautta.<b><h4>Vinkki</h4></b>Suosittelemme varaamaan suosikkituotteesi hyvissä ajoin varmistaaksesi sujuvamman toimituksen!\n\n',
    },
    noOptionsAvailable: {
        en: '<h3><b>No delivery methods available for selected dates</b></h3> Select another rental period to enable more delivery methods for this item. <h4>Why are delivery methods limited for this item?</h4>We may limit certain delivery methods managed by ROBES if we cannot ensure that the item can be delivered to you in time for your rental or returned and cleaned in time for an upcoming rental.<b><h4>Tip</h4></b>We recommend booking your item of choice well in advance to ensure a more convenient delivery!\n\n',
        fi: '<h3><b>Ei toimitusvaihtoehtoja valituille päiville</b></h3> Valitse toinen vuokrausaika mahdollistaaksesi lisää toimitusvaihtoehtoja tälle tuotteelle. <h4>Miksi toimitusvaihtoehdot ovat rajoitetut tälle tuotteelle?</h4>Voimme rajoittaa tiettyjä toimitustapoja, jos emme voi taata, että tuote voidaan toimittaa sinulle ajoissa vuokrausta varten tai palauttaa ja puhdistaa ajoissa seuraavaa vuokrausta varten.<b><h4>Vinkki</h4></b>Suosittelemme varaamaan suosikkituotteesi hyvissä ajoin varmistaaksesi sujuvamman toimituksen!\n\n',
    },
    moreReturnMethodsComingSoon: {
        en: '<h3><b>More options coming soon</b></h3> We are working on bringing return deliveries to ROBES. Stay tuned!',
        fi: '<h3><b>Lisää palautusvaihtoehtoja tulossa</b></h3> Pyrimme tuomaan palautustoimitukset ROBESiin mahdollisimman pian. Pysy kuulolla!',
    },
    showRoom: {
        en: 'ROBES Showroom',
        fi: 'ROBES Showroom',
    },
    deliveryOption: {
        en: 'Delivery',
        fi: 'Toimitustapa',
    },
    showroom: {
        en: 'Showroom',
        fi: 'Showroom',
    },
    wolt: {
        en: 'Wolt',
        fi: 'Wolt',
    },

    showroomDeliveryDescription: {
        en: 'Pickup from ROBES Showroom',
        fi: 'Nouda ROBES Showroomista',
    },

    serviceFee: {
        en: 'Service Fee',
        fi: 'Palvelumaksu',
    },
    woltDeliveryFee: {
        en: 'Home delivery fee',
        fi: 'Wolt toimitusmaksu',
    },
    uberDeliveryFee: {
        en: 'Home delivery fee',
        fi: 'Uber toimitusmaksu',
    },
    matkahuoltoDeliveryFee: {
        en: 'Parcel delivery fee',
        fi: 'Matkahuolto toimitusmaksu',
    },
    woltDeliveryDisabled: {
        en: `Home delivery is not available for the rental start date. Select another delivery method or rental start date.`,
        fi: `Valitettavasti Kotiinkuljetusta ei ole saatavilla valitulle vuokrausajankohdalle. Valitse toinen toimitustapa tai vuokrausajankohta.`,
    },
    robesCredits: {
        en: 'ROBES Credits',
        fi: 'ROBES Krediitit',
    },
    useRobesCredits: {
        en: 'Use ROBES Credits',
        fi: 'Käytä ROBES Krediittejä',
    },
    creditsAvailable: {
        en: '{{ credits }} credits available',
        fi: '{{ credits }} krediittiä käytettävissä',
    },
    creditsExceedMaxUsage: {
        en: 'Up to {{ max }} credits can be spent on this rental.',
        fi: 'Korkeintaan {{ max }} krediittiä voi käyttää tähän vuokraukseen.',
    },
    creditsUsageDisabledCaption: {
        en: 'Credits can be used when total rental price is at least {{ price }}.',
        fi: 'Krediittejä voi käyttää maksuvälineenä, kun vuokrauksen kokonaishinta on vähintään {{ price }}.',
    },
    creditsUsageCaption: {
        en: 'Use ROBES Credits ({{ credits }} available)',
        fi: 'Käytä ROBES krediittejä ({{ credits}}) käytettävissä)',
    },
    reversal: {
        en: 'Reversal',
        fi: 'Kumoaminen',
    },
    valueAddedTax: {
        en: 'VAT 25.5%',
        fi: 'ALV 25.5%',
    },
    period: {
        en: 'Period',
        fi: 'Ajanjakso',
    },
    offlinePickupDate: {
        en: 'Offline pickup date',
        fi: 'Noutopäivä',
    },
    status: {
        en: 'Status',
        fi: 'Tila',
    },
    toPerson: {
        en: 'Customer',
        fi: 'Asiakas',
    },
    customer: {
        en: 'Renter',
        fi: 'Vuokraaja',
    },
    provider: {
        en: 'Lender',
        fi: 'Lainanantaja',
    },
    fromPerson: {
        en: 'Owner',
        fi: 'Omistaja',
    },
    income: {
        en: 'Income',
        fi: 'Kokonaisansio',
    },
    cost: {
        en: 'Cost',
        fi: 'Kokonaishinta',
    },
    decline: {
        en: 'Decline',
        fi: 'Hylkää',
    },
    cancel: {
        en: 'Cancel',
        fi: 'Peruuta',
    },
    cancelBooking: {
        en: 'Cancel booking',
        fi: 'Peruuta varaus',
    },
    continue: {
        en: 'Continue',
        fi: 'Jatka',
    },
    skip: {
        en: 'Skip',
        fi: 'Ohita',
    },
    hasCompletedSCA: {
        en: 'User identity is verified',
        fi: 'Käyttäjän henkilöllisyys todennettu',
    },
    requestDeletionTitle: {
        en: 'You may request deletion of your account by sending an email to support@robesrental.com by using this <supportLink>link.</supportLink>',
        fi: 'Voit pyytää tilisi poistamista lähettämällä sähköpostia osoitteeseen support@robesrental.com käyttämällä tätä <supportLink>linkkiä.</supportLink>',
    },
    requestDeletionBodyTitle: {
        en: 'Deleting your ROBES Account means that:',
        fi: 'ROBES-käyttäjätilin poistaminen tarkoittaa:',
    },
    requestDeletionBody: {
        en: '<listItem> The user account and all the listings the user has created will be deleted. </listItem> <listItem> Personal user data will be removed according to GDPR guidelines. The messages and reviews the user has sent will not be deleted. </listItem> <listItem> If the user has ongoing transactions, they will not move forward. </listItem> <listItem> The user cannot receive payouts after deletion. </listItem> <listItem> Payouts will not be made for any ongoing transaction involving this user, including payouts for other users. </listItem>',
        fi: '<listItem> Käyttäjätili ja kaikki käyttäjän luomat listaukset poistetaan. </listItem> <listItem> Käyttäjän henkilökohtaiset tiedot poistetaan GDPR-ohjeiden mukaisesti. Käyttäjän lähettämiä viestejä ja arvosteluja ei poisteta. </listItem> <listItem> Jos käyttäjällä on käynnissä olevia tapahtumia, ne eivät etene pidemmälle. </listItem> <listItem> Käyttäjä ei voi saada maksuja poiston jälkeen. </listItem> <listItem> Maksuja ei suoriteta mistään meneillään olevasta tapahtumasta, jossa tämä käyttäjä on mukana, mukaan lukien maksut muille käyttäjille. </listItem>',
    },
    permanentAction: {
        en: 'This action is <strong>permanent.</strong>',
        fi: 'Tämä toiminto on <strong>lopullinen.</strong>',
    },
    accountDeletionEmailSubject: {
        en: 'ROBES - Account deletion request',
        fi: 'ROBES - Käyttäjätilin sulkemispyyntö',
    },
    accountDeletionEmailBody: {
        en: 'I hereby request my ROBES account to be deleted. I acknowledge that this action is permanent, and that my account can not be recovered afterwards.',
        fi: 'Täten pyydän, että ROBES-tilini poistetaan. Ymmärrän, että tämä toimenpide on lopullinen eikä tiliäni voida tämän jälkeen enää palauttaa.',
    },

    'transaction-order-confirm': {
        en: 'Accept proposal for listing {{ title }}?',
        fi: 'Hyväksy listauksen {{ title }} vuokrauspyyntö?',
    },
    'transaction-order-decline': {
        en: 'Decline proposal for listing {{ title }}?',
        fi: 'Hylkää listauksen {{ title }} vuokrauspyyntö?',
    },
    'transaction-sale-confirm': {
        en: 'Accept rental proposal by {{ name }} for listing {{ title }}?',
        fi: 'Hyväksy käyttäjän {{ name }} vuokrapyyntö listauksesta {{ title }}?',
    },
    'transaction-sale-decline': {
        en: 'Decline this rental proposal from user {{ name }}?',
        fi: 'Kieltäydy käyttäjän {{ name }} vuokrapyynnöstä?',
    },
    'transaction-dialog-title-confirm': {
        en: 'Confirm proposal?',
        fi: 'Hyväksy pyyntö?',
    },
    'transaction-dialog-title-decline': {
        en: 'Decline proposal',
        fi: 'Kieltäydy pyynnöstä',
    },
    'transaction-dialog-title-cancel-request': {
        en: 'Cancel rental request?',
        fi: 'Peruuta vuokrauspyyntö?',
    },
    'transaction-dialog-message-cancel-request': {
        en: 'You will be refunded in full and the lender will be informed about your cancellation.',
        fi: 'Maksamasi summa palautetaan sinulle, ja lainanantajalle ilmoitetaan peruutuksestasi.',
    },
    'transaction-dialog-title-cancel-rental': {
        en: 'Cancel rental?',
        fi: 'Peruuta vuokraus?',
    },
    'transaction-dialog-message-cancel-rental': {
        en: 'Rentals can be cancelled up to 3 days before the rental starts with no cost. If you wish to cancel the rental when there is less than 3 days remaining until the rental starts, email us at support@robesrental.com.',
        fi: 'Vuokrauksen voi peruuttaa enintään 3 päivää ennen vuokrauksen alkamista ilman kustannuksia. Tämän jälkeen vuokraus on peruutettava sähköpostitse asiakaspalvelussamme support@robesrental.com.',
    },
    'transaction-card-counterparty-sale': {
        en: 'Customer',
        fi: 'Asiakas',
    },
    'transaction-card-counterparty-order': {
        en: 'Owner',
        fi: 'Omistaja',
    },
    'transaction-card-chat-text-sale': {
        en: 'Chat with renter',
        fi: 'Keskustele vuokraajan kanssa',
    },
    'transaction-card-chat-text-order': {
        en: 'Chat with lender',
        fi: 'Keskustele vuokraajan kanssa',
    },

    stripeAccountSetupError: {
        en: 'The listing author has not added their payment information and the listing cannot be booked at the moment.',
        fi: 'Listauksen luoja ei ole päivittänyt maksutietojaan joten listaus ei ole tällä hetkellä varattavissa.',
    },
    ownerMissingCapabilitiesError: {
        en: 'This item is temporarily unavailable for booking as the lender needs to complete some account details. You can message them to express your interest in renting this item!',
        fi: 'Tämä listaus ei ole tällä hetkellä varattavissa, koska listauksen omistajan tilitiedot eivät ole ajantasalla. Voit lähettää heille viestin ilmaistaksesi kiinnostuksesi tämän tuotteen vuokraamiseen!',
    },
    stripe_error_unknown: {
        en: 'Something went wrong, please try again. If this issue persists, contact support.',
        fi: 'Jokin meni pieleen, yritä uudelleen. Jos ongelma jatkuu, ota yhteyttä asiakaspalveluun.',
    },
    stripe_error_account_number_invalid_FI: {
        en: 'Invalid IBAN. Input your IBAN in the following format: FI2231298227946566 or FI22 3129 8227 9465 66.',
        fi: 'Vääränmuotoinen IBAN. Syötä IBAN muodossa FI2231298227946566 tai FI22 3129 8227 9465 66.',
    },
    stripe_error_account_number_invalid_US: {
        en: 'Invalid account number. Account number should be 8-12 digits long.',
        fi: 'Vääränmuotoinen tilinumero. Tilinumeron tulisi olla 8-12 merkkiä pitkä.',
    },
    stripe_error_routing_number_invalid_US: {
        en: 'Invalid routing number. Routing number should have 9 digits.',
        fi: 'Vääränmuotoinen reititysnumero. Reititysnumeron tulisi olla 9 merkkiä pitkä.',
    },
    stripeVerificationFailed: {
        en: 'Verification failed. Please check your information and try again.',
        fi: 'Tunnistautuminen epäonnistui. Tarkista tietosi ja yritä uudelleen.',
    },
    stripeNotConnectedError: {
        en: `Before you can start earning with your wardrobe, please share your payment information. Select the bank account to which rental payouts are made. Adding your information takes less than a minute, honest!`,
        fi: `Lisää maksutietosi ja aloita tienaamaan vaatekaapillasi jo tänään. Valitse tili, jolle vuokraustulot maksetaan vuokrausjaksojen jälkeen. Tilitietojen lisääminen vie alle minuutin.`,
    },
    stripeFutureRequirementsWarning: {
        en: 'Payouts will be disabled soon! Please update your account details  via the link below to continue receiving your rental payments.',
        fi: 'Maksut ovat poissa käytöstä pian! Päivitä tietosi alla olevan linkin kautta jatkaaksesi vuokramaksujen vastaanottamista.',
    },
    stripeFutureRequirementsDescription: {
        en: 'Our payment processor, Stripe, requires additional information to ensure the safety of our service. Please follow the link below to update your information.',
        fi: 'Maksupalvelumme, Stripe, vaatii lisätietoja palvelumme turvallisuuden takaamiseksi. Päivitä tietosi alla olevan linkin kautta.',
    },
    stripeRequirementsDescription: {
        en: `
        <h3>Why is This Information Required?</h3> As you start earning, Stripe might request additional information from you once you reach a certain revenue threshold (this varies for each user). This is a standard procedure to fulfill their “Know Your Customer” (KYC) obligations and to keep your transactions secure.
        
        <h3>What is Stripe?</h3>At ROBES, we use Stripe, a trusted global payment processor, to handle payments securely for your earnings when you lend clothes on our platform. Stripe ensures that all transactions are safe, and they comply with financial regulations to protect both you and your earnings.

            For more information, you can visit Stripe's documentation on <identityVerificationLink>identity verification</identityVerificationLink> and <KYCLink>KYC process</KYCLink>.

            Thank you for being a part of our community and happy lending!`,
        fi: `
            <h3>Mikä on Stripe?</h3>ROBES käyttää Stripea, luotettua globaalia maksupalvelua, joka käsittelee maksut turvallisesti ansioistasi, kun lainaat vaatteitasi alustallamme. Stripe varmistaa, että kaikki tapahtumat ovat turvallisia ja he noudattavat rahoitusmääräyksiä suojellakseen sekä sinua että ansioitasi.

            <h3>Miksi Tätä Tietoa Tarvitaan?</h3> Kun alat ansaita vaatteillasi, Stripe saattaa pyytää sinulta lisätietoja, kun saavutat tietyn tulorajan. Tämä on standardimenettely, jolla täytetään heidän "Tunne asiakkaasi" (KYC) -velvoitteensa ja varmistetaan, että tilitapahtumasi ovat turvallisia.

            Lisätietoja löydät Stripen dokumentaatiosta <identityVerificationLink>henkilöllisyyden varmennuksesta</identityVerificationLink> ja <KYCLink>KYC-prosessista</KYCLink>.

            Kiitos, että olet osa yhteisöämme ja iloista lainaamista!`,
    },
    stripeAccountPausedByAdminError: {
        en: 'Your account payouts have been paused by the admin. Please contact customer support for more information.',
        fi: 'Tilisi maksut on keskeytetty ylläpidon toimesta. Ota yhteyttä asiakaspalveluumme saadaksesi lisätietoja.',
    },
    fillInformationByDeadline: {
        en: '<h3>Fill in the information by</h3> {{ deadline }}',
        fi: '<h3>Täytä tiedot päivämäärään mennessä</h3> {{ deadline }}',
    },
    stripePayoutsDisabledError: {
        en: 'Additional identification information is required to ensure the safety of our service. Please follow the link below to update your information. This is a requirement for all ROBES users in order to use the service and receive rental payments.',
        fi: 'Lisätietoja tunnistamistasi varten tarvitaan palvelumme turvallisuuden takaamiseksi. Seuraamalla alla olevaa linkkiä pääset päivittämään tietosi. Tämä on vaatimus kaikille ROBES-käyttäjille palvelun käyttämiseksi ja vuokramaksujen vastaanottamiseksi.',
    },
    stripePayoutsDisabledErrorShort: {
        en: 'Payouts are currently disabled. Go to Payments and payouts settings to enable payouts.',
        fi: 'Maksut ovat tällä hetkellä poissa käytöstä. Mene Maksut ja maksut -asetuksiin ottaaksesi maksut käyttöön.',
    },
    stripeNotConnectedButtonTitle: {
        en: 'Press here to add payment information',
        fi: 'Paina tästä lisätäksesi maksutietosi',
    },
    stripePayoutsDisabledSnackbar: {
        en: 'Payouts are currently disabled. Please update your bank account details immediately on the <payoutLink>payments and payouts</payoutLink> page to continue receiving your rental payments.',
        fi: 'Maksut ovat tällä hetkellä poissa käytöstä. Päivitä pankkitilisi tiedot välittömästi  <payoutLink>maksujen ja maksusuoritusten</payoutLink> sivulla jatkaaksesi vuokramaksujen vastaanottamista.',
    },
    stripeFutureRequirementsSnackbar: {
        en: 'Payouts will be disabled soon! Please update your account details on the <payoutLink>payments and payouts</payoutLink> page to continue receiving your rental payments.',
        fi: 'Maksut ovat poissa käytöstä pian! Päivitä tietosi <payoutLink>maksujen ja maksusuoritusten</payoutLink> sivulla jatkaaksesi vuokramaksujen vastaanottamista.',
    },
    bookingTimeNotAvailableError: {
        en: 'Unfortunately, this booking time is no longer available.',
        fi: 'Valitettavasti tämä varausaika ei ole enää saatavilla.',
    },
    listingNotAvailableError: {
        en: 'Unfortunately, this listing is no longer available.',
        fi: 'Valitettavasti tämä listaus ei ole enää saatavilla.',
    },
    googlePayError: {
        en: 'Google Pay payment did not go through. Please try again, or try another payment method.',
        fi: 'Google Pay maksu epäonnistui. Yritä uudelleen tai kokeile toista maksutapaa.',
    },
    applePayError: {
        en: 'Apple Pay payment did not go through. Please try again, or try another payment method.',
        fi: 'Apple Pay maksu epäonnistui. Yritä uudelleen tai kokeile toista maksutapaa.',
    },
    cardPaymentError: {
        en: 'Card payment did not go through. Please try again, or try another payment method.',
        fi: 'Korttimaksu epäonnistui. Yritä uudelleen tai kokeile toista maksutapaa.',
    },
    paymentCancelledError: {
        en: 'Payment was cancelled. You can try again or choose another payment method.',
        fi: 'Maksu keskeytyi. Voit yrittää maksua uudelleen tai valita toisen maksutavan.',
    },
    pendingConfirmation: {
        en: 'Pending confirmation',
        fi: 'Odottaa hyväksyntää',
    },
    deleted: {
        en: 'Deleted',
        fi: 'Poistettu',
    },
    pendingApproval: {
        en: 'Pending approval',
        fi: 'Odottaa hyväksyntää',
    },
    pendingApprovalDescription: {
        en: 'Pending approval. We will notify you once we have approved this listing.',
        fi: 'Odottaa hyväksyntää. Ilmoitamme sinulle, kun ilmoituksesi on hyväksytty.',
    },
    rentalStartsIn: {
        en: 'Rental starts in',
        fi: 'Vuokra-ajan alkuun',
    },
    rentalPeriodComplete: {
        en: 'Rental period complete',
        fi: 'Vuokra-aika päättynyt',
    },
    rented: {
        en: 'Rented',
        fi: 'Vuokrattu',
    },
    youEarn: {
        en: 'You earn',
        fi: 'Ansaitset',
    },
    comingSoon: {
        en: 'Coming soon!',
        fi: 'Tulossa pian!',
    },
    scrollToProceed: {
        en: 'Scroll down to proceed',
        fi: 'Siirry alaspäin jatkaaksesi',
    },
    swipeToProceed: {
        en: 'Swipe to proceed',
        fi: 'Pyyhkäise jatkaaksesi',
    },

    // How it works

    howItWorks: {
        en: 'How does ROBES work?',
        fi: 'Kuinka ROBES toimii?',
    },
    from: {
        en: 'From',
        fi: 'Alkaen',
    },
    selectCountryOfResidence: {
        en: 'Please select country of residence',
        fi: 'Valitse asuinmaa',
    },
    country: {
        en: 'Country',
        fi: 'Maa',
    },
    countryOfResidence: {
        en: 'Country of residence',
        fi: 'Asuinmaa',
    },
    changeCountryOfResidenceTitle: {
        en: 'Change country of residence and log out?',
        fi: 'Vaihda asuinmaa ja kirjaudu ulos?',
    },
    changeCountryOfResidenceMessage: {
        en: `Are you sure you want to change your country of residence? ROBES Accounts are country specific, so you will need to create a new account for the selected country. \n\n Selecting confirm will log you out.`,
        fi: `Haluatko varmasti vaihtaa asuinmaata? ROBES-tilit ovat maakohtaisia, joten sinun on luotava uusi tili valitulle maalle. \n Valitsemalla 'vahvista' sinut kirjataan ulos käyttäjältäsi`,
    },
    countryOfResidenceChangeSuccess: {
        en: 'Country of residence changed successfully',
        fi: 'Asuinmaa vaihdettu onnistuneesti',
    },
    fromAbbreviated: {
        en: 'From',
        fi: 'Alk.',
    },

    // Countries

    'country-FI': {
        en: 'Finland',
        fi: 'Suomi',
    },
    'country-US': {
        en: 'United States',
        fi: 'Yhdysvallat',
    },

    // Transaction card states

    // Uber - Renter
    rentalStatusRenterUpcomingUber: {
        en: 'Uber courier will deliver your item to you <b>{{ deliveryTime }}</b>.\n\nPlease ensure you are available to receive the item as agreed.',
        fi: 'Uber toimittaa tuotteen sinulle <b>{{ deliveryTime }}</b>.\n\nSaat seurantalinkin tekstiviestillä juuri ennen toimituksen saapumista. \n\nVarmista, että olet paikalla vastaanottamassa tuotetta sovittuna aikana.',
    },
    rentalStatusRenterStartsTodayUber: {
        en: 'Uber courier will deliver your item to you <b>{{ deliveryTime }}</b>.\n\n\n\nPlease ensure you are available to receive the item as agreed.',
        fi: 'Uber toimittaa tuotteen sinulle <b>{{ deliveryTime }}</b>.\n\nSaat seurantalinkin tekstiviestillä juuri ennen toimituksen saapumista. \n\nVarmista, että olet paikalla vastaanottamassa tuotetta sovittuna aikana.',
    },
    rentalStatusRenterInProgressUber: {
        en: `Enjoy your rental! We will inform you about the return delivery at the end of the rental period.`,
        fi: `Vuokraus on käynnissä, palauta tuote ROBES Showroomiin osoitteeseen <underline>${showroomDisplayNameFull}</underline>, <b> {{ d2 }}.</b>`,
    },
    rentalStatusRenterEndsTodayUber: {
        en: `Rental ends today! Uber courier will pick your rental item up at <b>{{ returnTime }}.</b>\n\nPlease ensure you are available to return the item as agreed.`,
        fi: `Vuokrausaika päättyy tänään, palauta tuote ROBES Showroomiin osoitteeseen <underline>${showroomDisplayNameFull}</underline>, <b> {{ d2 }}.</b>`,
    },
    rentalStatusRenterPastUber: {
        en: 'Rental period has ended, thank you for renting!',
        fi: 'Vuokrausaika on päättynyt, kiitos lainaamisesta!',
    },

    // Uber - Lender
    rentalStatusLenderNotAcceptedUber: {
        en: `Uber courier will pick up your item from you at <b>{{ deliveryTime }}</b> .</b> \n\nPlease ensure you are availble to hand over the item as agreed.`,
        fi: `Tuo tuote ROBES Showroomiin osoitteeseen <underline>${showroomDisplayNameFull}</underline> <b>{{ d1 }}.</b> \n\n ROBES hoitaa tuotteen toimituksen vuokraajalle ja takaisin. <h3>Tuotteen palautus</h3>Tuotteesi on noudettavissa ROBESin showroomilta <b>{{ d2 }}</b>.`,
    },
    rentalStatusLenderNotAcceptedPresentInShowroomUber: {
        en: `Your item is at the showroom.\n\n ROBES will handle delivery and return with the renter for you! <h3>Returning the item</h3>Your item will be available for pickup from ROBES showroom <b> {{ d2 }}</b>.`,
        fi: 'Tuotteesi on showroomilla.\n\n ROBES hoitaa toimituksen ja palautuksen puolestasi! <h3>Tuotteen palautus</h3>Tuotteesi on noudettavissa ROBESin showroomilta <b>{{ d2 }}</b>.',
    },
    rentalStatusLenderUpcomingUber: {
        en: `Hand over the item to the Uber courier before the time of delivery <b>{{ deliveryTime }}</b>, at your location.`,
        fi: `Tuo tuote ROBES Showroomiin osoitteeseen <b>${showroomDisplayNameFull}</b>.`,
    },
    rentalStatusLenderUpcomingPresentInShowroomUber: {
        en: 'Your item is at the showroom.\n\n ROBES will handle delivery and return with the renter for you! Your item will be available for pickup from ROBES showroom <b> {{ d2 }}.</b>',
        fi: 'Tuotteesi on showroomilla.\n\n ROBES hoitaa toimituksen ja palautuksen puolestasi! Tuotteesi on noudettavissa ROBESin showroomilta <b> {{ d2 }}.</b>',
    },
    rentalStatusLenderStartsTodayUber: {
        en: 'Your item is at the showroom.\n\n ROBES will handle delivery and return with the renter for you! Your item will be available for pickup from ROBES showroom <b> {{ d2 }}.</b>',
        fi: 'Tuotteesi on showroomilla.\n\n ROBES hoitaa toimituksen ja palautuksen puolestasi! Tuotteesi on noudettavissa ROBESin showroomilta <b> {{ d2 }}.</b>',
    },
    rentalStatusLenderStartsTodayPresentInShowroomUber: {
        en: `Rental starts today, bring the item to ROBES Showroom at <underline>${showroomDisplayNameFull}</underline>, <b> {{ d1 }}.`,
        fi: `Vuokrausaika alkaa tänään, tuo tuote ROBES Showroomiin osoitteeseen <underline>${showroomDisplayNameFull}</underline>, <b> {{ d1 }}.`,
    },
    rentalStatusLenderInProgressUber: {
        en: 'Rental in progress, you may come pick the item back up on <b>{{ d2 }}</b>.',
        fi: 'Vuokraus on käynnissä, voit tulla hakemaan tuotteen takaisin <b>{{ d2 }}</b>.',
    },
    rentalStatusLenderEndsTodayUber: {
        en: `Rental period ends today, you may come pick up the item from ROBES Showroom at <underline>${showroomDisplayNameFull}</underline>, <b> {{ d2 }} </b>`,
        fi: `Vuokrausaika päättyy tänään, voit tulla hakemaan tuotteen ROBES Showroomista osoitteesta <underline>${showroomDisplayNameFull}</underline>, <b> {{ d2 }} </b>`,
    },
    rentalStatusLenderPastUber: {
        en: 'Rental has ended, thank you for lending!',
        fi: 'Vuokrausaika on päättynyt, kiitos lainaamisesta!',
    },

    // Matkahuolto - Renter
    rentalStatusRenterMatkahuoltoPendingActivation: {
        en: `Your parcel tracking code will appear here after the owner has sent the item. \n\nYou will receive an SMS notification from Matkahuolto when your item is ready for pickup. \n
        <h3>Pickup point</h3><ul><li>{{ pickupPoint.Name }}</li>
        <li>{{ pickupPoint.StreetAddress }}</li>
        <li>{{ pickupPoint.PostalCode }} {{ pickupPoint.City }}</li></ul>`,
        fi: `
        Pakettisi seurantakoodi ilmestyy tähän, kun omistaja on lähettänyt tuotteen. \n\nSaat Matkahuollolta tekstiviestin, kun paketti on noudettavissa. \n
        <h3>Noutopiste</h3>
        <ul><li>{{ pickupPoint.Name }}</li>
        <li>{{ pickupPoint.StreetAddress }}</li>
        <li>{{ pickupPoint.PostalCode }} {{ pickupPoint.City }}</li></ul>`,
    },
    rentalStatusRenterMatkahuoltoDeliveryTracking: {
        en: `Track your parcel with code \n<StyledTrackingUrl></StyledTrackingUrl>. \n\nYou will receive an SMS notification from Matkahuolto when your item is ready for pickup. \n
        <h3>Pickup point</h3><ul><li>{{ pickupPoint.Name }}</li>
        <li>{{ pickupPoint.StreetAddress }}</li>
        <li>{{ pickupPoint.PostalCode }} {{ pickupPoint.City }}</li></ul>`,
        fi: `Seuraa pakettia koodilla \n<StyledTrackingUrl></StyledTrackingUrl>. \nSaat Matkahuollolta tekstiviestin, kun paketti on noudettavissa. \n
        <h3>Noutopiste</h3>
        <ul><li>{{ pickupPoint.Name }}</li>
        <li>{{ pickupPoint.StreetAddress }}</li>
        <li>{{ pickupPoint.PostalCode }} {{ pickupPoint.City }}</li></ul>`,
    },
    rentalStatusRenterInProgressMatkahuolto: {
        en: 'Enjoy your rental! We will inform you about the return delivery at the end of the rental period.',
        fi: 'Nauti vuokrauksestasi! Ilmoitamme sinulle palautuksesta vuokra-ajan lopussa.',
    },
    rentalStatusRenterMatkahuoltoCancelled: {
        en: 'Rental request has been cancelled',
        fi: 'Vuokrauspyyntö on peruttu',
    },
    rentalStatusRenterMatkahuoltoReturnInstructions: {
        en: 'Enjoy your rental! \n\nSee the <ReturnInstructionsButton>return instructions</ReturnInstructionsButton> for returning the item to the lender, <b>latest by {{ renterReturnDate }}</b>',
        fi: 'Nauti vuokrastasi! \n\nKatso <ReturnInstructionsButton>palautusohjeet</ReturnInstructionsButton> palauttaaksesi tuotteen omistajalle, <b>viimeistään {{ renterReturnDate }}</b>',
    },
    rentalStatusRenterMatkahuoltoReturnConfirmation: {
        en: 'Your return delivery has been confirmed. \n\n Thank you for renting!',
        fi: 'Palautuksesi on vahvistettu. \n\n Kiitos lainaamisesta!',
    },

    // Matkahuolto - Lender
    rentalStatusLenderNotAcceptedMatkahuolto: {
        en: `Send your item to the renter as a Matkahuolto parcel delivery <b>latest by {{ lenderDropoffDate }} </b>  \n\nYour parcel activation code will be shown to you after confirming the rental request.<h3>Returning the item</h3>The renter will send your item back to you and will be available for pickup at your preferred Matkahuolto service point <b>latest by {{ lenderPickupDate }}</b>`,
        fi: `Lähetä tuotteesi vuokraajalle Matkahuollon pakettina <b>viimeistään {{ lenderDropoffDate }} </b>  \n\nPakettisi aktivointikoodi näytetään sinulle vuokrauspyynnön hyväksymisen jälkeen.\n\n<h3>Tuotteen palautus</h3>Vuokraaja lähettää tuotteesi takaisin sinulle ja se on noudettavissa valitsemastasi Matkahuollon palvelupisteestä <b>viimeistään {{ lenderPickupDate }}</b>`,
    },
    rentalStatusLenderNotAcceptedPresentInShowroomMatkahuolto: {
        en: `Your item is at the showroom.\n\n ROBES will handle delivery and return with the renter for you! <h3>Returning the item</h3>Your item will be available for pickup from ROBES showroom <b> {{ lenderPickupDate }}</b>.`,
        fi: 'Tuotteesi on showroomilla.\n\n ROBES hoitaa toimituksen ja palautuksen puolestasi! <h3>Tuotteen palautus</h3>Tuotteesi on noudettavissa ROBESin showroomilta <b>{{ lenderPickupDate }}</b>.',
    },
    rentalStatusLenderMatkahuoltoDeliveryInstructions: {
        en: `See the <DeliveryInstructionsButton>delivery instructions</DeliveryInstructionsButton> for sending your item to the renter, <b>latest by {{ lenderDropoffDate }}</b>`,
        fi: `Katso <DeliveryInstructionsButton>toimitusohjeet</DeliveryInstructionsButton> lähettääksesi tuotteesi vuokraajalle, <b>viimeistään {{ lenderDropoffDate }}</b>`,
    },
    rentalStatusLenderMatkahuoltoCancelled: {
        en: 'Rental request has been cancelled',
        fi: 'Vuokrauspyyntö on peruttu',
    },
    rentalStatusLenderMatkahuoltoDeliveryInstructionsPresentInShowroom: {
        en: 'Your item is at the showroom.\n\n ROBES will handle delivery and return with the renter for you! Your item will be available for pickup from ROBES showroom <b> {{ lenderPickupDate }}.</b>',
        fi: 'Tuotteesi on showroomilla.\n\n ROBES hoitaa toimituksen ja palautuksen puolestasi! Tuotteesi on noudettavissa ROBESin showroomilta <b> {{ lenderPickupDate }}.</b>',
    },
    rentalStatusLenderMatkahuoltoInitialTrackingPresentInShowroom: {
        en: 'Your item is at the showroom.\n\n ROBES will handle delivery and return with the renter for you! Your item will be available for pickup from ROBES showroom <b> {{ lenderPickupDate }}.</b>',
        fi: 'Tuotteesi on showroomilla.\n\n ROBES hoitaa toimituksen ja palautuksen puolestasi! Tuotteesi on noudettavissa ROBESin showroomilta <b> {{ lenderPickupDate }}.</b>',
    },
    rentalStatusLenderMatkahuoltoReturnTrackingPresentInShowroom: {
        en: 'Your item is at the showroom.\n\n ROBES will handle delivery and return with the renter for you! Your item will be available for pickup from ROBES showroom <b> {{ lenderPickupDate }}.</b>',
        fi: 'Tuotteesi on showroomilla.\n\n ROBES hoitaa toimituksen ja palautuksen puolestasi! Tuotteesi on noudettavissa ROBESin showroomilta <b> {{ lenderPickupDate }}.</b>',
    },
    rentalStatusLenderStartsTodayPresentInShowroomMatkahuolto: {
        en: 'Your item is at the showroom.\n\n ROBES will handle delivery and return with the renter for you! Your item will be available for pickup from ROBES showroom <b> {{ lenderPickupDate }}.</b>',
        fi: 'Tuotteesi on showroomilla.\n\n ROBES hoitaa toimituksen ja palautuksen puolestasi! Tuotteesi on noudettavissa ROBESin showroomilta <b> {{ lenderPickupDate }}.</b>',
    },
    rentalStatusLenderMatkahuoltoPresentInShowroom: {
        en: 'Your item is at the showroom.\n\n ROBES will handle delivery and return with the renter for you! Your item will be available for pickup from ROBES showroom <b> {{ lenderPickupDate }}.</b>',
        fi: 'Tuotteesi on showroomilla.\n\n ROBES hoitaa toimituksen ja palautuksen puolestasi! Tuotteesi on noudettavissa ROBESin showroomilta <b> {{ lenderPickupDate }}.</b>',
    },
    rentalStatusLenderMatkahuoltoDeliveryTracking: {
        en: 'Track your sent parcel with code <StyledTrackingUrl></StyledTrackingUrl> \n\nYou will be notified about the return delivery and receive a new tracking code at the end of the rental period.',
        fi: 'Seuraa lähetettyä pakettiasi koodilla <StyledTrackingUrl></StyledTrackingUrl> \n\nSinulle ilmoitetaan palautuksesta ja saat uuden seurantakoodin vuokrausjakson lopussa.',
    },
    rentalStatusLenderMatkahuoltoReturnPendingActivation: {
        en: `Renter has received your item!\n\nReturn tracking information will be shown here after the rental has ended. See the <ReturnInstructionsButton>return details</ReturnInstructionsButton> for more information.`,
        fi: `Vuokraaja on vastaanottanut pakettisi!\n\nPalautuslähetyksen seurantatiedot näytetään täällä vuokrausjakson päätyttyä. Katso <ReturnInstructionsButton>palautusohjeet</ReturnInstructionsButton> lisätietoja varten.`,
    },
    rentalStatusLenderMatkahuoltoReturnTracking: {
        en: 'Track your return delivery with code <StyledTrackingUrl></StyledTrackingUrl> \n\nYou will receive an SMS notification from Matkahuolto when your item is ready for pickup. \n',
        fi: 'Seuraa palautuslähetystä koodilla <StyledTrackingUrl></StyledTrackingUrl> \n\nSaat tekstiviestin Matkahuollolta, kun tuotteesi on valmis noudettavaksi. \n',
    },
    rentalStatusLenderMatkahuoltoRentalComplete: {
        en: 'Thank you for lending!',
        fi: 'Kiitos lainaamisesta!',
    },
    rentalStatusLenderPastMatkahuolto: {
        en: 'Rental has ended, thank you for lending!',
        fi: 'Vuokrausaika on päättynyt, kiitos lainaamisesta!',
    },

    // Wolt - Renter
    rentalStatusRenterUpcomingWolt: {
        en: 'Wolt will deliver your item to you <b>{{ deliveryTime }}</b>.\n\nYou will receive an SMS with a delivery tracking link just before the time of arrival. \n\nPlease ensure you are available to receive the item as agreed.',
        fi: 'Wolt toimittaa tuotteen sinulle <b>{{ deliveryTime }}</b>.\n\nSaat seurantalinkin tekstiviestillä juuri ennen toimituksen saapumista. \n\nVarmista, että olet paikalla vastaanottamassa tuotetta sovittuna aikana.',
    },
    rentalStatusRenterStartsTodayWolt: {
        en: 'Wolt will deliver your item to you <b>{{ deliveryTime }}</b>.\n\nYou will receive an SMS with a delivery tracking link just before the time of arrival. \n\nPlease ensure you are available to receive the item as agreed.',
        fi: 'Wolt toimittaa tuotteen sinulle <b>{{ deliveryTime }}</b>.\n\nSaat seurantalinkin tekstiviestillä juuri ennen toimituksen saapumista. \n\nVarmista, että olet paikalla vastaanottamassa tuotetta sovittuna aikana.',
    },
    rentalStatusRenterInProgressWolt: {
        en: `Enjoy your rental!\n\nReturn the item to ROBES Showroom at <underline>${showroomDisplayNameFull}</underline>, <b> {{ d2 }}.</b>`,
        fi: `Vuokraus on käynnissä, palauta tuote ROBES Showroomiin osoitteeseen <underline>${showroomDisplayNameFull}</underline>, <b> {{ d2 }}.</b>`,
    },
    rentalStatusRenterEndsTodayWolt: {
        en: `Rental ends today, return the item to ROBES Showroom at <underline>${showroomDisplayNameFull}</underline>, <b> {{ d2 }}.</b>`,
        fi: `Vuokrausaika päättyy tänään, palauta tuote ROBES Showroomiin osoitteeseen <underline>${showroomDisplayNameFull}</underline>, <b> {{ d2 }}.</b>`,
    },
    rentalStatusRenterPastWolt: {
        en: 'Rental period has ended, thank you for renting!',
        fi: 'Vuokrausaika on päättynyt, kiitos lainaamisesta!',
    },

    // Wolt - Lender
    rentalStatusLenderNotAcceptedWolt: {
        en: `Please bring the item to ROBES Showroom at <underline>${showroomDisplayNameFull}</underline>, <b> {{ d1 }}.</b> \n\nROBES manages delivery to the renter and collects the item after rental. <h3>Returning the item</h3>Your item will be available for pickup from ROBES showroom <b> {{ d2 }}</b>.`,
        fi: `Tuo tuote ROBES Showroomiin osoitteeseen <underline>${showroomDisplayNameFull}</underline> <b>{{ d1 }}.</b> \n\n ROBES hoitaa tuotteen toimituksen vuokraajalle ja takaisin. <h3>Tuotteen palautus</h3>Tuotteesi on noudettavissa ROBESin showroomilta <b>{{ d2 }}</b>.`,
    },
    rentalStatusLenderNotAcceptedPresentInShowroomWolt: {
        en: `Your item is at the showroom.\n\n ROBES will handle delivery and return with the renter for you! <h3>Returning the item</h3>Your item will be available for pickup from ROBES showroom <b> {{ d2 }}</b>.`,
        fi: 'Tuotteesi on showroomilla.\n\n ROBES hoitaa toimituksen ja palautuksen puolestasi! <h3>Tuotteen palautus</h3>Tuotteesi on noudettavissa ROBESin showroomilta <b>{{ d2 }}</b>.',
    },
    rentalStatusLenderUpcomingWolt: {
        en: `Bring the item to ROBES Showroom at <underline>${showroomDisplayNameFull}</underline>, <b> {{ d1 }}.`,
        fi: `Tuo tuote ROBES Showroomiin osoitteeseen <underline>${showroomDisplayNameFull}</underline>, <b> {{ d1 }}.`,
    },
    rentalStatusLenderUpcomingPresentInShowroomWolt: {
        en: 'Your item is at the showroom.\n\n ROBES will handle delivery and return with the renter for you! Your item will be available for pickup from ROBES showroom <b> {{ d2 }}.</b>',
        fi: 'Tuotteesi on showroomilla.\n\n ROBES hoitaa toimituksen ja palautuksen puolestasi! Tuotteesi on noudettavissa ROBESin showroomilta <b> {{ d2 }}.</b>',
    },
    rentalStatusLenderStartsTodayWolt: {
        en: 'Your item is at the showroom.\n\n ROBES will handle delivery and return with the renter for you! Your item will be available for pickup from ROBES showroom <b> {{ d2 }}.</b>',
        fi: 'Tuotteesi on showroomilla.\n\n ROBES hoitaa toimituksen ja palautuksen puolestasi! Tuotteesi on noudettavissa ROBESin showroomilta <b> {{ d2 }}.</b>',
    },
    rentalStatusLenderStartsTodayPresentInShowroomWolt: {
        en: `Rental starts today, bring the item to ROBES Showroom at <underline>${showroomDisplayNameFull}</underline>, <b> {{ d1 }}.`,
        fi: `Vuokrausaika alkaa tänään, tuo tuote ROBES Showroomiin osoitteeseen <underline>${showroomDisplayNameFull}</underline>, <b> {{ d1 }}.`,
    },
    rentalStatusLenderInProgressWolt: {
        en: 'Rental in progress, you may come pick the item back up on <b>{{ d2 }}</b>.',
        fi: 'Vuokraus on käynnissä, voit tulla hakemaan tuotteen takaisin <b>{{ d2 }}</b>.',
    },
    rentalStatusLenderEndsTodayWolt: {
        en: `Rental period ends today, you may come pick up the item from ROBES Showroom at <underline>${showroomDisplayNameFull}</underline>, <b> {{ d2 }} </b>`,
        fi: `Vuokrausaika päättyy tänään, voit tulla hakemaan tuotteen ROBES Showroomista osoitteesta <underline>${showroomDisplayNameFull}</underline>, <b> {{ d2 }} </b>`,
    },
    rentalStatusLenderPastWolt: {
        en: 'Rental has ended, thank you for lending!',
        fi: 'Vuokrausaika on päättynyt, kiitos lainaamisesta!',
    },

    // Showroom - Renter
    rentalStatusRenterUpcomingShowroom: {
        en: `Pick up the item from ROBES Showroom at <underline>${showroomDisplayNameFull}</underline>, <b> {{ d1 }}.</b>`,
        fi: `Nouda tuote ROBES Showroomista osoitteesta <underline>${showroomDisplayNameFull}</underline>, <b> {{ d1 }}.</b>`,
    },
    rentalStatusRenterStartsTodayShowroom: {
        en: `Rental starts today, remember to pick up the item from ROBES Showroom at <underline>${showroomDisplayNameFull}</underline>, <b> {{ d1 }}.</b>`,
        fi: `Vuokrausaika alkaa tänään, muista noutaa tuote ROBES Showroomista osoitteesta <underline>${showroomDisplayNameFull}</underline>, <b> {{ d1 }}.</b>`,
    },
    rentalStatusRenterInProgressShowroom: {
        en: `Rental in progress, return the item to ROBES Showroom at <underline>${showroomDisplayNameFull}</underline>, <b> {{ d2 }}.</b>`,
        fi: `Vuokraus on käynnissä, palauta tuote ROBES Showroomiin osoitteeseen <underline>${showroomDisplayNameFull}</underline>, <b> {{ d2 }}.</b>`,
    },
    rentalStatusRenterEndsTodayShowroom: {
        en: `Rental ends today, return the item to ROBES Showroom at <underline>${showroomDisplayNameFull}</underline>, <b> {{ d1 }}.</b>`,
        fi: `Vuokrausaika päättyy tänään, palauta tuote ROBES Showroomiin osoitteeseen <underline>${showroomDisplayNameFull}</underline>, <b> {{ d1 }}.</b>`,
    },
    rentalStatusRenterPastShowroom: {
        en: 'Rental period has ended, thank you for renting!',
        fi: 'Vuokrausaika on päättynyt, kiitos lainaamisesta!',
    },

    // Showroom - Lender
    rentalStatusLenderNotAcceptedShowroom: {
        en: `Please bring the item to ROBES Showroom at <underline>${showroomDisplayNameFull}</underline>, <b> {{ d1 }}.</b> \n\nROBES manages delivery to the renter and collects the item after rental. <h3>Returning the item</h3>Your item will be available for pickup from ROBES showroom <b> {{ d2 }}</b>.`,
        fi: `Tuo tuote ROBES Showroomiin osoitteeseen <underline>${showroomDisplayNameFull}</underline> <b>{{ d1 }}.</b> \n\n ROBES hoitaa tuotteen toimituksen vuokraajalle ja takaisin. <h3>Tuotteen palautus</h3>Tuotteesi on noudettavissa ROBESin showroomilta <b>{{ d2 }}</b>.`,
    },
    rentalStatusLenderNotAcceptedPresentInShowroomShowroom: {
        en: `Your item is at the showroom.\n\n ROBES will handle delivery and return with the renter for you! <h3>Returning the item</h3>Your item will be available for pickup from ROBES showroom <b> {{ d2 }}</b>.`,
        fi: 'Tuotteesi on showroomilla.\n\n ROBES hoitaa toimituksen ja palautuksen puolestasi! <h3>Tuotteen palautus</h3>Tuotteesi on noudettavissa ROBESin showroomilta <b>{{ d2 }}</b>.',
    },
    rentalStatusLenderUpcomingShowroom: {
        en: `Bring the item to ROBES Showroom at <underline>${showroomDisplayNameFull}</underline>, <b> {{ d1 }}.`,
        fi: `Tuo tuote ROBES Showroomiin osoitteeseen <underline>${showroomDisplayNameFull}</underline>, <b> {{ d1 }}.`,
    },
    rentalStatusLenderUpcomingPresentInShowroomShowroom: {
        en: 'Your item is at the showroom.\n\n ROBES will handle delivery and return with the renter for you! Your item will be available for pickup from ROBES showroom <b> {{ d2 }}.</b>',
        fi: 'Tuotteesi on showroomilla.\n\n ROBES hoitaa toimituksen ja palautuksen puolestasi! Tuotteesi on noudettavissa ROBESin showroomilta <b> {{ d2 }}.</b>',
    },
    rentalStatusLenderStartsTodayShowroom: {
        en: `Rental starts today, bring the item to ROBES Showroom at <underline>${showroomDisplayNameFull}</underline>, <b> {{ d1 }}.`,
        fi: `Vuokrausaika alkaa tänään, tuo tuote ROBES Showroomiin osoitteeseen <underline>${showroomDisplayNameFull}</underline>, <b> {{ d1 }}.`,
    },
    rentalStatusLenderInProgressShowroom: {
        en: 'Rental in progress, you may come pick the item back up on <b>{{ d2 }}</b>.',
        fi: 'Vuokraus on käynnissä, voit tulla hakemaan tuotteen takaisin <b>{{ d2 }}</b>.',
    },
    rentalStatusLenderEndsTodayShowroom: {
        en: `Rental ends today, you may come pick up the item from ROBES Showroom at <underline>${showroomDisplayNameFull}</underline>, <b> {{ d2 }} </b>`,
        fi: `Vuokrausaika päättyy tänään, voit tulla hakemaan tuotteen ROBES Showroomista osoitteesta <underline>${showroomDisplayNameFull}</underline>, <b> {{ d2 }} </b>`,
    },
    rentalStatusLenderPastShowroom: { en: 'Rental has ended, thank you for lending!', fi: 'Vuokrausaika on päättynyt, kiitos lainaamisesta!' },

    // Face to face - Renter
    rentalStatusRenterUpcomingFaceToFace: {
        en: 'Rental starts {{ d1 }}. Please agree on the time and location of exchange with the lender via chat.',
        fi: 'Vuokra-aika alkaa {{ d1 }}. Sovi noudon ajankohdasta ja paikasta vuokraajan kanssa chatin kautta.',
    },
    rentalStatusRenterStartsTodayFaceToFace: {
        en: 'Rental starts today! Please agree on the time and location of exchange with the lender via chat.',
        fi: 'Vuokra-aika alkaa tänään! Sovi noudon ajankohdasta ja paikasta vuokraajan kanssa chatin kautta.',
    },
    rentalStatusRenterInProgressFaceToFace: {
        en: 'Enjoy your rental!\n\nRemember to arrange the return of the item with the lender via chat.',
        fi: 'Vuokraus on käynnissä. Muista sopia tuotteen palautuksesta vuokraajan kanssa chatin kautta.',
    },
    rentalStatusRenterEndsTodayFaceToFace: {
        en: 'Rental ends today! Remember to arrange the return of the item with the lender via chat.',
        fi: 'Vuokrausaika päättyy tänään! Muista sopia tuotteen palautuksesta vuokraajan kanssa chatin kautta.',
    },
    rentalStatusRenterPastFaceToFace: {
        en: 'Rental has ended, thank you for renting!',
        fi: 'Vuokrausaika on päättynyt, kiitos lainaamisesta!',
    },

    // Face to face - Lender
    rentalStatusLenderNotAcceptedFaceToFace: {
        en: 'Renter has requested delivery by meeting face-to-face. Please agree on the time and location of exchange with the renter via chat.',
        fi: 'Vuokraaja on pyytänyt kasvokkain tapahtuvaa toimitusta. Sovi noudon ajankohdasta ja paikasta vuokraajan kanssa chatin kautta.',
    },
    rentalStatusLenderNotAcceptedPresentInShowroomFaceToFace: {
        en: 'Renter has requested delivery by meeting face-to-face. Please agree on the time and location of exchange with the renter via chat.',
        fi: 'Vuokraaja on pyytänyt kasvokkain tapahtuvaa toimitusta. Sovi noudon ajankohdasta ja paikasta vuokraajan kanssa chatin kautta.',
    },
    rentalStatusLenderUpcomingFaceToFace: {
        en: 'Rental starts {{ d1 }}. Please agree on the time and location of exchange with the renter via chat.',
        fi: 'Vuokra-aika alkaa {{ d1 }}. Sovi noudon ajankohdasta ja paikasta vuokraajan kanssa chatin kautta.',
    },
    rentalStatusLenderUpcomingPresentInShowroomFaceToFace: {
        en: 'Rental starts {{ d1 }}. Please agree on the time and location of exchange with the renter via chat.',
        fi: 'Vuokra-aika alkaa {{ d1 }}. Sovi noudon ajankohdasta ja paikasta vuokraajan kanssa chatin kautta.',
    },
    rentalStatusLenderStartsTodayFaceToFace: {
        en: 'Rental starts today! Please agree on the time and location of exchange with the renter via chat.',
        fi: 'Vuokra-aika alkaa tänään! Sovi noudon ajankohdasta ja paikasta vuokraajan kanssa chatin kautta.',
    },
    rentalStatusLenderInProgressFaceToFace: {
        en: 'Rental is in progress. Remember to arrange the return of the item with the renter via chat.',
        fi: 'Vuokraus on käynnissä. Muista sopia tuotteen palautuksesta vuokraajan kanssa chatin kautta.',
    },
    rentalStatusLenderEndsTodayFaceToFace: {
        en: 'Rental ends today! Remember to arrange the return of the item with the renter via chat.',
        fi: 'Vuokrausaika päättyy tänään! Muista sopia tuotteen palautuksesta vuokraajan kanssa chatin kautta.',
    },
    rentalStatusLenderPastFaceToFace: {
        en: 'Rental period has ended, thank you for lending!',
        fi: 'Vuokrausaika on päättynyt, kiitos lainaamisesta!',
    },

    woltDeliveryConditionsTitle: {
        en: 'Delivery conditions',
        fi: 'Toimitusehdot',
    },
    woltDeliveryDescription: {
        en: 'Delivery to your location',
        fi: 'Toimitus valitsemaasi paikkaan',
    },

    // Wardrobe

    deliveryDetailsDrawerLabel: {
        en: 'Delivery details',
        fi: 'Toimitustiedot',
    },
    deliveryDetailsDrawerInstructionsLabel: {
        en: 'Delivery instructions',
        fi: 'Lähetysohjeet',
    },
    deliveryDetailsDrawerContent: {
        en: `Parcel delivery\nLatest by <b>{{ date }}</b>`,
        fi: `Matkahuolto pakettitoimitus\nViimeistään <b>{{ date }}</b>`,
    },
    deliveryDetailsDrawerContentSale: {
        en: `Parcel delivery\nSend latest by <b>{{ date }}</b>`,
        fi: `Matkahuolto pakettitoimitus\nLähetä viimeistään <b>{{ date }}</b>`,
    },
    deliveryDetailsDrawerContentShowroom: {
        en: 'Parcel delivery, managed by ROBES\nLatest by <b>{{ date }}</b>',
        fi: 'Matkahuolto pakettitoimitus, ROBESin hallinnoima\nViimeistään <b>{{ date }}</b>',
    },
    returnDetailsDrawerLabel: {
        en: 'Return details',
        fi: 'Palautustiedot',
    },
    returnDetailsDrawerInstructionsLabel: {
        en: 'Return instructions',
        fi: 'Palautusohjeet',
    },
    returnDetailsDrawerContent: {
        en: `Pick up available from {{ pickupPointName }} \n<b>{{ date }}</b>`,
        fi: `Nouto {{ pickupPointName }} \n<b>{{ date }}</b>`,
    },
    returnDetailsDrawerContentShowroom: {
        en: `Pick up available from ROBES Showroom\n<b>{{ date }}, {{ openingHours }}</b>`,
        fi: `Nouto ROBES Showroomilta\n<b>{{ date }}, {{ openingHours }} </b>`,
    },
    returnDetailsDrawerInstructionsContent: {
        en: `Drop off to Matkahuolto service point\nLatest by <b>{{ date }}</b>`,
        fi: `Jätä Matkahuollon palvelupisteeseen\nViimeistään <b>{{ date }}</b>`,
    },
    latestBy: {
        en: 'Latest by {{date}}',
        fi: 'Viimeistään {{date}}',
    },
    parcelTrackingCode: {
        en: `Parcel tracking`,
        fi: `Paketin seuranta`,
    },
    trackingCode: {
        en: `Tracking code`,
        fi: `Seurantakoodi`,
    },
    matkahuoltoSmsNotification: {
        en: 'You will receive an SMS from Matkahuolto when the parcel is ready for pickup.',
        fi: 'Saat tekstiviestin Matkahuollolta kun pakettisi on noudettavissa.',
    },
    'order-noTrackingNumber': {
        en: `Your tracking code will appear here when the parcel is on its way.`,
        fi: `Seurantakoodisi ilmestyy tähän, kun paketti on matkalla.`,
    },
    'sale-noTrackingNumber': {
        en: `Your tracking code will appear here after the parcel has been activated.`,
        fi: `Seurantakoodisi ilmestyy tähän, kun paketti on aktivoitu.`,
    },
    deliveryDate: {
        en: 'Delivery date',
        fi: 'Toimituspäivä',
    },
    pickupPoint: {
        en: 'Pickup point',
        fi: 'Noutopiste',
    },
    activationCode: {
        en: 'Activation code\n<b>{{ code }}</b>',
        fi: 'Aktivointikoodi\n<b>{{ code }}</b>',
    },
    returnInstructions: {
        en: 'Return instructions',
        fi: 'Palautusohjeet',
    },
    matkahuoltoInstructionsPacking: {
        en: 'Pack the item and ensure the package is properly sealed to prevent item from damage during delivery.',
        fi: 'Pakkaa tuote ja varmista, että paketti on asianmukaisesti suljettu, jotta tuote ei vahingoitu kuljetuksen aikana.',
    },
    matkahuoltoInstructionsActivationCode: {
        en: 'Clearly mark the <b>your name and Matkahuolto activation code</b> on the package.',
        fi: 'Merkitse <b>nimesi ja Matkahuollon aktivointikoodi</b> selvästi pakettiin.',
    },
    matkahuoltoInstructionsDropOff: {
        en: 'Drop off your parcel at a Matkahuolto service point <b>latest by {{ date }}</b>.',
        fi: 'Jätä pakettisi Matkahuollon palvelupisteeseen <b>viimeistään {{ date }}</b>.',
    },
    matkahuoltoInstructionsConfirmation: {
        en: 'You will receive a confirmation of the delivery. All set!',
        fi: 'Saat vahvistuksen tuotteen lähetyksestä. Kaikki valmista!',
    },
    matkahuoltoActivationCodeInstructions: {
        en: 'Clearly mark <b>your name and Matkahuolto activation code</b> on the package',
        fi: 'Merkitse <b>nimesi ja Matkahuollon aktivointikoodi</b> selvästi pakettiin',
    },
    matkahuoltoActivationCodeSectionPendingActivation: {
        en: 'We will notify you when your activation code is available before the delivery date',
        fi: 'Ilmoitamme sinulle, kun aktivointikoodisi on saatavilla ennen toimituspäivää',
    },
    matkahuoltoActivationCodeSectionActivationCode: {
        en: 'Activation code \n <b>{{ code }}</b>',
        fi: 'Aktivointikoodi \n <b>{{ code }}</b>',
    },
    agreeDetailsWithLender: {
        en: 'Agree in the chat with the lender on the time and location of exchange.',
        fi: 'Sovi chatissa lainanantajan kanssa vaihdon ajankohdasta ja paikasta.',
    },
    woltEnsureAvailability: {
        en: 'Please ensure you are available to receive the item from the Wolt partner at the agreed time and location. \n\nYou will receive an SMS with a Home delivery tracking link shortly before the time of delivery.',
        fi: 'Varmista, että olet saatavilla vastaanottamaan tuotteen Wolt-kumppanilta sovittuna aikana ja paikassa. \n\nSaat tekstiviestinä Wolt-toimituksen seurantalinkin pian ennen toimitusaikaa.',
    },
    instructionsForTheCourier: {
        en: 'Instructions for the courier (optional)',
        fi: 'Ohjeet kuriirille (valinnainen)',
    },
    woltEnsureAvailabilityOnTime: {
        en: 'Please ensure you are available to receive the item from the Wolt partner at the agreed time and location.',
        fi: 'Varmista, että olet saatavilla vastaanottamaan tuotteen Wolt-kumppanilta sovittuna aikana ja paikassa.',
    },
    lenderReminderBringItemToShowroom: {
        en: `Please bring the item to ROBES Showroom at ${showroomDisplayNameFull}, <b> {{ date }}.</b> \n\nThe ROBES team will hand over your item to the renter at the time of rental and collect the item from the renter after the rental.\n\nYour item will be available for pickup <b>on {{ renterReturnDate }}</b>.`,
        fi: `Tuo tuote ROBES Showroomiin osoitteeseen ${showroomDisplayNameFull} <b>{{ date }}.</b> \n\nROBES-tiimi luovuttaa tuotteesi vuokraajalle vuokra-ajan alkaessa ja noutaa tuotteen vuokra-ajan päätyttyä.\n\nTuotteesi on noudettavissa <b>{{ renterReturnDate }}</b>.`,
    },
    agreeDetailsWithRenter: {
        en: 'Agree in the chat with the renter on the time and location of exchange.',
        fi: 'Sovi chatissa vuokraajan kanssa vaihdon ajankohdasta ja paikasta.',
    },
    pickUpOnDate: {
        en: 'Pick up the item on the agreed time and location today.',
        fi: 'Hae tuote sovittuna aikana ja sovitussa paikassa tänään.',
    },
    pickUpItemFromShowroomOnDate: {
        en: `Rental has ended, pick up the item from the ROBES Showroom at ${showroomDisplayNameFull}, {{ date }}.`,
        fi: `Vuokra-aika on päättynyt, nouda tuote ROBES Showroomista osoitteesta ${showroomDisplayNameFull}, {{ date }}.`,
    },
    deliverOnDate: {
        en: 'Deliver the item on the agreed time and location today.',
        fi: 'Toimita tuote sovittuna aikana ja sovitussa paikassa tänään.',
    },
    rentalInProgressPickUpItemOnDate: {
        en: `Rental in progress. Pick up the item from the ROBES Showroom at ${showroomDisplayNameFull} on {{ date }}.`,
        fi: `Vuokra-aika käynnissä. Nouda tuote ROBES Showroomista osoitteesta ${showroomDisplayNameFull} {{ date }}`,
    },
    rentalInProgress: {
        en: `Rental in progress. After the rental period, you can pick up the item from the ROBES Showroom at ${showroomDisplayNameFull}.`,
        fi: `Vuokra-aika käynnissä. Vuokra-ajan päätyttyä voit noutaa tuotteen ROBES Showroomista osoitteesta ${showroomDisplayNameFull}.`,
    },
    returnItemByToday: {
        en: 'Return the item unwashed on the agreed time and location today. Remember to notify the lender if the item has been damaged!',
        fi: 'Palauta tuote pesemättömänä sovittuna aikana ja sovitussa paikassa tänään. Muista ilmoittaa lainaajalle, jos esine on vahingoittunut!',
    },
    pickUpItemOnReturnDate: {
        en: 'Pick up the item from the ROBES showroom on {{ renterReturnDate }}  Remember to check the condition of your item when receiving it from the renter!',
        fi: 'Nouda tuote ROBESin showroomista {{ renterReturnDate }}. Muista tarkistaa kohteen kunto, kun vastaanotat sen vuokraajalta!',
    },
    rentalStarted: {
        en: 'Rental started',
        fi: 'Vuokra-aika alkanut',
    },
    rentalNotStartedYet: {
        en: 'Rental period not started yet',
        fi: 'Vuokra-aika ei alkanut vielä',
    },
    startsIn: {
        en: 'Starts in {{ time }}',
        fi: 'Alkaa {{ time }} päästä',
    },
    rentalOngoing: {
        en: 'Rental is ongoing',
        fi: 'Vuokraus on käynnissä',
    },
    rentalEndsIn: {
        en: 'Rental period ends in {{ time }}',
        fi: 'Vuokra-aika päättyy {{ time }}',
    },
    pendingLenderResponse: {
        en: 'Pending response from lender within <b>{{ time }} hours.</b>',
        fi: 'Odottaa vaatteen omistajan vastausta, viimeistään {{ time }} tunnin kuluessa.',
    },
    pendingResponse: {
        en: 'Accept or decline rental request within <b>{{ time }} hours.</b>',
        fi: 'Hyväksy tai hylkää vuokrauspyyntö seuraavan <b>{{ time }} tunnin kuluessa.</b>',
    },
    pendingPayment: {
        en: 'Pending payment',
        fi: 'Odottaa maksua',
    },
    rentalCompleteRenter: {
        en: 'Thank you for renting!',
        fi: 'Kiitos vuokraamisesta!',
    },
    rentalCompleteLender: {
        en: 'Thank you for lending!',
        fi: 'Kiitos vuokraamisesta!',
    },
    nextStep: {
        en: 'Next step',
        fi: 'Seuraavaksi',
    },
    deliveryInstructions: {
        en: 'Delivery instructions',
        fi: 'Toimitusohjeet',
    },
    'parcelDropoffLateAlertTitle-sale': {
        en: 'Parcel drop-off is late',
        fi: 'Paketin lähetys myöhässä',
    },
    'parcelDropoffLateAlertTitle-order': {
        en: 'Parcel drop-off is late',
        fi: 'Paketin palautus myöhässä',
    },
    'parcelDropoffLateAlertContent-sale': {
        en: 'Matkahuolto has not yet received your parcel at the service point. \n\n<b>Contact the renter as soon as possible if you cannot make the delivery.</b>',
        fi: 'Matkahuolto ei ole vastaanottanut tuotettasi toimipisteellä. \n\n<b>Olethan pikimmiten yhteydessä vuokraajaan, mikäli olet estetty toimittamasta tuotetta sovitussa ajassa.</b>',
    },
    'parcelDropoffLateAlertContent-order': {
        en: 'Matkahuolto has not yet received your parcel at the service point. \n\n<b>Contact the item owner as soon as possible if you cannot return the item on time.</b>',
        fi: 'Matkahuolto ei ole vastaanottanut tuotettasi toimipisteellä. \n\n<b>Olethan yhteydessä tuotteen omistajaan, mikäli olet estetty palauttamasta tuotetta sovitussa ajassa.</b>',
    },
    'parcelDropoffLateAlertContent-order-showroom': {
        en: 'Matkahuolto has not yet received your parcel at the service point. \n\n<b>Contact ROBES support as soon as possible if you cannot return the item on time.</b>',
        fi: 'Matkahuolto ei ole vastaanottanut tuotettasi toimipisteellä. \n\n<b>Olethan yhteydessä ROBESiin, mikäli olet estetty palauttamasta tuotetta sovitussa ajassa.</b>',
    },
    // Colors

    black: {
        en: 'Black',
        fi: 'Musta',
    },
    white: {
        en: 'White',
        fi: 'Valkoinen',
    },
    grey: {
        en: 'Grey',
        fi: 'Harmaa',
    },
    blue: {
        en: 'Blue',
        fi: 'Sininen',
    },
    metallic: {
        en: 'Metallic',
        fi: 'Metallinen',
    },
    turquoise: {
        en: 'Turquoise',
        fi: 'Turkoosi',
    },
    green: {
        en: 'Green',
        fi: 'Vihreä',
    },
    brown: {
        en: 'Brown',
        fi: 'Ruskea',
    },
    yellow: {
        en: 'Yellow',
        fi: 'Keltainen',
    },
    gold: {
        en: 'Gold',
        fi: 'Kulta',
    },
    beige: {
        en: 'Beige',
        fi: 'Beige',
    },
    red: {
        en: 'Red',
        fi: 'Punainen',
    },
    burgundy: {
        en: 'Burgundy',
        fi: 'Burgundy',
    },
    orange: {
        en: 'Orange',
        fi: 'Oranssi',
    },
    pink: {
        en: 'Pink',
        fi: 'Pinkki',
    },
    violet: {
        en: 'Violet',
        fi: 'Violetti',
    },
    multiple: {
        en: 'Multiple',
        fi: 'Monivärinen',
    },

    // Categories

    coatsAndJackets: {
        en: 'Coats & jackets',
        fi: 'Takit',
    },
    suits: {
        en: 'Suits',
        fi: 'Puvut/Jakut',
    },
    dresses: {
        en: 'Dresses',
        fi: 'Mekot',
    },
    knitwear: {
        en: 'Knitwear',
        fi: 'Neuleet',
    },
    shirtsAndTshirts: {
        en: 'Shirts & T-shirts',
        fi: 'Paidat ja T-paidat',
    },
    tops: {
        en: 'Tops',
        fi: 'Topit',
    },
    skirts: {
        en: 'Skirts',
        fi: 'Hameet',
    },
    trousers: {
        en: 'Trousers',
        fi: 'Housut',
    },
    shorts: {
        en: 'Shorts',
        fi: 'Shortsit',
    },
    homewear: {
        en: 'Homewear',
        fi: 'Oloasut',
    },
    jumpsuits: {
        en: 'Jumpsuits',
        fi: 'Haalarit',
    },
    maternityWear: {
        en: 'Maternity wear',
        fi: 'Äitiysvaatteet',
    },
    accessories: {
        en: 'Accessories',
        fi: 'Asusteet',
    },
    bags: {
        en: 'Bags',
        fi: 'Laukut',
    },
    shoes: {
        en: 'Shoes',
        fi: 'Kengät',
    },
    jewellery: {
        en: 'Jewellery',
        fi: 'Korut',
    },
    male: {
        en: 'Male',
        fi: 'Mies',
    },
    female: {
        en: 'Female',
        fi: 'Nainen',
    },
    unisex: {
        en: 'Unisex',
        fi: 'Unisex',
    },
    bodytype: {
        en: 'Bodytype',
        fi: 'Kehotyyppi',
    },
    itemDescription: {
        en: 'Item Description',
        fi: 'Tuotekuvaus',
    },
    itemDescriptionBody: {
        en: `Let's give your Robe the spotlight it deserves! We want to hear all about it - what are the materials used, how does it feel when you wear it, and most importantly, where can we pick it up and flaunt it like we own it? And if you have a unique story behind the piece, we'd love to hear that too! Don't hold back, let your creativity and personal style shine.`,
        fi: `Kerro meille lisää Robestasi! Mitä materiaaleja se sisältää, miltä se tuntuu käytössä, kuinka tuote istuu kokoon nähden, ja mikä tärkeintä, mistä kaupungista ja kaupunginosasta tuotteen voi noutaa vuokralle? Me rakastamme myös kuulla, jos sinulla on spesiaaleja muistoja vaatteesta tai muotivinkkejä jaettavaksi!`,
    },

    // Weekdays
    mondayLong: {
        en: 'Monday',
        fi: 'Maanantai',
    },
    tuesdayLong: {
        en: 'Tuesday',
        fi: 'Tiistai',
    },
    wednesdayLong: {
        en: 'Wednesday',
        fi: 'Keskiviikko',
    },
    thursdayLong: {
        en: 'Thursday',
        fi: 'Torstai',
    },
    fridayLong: {
        en: 'Friday',
        fi: 'Perjantai',
    },
    saturdayLong: {
        en: 'Saturday',
        fi: 'Lauantai',
    },
    sundayLong: {
        en: 'Sunday',
        fi: 'Sunnuntai',
    },
    mondayShort: {
        en: 'Mon',
        fi: 'Ma',
    },
    tuesdayShort: {
        en: 'Tue',
        fi: 'Ti',
    },
    wednesdayShort: {
        en: 'Wed',
        fi: 'Ke',
    },
    thursdayShort: {
        en: 'Thu',
        fi: 'To',
    },
    fridayShort: {
        en: 'Fri',
        fi: 'Pe',
    },
    saturdayShort: {
        en: 'Sat',
        fi: 'La',
    },
    sundayShort: {
        en: 'Sun',
        fi: 'Su',
    },

    // Error
    somethingWentWrong: {
        en: 'Something went wrong!',
        fi: 'Jokin meni pieleen!',
    },
    imageDownloadError: {
        en: 'Could not download image. You can try with another image, or you can refresh the page and try again. If this occurs again, contact us at support@robesrental.com',
        fi: 'Kuvan lataus epäonnistui. Voit kokeilla toista kuvaa, tai ladata sivun uudelleen. Jos virhe toistuu, ota yhteyttä tukeemme: support@robesrental.com',
    },
    imagePermissionsNotGranted: {
        en: 'Access to photos denied! Go to ROBES app settings to allow access.',
        fi: 'Pääsy kuviin estetty! Mene ROBES-sovelluksen asetuksiin ja salli kuvien käyttö.',
    },
    oops: {
        en: 'Oops!',
        fi: 'Hups!',
    },
    oopsPageNotFound: {
        en: 'Oops! Page not found',
        fi: 'Hups! Sivua ei löytynyt',
    },
    pageNotAvailable: {
        en: 'The page you are looking for might have been removed or is temporarily unavailable.',
        fi: 'Etsimääsi sivua ei löytynyt. Sivu on saattanut poistua tai olla väliaikaisesti poissa käytöstä.',
    },
    tryRefreshing: {
        en: 'Try reloading the page',
        fi: 'Kokeile ladata sivu uudelleen',
    },
    amountBetween: {
        en: 'Should be between {{ min }} and {{ max }}',
        fi: 'Arvon tulee olla {{ min }} ja {{ max }}',
    },
    lessThanDaily: {
        en: 'Cannot be less than daily price',
        fi: 'Ei voi olla pienempi kuin päivähinta',
    },
    moreThanMonthly: {
        en: 'Cannot be more than monthly price',
        fi: 'Ei voi olla suurempi kuin kuukausihinta',
    },
    shouldBeLessThan: {
        en: 'Should be less than or equal to {{ value }}',
        fi: 'Tulee olla pienempi tai yhtäsuuri kuin {{ value }}',
    },
    shouldBeLessThanOriginal: {
        en: 'Should be less than original price of the item',
        fi: 'Tulee olla pienempi kuin tuotteen OVH',
    },
    shouldBeMoreThan: {
        en: 'Should be more than or equal to {{ value }}',
        fi: 'Tulee olla suurempi tai yhtäsuuri kuin {{ value }}',
    },
    rentalTimeline: {
        en: 'Rental timeline',
        fi: 'Vuokrauksen aikajana',
    },
    reloadPage: {
        en: 'Reload page',
        fi: 'Lataa uudelleen',
    },
    returnHomeText: {
        en: 'Press <1> here </1> to return to home page',
        fi: 'Paina <1> tästä </1> palataksesi kotisivulle',
    },
    ifProblemPersists: {
        en: 'If the problem persists, please contact us at <1> hello@robesrental.com </1>, and we will contact you as soon as possible.',
        fi: 'Jos ongelma jatkuu, ota yhteyttä meihin <1> hello@robesrental.com </1>, palaamme asiaan pikimmiten.',
    },
    weeklyDiscount: {
        en: 'Weekly discount',
        fi: 'Viikkoalennus',
    },
    monthlyDiscount: {
        en: 'Monthly discount',
        fi: 'Kuukausialennus',
    },

    // Contact us
    faq: {
        en: 'FAQ',
        fi: 'FAQ',
    },
    faqSectionTitle: {
        en: 'Any questions?',
        fi: 'Onko mielessäsi kysymyksiä palveluun liittyen?',
    },
    faqSectionBody: {
        en: `Discover the answers to our most frequently asked questions at our 
        <1> FAQ page. </1>`,
        fi: `Tutustu ja löydä vastaukset useimmiten kysyttyihin kysymyksiin <1> FAQ-osiostamme </>.`,
    },
    contactUsPageTitle: {
        en: `Can't find what you're looking for?`,
        fi: 'Onko jotain, mihin et löytänyt vastausta?',
    },
    contactUsPageBody: {
        en: `We're here to help! Whether you have questions about your rentals, need assistance with your account, or just want to get in touch, our team is ready to support you.`,
        fi: `Olemme täällä sinua varten! Olipa kyseessä kysymys vuokrauksistasi, tilisi hallinnasta tai haluat vain ottaa yhteyttä, tiimimme on valmiina tukemaan sinua.`,
    },
    contactUsChatWithSupportTitle: {
        en: `Get in Touch Via Chat`,
        fi: `Ota yhteyttä chatin kautta`,
    },
    contactUsChatWithSupportBody: {
        en: `For the fastest response, click the button below to reach our support team directly. We're here to make sure you have the best experience possible.`,
        fi: `Saat nopeimman vastauksen, kun klikkaat alla olevaa painiketta ja otat yhteyttä suoraan tukitiimiimme. Olemme täällä varmistaaksemme, että sinulla on paras mahdollinen kokemus.`,
    },
    contactUsEmailSectionTitle: {
        en: `Prefer Email?`,
        fi: 'Haluatko lähettää meille sähköpostia?',
    },
    contactUsEmailSectionBody: {
        en: `Write to us at our email <1>hello@robesrental.com</1> and we will contact you soon.`,
        fi: `Kirjoita ja kerro meille toiveesi, palautteesi tai kysymyksesi sähköpostitse 
        <1>hello@robesrental.com</1> ja palaamme asiaan pikimmiten.`,
    },

    // Transaction lastTransitions

    'transition/complete': {
        en: 'Completed',
        fi: 'Päättynyt',
    },
    'transition/decline': {
        en: 'Declined',
        fi: 'Ei hyväksytty',
    },
    'transition/expire': {
        en: 'Expired',
        fi: 'Päättynyt',
    },
    'transition/cancel': {
        en: 'Cancelled by operator',
        fi: 'Keskeytetty ylläpidon toimesta',
    },
    'transition/cancel-no-refund': {
        en: 'Cancelled',
        fi: 'Keskeytetty',
    },
    'transition/customer-cancel': {
        en: 'Cancelled by customer',
        fi: 'Keskeytetty vuokraajan toimesta',
    },
    'transition/customer-decline-before-confirm-payment': {
        en: 'Declined by customer',
        fi: 'Peruutettu vuokraajan toimesta',
    },
    'transition/operator-decline': {
        en: 'Operator declined',
        fi: 'Peruutettu ylläpidon toimesta',
    },
    'transition/operator-decline-no-refund': {
        en: 'Operator declined',
        fi: 'Peruutettu ylläpidon toimesta',
    },
    'transition/customer-cancel-after-accept': {
        en: 'Cancelled by customer',
        fi: 'Keskeytetty asiakkaan toimesta',
    },
    'transition/provider-cancel-after-accept': {
        en: 'Cancelled by provider',
        fi: 'Keskeytetty omistajan toimesta',
    },
    'transition/accept': {
        en: 'Accepted',
        fi: 'Hyväksytty',
    },
    'transition/operator-accept': {
        en: 'Accepted',
        fi: 'Hyväksytty',
    },
    'transition/request-payment': {
        en: 'Pending payment',
        fi: 'Odottaa maksua',
    },
    'transition/confirm-payment': {
        en: 'Pending confirmation',
        fi: 'Odottaa hyväksyntää',
    },

    'history-transition/request-payment': {
        en: 'Request payment',
        fi: 'Maksupyyntö',
    },
    'history-transition/confirm-payment': {
        en: 'Payment confirmed',
        fi: 'Maksettu',
    },
};

export const getTranslation = (language: SupportedLanguage): ResourceLanguage => {
    const translation = {} as Record<string, string>;
    Object.keys(translations).forEach((key) => {
        translation[key] = translations[key][language];
    });
    return { translation };
};
