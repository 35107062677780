import { useQuery } from '@tanstack/react-query';
import { getApiClient } from '../services/sharetribe/apiClients';
import { ParcelTracking } from '../parcel-tracking/types';

interface UseDeliveryTrackingArgs {
    transactionId: string;
    deliveryType: 'delivery' | 'return';
    enabled: boolean;
}

export const useParcelTracking = ({ transactionId, enabled, deliveryType }: UseDeliveryTrackingArgs) => {
    const getParcelTracking = async () => {
        if (!transactionId || !deliveryType) {
            return undefined;
        }
        const { data } = await getApiClient('delivery').get<ParcelTracking>(`/tracking/${transactionId}?deliveryType=${deliveryType}`);
        return data;
    };

    return useQuery(['delivery-tracking', transactionId, deliveryType], getParcelTracking, { enabled });
};
