import React, { useCallback, useState } from 'react';
import { Box, Button, CircularProgress, Skeleton, Typography } from '@mui/material';
import { StepBackButton, StepContent, StepContentContainer } from '../LenderOnboarding.components';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { ArrowBack, Error as MUIError } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import { useCurrentUser } from '../../../user/hooks/useUser';
import { useCreateStripeAccount } from '../../../views/UserProfile/hooks/useCreateStripeAccount';
import { useCreateStripeAccountLink } from '../../../views/UserProfile/hooks/useCreateStripeAccountLink';
import { useStripeAccount } from '../../../views/UserProfile/hooks/useStripeAccount';
import { hasRequirements } from '../../../views/UserProfile/Stripe/helpers';
import { RedirectToStripe } from '../../../views/UserProfile/Stripe/RedirectToStripe';
import { StripeConnectInputs } from '../../../views/UserProfile/Stripe/StripeConnectForm';
import { StripeVerifiedLogo } from '../../../views/UserProfile/Stripe/StripeVerifiedLogo';
import { ContentLoader } from '../../ContentLoader/ContentLoader';
import { StripeAccountAlertMaybe } from '../../StripeAccountAlert/StripeAccountAlertMaybe';
import { FormProvider, useForm } from 'react-hook-form';
import { StripeConnectFormData } from '../../../views/UserProfile/Stripe/StripeConnectForm.types';
import { getAppConfig } from '../../../countryConfigs';
import { getCountryCurrency, supportedCountryCodes } from '../../../views/UserProfile/Stripe/StripeConnectForm.utils';
import { useHasPermission } from '../../../user/hooks/usePermissions';
import StripeLogo from '../../../assets/images/Stripe.png';
import { BaseStepProps } from '../LenderOnboarding.types';
import { AnimatedContainer } from '../../../animations/components/AnimatedContainer';
import { swap } from '../../../animations/constants';

const StripeConnectSkeleton = () => (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '4px' }}>
        <Skeleton width="100%" height={20} variant="text" />
        <Skeleton width="100%" height={20} variant="text" />
        <Skeleton width="50%" height={20} variant="text" />
        <Skeleton width="100%" height={30} variant="rounded" />
        <Skeleton width="100%" height={40} variant="rounded" />
        <Skeleton width="60%" height={40} variant="text" sx={{ marginTop: '30px' }} />
        <div style={{ display: 'flex', width: '100%', gap: '5px' }}>
            <Skeleton style={{ flexGrow: 1 }} height={50} variant="rounded" />
            <Skeleton style={{ flexGrow: 1 }} height={50} variant="rounded" />
        </div>

        <Skeleton width="100%" height={40} variant="rounded" />
    </Box>
);

const RedirectingContent = () => {
    const { t } = useTranslation();

    return (
        <AnimatedContainer variants={swap}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px', alignItems: 'center', width: '100%', textAlign: 'center' }}>
                <img src={StripeLogo} height={80} style={{ objectFit: 'contain' }} alt="Stripe" />
                <CircularProgress variant="indeterminate" />
                <Typography variant="h6">{t('lenderOnboardingStripeRedirectingText')}</Typography>
            </Box>
        </AnimatedContainer>
    );
};

type StripeConnectStepProps = BaseStepProps;

export const StripeConnectStep: React.FC<StripeConnectStepProps> = ({ onComplete, loading, onGoBack }) => {
    const [tokenState, setTokenState] = useState({ token: '', stripeError: '' });
    const [isValidating, setIsValidating] = useState(false);
    const [isRedirecting, setIsRedirecting] = useState(false);
    const [accountLinkError, setAccountLinkError] = useState(false);

    const { t } = useTranslation();
    const { returnType } = useParams();

    const { data: user, isInitialLoading: isUserLoading } = useCurrentUser();

    const { data: stripeAccountData, isInitialLoading: isStripeAccountLoading } = useStripeAccount();
    const stripeConnected = user?.stripeConnected;

    const stripeAccountLinkOptions = {
        onError: () => {
            setAccountLinkError(true);
            setIsRedirecting(false);
        },
        beforeClose: () => {
            setIsRedirecting(false);
        },
    };

    const { mutate: startStripeOnboarding } = useCreateStripeAccountLink('/lender-onboarding', stripeAccountLinkOptions);

    const handleStartOnboarding = useCallback(() => {
        if (isRedirecting) {
            return;
        }
        setIsRedirecting(true);
        startStripeOnboarding('custom_account_verification');
    }, [isRedirecting, startStripeOnboarding]);

    const { mutate: createStripeAccount, isLoading: stripeAccountInProgress } = useCreateStripeAccount(handleStartOnboarding);

    const returnedNormallyFromStripe = returnType === 'success';
    const returnedAbnormallyFromStripe = returnType === 'error';

    const { bankInformationMissing, deadline, pausedByAdmin } = stripeAccountData || {};

    const showLoader = isUserLoading || isStripeAccountLoading;

    const getDefaultCountryCode = () => {
        const stripeAccountCountry = stripeAccountData?.country;
        if (stripeAccountCountry && supportedCountryCodes.includes(stripeAccountCountry as 'FI' | 'US')) {
            return stripeAccountCountry as 'FI' | 'US';
        }

        const { countryCode } = getAppConfig();
        return countryCode as 'FI' | 'US';
    };

    const defaultCountryCode = getDefaultCountryCode();
    const canChangeCOD = useHasPermission('settings_change_country');

    const methods = useForm<StripeConnectFormData>({
        defaultValues: {
            countryCode: defaultCountryCode,
        },
    });

    const {
        watch,
        formState: { isDirty, isValid },
    } = methods;

    const countryCode = watch('countryCode');
    const currency = getCountryCurrency(countryCode);

    const handleSubmit = () => {
        if (user?.stripeConnected) {
            const missingInformation = hasRequirements(stripeAccountData, ['currently_due', 'past_due']);
            if (!missingInformation) {
                onComplete();
            } else {
                handleStartOnboarding();
            }
        } else {
            const callback = (formData: StripeConnectFormData) => {
                const { token: bankAccountToken, stripeError } = tokenState;
                if (!bankAccountToken || stripeError) {
                    throw new Error('Bank account token missing or stripe error present');
                }

                createStripeAccount({ ...formData, currency, bankAccountToken });
            };

            methods.handleSubmit(callback)();
        }
    };

    const stripeAccountComplete = stripeConnected && !bankInformationMissing && !hasRequirements(stripeAccountData, ['currently_due', 'past_due']);

    if (isRedirecting) {
        return (
            <StepContent>
                <StepContentContainer>
                    <RedirectingContent />
                </StepContentContainer>
            </StepContent>
        );
    }

    return (
        <StepContent>
            <FormProvider {...methods}>
                <StepContentContainer>
                    {!stripeAccountComplete && (
                        <Typography variant="h6" sx={{ mt: 4, textAlign: 'start', mb: 4 }}>
                            {t('lenderOnboardingStripeConnectTitle')}
                        </Typography>
                    )}
                    <ContentLoader status={showLoader ? 'loading' : 'idle'} skeleton={<StripeConnectSkeleton />}>
                        <>
                            {stripeConnected ? (
                                <>
                                    {!returnedAbnormallyFromStripe &&
                                        !returnedNormallyFromStripe &&
                                        !hasRequirements(stripeAccountData, ['currently_due', 'past_due']) && (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    gap: '25px',
                                                    width: '100%',
                                                    marginTop: '20px',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <StripeVerifiedLogo showRobesLogo={false} />
                                            </div>
                                        )}
                                    {returnedAbnormallyFromStripe && !accountLinkError && <RedirectToStripe redirectFn={handleStartOnboarding} />}
                                    {returnedAbnormallyFromStripe && accountLinkError && (
                                        <div style={{ width: '100%', textAlign: 'center' }}>
                                            <MUIError />
                                            <Typography>{t('somethingWentWrongOurEnd')}</Typography>
                                        </div>
                                    )}
                                    {returnedNormallyFromStripe && !hasRequirements(stripeAccountData, ['currently_due', 'past_due']) && (
                                        <div
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                flexDirection: 'column',
                                            }}
                                        >
                                            <StripeVerifiedLogo showRobesLogo={false} />
                                        </div>
                                    )}
                                    {!bankInformationMissing &&
                                        !returnedAbnormallyFromStripe &&
                                        hasRequirements(stripeAccountData, ['currently_due', 'past_due']) && (
                                            <Box sx={{ width: '100%' }}>
                                                <StripeAccountAlertMaybe
                                                    stripeConnected={stripeConnected}
                                                    stripeAccount={stripeAccountData}
                                                    deadline={deadline}
                                                    hideButton
                                                />
                                            </Box>
                                        )}
                                </>
                            ) : (
                                <div style={{ display: 'flex', gap: '20px', flexDirection: 'column' }}>
                                    <form>
                                        <StripeConnectInputs
                                            methods={methods}
                                            stripeAccountData={stripeAccountData}
                                            tokenState={tokenState}
                                            countryCode={countryCode}
                                            defaultCountryCode={defaultCountryCode}
                                            currency={currency}
                                            setTokenState={setTokenState}
                                            canChangeCOD={canChangeCOD}
                                            onValidating={setIsValidating}
                                        />
                                    </form>
                                </div>
                            )}
                        </>
                    </ContentLoader>
                </StepContentContainer>

                <StepBackButton onClick={onGoBack}>
                    <ArrowBack />
                </StepBackButton>

                {!stripeAccountComplete && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', gap: '20px', p: 2, flexDirection: 'column' }}>
                        <img src={StripeLogo} height={80} style={{ objectFit: 'contain' }} alt="Stripe" />{' '}
                        <Typography sx={{ textAlign: 'center' }}>{t('lenderOnboardingStripeConnectDescription')}</Typography>
                    </Box>
                )}

                <LoadingButton
                    disabled={stripeConnected ? false : !stripeAccountComplete && (!isDirty || !isValid || !tokenState.token)}
                    loading={loading || isValidating || isRedirecting || stripeAccountInProgress}
                    variant="contained"
                    onClick={handleSubmit}
                    sx={{ m: 2 }}
                >
                    {t('continue')}
                </LoadingButton>
            </FormProvider>
        </StepContent>
    );
};
