import { motion } from 'framer-motion';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavigationType, useNavigationType } from 'react-router-dom';
import { containerVariantsStagger, itemVariants } from '../../../animations/constants';
import { ContentLoader } from '../../../components/ContentLoader/ContentLoader';
import { noop, useCombinedQueryStatus } from '../../../helpers/commonHelpers';
import { OwnTransaction } from '../../../transactions/apiTypes';
import { useTransitionTransaction } from '../../../transactions/hooks/useTransitionTransaction';
import { getAcceptPayload, getDeclinePayload } from '../../../transactions/transactionHelpers';
import { ReactFCWithChildren } from '../../../types/types';
import { LoadMoreButton } from '../../Listings/LoadMoreButton/LoadMoreButton';
import { TransactionCard } from './TransactionCard';
import { TransactionCardsSkeleton } from './TransactionCardsSkeleton';
import { useConfirm } from '../../../context/confirm';
import { useOwnTransactions } from '../../../transactions/hooks/useOwnTransactions';
import { transactionFilters } from '../Components/TransactionStateFilters.constants';
import { useDeliveryOptions } from '../../../queries/useDeliveryTiming';
import { useHasPermission } from '../../../user/hooks/usePermissions';

interface TransactionCardContainerProps {
    type: 'sale' | 'order';
}

export const TransactionCardContainer: ReactFCWithChildren<TransactionCardContainerProps> = ({ type }) => {
    const { t } = useTranslation();

    const [selectedTransactionFilters, setSelectedTransactionFilters] = useState<string[]>(transactionFilters);

    const {
        data,
        status: transactionsStatus,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
    } = useOwnTransactions(type, 1, 5, selectedTransactionFilters);

    const navigationType = useNavigationType();

    const { confirm, close, updateOptions } = useConfirm();
    const hasDevControlPermissions = useHasPermission('developer_controls');

    const { mutate: transitionTransaction, isLoading } = useTransitionTransaction(close);
    const transactions = data?.pages.map((page) => page.data).flat() || [];
    const totalItems = data?.pages[0].meta.totalItems || 0;

    const { data: deliveryOptions, status: deliveryOptionsStatus } = useDeliveryOptions();

    const handleAction = useCallback(
        (action: 'confirm' | 'decline', transaction: OwnTransaction) => {
            const message = t(`transaction-${type}-${action}`, { name: transaction.customer.displayName, title: transaction.listing.title });
            const title = t(`transaction-dialog-title-${action}`, { title: transaction.listing.title });
            const transactionId = transaction.transaction.id;

            const onConfirm = () => {
                const payload = action === 'confirm' ? getAcceptPayload(transactionId) : getDeclinePayload(transactionId);
                transitionTransaction(payload);
            };

            confirm({ title, message, loading: isLoading, onConfirm }).then(noop).catch(noop);
        },
        [type, t],
    );

    useEffect(() => {
        updateOptions({ loading: isLoading });
    }, [isLoading]);

    const handleChange = (selectedValues: string[]) => {
        setSelectedTransactionFilters(selectedValues);
    };

    const combinedStatus = useCombinedQueryStatus([transactionsStatus, deliveryOptionsStatus]);

    const noItemsText = useMemo(() => {
        // Filters active
        if (selectedTransactionFilters.length !== transactionFilters.length) {
            return t('nothingHere');
        }

        return type === 'sale' ? t('startLending') : t('startRenting');
    }, [type, selectedTransactionFilters]);

    return (
        <>
            {/* <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <TransactionStateMenu
                    menuItems={transactionFilterMenuItems}
                    menuButtonLabel={
                        <>
                            {t('show')}
                            <FilterList />
                        </>
                    }
                    selectedValues={selectedTransactionFilters}
                    onChange={handleChange}
                />
            </Box> */}

            <ContentLoader status={combinedStatus} skeleton={<TransactionCardsSkeleton />}>
                {data?.pages && (
                    <motion.ul
                        style={{ display: 'flex', flexWrap: 'wrap', listStyle: 'none', width: '100%', paddingInlineStart: '0' }}
                        variants={containerVariantsStagger}
                        custom={navigationType !== NavigationType.Pop ? { stagger: 0.05 } : { stagger: 0 }}
                        initial="hidden"
                        animate="show"
                    >
                        {transactions.map((transactionData) => (
                            <motion.li
                                key={transactionData.transaction.id}
                                variants={itemVariants}
                                custom={navigationType !== NavigationType.Pop ? 1 : 1}
                                layout="position"
                                style={{ width: '100%', padding: '15px' }}
                            >
                                <TransactionCard
                                    deliveryOptions={deliveryOptions || []}
                                    transactionData={transactionData}
                                    transactionType={type}
                                    isDeveloper={hasDevControlPermissions}
                                    onAction={handleAction}
                                />
                            </motion.li>
                        ))}

                        <LoadMoreButton
                            fetchNextPage={fetchNextPage}
                            hasNextPage={hasNextPage}
                            noItemsText={noItemsText}
                            isFetchingNextPage={isFetchingNextPage}
                            noItems={totalItems === 0}
                            sx={{ width: '100%' }}
                        />
                    </motion.ul>
                )}
            </ContentLoader>
        </>
    );
};
