import { Alert, AlertTitle, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

interface DeliveryLateAlertProps {
    transactionType: 'sale' | 'order';
    isPresentInShowroom: boolean | undefined;
}

export const DeliveryLateAlert: React.FC<DeliveryLateAlertProps> = (props) => {
    const { transactionType, isPresentInShowroom } = props;
    const { t } = useTranslation();

    let contenti18nKey = `parcelDropoffLateAlertContent-${transactionType}`;

    if (transactionType === 'order' && isPresentInShowroom) {
        contenti18nKey = contenti18nKey + '-showroom';
    }

    return (
        <Alert severity="warning">
            <AlertTitle>{t(`parcelDropoffLateAlertTitle-${transactionType}`)}</AlertTitle>
            <Typography variant="body2">
                <Trans i18nKey={contenti18nKey} components={{ b: <b /> }} />
            </Typography>
        </Alert>
    );
};
