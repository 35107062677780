import { Toast } from '@capacitor/toast';
import { ArrowForwardIos, LocationCity, LocationOn, People } from '@mui/icons-material';
import { Alert, AlertTitle, Divider, Typography } from '@mui/material';
import { isEqual } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { RegisterOptions, useFormContext } from 'react-hook-form';
import { TFunction, Trans, useTranslation } from 'react-i18next';
import { AnimatedContainer } from '../../animations/components/AnimatedContainer';
import { fadeUp } from '../../animations/constants';
import { FormRenderer } from '../../components/FormRenderer/FormRenderer';
import { FormField, FormFieldSelect, FormSelectItem, SelectItem } from '../../components/FormRenderer/FormRenderer.types';
import { ShowMore } from '../../components/ShowMore/ShowMore';
import { StyledSvgIcon } from '../../components/Styled/Styled.components';
import SwipeableFormDrawer from '../../components/SwipeableFormDrawer/SwipeableFormDrawer';
import { useDrawer } from '../../context/drawer';
import { assertNever, capitalizeString, formatPrice } from '../../helpers/commonHelpers';
import { formatDayMonthAndDate } from '../../helpers/dateAndTimeHelpers';
import {
    DeliveryInformation,
    getAvailabilityHoursString,
    getDeliveryInformation,
    getOpeningHourStringForDate,
    getTransactionDate,
} from '../../helpers/delivery';
import { usePickupPoints } from '../../queries/usePickupPoints';
import { AlertType, DeliveryOption, SectionIcon } from '../../types/delivery';
import { useCurrentUser } from '../../user/hooks/useUser';
import {
    CheckoutSectionRendererProps,
    DateTimeFormSectionProps,
    DisplayDisplayDateSectionProps,
    FormSectionRendererProps,
    PickupPointSelectorSectionProps,
    ReturnMethodFormSectionProps,
} from './CheckoutSectionRenderer.types';
import { DifferentDayAlertMaybe } from './DifferentDayAlertMaybe';
import { DisplayFormValueComponent } from './DisplayFormValue';
import {
    renderDateTimeValue,
    renderFormSectionValue,
    renderPickupPointValue,
    renderReturnMethodValue,
    renderSelectItem,
} from './LogisticsSection.helpers';

const renderIcon = (sectionIcon: SectionIcon | undefined) => {
    if (!sectionIcon) {
        return null;
    }

    switch (sectionIcon.icon) {
        case 'house':
            return (
                <StyledSvgIcon>
                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                        <path d="M220.001-180.001v-320h-87.689L480-813.075l156.923 141.692v-97.846h92.306v180.46l98.459 88.768h-87.689v320H524.616v-224.615h-89.232v224.615H220.001ZM280-240h95.386v-224.614h209.228V-240H680v-312L480-732 280-552v312Zm95.386-224.614h209.228-209.228Zm28.46-94.771h152.308q0-30.076-22.847-49.422-22.846-19.347-53.307-19.347-30.461 0-53.307 19.312-22.847 19.312-22.847 49.457Z" />
                    </svg>
                </StyledSvgIcon>
            );
        case 'clock':
            return (
                <StyledSvgIcon>
                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                        <path d="m618.924-298.924 42.152-42.152-151.077-151.087V-680h-59.998v212.154l168.923 168.922ZM480.067-100.001q-78.836 0-148.204-29.92-69.369-29.92-120.682-81.21-51.314-51.291-81.247-120.629-29.933-69.337-29.933-148.173t29.92-148.204q29.92-69.369 81.21-120.682 51.291-51.314 120.629-81.247 69.337-29.933 148.173-29.933t148.204 29.92q69.369 29.92 120.682 81.21 51.314 51.291 81.247 120.629 29.933 69.337 29.933 148.173t-29.92 148.204q-29.92 69.369-81.21 120.682-51.291 51.314-120.629 81.247-69.337 29.933-148.173 29.933ZM480-480Zm0 320q133 0 226.5-93.5T800-480q0-133-93.5-226.5T480-800q-133 0-226.5 93.5T160-480q0 133 93.5 226.5T480-160Z" />
                    </svg>
                </StyledSvgIcon>
            );
        case 'arrow-back':
            return (
                <StyledSvgIcon>
                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                        <path d="M372.308-267.692 160-480l212.308-212.308L400.615-664 236.616-500H760v-160h40v200H236.616l163.999 164-28.307 28.308Z" />
                    </svg>
                </StyledSvgIcon>
            );
        case 'person':
            return <People />;
        case 'building':
            return <LocationCity />;

        case 'location':
            return <LocationOn />;
        default:
            return null;
    }
};

const renderDateAlertMaybe = (args: {
    deliveryInformation: DeliveryInformation;
    type: AlertType;
    date: Date;
    deliveryOptions: DeliveryOption[];
    deliveryMethod: string;
    t: TFunction;
}) => {
    const { deliveryInformation, type, date, deliveryMethod, deliveryOptions, t } = args;

    if (!deliveryInformation || !deliveryInformation?.isDeliveryDateDifferent) {
        return null;
    }

    const { daysDiff } = deliveryInformation;

    const dayString = daysDiff === 1 ? t('day') : t('days');
    const daysDiffString = `${daysDiff} ${dayString}`;

    const configByType = {
        delivery: {
            title: 'deliveryDateNote',
            i18nKey: 'deliveryOnDate',
            compareDate: 'renterDeliveryDate',
        },
        return: {
            title: 'returnDateNote',
            i18nKey: 'returnOnDate',
            compareDate: 'renterReturnDate',
        },
    } as const;

    const { title, i18nKey } = configByType[type];

    return (
        <Alert severity="info" sx={{ borderRadius: '15px', mt: 1, mb: 2 }}>
            <AlertTitle sx={{ fontWeight: 'bold' }}>{t(title)}</AlertTitle>
            <ShowMore
                maxHeight={50}
                value={
                    <Trans
                        i18nKey={i18nKey}
                        components={{ b: <b />, i: <i />, br: <br /> }}
                        values={{
                            date: formatDayMonthAndDate(date),
                            daysDiffString,
                            openingHourDates: getAvailabilityHoursString(deliveryOptions, deliveryMethod, t),
                        }}
                    />
                }
            />
        </Alert>
    );
};

const FormSectionRenderer: React.FC<FormSectionRendererProps> = ({ section, openDrawersState, handleClickDrawerButton, handleCloseDrawer }) => {
    const { t } = useTranslation();
    const { label, description, fieldName, formFields, rememberForLater } = section;

    const identifier = fieldName ? fieldName : formFields[0].controlName;

    const resolvedFormFields = useMemo(() => {
        const fields = formFields.map((field) => {
            const { required, ...rest } = field;

            let registerOptions: RegisterOptions = {
                required,
                shouldUnregister: true,
            };

            if ('pattern' in field) {
                registerOptions.pattern = field.pattern;
            }

            if ('validate' in field) {
                registerOptions.validate = field.validate;
            }

            return { ...rest, registerOptions };
        }) as FormField[];

        if (rememberForLater) {
            fields.push({
                type: 'checkbox',
                controlName: 'rememberForLater',
                registerOptions: { shouldUnregister: true },
                label: t('rememberForLater'),
            });
        }

        if (fieldName) {
            fields.push({
                type: 'actions',
                parentControl: fieldName,
                sx: { mt: 4, paddingBottom: 'env(safe-area-inset-bottom)' },
                actions: [
                    { type: 'cancel', label: t('cancel'), variant: 'outlined' },
                    { type: 'confirm', label: t('confirm'), variant: 'contained' },
                ],
            });
        }

        return fields;
    }, [formFields, rememberForLater]);

    const summaryFields = useMemo(() => formFields.filter((field) => field.summary !== 'hide'), [formFields]);

    return (
        <>
            <SwipeableFormDrawer
                id={identifier}
                label={label}
                buttonLabel={label}
                open={openDrawersState[identifier] || false}
                onClick={() => handleClickDrawerButton(identifier)}
                onClose={() => handleCloseDrawer(identifier)}
                renderValue={renderFormSectionValue({ placeholder: description, fieldName: identifier, summaryFields })}
                sx={{
                    padding: '16px 16px calc(16px + env(safe-area-inset-bottom))',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '15px',
                }}
                buttonSuffix={<ArrowForwardIos fontSize="small" sx={{ opacity: 0.7 }} />}
                buttonPrefix={renderIcon(section.sectionIcon)}
            >
                <FormRenderer formFields={resolvedFormFields} fieldName={fieldName} />
            </SwipeableFormDrawer>
        </>
    );
};

const getPickupPointArgs = (deliveryDetails: Record<string, string>) => {
    if (!deliveryDetails || !deliveryDetails.street || !deliveryDetails.postalCode || !deliveryDetails.city) {
        return null;
    }

    const { street, postalCode, city } = deliveryDetails;

    return { street, postalCode, city };
};

export const PickupPointSelector: React.FC<PickupPointSelectorSectionProps> = ({
    section,
    openDrawersState,
    handleClickDrawerButton,
    handleCloseDrawer,
}) => {
    const { t } = useTranslation();
    const { setValue, watch } = useFormContext();
    const identifier = 'pickupPoint';
    const deliveryDetails = watch('deliveryDetails');

    const [pickupPointArgs, setPickupPointArgs] = useState<Record<string, string> | null>(getPickupPointArgs(deliveryDetails));
    const { label } = section;

    const { data } = usePickupPoints(pickupPointArgs);

    useEffect(() => {
        const args = getPickupPointArgs(deliveryDetails);
        setPickupPointArgs(args);

        const showToast = async () => {
            await Toast.show({ text: t('pickupPointsQueryChanged'), position: 'bottom', duration: 'short' });
        };

        const pickupPointQueryChanged = pickupPointArgs && args && !isEqual(pickupPointArgs, args);

        if (pickupPointQueryChanged) {
            setValue(identifier, '');
            showToast();
        }
    }, [deliveryDetails]);

    const resolvedFormFields: FormFieldSelect[] = useMemo(() => {
        if (!data) {
            return [];
        }

        const items: SelectItem[] = data.map((pickupPoint) => {
            return {
                type: 'item',
                value: `${pickupPoint.Id}`,
                title: `${pickupPoint.Name} (${pickupPoint.Distance} km)`,
                content: `${pickupPoint.StreetAddress}, ${String(pickupPoint.PostalCode).padStart(5, '0')} ${pickupPoint.City}`,
            };
        });

        return [
            {
                type: 'select',
                variant: 'radio',
                controlName: identifier,
                label,
                closeDrawerOnSelect: true,
                renderItem: (item) => renderSelectItem(item, { renderPrefix: false, renderSuffix: true }),
                registerOptions: { required: true, shouldUnregister: true },
                items,
            },
        ];
    }, [data]);

    return (
        <>
            <SwipeableFormDrawer
                id={identifier}
                label={label}
                buttonLabel={label}
                open={openDrawersState[identifier] || false}
                onClick={() => handleClickDrawerButton(identifier)}
                onClose={() => handleCloseDrawer(identifier)}
                renderValue={renderPickupPointValue(data || [])}
                sx={{
                    padding: '16px 16px calc(16px + env(safe-area-inset-bottom))',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '15px',
                }}
                buttonSuffix={<ArrowForwardIos fontSize="small" sx={{ opacity: 0.7 }} />}
                buttonPrefix={renderIcon(section.sectionIcon)}
            >
                <FormRenderer formFields={resolvedFormFields} fieldName={identifier} />
            </SwipeableFormDrawer>
        </>
    );
};

export const DateTimeFormRenderer: React.FC<DateTimeFormSectionProps> = ({
    section,
    openDrawersState,
    handleClickDrawerButton,
    handleCloseDrawer,
    bookingDetails,
    deliveryOptions,
}) => {
    const { t } = useTranslation();
    const { label, fieldName } = section;

    const alertCompareDate = section.formField.alertCompareDate;
    const identifier = section.formField.controlName;
    const deliveryInformation = getDeliveryInformation(
        deliveryOptions,
        bookingDetails.deliveryMethod,
        bookingDetails.booking.range.bookingStart,
        'delivery',
    );

    if (!deliveryInformation) {
        return null;
    }

    const { min, max } = deliveryInformation;

    const alert = renderDateAlertMaybe({
        deliveryInformation,
        type: 'delivery',
        date: min,
        deliveryOptions,
        deliveryMethod: bookingDetails.deliveryMethod,
        t,
    });

    const getResolvedFormFields = () => {
        const field = section.formField;
        const { required, ...rest } = field;

        if (field.type === 'time') {
            return [
                {
                    ...rest,
                    maxTime: max,
                    minTime: min,
                    range: field.displayTimeRangeMinutes,
                    closeDrawerOnSelect: true,
                    parentControl: field.controlName,
                    registerOptions: { required, shouldUnregister: true },
                    alert,
                },
            ];
        }

        return [{ ...rest, parentControl: field.controlName, closeDrawerOnSelect: true, registerOptions: { required, shouldUnregister: true } }];
    };

    const resolvedFormFields = getResolvedFormFields();

    const renderValueFn = () => {
        if (section.formField.type === 'time') {
            const compareDate = getTransactionDate(deliveryOptions, bookingDetails.deliveryMethod, alertCompareDate);

            return renderDateTimeValue(identifier, compareDate, section.formField.displayTimeRangeMinutes);
        }

        return renderDateTimeValue(identifier);
    };

    const drawerProps = {
        label: section.formField.type === 'time' ? label : '',
        sx: {
            padding:
                section.formField.type === 'time'
                    ? '16px 16px calc(16px + env(safe-area-inset-bottom))'
                    : '0 0 calc(16px + env(safe-area-inset-bottom)',
            display: 'flex',
            flexDirection: 'column',
            gap: '15px',
        },
    };

    return (
        <>
            <SwipeableFormDrawer
                id={identifier}
                buttonLabel={label}
                open={openDrawersState[identifier] || false}
                onClick={() => handleClickDrawerButton(identifier)}
                onClose={() => handleCloseDrawer(identifier)}
                renderValue={renderValueFn()}
                buttonSuffix={<ArrowForwardIos fontSize="small" sx={{ opacity: 0.7 }} />}
                buttonPrefix={renderIcon(section.sectionIcon)}
                {...drawerProps}
            >
                <FormRenderer formFields={resolvedFormFields as FormField[]} fieldName={fieldName} />
            </SwipeableFormDrawer>

            {alertCompareDate && (
                <DifferentDayAlertMaybe deliveryOptions={deliveryOptions} bookingDetails={bookingDetails} alertCompareDate={alertCompareDate} />
            )}
        </>
    );
};

export const ReturnMethodFormSection: React.FC<ReturnMethodFormSectionProps> = ({
    section,
    openDrawersState,
    handleClickDrawerButton,
    handleCloseDrawer,
}) => {
    const { label, selectItems } = section;

    const identifier = 'returnMethod';

    const resolvedFormFields: FormFieldSelect[] = useMemo(() => {
        const items: FormSelectItem[] = selectItems.map((item) => {
            if (item.type === 'alert') {
                return {
                    type: 'alert',
                    severity: item.severity || 'info',
                    content: <div style={{ margin: 0, padding: 0 }}>{item.content}</div>,
                };
            }

            const { prefix, price, text, ...rest } = item;

            const itemPrefix = <StyledSvgIcon>{renderIcon(item.prefix)}</StyledSvgIcon>;
            const itemSuffix = (
                <Typography variant="body2" fontWeight="bold" textAlign="end">
                    {formatPrice(price || 0)}
                </Typography>
            );

            if (item.type === 'item') {
                return {
                    ...rest,
                    type: 'item',
                    prefix: itemPrefix,
                    suffix: itemSuffix,
                    content: item.text,
                };
            }

            assertNever(item);
        });

        return [
            {
                type: 'select',
                controlName: identifier,
                label,
                closeDrawerOnSelect: true,
                renderItem: renderSelectItem,
                registerOptions: { required: true, shouldUnregister: true },
                items,
            },
        ];
    }, [selectItems]);

    return (
        <>
            <SwipeableFormDrawer
                id={identifier}
                label={label}
                buttonLabel={label}
                open={openDrawersState[identifier] || false}
                onClick={() => handleClickDrawerButton(identifier)}
                onClose={() => handleCloseDrawer(identifier)}
                renderValue={renderReturnMethodValue()}
                sx={{ paddingBottom: 'env(safe-area-inset-bottom)' }}
                buttonSuffix={<ArrowForwardIos fontSize="small" sx={{ opacity: 0.7 }} />}
                buttonPrefix={renderIcon(section.sectionIcon)}
            >
                <FormRenderer formFields={resolvedFormFields} fieldName={identifier} />
            </SwipeableFormDrawer>
        </>
    );
};

const dateByTypeMap = {
    delivery: 'renterDeliveryDate',
    return: 'renterReturnDate',
} as const;

const DisplayDateSection: React.FC<DisplayDisplayDateSectionProps> = ({ section, bookingDetails, deliveryOptions }) => {
    const { type, label, format, prefix, displayDifferentDayAlert } = section;
    const { deliveryMethod } = bookingDetails;

    const resolvedDisplayValue = dateByTypeMap[type];
    const displayDateFormatted = getOpeningHourStringForDate(deliveryOptions, deliveryMethod, resolvedDisplayValue, format);

    const renderAlert = () => {
        if (!displayDifferentDayAlert) {
            return null;
        }

        return <DifferentDayAlertMaybe deliveryOptions={deliveryOptions} bookingDetails={bookingDetails} alertCompareDate={resolvedDisplayValue} />;
    };

    const resolvedValue = prefix ? `${prefix} ${displayDateFormatted}` : capitalizeString(displayDateFormatted || '');

    return <DisplayFormValueComponent value={resolvedValue} label={label} alertComponent={renderAlert} prefix={renderIcon(section.sectionIcon)} />;
};

export const CheckoutSectionRenderer: React.FC<CheckoutSectionRendererProps> = ({ checkoutSections, bookingDetails, deliveryOptions }) => {
    const { data: currentUser } = useCurrentUser();
    const { getValues } = useFormContext();
    const { openDrawersState, openDrawer, closeDrawers } = useDrawer();

    const handleClickDrawerButton = useCallback(
        (name: string) => {
            if (openDrawersState[name]) {
                closeDrawers(name);
            } else {
                openDrawer(name, true);
            }
        },
        [openDrawersState],
    );

    const handleCloseDrawer = useCallback((name: string) => {
        closeDrawers(name);
    }, []);

    let shouldRender = true;

    return (
        <>
            {checkoutSections.map((section, i) => {
                if (!shouldRender) {
                    return null;
                }

                const commonProps = {
                    openDrawersState,
                    handleClickDrawerButton,
                    handleCloseDrawer,
                    bookingDetails,
                    deliveryOptions,
                    currentUser,
                };

                const { component } = section;
                const key = `${component}-${i}`;

                const renderComponent = () => {
                    switch (section.component) {
                        case 'common.condition-block':
                            const { conditions } = section;

                            const conditionsMet = conditions.every((condition) => {
                                const value = getValues(condition.value);

                                return condition.exists ? !!value : !value;
                            });

                            shouldRender = conditionsMet;

                            return null;

                        case 'delivery.info-section':
                            return (
                                <DisplayFormValueComponent
                                    value={section.description}
                                    label={section.label}
                                    prefix={renderIcon(section.sectionIcon)}
                                />
                            );
                        case 'delivery.date-time-form-section':
                            return <DateTimeFormRenderer section={section} {...commonProps} />;

                        case 'delivery.delivery-details-form':
                            return <FormSectionRenderer section={section} {...commonProps} />;

                        case 'delivery.return-method-selector':
                            return <ReturnMethodFormSection section={section} {...commonProps} />;

                        case 'delivery.selected-info-section':
                            return <DisplayDateSection section={section} {...commonProps} />;

                        case 'common.divider':
                            return <Divider sx={{ px: section.px, py: section.py }} />;

                        case 'common.section-title':
                            return (
                                <Typography variant={section.variant} sx={{ fontWeight: section.bold ? 'bold' : 'normal' }}>
                                    {section.text}
                                </Typography>
                            );

                        case 'delivery.pickup-point-selector':
                            return <PickupPointSelector section={section} {...commonProps} />;
                        default:
                            return null;
                    }
                };

                const renderedComponent = renderComponent();
                if (renderedComponent === null) {
                    return null;
                }

                return (
                    <AnimatedContainer
                        variants={fadeUp}
                        transition={{ delay: 0.1 }}
                        key={key}
                        style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}
                    >
                        {renderedComponent}
                    </AnimatedContainer>
                );
            })}
        </>
    );
};
