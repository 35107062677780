import { useTranslation } from 'react-i18next';
import { useUser } from '../../user/hooks/useUser';
import { Box, Button, SxProps, Typography, styled } from '@mui/material';
import { Chat } from '@mui/icons-material';
import { useChatWindow } from '../../context/chat';
import { ExtendedListing } from '../../types/apiTypes';
import { getApiClient } from '../../services/sharetribe/apiClients';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { sleep } from '../../helpers/commonHelpers';
import { AnimatedContainer } from '../../animations/components/AnimatedContainer';
import { fadeIn } from '../../animations/constants';

const ButtonContainer = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
}));

interface ChatWithUserButtonProps {
    sx?: SxProps;
    userId: string;
    buttonText: string;
    description?: string;
    listingId?: string;
}

export const ChatWithUserButton: React.FC<ChatWithUserButtonProps> = ({ sx, description, userId, listingId, buttonText }) => {
    const [loading, setLoading] = useState(false);
    const { data } = useUser(userId);
    const { openChat } = useChatWindow();

    if (!data) return <ButtonContainer />;

    const handleOpenChat = async () => {
        setLoading(true);

        try {
            let listing: ExtendedListing | undefined;

            if (listingId) {
                const { data } = await getApiClient('listings').get<ExtendedListing>(`/${listingId}`);
                listing = data;
            }

            const payload = {
                participant: {
                    id: data.id,
                    displayName: data.profile.displayName,
                    profileImage: data.profileImage,
                },
                ...(listing && { listing }),
            };

            openChat(payload);
        } catch (err) {
            console.error(err);
        }

        setLoading(false);
    };

    return (
        <AnimatedContainer variants={fadeIn} style={{ width: '100%' }}>
            <ButtonContainer sx={sx}>
                {description && (
                    <Typography variant="body2" sx={{ mb: 1, opacity: 0.8 }}>
                        {description}
                    </Typography>
                )}

                <LoadingButton variant="contained" size="small" loading={loading} fullWidth onClick={handleOpenChat}>
                    {buttonText}
                </LoadingButton>
            </ButtonContainer>
        </AnimatedContainer>
    );
};
