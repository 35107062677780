import React, { useState } from 'react';
import parse from 'html-react-parser';
import { ButtonBase, Typography, styled } from '@mui/material';
import { Trans } from 'react-i18next';
import { Modal } from '../../components/Modal/Modal';
import { TosSpan } from './SignUp.components';
import { DynamicStrapiPage } from '../../strapi/components/DynamicStrapiPage';

export const TermsOfServiceModal: React.FC = () => {
    const [tosOpen, setTosOpen] = useState(false);

    return (
        <>
            <ButtonBase onClick={() => setTosOpen(true)} sx={{ width: '100%' }}>
                <Typography variant="caption">
                    <Trans i18nKey="tosAgreementText">
                        By signing up, you agree to our <TosSpan>Terms of Service</TosSpan>
                    </Trans>
                </Typography>
            </ButtonBase>

            <Modal open={tosOpen} onClose={() => setTosOpen(false)} variant="lg" modalStyle={{ overflowY: 'auto' }}>
                <DynamicStrapiPage name="terms-of-service" />
            </Modal>
        </>
    );
};
