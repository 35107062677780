import Typography from '@mui/material/Typography';
import React from 'react';
import { StyledLink } from '../../../components/Styled/Styled.components';
import { OpenInNew } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

interface StyledTrackingUrlProps {
    shipmentNumber?: string;
    trackingUrl?: string;
    transactionType: string;
}

export const StyledTrackingUrl: React.FC<StyledTrackingUrlProps> = ({ transactionType, shipmentNumber, trackingUrl }) => {
    const { t } = useTranslation();

    return (
        <Typography variant="body2" sx={{ display: 'inline' }}>
            {trackingUrl ? (
                <StyledLink to={trackingUrl} target="_blank" rel="noopener">
                    {shipmentNumber}
                    <OpenInNew sx={{ ml: '4px', fontSize: '10px' }} />
                </StyledLink>
            ) : (
                t(`${transactionType}-noTrackingNumber`)
            )}
        </Typography>
    );
};
