export const MatkaHuoltoStatusCodes = {
    '02': 'Electronic pre-notification received',
    '08': 'Picked up',
    '10': 'At departure package point',
    '12': 'Consolidated',
    '15': 'Received for transport',
    '25': 'Loaded in the main load',
    '35': 'Incoming shelving',
    '40': 'Waiting for loading for distribution',
    '41': 'Waiting for loading to the pick-up point',
    '45': 'Loaded in the distribution load',
    '46': 'Loaded to the pick-up point',
    '47': 'Delivered to the pick-up point',
    '48': 'Arrived at the pick-up point',
    '50': 'Ready for pick-up',
    '55': 'Notified (1st time)',
    '56': 'Notified (2nd time)',
    '57': 'Notified manually',
    '60': 'Delivered',
    '61': 'Delivered with authorization',
    '62': 'Delivery cancelled',
    '65': 'Bus prepayment paid to sender',
    '70': 'Returned as unclaimed',
    '97': 'Distribution failed, transferred to office',
};
