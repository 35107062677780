import { ArrowBack } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useSignicatIdentificationFlow } from '../../../hooks/auth/signicat/useIdentification';
import { IdentityVerification, IdentityVerificationState } from '../../../views/IdentityVerification/IdentityVerification';
import { StepBackButton, StepContent, StepContentContainer } from '../UserOnboarding.components';
import { BaseStepProps } from '../UserOnboarding.types';

interface SignicatVerificationStepProps extends BaseStepProps {
    identificationState: IdentityVerificationState;
}

export const VerificationStep: React.FC<SignicatVerificationStepProps> = ({
    onComplete,
    loading,
    onGoBack,
    hasPreviousStep,
    identificationState,
}) => {
    const { t } = useTranslation();
    const state = identificationState || 'initial';
    const location = useLocation();

    const redirectParams = {
        path: location.pathname === '/' ? 'index' : location.pathname,
    };

    const { mutate: startRedirectFlow, isLoading } = useSignicatIdentificationFlow(redirectParams);

    return (
        <StepContent>
            <StepContentContainer>
                <IdentityVerification identificationProvider="signicat" state={state} />
            </StepContentContainer>

            {hasPreviousStep && (
                <StepBackButton onClick={onGoBack}>
                    <ArrowBack />
                </StepBackButton>
            )}

            {(state === 'abort' || state === 'error') && (
                <>
                    <LoadingButton variant="contained" onClick={() => startRedirectFlow()}>
                        {t('tryAgain')}
                    </LoadingButton>
                    <LoadingButton variant="text" onClick={() => onComplete()}>
                        {t('skip')}
                    </LoadingButton>
                </>
            )}

            {state === 'initial' && (
                <>
                    <LoadingButton loading={isLoading} variant="contained" onClick={() => startRedirectFlow()}>
                        {t('verifyIdentityShort')}
                    </LoadingButton>
                    <LoadingButton variant="text" onClick={() => onComplete()} loading={loading}>
                        {t('later')}
                    </LoadingButton>
                </>
            )}

            {state === 'success' && (
                <>
                    <LoadingButton variant="contained" onClick={onComplete} loading={loading}>
                        {t('continue')}
                    </LoadingButton>
                </>
            )}
        </StepContent>
    );
};
