import React from 'react';
import {
    Alert,
    Box,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    List,
    ListItemButton,
    Radio,
    RadioGroup,
    Typography,
} from '@mui/material';
import { useFormContext, Controller, ControllerRenderProps, RegisterOptions } from 'react-hook-form';
import { FormSelectItem, SelectItem } from '../FormRenderer.types';
import { ShowMore } from '../../ShowMore/ShowMore';

interface FormSelectProps {
    controlName: string;
    label: string;
    items: FormSelectItem[];
    variant?: 'select' | 'radio';
    registerOptions?: RegisterOptions;
    onSelect?: () => void;
    renderItem?: (item: SelectItem) => React.ReactNode;
}

export const FormSelect: React.FC<FormSelectProps> = ({ controlName, items, renderItem, onSelect, variant = 'select', registerOptions }) => {
    const { control } = useFormContext();

    const renderOption = (item: SelectItem) => {
        if (renderItem) {
            return renderItem(item);
        }
        return <>{item.title}</>;
    };

    const handleSelect = (field: ControllerRenderProps, value: string) => {
        if (onSelect) {
            onSelect();
        }

        field.onChange(value);
    };

    const renderRadioGroup = (field: ControllerRenderProps) => (
        <RadioGroup onChange={(event) => handleSelect(field, event.target.value)}>
            {items.map((item, index) => (
                <React.Fragment key={index}>
                    {item.type === 'item' ? (
                        <FormControlLabel
                            value={item.value}
                            labelPlacement="start"
                            sx={{ py: 1, justifyContent: 'space-between' }}
                            control={<Radio checked={field.value === item.value} disabled={item.disabled} sx={{ py: 1 }} />}
                            label={renderOption(item)}
                        />
                    ) : (
                        <Box sx={{ width: '100%', px: 2, py: 1 }}>
                            <Alert severity="info">
                                {item.maxHeight ? (
                                    <ShowMore value={item.content} maxHeight={item.maxHeight} rows={item.rows} />
                                ) : (
                                    <Typography variant="caption">{item.content}</Typography>
                                )}
                            </Alert>
                        </Box>
                    )}
                    {index < items.length - 1 && <Divider />}
                </React.Fragment>
            ))}
        </RadioGroup>
    );

    const renderList = (field: ControllerRenderProps) => (
        <List>
            {items.map((item, index) => (
                <React.Fragment key={index}>
                    {item.type === 'item' ? (
                        <>
                            <ListItemButton
                                disabled={item.disabled}
                                selected={field.value === item.value}
                                onClick={() => handleSelect(field, item.value)}
                            >
                                {renderOption(item)}
                            </ListItemButton>
                            {item.disabled && item.disabledReason && (
                                <FormHelperText sx={{ px: 2, py: 1 }} error>
                                    {item.disabledReason}
                                </FormHelperText>
                            )}
                        </>
                    ) : (
                        <Box sx={{ width: '100%', px: 2, py: 1 }}>
                            <Alert severity="info">
                                {item.maxHeight ? (
                                    <ShowMore value={item.content} maxHeight={item.maxHeight} rows={item.rows} />
                                ) : (
                                    <Typography variant="caption">{item.content}</Typography>
                                )}
                            </Alert>
                        </Box>
                    )}
                </React.Fragment>
            ))}
        </List>
    );

    return (
        <FormControl fullWidth>
            <Controller
                name={controlName}
                control={control}
                rules={{ ...registerOptions }}
                render={({ field }) => (variant === 'radio' ? renderRadioGroup(field) : renderList(field))}
            />
        </FormControl>
    );
};
