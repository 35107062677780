import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import Heart from '../../../assets/images/onboarding/Heart.png';
import ShowCase from '../../../assets/images/onboarding/Showcase.png';
import { User } from '../../../types/apiTypes';
import { StepBackButton, StepContent, StepContentContainer } from '../LenderOnboarding.components';
import { StaggerList, StaggerListElement } from '../../../animations/components/Stagger';

import { itemVariantsB } from '../../../animations/constants';
import { motion } from 'framer-motion';
import { BaseStepProps } from '../LenderOnboarding.types';
import { Trans, useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { ArrowBack } from '@mui/icons-material';
import { useSafeNavigate } from '../../../hooks/useSafeNavigate';

interface StartStepProps extends BaseStepProps {
    user: User;
}

const ExistingLenderContent: React.FC = () => {
    const { t } = useTranslation();

    return (
        <StaggerList custom={{ stagger: 0.3 }} style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}>
            <StaggerListElement
                custom={{ stagger: 0 }}
                transition={{ delay: 0.5 }}
                variants={itemVariantsB}
                style={{ height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <img src={Heart} height={60} />
            </StaggerListElement>

            <StaggerListElement transition={{ delay: 0.8 }}>
                <Typography variant="h5" fontWeight="bold">
                    {t('existingLenderOnboardingFirstStepTitle')}
                </Typography>
            </StaggerListElement>

            <StaggerListElement transition={{ delay: 1.4 }} custom={0}>
                <Typography variant="subtitle1" sx={{ marginBottom: '20px' }}>
                    {t('existingLenderOnboardingFirstStepDescription')}
                </Typography>
            </StaggerListElement>
        </StaggerList>
    );
};

const NewLenderContent: React.FC = () => {
    const { t } = useTranslation();

    return (
        <StaggerList custom={{ stagger: 0.3 }} style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}>
            <StaggerListElement
                custom={{ stagger: 0 }}
                transition={{ delay: 0.5 }}
                variants={itemVariantsB}
                style={{ height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <img src={ShowCase} style={{ objectFit: 'contain', width: '100%', maxWidth: '250px' }} />
            </StaggerListElement>

            <StaggerListElement transition={{ delay: 0.8 }}>
                <Typography variant="h5" fontWeight="bold">
                    {t('newLenderOnboardingFirstStepTitle')}
                </Typography>
            </StaggerListElement>

            <StaggerListElement transition={{ delay: 1.4 }} custom={0}>
                <Typography variant="subtitle1" sx={{ marginBottom: '20px' }}>
                    {t('newLenderOnboardingFirstStepDescription')}
                </Typography>
            </StaggerListElement>
        </StaggerList>
    );
};

export const StartStep: React.FC<StartStepProps> = ({ onComplete, loading, user }) => {
    const { t } = useTranslation();
    const isExistingLender = user.stripeConnected;
    const navigate = useSafeNavigate();

    return (
        <StepContent>
            <StepContentContainer>{isExistingLender ? <ExistingLenderContent /> : <NewLenderContent />}</StepContentContainer>

            <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 2, type: 'spring', damping: 7, stiffness: 30 }}
            >
                <LoadingButton loading={loading} variant="contained" onClick={onComplete}>
                    {t('continue')}
                </LoadingButton>
            </motion.div>

            {!isExistingLender && (
                <StepBackButton onClick={() => navigate(-1)}>
                    <ArrowBack />
                </StepBackButton>
            )}
        </StepContent>
    );
};
