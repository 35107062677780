import { useTranslation, Trans } from 'react-i18next';
import { AnimatedContainer } from '../../animations/components/AnimatedContainer';
import { fadeInScaleZoom } from '../../animations/constants';
import { AlertDrawer } from '../../components/AlertDrawer/AlertDrawer';
import { formatDateRange, formatDayMonthAndDate } from '../../helpers/dateAndTimeHelpers';
import { getFormattedOpeningHoursForReturnOrDeliveryDate } from '../../queries/useDeliveryTiming';
import { BookingDetails } from './LogisticsSection.helpers';
import { getAvailabilityHoursString, getDeliveryInformation, getTransactionDate, isDateInsideException } from '../../helpers/delivery';
import { DeliveryOption } from '../../types/delivery';

const typeByDateMap = {
    renterDeliveryDate: 'delivery',
    renterReturnDate: 'return',
};

export const DifferentDayAlertMaybe: React.FC<{
    deliveryOptions: DeliveryOption[];
    bookingDetails: BookingDetails;
    alertCompareDate: 'renterDeliveryDate' | 'renterReturnDate';
}> = ({ deliveryOptions, bookingDetails, alertCompareDate }) => {
    const { booking, deliveryMethod } = bookingDetails;
    const { t } = useTranslation();

    let compareStartDate: Date | string | null = null;
    const type = typeByDateMap[alertCompareDate];

    const getDeliveryInformationFn = () => {
        if (type === 'pickup' || type === 'delivery') {
            compareStartDate = getTransactionDate(deliveryOptions, deliveryMethod, 'renterDeliveryDate');
            const compareEndDate = booking.range.bookingStart;

            return getDeliveryInformation(deliveryOptions, deliveryMethod, compareEndDate, 'delivery');
        }

        if (type === 'return') {
            compareStartDate = getTransactionDate(deliveryOptions, deliveryMethod, 'renterReturnDate');
            const compareEndDate = booking.range.bookingEnd;

            return getDeliveryInformation(deliveryOptions, deliveryMethod, compareEndDate, 'return');
        }
    };

    const deliveryInformation = getDeliveryInformationFn();

    if (!deliveryInformation) {
        return null;
    }

    const { isDeliveryDateDifferent, daysDiff, availabilityExceptions } = deliveryInformation;

    const dayString = daysDiff === 1 ? t('day') : t('days');
    const daysDiffString = `${daysDiff} ${dayString}`;

    // If showing alert for return delivery, show office opening hours instead of delivery hours
    const openingHoursDatesString = getAvailabilityHoursString(deliveryOptions, deliveryMethod, t);

    // Pickup is considered "delivery" for the purposes of opening hours
    const dateType = type === 'pickup' ? 'delivery' : 'return';
    const openingHoursForDate = getFormattedOpeningHoursForReturnOrDeliveryDate(deliveryMethod, deliveryOptions, dateType);

    const getAvailabilityExceptionsStringMaybe = () => {
        const compareDate = type === 'return' ? booking.range.bookingEnd : booking.range.bookingStart;
        if (!compareDate) {
            return '';
        }

        const d = new Date(compareDate);

        if (isDateInsideException(d, availabilityExceptions)) {
            if (!availabilityExceptions?.length) {
                return '';
            }

            return availabilityExceptions.map((exception) => formatDateRange(exception.startDate, exception.endDate)).join(',<br />');
        }

        return '';
    };

    const availabilityExceptionsString = getAvailabilityExceptionsStringMaybe();

    if (isDeliveryDateDifferent) {
        return (
            <AnimatedContainer variants={fadeInScaleZoom} transition={{ delay: 0.3, type: 'spring', stiffness: 110, damping: 15 }}>
                <AlertDrawer
                    severity="info"
                    alertBtnAlertSx={{ padding: '6px 8px', textAlign: 'start' }}
                    summary={<Trans i18nKey={`${type}OnDateSummary`} values={{ daysDiffString }} components={{ b: <b /> }} />}
                    title={t(`${type}DateNote`)}
                    message={
                        <>
                            <Trans
                                i18nKey={`${type}OnDate`}
                                components={{
                                    b: <b />,
                                    i: <i />,
                                    br: <br />,
                                    h3: <h3 />,
                                    availability: availabilityExceptionsString ? (
                                        <Trans
                                            i18nKey="showroomAvailabilityExceptions"
                                            components={{ b: <b />, ul: <ul />, li: <li />, br: <br />, h4: <h4 /> }}
                                            values={{ availabilityExceptionsString }}
                                        ></Trans>
                                    ) : (
                                        <></>
                                    ),
                                }}
                                values={{
                                    date: formatDayMonthAndDate(compareStartDate),
                                    daysDiffString,
                                    availabilityExceptionsString,
                                    openingHoursForDate: openingHoursForDate,
                                    openingHourDates: openingHoursDatesString,
                                }}
                            />
                        </>
                    }
                />
            </AnimatedContainer>
        );
    }

    return null;
};
