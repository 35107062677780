import React, { useMemo } from 'react';
import { AnimatePresence, Variants, motion } from 'framer-motion';
import { useStepperContext } from './Stepper';
import { StepperIndicators } from './StepperIndicators';
import { Box, useTheme } from '@mui/material';
import { AnimatedContainer } from '../../animations/components/AnimatedContainer';
import { fadeInOutScale } from '../../animations/constants';

export const stepperVariants: Variants = {
    initial: (direction: 'left' | 'right' | undefined) => {
        let base = {
            opacity: 0,
        };

        if (direction === 'left') {
            return { ...base, x: '-100%' };
        }
        if (direction === 'right') {
            return { ...base, x: '100%' };
        }
        return base;
    },
    in: {
        opacity: 1,
        x: 0,
    },
    out: (direction: 'left' | 'right' | undefined) => {
        let base = {
            opacity: 0,
        };

        if (direction === 'left') {
            return { ...base, x: '100%' };
        }
        if (direction === 'right') {
            return { ...base, x: '-100%' };
        }
        return base;
    },
};

const INDICATORS_HEIGHT_PX = 40;

export const StepperContainer: React.FC<{ children: React.ReactNode; hideIndicators?: boolean }> = ({ children, hideIndicators = false }) => {
    const { direction, activeStep } = useStepperContext();

    const childrenArray = useMemo(() => React.Children.toArray(children).filter(Boolean), [children]);
    const step = childrenArray[activeStep];
    const theme = useTheme();

    return (
        <Box sx={{ width: '100%', height: '100%', position: 'relative' }}>
            <AnimatePresence initial mode="popLayout" custom={direction}>
                <AnimatedContainer
                    variants={stepperVariants}
                    key={activeStep}
                    custom={direction}
                    style={{ height: '100%' }}
                    transition={{ type: 'spring', damping: 16, bounce: 0, stiffness: 80 }}
                >
                    {step}
                </AnimatedContainer>
            </AnimatePresence>

            <AnimatePresence>
                {!hideIndicators && (
                    <AnimatedContainer
                        key="stepper-indicators"
                        variants={fadeInOutScale}
                        style={{
                            position: 'sticky',
                            bottom: '0',
                            height: `${INDICATORS_HEIGHT_PX}px`,
                            width: '100%',
                            paddingBottom: 'env(safe-area-inset-bottom)',
                            display: 'flex',
                            alignItems: 'center',
                            background: theme.palette.linearGradient.default,
                        }}
                    >
                        <StepperIndicators />
                    </AnimatedContainer>
                )}
            </AnimatePresence>
        </Box>
    );
};
