import { Browser } from '@capacitor/browser';
import { LoadingButton } from '@mui/lab';
import { FormHelperText, Grid, MenuItem, TextField, Typography, useTheme } from '@mui/material';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { StripeConnectFormProps, StripeConnectFormData } from './StripeConnectForm.types';
import { getCountryCurrency, requiredInputs, supportedCountries, supportedCountryCodes } from './StripeConnectForm.utils';
import { useCreateBankToken } from './useCreateBankToken';
import { useTranslation } from 'react-i18next';
import { getAppConfig } from '../../../countryConfigs';
import { getBankAccountPlaceholder, getRoutingNumberPlaceholderMaybe } from './helpers';
import { useHasPermission } from '../../../user/hooks/usePermissions';
import { StripeAccountDataWithStatus } from '../hooks/useStripeAccount';

interface StripeConnectInputsProps {
    methods: UseFormReturn<StripeConnectFormData>;
    stripeAccountData: StripeAccountDataWithStatus | undefined;
    tokenState: { token: string; stripeError: string };
    defaultCountryCode: 'FI' | 'US';
    countryCode: 'FI' | 'US';
    currency: string;
    onValidating: (validating: boolean) => void;
    setTokenState: (state: { token: string; stripeError: string }) => void;
    canChangeCOD: boolean;
}

export const StripeConnectInputs: React.FC<StripeConnectInputsProps> = ({
    methods,
    stripeAccountData,
    countryCode,
    defaultCountryCode,
    currency,
    tokenState,
    setTokenState,
    canChangeCOD,
    onValidating,
}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const {
        register,
        setValue,
        getValues,
        unregister,
        formState: { errors },
    } = methods;

    useEffect(() => {
        const formValues = getValues();
        const keys = Object.keys(formValues) as (keyof StripeConnectFormData)[];
        const alwaysRequired = ['countryCode', 'currency'];
        const requiredForCountry = formInputs as (keyof StripeConnectFormData)[];

        for (const value of keys) {
            if (alwaysRequired.includes(value)) {
                return;
            }

            if (!requiredForCountry.includes(value)) {
                unregister(value);
            }
        }
    }, [countryCode]);

    const bankTokenParams = {
        currency,
        countryCode,
        onSuccess: (token: string) => setTokenState({ token, stripeError: '' }),
        onError: (stripeError: string) => setTokenState({ token: '', stripeError }),
    };

    const { mutate: validateBankToken, isLoading: isValidating } = useCreateBankToken(bankTokenParams);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedValidateToken = useCallback(
        debounce(() => {
            const formValues = getValues();
            validateBankToken(formValues);
        }, 1000),
        [],
    );

    useEffect(() => {
        debouncedValidateToken();
    }, [debouncedValidateToken, countryCode, currency]);

    useEffect(() => {
        onValidating(isValidating);
    }, [isValidating]);

    const formInputs = useMemo(() => requiredInputs(countryCode), [countryCode]);

    const getPlaceholderMaybe = (inputType: typeof formInputs[number]) => {
        if (inputType === 'iban' || inputType === 'accountNumber') {
            return getBankAccountPlaceholder(stripeAccountData, countryCode);
        }

        if (inputType === 'routingNumber') {
            return getRoutingNumberPlaceholderMaybe(stripeAccountData, countryCode);
        }

        return '';
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                {canChangeCOD ? (
                    <TextField
                        fullWidth
                        // @ts-ignore
                        inputRef={register('countryCode', {
                            required: true,
                        })}
                        select
                        onChange={(e) => setValue('countryCode', e.target.value as 'FI' | 'US')}
                        label={t('countryOfResidence')}
                        SelectProps={{ MenuProps: { sx: { maxHeight: '400px' } } }}
                        defaultValue={defaultCountryCode}
                    >
                        {supportedCountries.map((item) => (
                            <MenuItem key={item.countryCode} value={item.countryCode}>
                                {t(item.countryCode)}
                            </MenuItem>
                        ))}
                    </TextField>
                ) : (
                    <TextField label={t('countryOfResidence')} value={defaultCountryCode} disabled />
                )}
            </Grid>

            <Grid item xs={6}>
                <TextField label={t('currency')} value={currency} disabled />
            </Grid>

            {formInputs.map((inputType) => {
                const placeholder = getPlaceholderMaybe(inputType);
                const placeholderProps = placeholder
                    ? {
                          placeholder,
                          inputProps: { sx: { '&::placeholder': { color: theme.palette.text.primary, opacity: 0.5 } } },
                          InputLabelProps: { shrink: true, style: { color: theme.palette.text.primary } },
                      }
                    : {};

                return (
                    <Grid item xs={12} key={inputType}>
                        <TextField
                            label={t(`stripeConnectForm.${inputType}.label`)}
                            fullWidth
                            required
                            {...placeholderProps}
                            error={!!tokenState.stripeError ? false : !!errors[inputType]}
                            helperText={!!errors[inputType]?.type ? t(errors[inputType]!.type) : ''}
                            {...register(inputType, {
                                required: true,
                                onChange: () => {
                                    debouncedValidateToken();
                                },
                            })}
                        />
                    </Grid>
                );
            })}

            {!!tokenState.stripeError && (
                <Grid item xs={12}>
                    <FormHelperText error>{tokenState.stripeError}</FormHelperText>
                </Grid>
            )}
        </Grid>
    );
};

export const StripeConnectForm: React.FC<StripeConnectFormProps> = ({ isLoading, stripeAccountData, connectStripeFn, onSubmit }) => {
    const [tokenState, setTokenState] = useState({ token: '', stripeError: '' });
    const [isValidating, setIsValidating] = useState(false);

    const canChangeCOD = useHasPermission('settings_change_country');

    const getDefaultCountryCode = () => {
        const stripeAccountCountry = stripeAccountData?.country;
        if (stripeAccountCountry && supportedCountryCodes.includes(stripeAccountCountry as 'FI' | 'US')) {
            return stripeAccountCountry as 'FI' | 'US';
        }

        const { countryCode } = getAppConfig();
        return countryCode as 'FI' | 'US';
    };

    const defaultCountryCode = getDefaultCountryCode();

    const methods = useForm<StripeConnectFormData>({
        defaultValues: {
            countryCode: defaultCountryCode,
        },
    });

    const {
        watch,
        formState: { isDirty, isValid, errors },
    } = methods;

    const countryCode = watch('countryCode');
    const currency = getCountryCurrency(countryCode);

    const submitHandler = (formData: StripeConnectFormData) => {
        const { token: bankAccountToken, stripeError } = tokenState;
        if (!bankAccountToken || stripeError) {
            throw new Error('Bank account token missing or stripe error present');
        }

        connectStripeFn({ ...formData, currency, bankAccountToken });
    };

    const onClickStripeAgreement = async () => {
        await Browser.open({ url: 'https://stripe.com/en-fi/legal/connect-account' });
    };

    return (
        <form onSubmit={methods.handleSubmit(submitHandler)}>
            <StripeConnectInputs
                methods={methods}
                stripeAccountData={stripeAccountData}
                tokenState={tokenState}
                countryCode={countryCode}
                defaultCountryCode={defaultCountryCode}
                currency={currency}
                setTokenState={setTokenState}
                canChangeCOD={canChangeCOD}
                onValidating={setIsValidating}
            />

            <div style={{ marginBottom: '10px', marginTop: '20px' }}>
                <Typography variant="caption">
                    By proceeding, you agree to the{' '}
                    <span
                        role="button"
                        tabIndex={0}
                        style={{ fontWeight: 'bold', textDecoration: 'underline' }}
                        onClick={onClickStripeAgreement}
                        onKeyDown={(evt) => {
                            if (evt.key === 'Enter') {
                                onClickStripeAgreement();
                            }
                        }}
                    >
                        Stripe Connected Account Agreement
                    </span>
                </Typography>
            </div>

            <LoadingButton
                fullWidth
                disabled={!isDirty || !isValid || !tokenState.token}
                type="submit"
                variant="contained"
                loading={isLoading || isValidating}
            >
                Proceed
            </LoadingButton>
        </form>
    );
};
