import React, { useEffect, useState } from 'react';
import { Preferences } from '@capacitor/preferences';
import { Box, Button, MenuItem, Select, Typography, InputLabel, FormControl, ListItemIcon, ListItemText } from '@mui/material';
import { US, FI } from 'country-flag-icons/react/3x2';
import { useTranslation } from 'react-i18next';
import { countrySelectionEnabled, handleChangeCountryOfResidence, tryResolveUserCountry } from '../../countryConfigs';
import { Capacitor } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { RobesLogoFull } from '../RobesLogo/RobesLogoFull';

interface CountryInitializerProps {
    children: React.ReactNode;
}

export const CountryInitializer: React.FC<CountryInitializerProps> = ({ children }) => {
    const [country, setCountry] = useState<string | null>(null);
    const [loading, setLoading] = useState(true);
    const [selectedCountry, setSelectedCountry] = useState<string>('');

    const { t } = useTranslation();

    const countries = [
        { value: 'US', label: 'country-US', icon: <US /> },
        { value: 'FI', label: 'country-FI', icon: <FI /> },
    ];

    const saveCountry = async (selectedCountry: string) => {
        await handleChangeCountryOfResidence(selectedCountry);
        setCountry(selectedCountry);
    };

    const initAppConfig = async () => {
        const { value } = await Preferences.get({ key: 'country' });

        let selectedCountry = value;

        if (selectedCountry) {
            await saveCountry(selectedCountry);
        } else {
            selectedCountry = await tryResolveUserCountry();

            if (selectedCountry) {
                await saveCountry(selectedCountry);
            }
        }

        if (Capacitor.isNativePlatform()) {
            await SplashScreen.hide();
        }

        setLoading(false);
    };

    const initAppConfigTemp = async () => {
        const { value } = await Preferences.get({ key: 'country' });

        if (!value || value !== 'FI') {
            await saveCountry('FI');
        } else {
            setCountry('FI');
        }

        if (Capacitor.isNativePlatform()) {
            await SplashScreen.hide();
        }

        setLoading(false);
    };

    useEffect(() => {
        if (countrySelectionEnabled) {
            initAppConfig();
        } else {
            initAppConfigTemp();
        }
    }, []);

    if (loading) {
        return null;
    }

    if (country) {
        return <>{children}</>;
    }

    return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh" gap={2}>
            <RobesLogoFull height={70} />
            <Typography variant="h6" sx={{ mt: 5 }}>
                {t('selectCountry')}
            </Typography>
            <FormControl sx={{ width: '300px', mt: 2 }}>
                <InputLabel id="country-select-label">{t('country')}</InputLabel>
                <Select
                    value={selectedCountry}
                    id="country-select"
                    labelId="country-select-label"
                    label={t('country')}
                    renderValue={(value) => {
                        const country = countries.find((country) => country.value === value);
                        return country ? (
                            <Box display="flex" gap={2} alignItems="center">
                                <ListItemIcon sx={{ minWidth: 20, height: 20 }}>{country.icon}</ListItemIcon>
                                <ListItemText>{t(country.label)}</ListItemText>
                            </Box>
                        ) : null;
                    }}
                    onChange={(e) => setSelectedCountry(e.target.value as string)}
                >
                    {countries.map((country) => (
                        <MenuItem key={country.value} value={country.value}>
                            <ListItemIcon sx={{ height: 20, mr: 2 }}>{country.icon}</ListItemIcon>
                            <ListItemText>{t(country.label)} </ListItemText>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Button
                variant="contained"
                color="primary"
                onClick={() => saveCountry(selectedCountry)}
                disabled={!selectedCountry}
                style={{ marginTop: 16 }}
            >
                {t('confirm')}
            </Button>
        </Box>
    );
};
