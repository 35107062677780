import React from 'react';
import { StepBackButton, StepContent, StepContentContainer } from '../LenderOnboarding.components';
import { LoadingButton } from '@mui/lab';
import { Trans, useTranslation } from 'react-i18next';
import { BaseStepProps } from '../LenderOnboarding.types';
import { ArrowBack, Info } from '@mui/icons-material';
import { User } from '../../../types/apiTypes';
import { Alert, Box, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { FormRenderer } from '../../FormRenderer/FormRenderer';
import { FormField } from '../../FormRenderer/FormRenderer.types';
import { emailValidation, phoneNumberValidation } from '../../../helpers/regex';
import { matchIsValidTel } from 'mui-tel-input';

interface DeliveryPreferencesStepProps extends BaseStepProps {
    user: User;
}

export interface DeliveryPreferences {
    street: string;
    postalCode: string;
    city: string;
    email: string;
    phone: string;
}

const getDefaultFormValues = (user: User) => {
    const filterPrivateRelayEmail = (email: string) => {
        if (email.includes('privaterelay.appleid.com')) {
            return '';
        }

        return email;
    };

    if (user.profile.privateData.deliveryPreferences) {
        return {
            street: user.profile.privateData.deliveryPreferences.street,
            postalCode: user.profile.privateData.deliveryPreferences.postalCode,
            city: user.profile.privateData.deliveryPreferences.city,
            email: filterPrivateRelayEmail(user.profile.privateData.deliveryPreferences.email),
            phone: user.profile.privateData.deliveryPreferences.phone,
        };
    }

    return {
        street: user.profile.privateData.deliveryDetails?.street,
        postalCode: user.profile.privateData.deliveryDetails?.postalCode,
        city: user.profile.privateData.deliveryDetails?.city,
        email: filterPrivateRelayEmail(user.email),
        phone: user.profile.privateData.phone,
    };
};

export const DeliveryPreferencesStep: React.FC<DeliveryPreferencesStepProps> = ({ user, onComplete, loading, onGoBack, hasPreviousStep }) => {
    const { t } = useTranslation();
    const defaultValues = getDefaultFormValues(user);
    const methods = useForm<DeliveryPreferences>({ defaultValues });

    const formFields: FormField[] = [
        {
            type: 'text',
            controlName: 'street',
            label: t('street'),
            registerOptions: { required: true },
        },
        {
            type: 'text',
            controlName: 'postalCode',
            label: t('postalCode'),
            registerOptions: { required: true, pattern: /^\d{5}$/ },
        },
        {
            type: 'text',
            controlName: 'city',
            label: t('city'),
            registerOptions: { required: true },
        },
        {
            type: 'text',
            controlName: 'email',
            label: t('email'),
            registerOptions: { required: true, pattern: emailValidation },
        },
        {
            type: 'phone',
            controlName: 'phone',
            label: t('phoneNumber'),
            registerOptions: { required: true, validate: matchIsValidTel },
        },
    ];

    const handleSubmit = () => {
        methods.handleSubmit(onComplete)();
    };

    return (
        <StepContent>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onComplete)} style={{ width: '100%' }}>
                    <StepContentContainer>
                        <Typography variant="h6">{t('lenderOnboardingAddContactDetailsTitle')}</Typography>

                        <FormRenderer formFields={formFields} />

                        <Box sx={{ display: 'flex', gap: '10px', alignItems: 'flex-start', marginTop: '30px' }}>
                            <Info />
                            <Typography variant="body1" sx={{ textAlign: 'start' }}>
                                <Trans i18nKey="lenderOnboardingDeliveryPreferencesDescription" components={{ b: <b /> }} />
                            </Typography>
                        </Box>
                    </StepContentContainer>

                    {hasPreviousStep && (
                        <StepBackButton onClick={onGoBack}>
                            <ArrowBack />
                        </StepBackButton>
                    )}
                </form>
            </FormProvider>

            <LoadingButton variant="contained" onClick={handleSubmit} loading={loading}>
                {t('continue')}
            </LoadingButton>
        </StepContent>
    );
};
