/**
 * ALL of these should be equal to the constants in banda-api
 */

export enum TransactionState {
    INITIAL = 'initial',
    PENDING_PAYMENT = 'pending-payment',
    PAYMENT_EXPIRED = 'payment-expired',
    PREAUTHORIZED = 'preauthorized',
    DELIVERED = 'delivered',
    COMPLETE = 'complete',
    REVIEWED_BY_PROVIDER = 'reviewed-by-provider',
    REVIEWED_BY_CUSTOMER = 'reviewed-by-customer',
    REVIEWED = 'reviewed',
    ACCEPTED = 'accepted',
    DECLINED = 'declined',
}

export enum TransitionName {
    COMPLETE = 'transition/complete',
    REQUEST_PAYMENT = 'transition/request-payment',
    CONFIRM_PAYMENT = 'transition/confirm-payment',
    ACCEPT = 'transition/accept',
    OPERATOR_ACCEPT = 'transition/operator-accept',
    EXPIRE = 'transition/expire',
    DECLINE = 'transition/decline',
    CUSTOMER_DECLINE_BEFORE_CONFIRM_PAYMENT = 'transition/customer-decline-before-confirm-payment',
    OPERATOR_DECLINE = 'transition/operator-decline',
    OPERATOR_DECLINE_NO_REFUND = 'transition/operator-decline-no-refund',
    CANCEL = 'transition/cancel',
    CANCEL_NO_REFUND = 'transition/cancel-no-refund',
    CUSTOMER_CANCEL = 'transition/customer-cancel',
    CUSTOMER_CANCEL_AFTER_ACCEPT = 'transition/customer-cancel-after-accept',
    PROVIDER_CANCEL_AFTER_ACCEPT = 'transition/provider-cancel-after-accept',
    REVIEW_1_BY_PROVIDER = 'transition/review-1-by-provider',
    REVIEW_2_BY_PROVIDER = 'transition/review-2-by-provider',
    REVIEW_1_BY_CUSTOMER = 'transition/review-1-by-customer',
    REVIEW_2_BY_CUSTOMER = 'transition/review-2-by-customer',
}
