import * as Sentry from '@sentry/react';
import { FetchStatus, QueryStatus } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { TFunction } from 'i18next';
import { cloneDeep, sortBy } from 'lodash';
import { getAppCountryCode, getAppCurrency } from '../countryConfigs';
import { LineItem, Money } from '../transactions/types';
import { ExtendedListing, Image, Listing, ListingPreview, ResponseMeta } from '../types/apiTypes';
import { Nil } from '../types/types';
import { getAllSizingOptions } from '../views/AddListing/constants';
import { parseFloatString } from '../views/AddListing/PricingModelForm.helpers';
import { formatShortMonthAndDate } from './dateAndTimeHelpers';
import { logger } from './logger';

/**
 * Updates a collection and returns it
 * @param collection collection to update
 * @param newEl element that will replace the element with the matching identifier
 * @param key unique identifier of the element
 */
export const updateCollectionBy = <T, K extends keyof T>(collection: T[], newEl: T, key: K): T[] => {
    const copy = collection.slice(0);
    const index = copy.findIndex((el) => el[key] === newEl[key]);

    if (index > -1) {
        copy[index] = newEl;
    } else {
        logger.error(`no element with identifier ${String(key)} === ${newEl[key]} found in collection`);
    }

    return copy;
};

/**
 * Removes multiple spaces from the object string values while keeping the type intact.
 */
export const trimObjectStringValues = <T extends Record<string, unknown>>(obj: T): T => {
    const objectKeys = Object.keys(obj) as Array<keyof typeof obj>;
    return objectKeys.reduce((acc, curr) => {
        if (typeof obj[curr] === 'string') {
            const currentVal = obj[curr] as string;
            acc[curr] = currentVal.replace(/ +/g, ' ') as typeof obj[typeof curr];
        }
        return acc;
    }, {} as typeof obj);
};

export const markSelectOptionsAsSelected = <T extends { selected: boolean; value: string }>(currentOpts: T[], value: string): T[] => {
    const copy = cloneDeep(currentOpts);
    const selectedOpts = value.split(',');
    selectedOpts.forEach((selection) => {
        const currentIdx = currentOpts.findIndex((curr) => curr.value === selection);
        if (currentIdx > -1) {
            copy[currentIdx].selected = true;
        }
    });

    return copy;
};

export const getNextPageParam = <T extends { meta: ResponseMeta; nextPage?: number } | null>(lastPage: T): number | undefined => {
    if (!lastPage || !lastPage.meta) {
        return undefined;
    }
    const { totalPages } = lastPage.meta;

    const hasNextPage = lastPage.nextPage && lastPage.nextPage <= totalPages;
    return hasNextPage ? lastPage.nextPage : undefined;
};

export const measureDuration = () => {
    let time: number;

    return {
        start: () => {
            time = new Date().getTime();
        },
        end: () => {
            const now = new Date().getTime();
            return now - time;
        },
    };
};

export const withTimeout = async <T>(promise: Promise<T>, timeout: number): Promise<T> => {
    const timer = new Promise<never>((_, reject) => setTimeout(() => reject(new Error('Function execution aborted due to timeout')), timeout));

    try {
        const res = await promise;
        return await Promise.race([res, timer]);
    } catch (error) {
        throw error;
    }
};

export const isDev = process.env.NODE_ENV !== 'production';

export const isNil = (x: unknown): x is null | undefined => x == null;

export const isNotNil = <T>(x: T | Nil): x is T => !isNil(x);

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noop = (): void => {};

export const toggleSelectOption = <T extends { selected: boolean; value: string }>(options: T[], selectedOption: T): T[] => {
    const currentSelection = options.find((option) => option.value === selectedOption.value);
    if (currentSelection) {
        const updatedSelection = {
            ...currentSelection,
            selected: !currentSelection.selected,
        };
        return updateCollectionBy(options, updatedSelection, 'value');
    }

    return options;
};

export const getCurrencySymbol = (currency: string) => {
    switch (currency) {
        case 'EUR':
            return '€';
        case 'USD':
            return '$';
        default:
            return currency;
    }
};

export const getDiscountedDailyPrice = (price: Money, discountNum: number) => ({
    ...price,
    amount: price.amount * (1 - Math.abs(discountNum) / 100),
});

export const capitalizeEachWord = (s: string): string => (s && s.length ? s.replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase())) : '-');

export const capitalizeString = (s: string): string => (s && s.length ? `${s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()}` : '-');

export const getListingTitle = (listing: Listing | ExtendedListing | ListingPreview, t: TFunction) => {
    if (!listing.publicData) {
        return '';
    }

    const { brand, category, size } = listing.publicData;

    return [brand, category, getSizeLabel(size)].map((attribute) => capitalizeEachWord(t(attribute))).join(', ');
};

export const getSizeLabel = (size: string | undefined) => getAllSizingOptions().find((option) => option.value === size)?.label || '';

export const isAxiosError = (error: unknown): error is AxiosError => {
    return (error as { isAxiosError: boolean }).isAxiosError;
};

export const resolveImageUrl = (image: Image) => {
    if (!image.variants) {
        return image.url;
    }

    if (image.variants['scaled-xlarge']) {
        return image.variants['scaled-xlarge'].url;
    }

    if (image.variants.default) {
        return image.variants.default.url;
    }

    return image.url;
};

export const formatPriceString = (amount: string) => formatPrice(parseFloat(`${amount}`.replace(',', '.')) * 100);

export const formatPrice = (amount: number): string => {
    const countryCode = getAppCountryCode();
    const currency = getAppCurrency();

    let price = amount / 100 || 0;

    return price.toLocaleString(`en-${countryCode}`, { style: 'currency', currency });
};

export const formatPriceStyled = (amount: number): string => {
    const countryCode = getAppCountryCode();
    const currency = getAppCurrency();

    let price = amount / 100 || 0;

    return price ? price.toLocaleString(`en-${countryCode}`, { style: 'currency', currency, maximumFractionDigits: 0 }) : '-';
};

export const formatLineItemDiscountPercentage = (lineItem: LineItem) => `${Math.abs(lineItem.percentage as number)}% `;
export const formatLineItemDiscountPrice = (lineItem: LineItem) => {
    const amount = (lineItem.unitPrice.amount * Math.abs(lineItem.percentage as number)) / 100;
    const formattedPrice = formatPrice(amount);

    return `-${formattedPrice}`;
};

export const stringToMoney = (amount: string) => {
    const currency = getAppCurrency();
    return { amount: parseFloatString(amount) * 100, currency };
};

export const formatPercentage = (amount: number | undefined) => {
    if (!amount) {
        return '';
    }
    if (isNaN(amount)) {
        return '';
    }

    const normalized = Math.max(amount, -100);
    if (normalized <= -100) {
        return '';
    }
    const prefix = Math.sign(normalized) === -1 ? '' : '-';
    return `(${prefix}${normalized}%)`;
};

export function isDefined<T>(value: T | undefined | null): value is T {
    return value !== undefined && value !== null;
}

export const formatDateRange = (startDate: Date | undefined, endDate: Date | undefined) => {
    if (!startDate || !endDate) {
        return '';
    }

    return `${capitalizeString(formatShortMonthAndDate(startDate))} - ${capitalizeString(formatShortMonthAndDate(endDate))}`;
};

export const format2Decimals = (price: string) =>
    parseFloat(price)
        .toFixed(2)
        .replace(/[.,]00$/, '');

export function assertIsDefined<T>(x: T, message: string): asserts x is NonNullable<T> {
    if (isNil(x)) {
        throw new Error(message);
    }
}

export function invariant(condition: any, message: string): asserts condition {
    if (condition) {
        return;
    }

    throw new Error(message);
}

export function assertNever(x: never): never {
    throw new Error(`Shouldn't get to this point: ${x}`);
}

export function assertObjectKeys<T extends Record<string, unknown>, K extends keyof T>(
    obj: T,
    keys: K[],
    message: string,
): asserts obj is T & Required<Pick<T, K>> {
    const missingKeys = keys.filter((key) => !(key in obj));
    invariant(missingKeys.length === 0, `${message}: ${missingKeys.join(', ')}`);
}

export const sleep = (duration = 500) =>
    new Promise<void>((resolve) => {
        setTimeout(() => {
            resolve();
        }, duration);
    });

export function sortCollectionByArr<T, K extends keyof T>(collection: T[], arr: T[K][], key: K) {
    return sortBy(collection, (item) => {
        return arr.indexOf(item[key]);
    });
}

export function sendToSentry(exception: unknown, extras?: Record<string, unknown>): void {
    if (isDev) {
        logger.error({ exception, extras });
    }

    Sentry.withScope((scope) => {
        if (isNotNil(extras)) {
            scope.setExtras(extras);
        }
        Sentry.captureException(exception);
    });
}

export function useCombinedQueryStatus(statuses: string[]): QueryStatus | FetchStatus {
    if (statuses.some((status) => status === 'loading')) {
        return 'loading';
    } else if (statuses.some((status) => status === 'error')) {
        return 'error';
    } else if (statuses.every((status) => status === 'success')) {
        return 'success';
    } else {
        return 'idle';
    }
}
