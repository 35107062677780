/* eslint-disable max-len */
/* eslint-disable no-useless-escape */
export const emailValidation =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const displayNameValidation = /^[a-zA-Z äöüÄÖÜß]+$/;

export const phoneNumberValidation = /([0-9\s\-]{7,})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;

export const postalCodeValidation = /^[0-9]{5}$/;
