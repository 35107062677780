import { isBefore } from 'date-fns';
import { assertIsDefined } from '../../../helpers/commonHelpers';
import { OwnTransaction, TransactionProtectedData } from '../../../transactions/apiTypes';
import { TransitionName } from '../../../transactions/constants';
import { formatDayMonthAndDate } from '../../../helpers/dateAndTimeHelpers';

/**
 * The following explanation pertains to the completion logic for transactions with Wolt and Showroom delivery methods, faceToFace deliveries are not affected.
 *
 * From the user's perspective, a transaction is considered complete when they have fulfilled their part of the agreement. This is determined by the delivery method and the return date:
 *  1. For renters: The transaction is considered complete when the return date has passed, indicating that they have returned the item.
 *  2. For lenders: The transaction is considered complete when the day after the return date has passed, indicating that they have picked up the returned item.
 *
 * This affects how the transaction status is displayed to the user. However, it's important to note that the transaction's state in the backend is not yet marked as complete at this point.
 *
 * The backend only marks the transaction as complete after a few days have passed since the lender picked up the item. This delay allows for the cleaning of the clothes.
 *
 * This completion logic is separate from Sharetribe's built-in transaction completion logic, which considers a transaction complete when the bufferedBookingEnd date has passed.
 */
export const getDisplayLastTransition = (transaction: OwnTransaction['transaction'], type: 'order' | 'sale') => {
    const deliveryMethod = transaction.attributes.protectedData.deliveryMethod;
    const lastTransition = transaction.attributes.lastTransition;
    const renterReturnDateString = transaction.attributes.protectedData.renterReturnDate;
    const lenderReturnDateString = transaction.attributes.protectedData.lenderPickupDate;

    if (!renterReturnDateString || !lenderReturnDateString || (deliveryMethod !== 'wolt' && deliveryMethod !== 'showroom')) {
        return lastTransition;
    }

    // Other transitions returned as is
    if (lastTransition !== TransitionName.ACCEPT && lastTransition !== TransitionName.COMPLETE) {
        return lastTransition;
    }

    const compareDate = new Date();
    const renterReturnDate = new Date(renterReturnDateString);
    const lenderReturnDate = new Date(lenderReturnDateString);

    const rd = type === 'sale' ? lenderReturnDate : renterReturnDate;
    const shouldDisplayTransactionAsComplete = isBefore(rd, compareDate);

    return shouldDisplayTransactionAsComplete ? TransitionName.COMPLETE : TransitionName.ACCEPT;
};

export const formatOpeningHoursString = (
    protectedData: TransactionProtectedData,
    dateKey: keyof NonNullable<TransactionProtectedData['openingHours']>,
) => {
    const date = protectedData[dateKey];
    assertIsDefined(date, `date ${dateKey} is missing`);

    if (!protectedData.openingHours) {
        return protectedData[`${dateKey}OpeningHoursString`] || '';
    }

    return `${formatDayMonthAndDate(date)}, ${protectedData.openingHours[dateKey]}`;
};
