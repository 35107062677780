import { ArrowForwardIos } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormRenderer } from '../../components/FormRenderer/FormRenderer';
import { StyledSvgIcon } from '../../components/Styled/Styled.components';
import SwipeableFormDrawer from '../../components/SwipeableFormDrawer/SwipeableFormDrawer';
import { useDrawer } from '../../context/drawer';
import { Booking, BookingDraft } from '../../store/bookingReducer';
import { DeliveryOption } from '../../types/delivery';
import { Timeslot } from '../ViewListing/ListingFooter.helpers';
import { getCheckoutSectionsForDeliveryMethod } from './Checkout.sections';
import { CheckoutSectionRenderer } from './CheckoutSectionRenderer';
import { getDeliveryMethodFormFields, renderDeliveryMethodValue } from './LogisticsSection.helpers';

interface LogisticsSectionProps {
    listingId: string;
    booking: Booking | BookingDraft;
    timeslots: Timeslot[] | undefined;
    listingDeliveryOptions: string[];
    deliveryOptions: DeliveryOption[];
}

export const LogisticsSection: React.FC<LogisticsSectionProps> = ({ booking, timeslots, listingDeliveryOptions, deliveryOptions }) => {
    const { watch } = useFormContext();

    const deliveryMethod = watch('deliveryMethod');
    const returnMethod = watch('returnMethod');

    const { t } = useTranslation();

    const { openDrawersState, openDrawer, closeDrawers } = useDrawer();

    const handleClickDrawerButton = useCallback(
        (name: string) => {
            if (openDrawersState[name]) {
                closeDrawers(name);
            } else {
                openDrawer(name, true);
            }
        },
        [openDrawersState, openDrawer, closeDrawers],
    );

    const handleCloseDrawer = useCallback(
        (name: string) => {
            closeDrawers(name);
        },
        [closeDrawers],
    );

    const bookingDetails = {
        booking,
        deliveryMethod,
        listingDeliveryOptions,
        returnMethod,
        timeslots,
    };

    const deliveryMethodFormFields = useMemo(
        () => getDeliveryMethodFormFields(t, 'deliveryMethod', deliveryMethod, listingDeliveryOptions, deliveryOptions),
        [t, deliveryMethod, listingDeliveryOptions, deliveryOptions],
    );

    const checkoutSections = useMemo(() => {
        return getCheckoutSectionsForDeliveryMethod(t, deliveryMethod);
    }, [t, deliveryMethod]);

    return (
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '20px', py: 1 }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                {t('delivery')}
            </Typography>

            <SwipeableFormDrawer
                id="deliveryMethod"
                label={t('selectDeliveryMethod')}
                buttonLabel={t('deliveryMethod')}
                open={openDrawersState.deliveryMethod || false}
                onClick={() => handleClickDrawerButton('deliveryMethod')}
                onClose={() => handleCloseDrawer('deliveryMethod')}
                renderValue={renderDeliveryMethodValue()}
                sx={{ paddingBottom: 'env(safe-area-inset-bottom)' }}
                buttonSuffix={<ArrowForwardIos fontSize="small" sx={{ opacity: 0.7 }} />}
                buttonPrefix={
                    <StyledSvgIcon>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                            <path d="M450.001-177.23v-285.54L200-607.539v278.615q0 3.078 1.539 5.77 1.538 2.693 4.615 4.616L450.001-177.23Zm59.998 0 243.847-141.308q3.077-1.923 4.615-4.616 1.539-2.692 1.539-5.77v-278.615L509.999-462.77v285.54Zm-66.153 65.459L176.155-265.847q-17.077-9.846-26.615-26.308-9.539-16.461-9.539-36.153v-303.384q0-19.692 9.539-36.153 9.538-16.462 26.615-26.308l267.691-154.076q17.077-9.846 36.154-9.846t36.154 9.846l267.691 154.076q17.077 9.846 26.615 26.308 9.539 16.461 9.539 36.153v303.384q0 19.692-9.539 36.153-9.538 16.462-26.615 26.308L516.154-111.771q-17.077 9.846-36.154 9.846t-36.154-9.846ZM634.23-603.999l92.771-53.231-240.847-139.308q-3.077-1.923-6.154-1.923t-6.154 1.923l-86.847 50L634.23-603.999ZM480-514.461l93.001-53.846L326-711.077l-93.001 53.847L480-514.461Z" />
                        </svg>
                    </StyledSvgIcon>
                }
            >
                <FormRenderer formFields={deliveryMethodFormFields} />
            </SwipeableFormDrawer>

            {checkoutSections && (
                <CheckoutSectionRenderer checkoutSections={checkoutSections} bookingDetails={bookingDetails} deliveryOptions={deliveryOptions} />
            )}
        </Box>
    );
};
