import React, { useState } from 'react';
import { StepBackButton, StepContent, StepContentContainer } from '../UserOnboarding.components';
import { Button, ButtonGroup, TextField, Typography } from '@mui/material';
import { useUpdateProfile } from '../../../views/UserProfile/hooks/useUpdateProfile';
import { AnimatePresence, motion } from 'framer-motion';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { StaggerList, StaggerListElement } from '../../../animations/components/Stagger';
import ProfileShowCase from '../../../assets/images/onboarding/ProfileShowcase.png';
import { LoadingButton } from '@mui/lab';
import { User } from '../../../types/apiTypes';
import { BaseStepProps } from '../UserOnboarding.types';
import { ArrowBack } from '@mui/icons-material';

interface FormData {
    bio: string;
}

interface AddBioStepProps extends BaseStepProps {
    loading: boolean;
    user: User;
}

export const AddBioStep: React.FC<AddBioStepProps> = ({ onComplete, loading, user, onGoBack, hasPreviousStep }) => {
    const [animationComplete, setAnimationComplete] = useState(false);
    const [editing, setEditing] = useState(false);
    const { mutate: updateProfile, isLoading } = useUpdateProfile(onComplete, false);
    const { t } = useTranslation();

    const initialAnimationState = animationComplete ? { opacity: 1, y: 0 } : { opacity: 0, x: -70 };

    const {
        formState: { errors },
        handleSubmit,
        register,
    } = useForm<FormData>({ defaultValues: { bio: user.profile.bio } });

    const submitHandler = (data: FormData) => {
        updateProfile(data);
    };

    return (
        <StepContent>
            <AnimatePresence>
                {editing ? (
                    <>
                        <form
                            onSubmit={handleSubmit(submitHandler)}
                            style={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                            }}
                        >
                            <div
                                style={{
                                    height: '250px',
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <motion.img
                                    layoutId="profile-showcase"
                                    layout="preserve-aspect"
                                    src={ProfileShowCase}
                                    style={{
                                        height: '150px',
                                        width: '200px',
                                        borderRadius: '20px',
                                        objectFit: 'contain',
                                        boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
                                    }}
                                />
                            </div>
                            <StaggerList
                                custom={{ stagger: 0.3 }}
                                style={{
                                    flexGrow: 1,
                                    width: '100%',
                                    textAlign: 'center',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    gap: '20px',
                                }}
                            >
                                <StaggerListElement>
                                    <Typography variant="h6" fontWeight="bold">
                                        {t('bioStepTitle')}
                                    </Typography>
                                </StaggerListElement>

                                <StaggerListElement>
                                    <TextField
                                        error={!!errors['bio']}
                                        helperText={
                                            errors['bio']
                                                ? errors.bio.type === 'maxLength'
                                                    ? t('maxLengthError', { length: 5000 })
                                                    : t('required')
                                                : ''
                                        }
                                        fullWidth
                                        autoComplete="off"
                                        minRows={4}
                                        maxRows={10}
                                        variant="outlined"
                                        placeholder="Fashion-lover from Helsinki. 165 cm, size L, and devoted fan of Dior & Marimekko."
                                        multiline
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderRadius: '20px',
                                                },
                                            },
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                        {...register('bio', { required: true, maxLength: 5000 })}
                                    />
                                </StaggerListElement>
                            </StaggerList>

                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <div>
                                    <LoadingButton type="submit" variant="contained" loading={isLoading || loading} sx={{ width: 'auto' }}>
                                        {t('confirm')}
                                    </LoadingButton>
                                </div>

                                <div>
                                    <Button variant="text" onClick={() => setEditing(false)} sx={{ width: 'auto' }}>
                                        {t('cancel')}
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </>
                ) : (
                    <>
                        <StepContentContainer>
                            <StaggerList
                                custom={{ stagger: 0.3, delay: 0.3 }}
                                style={{
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-evenly',
                                    overflowY: 'auto',
                                }}
                            >
                                <motion.li
                                    onAnimationStart={() => setAnimationComplete(true)}
                                    initial={initialAnimationState}
                                    animate={{ opacity: 1, x: 0 }}
                                    transition={{ delay: 0.8, type: 'spring', damping: 9, stiffness: 30 }}
                                    style={{
                                        height: '250px',
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginBottom: '40px',
                                        padding: '10px',
                                    }}
                                >
                                    <motion.img
                                        layoutId="profile-showcase"
                                        layout="preserve-aspect"
                                        src={ProfileShowCase}
                                        style={{
                                            height: '225px',
                                            width: '300px',
                                            borderRadius: '20px',
                                            objectFit: 'contain',
                                            boxShadow: '0 3px 6px rgb(0 0 0 / 10%)',
                                        }}
                                    />
                                </motion.li>

                                <StaggerListElement transition={{ delay: 0.3 }}>
                                    <Typography variant="h6" fontWeight="bold">
                                        {t('bioStepTitle')}
                                    </Typography>
                                </StaggerListElement>

                                <StaggerListElement transition={{ delay: 0.6 }}>
                                    <Typography variant="body2">{t('bioStepDescription')}</Typography>
                                </StaggerListElement>
                            </StaggerList>
                        </StepContentContainer>

                        <Button variant="contained" onClick={() => setEditing(true)}>
                            {t('addBio')}
                        </Button>
                        <LoadingButton variant="text" onClick={onComplete} loading={loading}>
                            {t('later')}
                        </LoadingButton>
                    </>
                )}
            </AnimatePresence>

            {hasPreviousStep && (
                <StepBackButton onClick={onGoBack}>
                    <ArrowBack />
                </StepBackButton>
            )}
        </StepContent>
    );
};
