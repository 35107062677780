import React from 'react';
import { SettingsContainer } from '../UserProfile.components';
import { StripeConnectPage } from '../Stripe/StripeConnectPage';

export const PaymentsAndPayouts: React.FC = () => {
    return (
        <SettingsContainer>
            <StripeConnectPage />
        </SettingsContainer>
    );
};
