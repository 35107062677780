import axios, { AxiosError } from 'axios';
import { MutationCache, QueryClient, QueryCache } from '@tanstack/react-query';
import { sendToSentry } from './helpers/commonHelpers';
import { User } from './types/apiTypes';
import { getUserFn } from './user/hooks/useUser';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            /**
             * By default, retry queries 3 times before giving up.
             * 404's are not retried as they were, by definition, not found.
             */
            retry: (count, e) => {
                const error = e as AxiosError;
                if ('response' in error) {
                    const isNotFoundError = error.response?.status === 404 || error.response?.data.status === 404;
                    if (isNotFoundError) {
                        return false;
                    }
                }

                return count < 3;
            },
        },
    },
    mutationCache: new MutationCache({
        onError: (err, _variables, _context, mutation) => {
            let payload: Record<string, any> = {
                type: 'mutation',
                mutationId: mutation.mutationId,
                mutationKey: mutation.options.mutationKey,
                variables:
                    mutation.state.variables && typeof mutation.state.variables === 'object'
                        ? JSON.stringify(mutation.state.variables)
                        : mutation.state.variables,
            };

            if (axios.isAxiosError(err) && err.response?.data && err.response?.data.errors) {
                payload.errors = JSON.stringify(err.response.data.errors);
            }

            sendToSentry(err, payload);
        },
    }),
    queryCache: new QueryCache({
        onError: (err, query) => {
            sendToSentry(err, { type: 'query', queryHash: query.queryHash, queryKey: query.queryKey });
        },
    }),
});

export const getQueryClientInstance = () => queryClient;

export const getCurrentUser = () => queryClient.getQueryData<User>(['current-user']);

export const getOrFetchCurrentUser = async (token: string) => {
    const cachedUser = getCurrentUser();
    if (cachedUser) {
        return cachedUser;
    }

    const queryKey = ['current-user'];

    try {
        const user = await queryClient.fetchQuery(queryKey, async () => getUserFn(token));
        return user;
    } catch (error) {
        console.error('Error fetching current user', error);
        return null;
    }
};
