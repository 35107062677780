import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { OwnTransaction, BookingState } from '../../../transactions/apiTypes';
import { ReactFCWithChildren } from '../../../types/types';
import { Chat } from '@mui/icons-material';
import { Card, CardContent, Typography, CardActions, Button, useTheme, Divider, Collapse } from '@mui/material';
import { Box } from '@mui/system';
import { isBefore, addDays, isAfter } from 'date-fns';
import { noop } from 'lodash';
import { useTranslation } from 'react-i18next';
import { UnstyledLink } from '../../..';
import { BasicMenu } from '../../../components/BasicMenu/BasicMenu';
import { ChatWithSupportButton } from '../../../components/ChatWithUserButton/ChatWithSupportButton';
import { FormInputWithLabel } from '../../../components/FormInputWithLabel/FormInputWithLabel';
import { useChatWindow } from '../../../context/chat';
import { useConfirm } from '../../../context/confirm';
import { assertNever, formatDateRange, formatPrice, useCombinedQueryStatus } from '../../../helpers/commonHelpers';
import { useSafeNavigate } from '../../../hooks/useSafeNavigate';
import { TransitionName } from '../../../transactions/constants';
import { useTransitionTransaction } from '../../../transactions/hooks/useTransitionTransaction';
import { DeliveryInformationSection } from './DeliveryDetails';
import { getDisplayLastTransition } from './helpers';
import { LastTransitionBadge } from './LastTransitionBadge';
import { NextTransactionAction } from './NextTransactionAction';
import { CardTitle, CardSummary, CardSummaryDetails } from './TransactionCard.components';
import { UsernameLabel } from './UsernameLabel';
import { Image } from '@mantine/core';
import { DeliveryOption } from '../../../types/delivery';
import { BreakdownAccordion } from '../../../components/BreakdownAccordion/BreakdownAccordion';
import { calculateTotalFromLineItems } from '../../../helpers/currency';
import { MHDeliveryTrackingDrawer, MHDeliveryTrackingDrawerState } from '../Matkahuolto/MHDeliveryTrackingDrawer';
import { useParcelTracking } from '../../../queries/useParcelTracking';
import { DeveloperOptions, Overrides } from './DeveloperOptions';
import { isPendingDropoff } from '../../../matkahuolto/helpers';
import { convertToMidnightUTC } from '../../../helpers/dateAndTimeHelpers';
import { DeliveryLateAlert } from './DeliveryLateAlert';
import { isTransactionCancelled } from '../../../transactions/transactionHelpers';

const allowedTransitionsForChat = [TransitionName.ACCEPT, TransitionName.CONFIRM_PAYMENT];
const allowedTransitionsForCancellation = [TransitionName.ACCEPT, TransitionName.CONFIRM_PAYMENT];
const chatWithSupportDeliveryMethods = ['wolt', 'showroom', 'uber', 'matkahuolto'];
const deliveryTrackingDeliveryMethods = ['matkahuolto'];
const actionableStates: BookingState[] = ['pending', 'proposed'];

type Component =
    | 'card-menu'
    | 'chat-with-support-button'
    | 'chat-button'
    | 'lender-actions'
    | 'delivery-information'
    | 'delivery-tracking'
    | 'delivery-late-alert'
    | 'breakdown-accordion';

interface TransactionCardProps {
    transactionData: OwnTransaction;
    deliveryOptions: DeliveryOption[];
    onAction: (action: 'decline' | 'confirm', transaction: OwnTransaction) => void;
    transactionType: 'sale' | 'order';
    isDeveloper: boolean;
}

export const TransactionCard: ReactFCWithChildren<TransactionCardProps> = ({
    transactionData,
    transactionType,
    onAction,
    deliveryOptions,
    isDeveloper,
}) => {
    const [developerOptionsDrawerOpen, setDeveloperOptionsDrawerOpen] = useState(false);
    const [drawerState, setDrawerState] = useState<MHDeliveryTrackingDrawerState>(null);
    const [overrides, setOverrides] = useState<Partial<Overrides> | null>(null);

    const resolvedTransactionData = overrides?.transactionData || transactionData;
    const resolvedTransactionType = overrides?.transactionType || transactionType;

    const bookingState = resolvedTransactionData.booking.attributes.state;
    const transactionId = resolvedTransactionData.transaction.id;
    const deliveryMethod = resolvedTransactionData.transaction.attributes.protectedData.deliveryMethod;
    const lastTransition = resolvedTransactionData.transaction.attributes.lastTransition;

    const deliveryTrackingEnabled = deliveryTrackingDeliveryMethods.includes(deliveryMethod) && !isTransactionCancelled(lastTransition);

    const { data: deliveryTracking, status: deliveryTrackingStatus } = useParcelTracking({
        transactionId,
        enabled: deliveryTrackingEnabled,
        deliveryType: 'delivery',
    });

    const { data: returnDeliveryTracking, status: returnDeliveryTrackingStatus } = useParcelTracking({
        transactionId,
        enabled: deliveryTrackingEnabled,
        deliveryType: 'return',
    });

    const resolvedDeliveryTracking = overrides?.deliveryTracking || deliveryTracking;
    const resolvedReturnDeliveryTracking = overrides?.returnDeliveryTracking || returnDeliveryTracking;

    const handleSetOverride = (override: Partial<Overrides>) => {
        setOverrides({ ...overrides, ...override });
    };

    const combinedDeliveryTrackingStatus = useCombinedQueryStatus([deliveryTrackingStatus, returnDeliveryTrackingStatus]);

    const { provider, customer, listing, transaction, booking } = resolvedTransactionData;
    const { t } = useTranslation();
    const { openChat, closeChat } = useChatWindow();

    const theme = useTheme();
    const navigate = useSafeNavigate();

    const receiverText = t(`transaction-card-counterparty-${resolvedTransactionType}`);
    const chatText = t(`transaction-card-chat-text-${resolvedTransactionType}`);
    const participant = resolvedTransactionType === 'sale' ? customer : provider;

    const handleClickUser = useCallback((userId: string) => {
        closeChat();
        navigate(`/user/${userId}`);
    }, []);

    const handleOpenChat = useCallback(() => {
        const chatProps = {
            participant,
            onClickUser: handleClickUser,
        };

        openChat(chatProps);
    }, []);

    const { confirm, close, updateOptions } = useConfirm();
    const { mutate: transitionTransaction, isLoading } = useTransitionTransaction(close);

    const nextActionTranslationParams = {
        type: resolvedTransactionType,
        bookingStart: new Date(booking.attributes.displayStart),
        bookingEnd: new Date(booking.attributes.displayEnd),
        transaction: transaction.attributes,
        isPresentInShowroom: listing.presentInShowroom,
        deliveryOptions,
        deliveryTracking: resolvedDeliveryTracking,
        returnDeliveryTracking: resolvedReturnDeliveryTracking,
        setDrawerState,
    };

    const isInsideAllowedTimePeriodForCancellation = isBefore(new Date(), addDays(new Date(booking.attributes.displayStart), -3));
    const allowCancel = allowedTransitionsForCancellation.includes(lastTransition) && isInsideAllowedTimePeriodForCancellation;
    const showNextTransactionAction = deliveryMethod !== 'matkahuolto' || combinedDeliveryTrackingStatus === 'success';

    const menuItems = [
        {
            value: 'view-details',
            label: t('viewRentalDetails'),
        },
        {
            value: 'cancel',
            label: lastTransition === TransitionName.ACCEPT ? t('cancelRental') : t('cancelRentalRequest'),
            disabled: !allowCancel,
        },
    ];

    if (isDeveloper) {
        menuItems.push({
            value: 'developer-options',
            label: 'Developer options',
        });
    }

    const isTransactionAccepted = lastTransition === TransitionName.ACCEPT;

    const handleCancelRequest = () => {
        const cancelType = isTransactionAccepted ? 'rental' : 'request';
        const message = t(`transaction-dialog-message-cancel-${cancelType}`);
        const title = t(`transaction-dialog-title-cancel-${cancelType}`);

        const getCancelTransition = () => {
            if (!isTransactionAccepted) {
                return TransitionName.CUSTOMER_CANCEL;
            }

            if (resolvedTransactionType === 'sale') {
                return TransitionName.PROVIDER_CANCEL_AFTER_ACCEPT;
            }
            if (resolvedTransactionType === 'order') {
                return TransitionName.CUSTOMER_CANCEL_AFTER_ACCEPT;
            }

            assertNever(resolvedTransactionType);
        };

        const onConfirm = () => {
            const payload = {
                transactionId: transaction.id,
                transition: getCancelTransition(),
                params: {},
            };
            transitionTransaction(payload);
        };

        confirm({ title, message, loading: isLoading, onConfirm }).then(noop).catch(noop);
    };

    const handleMenuItemClick = (i: number) => {
        if (i === 0) {
            navigate(`/transaction/${transaction.id}`);
        }
        if (i === 1) {
            handleCancelRequest();
        }
        if (i === 2) {
            setDeveloperOptionsDrawerOpen(true);
        }
    };

    useEffect(() => {
        updateOptions({ loading: isLoading });
    }, [isLoading]);

    // Show delivery alert if user has not dropped off the parcel
    const isDeliveryLate = useMemo(() => {
        const resolvedTransactionType = nextActionTranslationParams.type;
        const transactionAttributes = nextActionTranslationParams.transaction;
        const tracking = resolvedTransactionType === 'sale' ? resolvedDeliveryTracking : resolvedReturnDeliveryTracking;

        if (!tracking) {
            return false;
        }

        const pendingDropoff = isPendingDropoff(tracking);

        if (!pendingDropoff) {
            return false;
        }

        const dropoff =
            resolvedTransactionType === 'sale'
                ? transactionAttributes.protectedData.lenderDropoffDate
                : transactionAttributes.protectedData.renterReturnDate;
        const dropoffDate = convertToMidnightUTC(new Date(dropoff as string));
        const now = convertToMidnightUTC(new Date());
        const isLate = isAfter(now, dropoffDate);

        return isLate;
    }, [nextActionTranslationParams]);

    const periodText = `${formatDateRange(new Date(booking.attributes.displayStart), new Date(booking.attributes.displayEnd))}`;

    // May differ from the actual last transition, see comment in getDisplayLastTransition
    const displayLastTransition = getDisplayLastTransition(transaction, resolvedTransactionType);

    const components: Component[] = useMemo(() => {
        const components: Component[] = [];

        const transactionAttributes = nextActionTranslationParams.transaction;
        const lastTransition = transactionAttributes.lastTransition;
        const deliveryMethod = transactionAttributes.protectedData.deliveryMethod;
        const resolvedTransactionType = nextActionTranslationParams.type;

        const pendingConfirm =
            resolvedTransactionType === 'sale' &&
            actionableStates.includes(bookingState) &&
            !resolvedTransactionData.customer.banned &&
            !resolvedTransactionData.customer.deleted;

        if (!isTransactionCancelled(lastTransition)) {
            if (deliveryTrackingDeliveryMethods.includes(deliveryMethod) && deliveryTrackingEnabled) {
                components.push('delivery-tracking');

                if (isDeliveryLate) {
                    components.push('delivery-late-alert');
                }
            } else {
                components.push('delivery-information');
            }
        }

        if (!pendingConfirm && allowedTransitionsForChat.includes(displayLastTransition)) {
            components.push(chatWithSupportDeliveryMethods.includes(deliveryMethod) ? 'chat-with-support-button' : 'chat-button');
        }

        if (pendingConfirm) {
            components.push('breakdown-accordion');
            components.push('lender-actions');
        } else {
            components.push('card-menu');
        }

        return components;
    }, [
        resolvedTransactionData,
        deliveryTrackingEnabled,
        resolvedTransactionType,
        resolvedDeliveryTracking,
        resolvedReturnDeliveryTracking,
        isDeliveryLate,
    ]);

    return (
        <Card raised>
            <CardContent sx={{ paddingBottom: '16px !important' }}>
                <CardTitle>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <UnstyledLink href={`/listing/${listing.id}`}>
                            <Typography
                                variant="overline"
                                sx={{ maxWidth: '90%', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', fontSize: '0.85rem' }}
                            >
                                {listing.title || t('deleted')}
                            </Typography>
                        </UnstyledLink>

                        {components.includes('card-menu') && (
                            <BasicMenu menuItems={menuItems} menuButtonSxProps={{ p: 0 }} onClickItem={handleMenuItemClick} />
                        )}
                    </Box>
                    <LastTransitionBadge transition={displayLastTransition} chipProps={{ size: 'small' }} />
                </CardTitle>

                <CardSummary>
                    <UnstyledLink href={`/listing/${listing.id}`}>
                        <Image
                            src={listing.imageUrl}
                            alt={listing.title}
                            withPlaceholder
                            height={110}
                            width={110}
                            styles={{ placeholder: { backgroundColor: theme.palette.background.paper } }}
                            sx={{
                                objectFit: 'cover',
                                borderRadius: '5px',
                                boxShadow: theme.palette.boxShadow.medium,
                            }}
                        />
                    </UnstyledLink>

                    <CardSummaryDetails>
                        <FormInputWithLabel
                            label={receiverText}
                            value={<UsernameLabel user={resolvedTransactionType === 'sale' ? customer : provider} />}
                        />
                        <FormInputWithLabel label={t('period')} value={<span style={{ fontWeight: 'bold' }}>{periodText}</span>} />
                    </CardSummaryDetails>
                </CardSummary>

                {isDeveloper && (
                    <DeveloperOptions
                        open={developerOptionsDrawerOpen}
                        onOverride={handleSetOverride}
                        onClose={() => setDeveloperOptionsDrawerOpen(false)}
                        onReset={() => setOverrides(null)}
                        transactionData={resolvedTransactionData}
                        transactionType={resolvedTransactionType}
                        deliveryTracking={resolvedDeliveryTracking}
                        returnDeliveryTracking={resolvedReturnDeliveryTracking}
                    />
                )}

                {components.includes('delivery-information') && (
                    <Box sx={{ mt: 2 }}>
                        <DeliveryInformationSection
                            deliveryOptions={deliveryOptions}
                            transactionProtectedData={transaction.attributes.protectedData}
                            transactionType={resolvedTransactionType}
                        />
                    </Box>
                )}

                {components.includes('delivery-tracking') && (
                    <Box sx={{ mt: 2 }}>
                        <MHDeliveryTrackingDrawer
                            drawerState={drawerState}
                            onSetDrawerState={setDrawerState}
                            translationParams={nextActionTranslationParams}
                            status={combinedDeliveryTrackingStatus}
                            transactionData={transactionData}
                        />

                        <Divider sx={{ mt: 2 }} />
                    </Box>
                )}

                {components.includes('breakdown-accordion') && (
                    <BreakdownAccordion
                        lineItems={transaction.attributes.lineItems}
                        containerSX={{ mt: 2 }}
                        summary={
                            <span
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    gap: '10px',
                                    alignItems: 'center',
                                    padding: '10px 0',
                                }}
                            >
                                {t('youEarn')}:
                                <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                    {formatPrice(calculateTotalFromLineItems(transaction.attributes.lineItems).amount)}
                                </span>
                            </span>
                        }
                    />
                )}

                {components.includes('delivery-late-alert') && (
                    <DeliveryLateAlert
                        transactionType={nextActionTranslationParams.type}
                        isPresentInShowroom={nextActionTranslationParams.isPresentInShowroom}
                    />
                )}

                <Collapse in={showNextTransactionAction} sx={{ mt: 2 }}>
                    <NextTransactionAction lastTransition={displayLastTransition} translationParams={nextActionTranslationParams} />
                </Collapse>

                {(components.includes('chat-with-support-button') || components.includes('chat-button')) && (
                    <CardActions sx={{ mt: 2, py: 1, px: 0, width: '100%' }}>
                        {components.includes('chat-with-support-button') && <ChatWithSupportButton listingId={listing.id} />}
                        {components.includes('chat-button') && (
                            <Button variant="contained" fullWidth startIcon={<Chat />} onClick={handleOpenChat}>
                                {chatText}
                            </Button>
                        )}
                    </CardActions>
                )}

                {components.includes('lender-actions') && (
                    <CardActions sx={{ justifyContent: 'space-between' }}>
                        <Button size="small" onClick={() => onAction('decline', transactionData)}>
                            {t('decline')}
                        </Button>
                        <Button size="small" onClick={() => onAction('confirm', transactionData)} variant="contained">
                            {t('confirm')}
                        </Button>
                    </CardActions>
                )}
            </CardContent>
        </Card>
    );
};
