import { SxProps, useTheme } from '@mui/material';
import React, { MutableRefObject, ReactNode, useRef } from 'react';
import { useBreakpoint } from '../../hooks/useBreakpoint';
import { PullToRefresh } from '../PullToRefresh/PullToRefresh';
import { ScrollRestore } from '../ScrollRestore/ScrollRestore';
import { RouteConfiguration } from '../../routes/types';
import { ResponsiveContainer } from './ResponsiveContainer';
import { Capacitor } from '@capacitor/core';

export const resolveConfigValue = (val: boolean | { mobile: boolean; desktop: boolean } | undefined, isDesktop: boolean) => {
    if (!val) {
        return null;
    }

    if (typeof val === 'boolean') {
        return val;
    }

    return isDesktop ? val.desktop : val.mobile;
};

type ResponsivePageProps = {
    config: RouteConfiguration;
    sx?: React.CSSProperties;
    onRefresh?: () => Promise<void>;
    children: ReactNode;
};

const DEFAULT_PAGE_WIDTH = 700;

export const ResponsivePage = ({ config, children, sx, onRefresh }: ResponsivePageProps) => {
    const { maxWidth, enablePullToRefresh, disableRootOverflow, ...rest } = config;
    const ref = useRef<HTMLDivElement>(null);
    const theme = useTheme();
    const isDesktop = useBreakpoint('sm');

    if (enablePullToRefresh && Capacitor.isNativePlatform()) {
        return (
            <ResponsiveContainer routeConfig={rest}>
                <PullToRefresh onRefresh={onRefresh}>{children}</PullToRefresh>
            </ResponsiveContainer>
        );
    }

    const desktopStyles: SxProps = {
        maxWidth: maxWidth ? maxWidth : DEFAULT_PAGE_WIDTH,
        background: theme.palette.background.paper,
    };

    const styles: SxProps = {
        ...(!disableRootOverflow && { overflowY: 'auto' }),
        ...(isDesktop && desktopStyles),
    };

    return (
        <>
            <ScrollRestore containerRef={ref as MutableRefObject<HTMLDivElement>} />
            <ResponsiveContainer ref={ref} routeConfig={rest} sx={{ ...styles, ...sx }}>
                {children}
            </ResponsiveContainer>
        </>
    );
};
