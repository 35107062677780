import { last } from 'lodash';
import { ParcelTracking } from '../parcel-tracking/types';
import { MHStatusCode, MHTrackingEvent } from './types';

export const getLatestMHStatusCodeFromEvents = (events: MHTrackingEvent[]) => {
    const latestEvent = last(events) as MHTrackingEvent;
    const statusCode = latestEvent.eventCode;

    return statusCode;
};

export const isLatestMHEventEqualOrGreaterThan = (events: MHTrackingEvent[], code: MHStatusCode): boolean => {
    if (!events || events.length === 0) {
        return false;
    }

    const latestEvent = getLatestMHStatusCodeFromEvents(events);

    return parseInt(latestEvent) >= parseInt(code);
};

export const isLatestMHEventEqual = (events: MHTrackingEvent[], code: MHStatusCode): boolean => {
    if (!events || events.length === 0) {
        return false;
    }

    const latestEvent = getLatestMHStatusCodeFromEvents(events);

    return parseInt(latestEvent) === parseInt(code);
};

export const isLatestMHEventBetween = (events: MHTrackingEvent[], { start, end }: { start: MHStatusCode; end: MHStatusCode }): boolean => {
    if (!events || events.length === 0) {
        return false;
    }

    const latestEvent = getLatestMHStatusCodeFromEvents(events);

    return parseInt(latestEvent) >= parseInt(start) && parseInt(latestEvent) <= parseInt(end);
};

export const isCancelled = (trackingData: ParcelTracking) => trackingData?.status === 'cancelled';
export const isPendingActivation = (trackingData: ParcelTracking) => trackingData?.status === 'pending-activation';
export const isPendingDropoff = (trackingData: ParcelTracking) =>
    trackingData?.status === 'active' && isLatestMHEventEqual(trackingData?.data?.events || [], '02');
export const isInTransit = (trackingData: ParcelTracking) =>
    trackingData?.status === 'active' && isLatestMHEventBetween(trackingData?.data?.events || [], { start: '02', end: '57' });
export const isDelivered = (trackingData: ParcelTracking) =>
    trackingData?.status === 'active' && isLatestMHEventEqualOrGreaterThan(trackingData?.data?.events || [], '60');

export const getMatkahuoltoTransitStatus = (trackingData: ParcelTracking | undefined) => {
    if (!trackingData) {
        return null;
    }

    if (isCancelled(trackingData)) {
        return 'cancelled';
    }

    if (isPendingActivation(trackingData)) {
        return 'pending-activation';
    }

    if (isPendingDropoff(trackingData)) {
        return 'pending-dropoff';
    }

    if (isInTransit(trackingData)) {
        return 'in-transit';
    }

    if (isDelivered(trackingData)) {
        return 'delivered';
    }

    return null;
};

export type MHDeliveryStatus = ReturnType<typeof getMatkahuoltoTransitStatus>;
