import { useState } from 'react';
import { SwipeableDrawerButton } from '../../../components/SwipeableDrawer/SwipeableDrawerButton';
import { useCurrentUser } from '../../../user/hooks/useUser';
import { TranslationParams } from './NextTransactionAction';
import { SwipeableDrawer } from '../../../components/SwipeableDrawer/SwipeableDrawer';
import { Box, Button, FormControl, FormControlLabel, FormHelperText, FormLabel, Switch, ToggleButton, Typography } from '@mui/material';
import { SimpleSelect } from '../../../components/SimpleSelect/SimpleSelect';
import { cloneDeep, defaultsDeep } from 'lodash';
import { MHStatusCode } from '../../../matkahuolto/types';
import { MatkaHuoltoStatusCodes } from '../../../matkahuolto/constants';
import { getLatestMHStatusCodeFromEvents } from '../../../matkahuolto/helpers';
import { DatePicker } from '@mui/x-date-pickers';
import { TransitionName } from '../../../transactions/constants';
import { OwnTransaction } from '../../../transactions/apiTypes';
import { ParcelTracking } from '../../../parcel-tracking/types';

export interface Overrides {
    transactionData: OwnTransaction;
    deliveryTracking: ParcelTracking | undefined;
    returnDeliveryTracking: ParcelTracking | undefined;
    transactionType: 'order' | 'sale';
}

type DeveloperOptionsProps = Overrides & {
    open: boolean;
    onOverride: (override: Partial<Overrides>) => void;
    onClose: () => void;
    onReset: () => void;
};

const getMockMHEventsByCode = (code: MHStatusCode) => {
    return [
        {
            eventTime: '2022-01-01T12:00:00.000Z',
            eventCode: code,
            officeCode: 'mhn',
            shipmentNumber: '123456789',
            eventPlace: 'xxx',
        },
    ];
};

export const DeveloperOptions: React.FC<DeveloperOptionsProps> = ({
    open,
    transactionType,
    transactionData,
    deliveryTracking,
    returnDeliveryTracking,
    onOverride,
    onClose,
    onReset,
}) => {
    const protectedData = transactionData.transaction.attributes.protectedData;
    const deliveryMethod = protectedData.deliveryMethod;
    const lastTransition = transactionData.transaction.attributes.lastTransition;
    const presentInShowroom = transactionData.listing.presentInShowroom;

    const onChangeType = (type: TranslationParams['type']) => {
        const newType = type === 'order' ? 'sale' : ('order' as const);

        onOverride({
            transactionType: newType,
        });
    };

    const onChangePresentInShowroom = () => {
        const newTransactionData = defaultsDeep(
            {
                listing: {
                    presentInShowroom: !presentInShowroom,
                },
            },
            transactionData,
        );

        onOverride({ transactionData: newTransactionData });
    };

    const onInitDeliveryTracking = (type: 'delivery' | 'return') => {
        const initialDeliveryTracking = type === 'delivery' ? deliveryTracking : returnDeliveryTracking;

        const newDeliveryTracking = defaultsDeep(
            {
                status: 'active',
                provider: 'matkahuolto',
                data: {
                    activationCode: '123456',
                    shipmentNumber: 'MH309179477FI',
                    trackingUrl: 'https://example.com',
                    events: getMockMHEventsByCode('02'),
                },
            },
            initialDeliveryTracking,
        );

        if (type === 'delivery') {
            onOverride({ deliveryTracking: newDeliveryTracking });
        } else {
            onOverride({ returnDeliveryTracking: newDeliveryTracking });
        }
    };

    const toggleDeliveryTrackingActive = (type: 'delivery' | 'return') => {
        const initialDeliveryTracking = type === 'delivery' ? deliveryTracking : returnDeliveryTracking;
        const newDeliveryTracking = defaultsDeep(
            {
                status: initialDeliveryTracking?.status === 'active' ? 'pending-activation' : 'active',
            },
            initialDeliveryTracking,
        );

        if (type === 'delivery') {
            onOverride({ deliveryTracking: newDeliveryTracking });
        } else {
            onOverride({ returnDeliveryTracking: newDeliveryTracking });
        }
    };

    const onSetDeliveryTrackingLastEvent = (type: 'delivery' | 'return', eventCode: MHStatusCode) => {
        const resolvedDeliveryTracking = cloneDeep(type === 'delivery' ? deliveryTracking : returnDeliveryTracking);

        if (!deliveryTracking?.data) {
            return;
        }

        const newDeliveryTracking = defaultsDeep(
            {
                data: {
                    events: getMockMHEventsByCode(eventCode),
                },
            },
            resolvedDeliveryTracking,
        );

        if (type === 'delivery') {
            onOverride({ deliveryTracking: newDeliveryTracking });
        } else {
            onOverride({ returnDeliveryTracking: newDeliveryTracking });
        }
    };

    const onSetTransactionDate = (date: Date | null, key: 'renterDeliveryDate' | 'renterReturnDate' | 'lenderDropoffDate' | 'lenderPickupDate') => {
        if (!date) {
            return;
        }

        const newTransactionData = defaultsDeep(
            {
                transaction: {
                    attributes: {
                        protectedData: { [key]: date.toISOString() },
                    },
                },
            },
            transactionData,
        );

        onOverride({ transactionData: newTransactionData });
    };

    const onSetLastTransition = (transition: TransitionName) => {
        const newTransactionData = defaultsDeep(
            {
                transaction: {
                    attributes: {
                        lastTransition: transition,
                    },
                },
            },
            transactionData,
        );

        onOverride({ transactionData: newTransactionData });
    };

    const isDeliveryTrackingActive = deliveryTracking?.status === 'active';
    const isReturnDeliveryTrackingActive = returnDeliveryTracking?.status === 'active';
    const isPresentInShowroom = transactionData.listing.presentInShowroom;

    const mhSelectItems = Object.keys(MatkaHuoltoStatusCodes)
        .map((code) => ({
            value: code as MHStatusCode,
            label: `${code} - ${MatkaHuoltoStatusCodes[code as MHStatusCode]}`,
        }))
        .sort((a, b) => a.value.localeCompare(b.value));

    const transitionSelectItems = Object.entries(TransitionName).map(([key, value]) => ({ value, label: key }));

    const deliveryTrackingLastEvent = deliveryTracking?.data?.events?.length ? getLatestMHStatusCodeFromEvents(deliveryTracking?.data?.events) : '';

    const returnDeliveryTrackingLastEvent = returnDeliveryTracking?.data?.events?.length
        ? getLatestMHStatusCodeFromEvents(returnDeliveryTracking?.data?.events)
        : '';

    const renterDeliveryDate = new Date(protectedData.renterDeliveryDate as string);
    const renterReturnDate = new Date(protectedData.renterReturnDate as string);
    const lenderDropoffDate = new Date(protectedData.lenderDropoffDate as string);
    const lenderPickupDate = new Date(protectedData.lenderPickupDate as string);

    return (
        <SwipeableDrawer open={open} onClose={onClose} fillHeight={false}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, paddingBottom: 4, paddingX: 2 }}>
                <Typography variant="body2">
                    Settings for overriding transaction values, client side only. <br />
                    <br />
                    <b>Note:</b> you can achieve otherwise impossible states this way -{'>'} not bugs, features.
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2, width: '100%' }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        General
                    </Typography>
                    <Button onClick={onReset}>Reset to original</Button>
                </Box>

                <FormControl component="fieldset" variant="standard">
                    <FormLabel component="legend">View transaction as</FormLabel>
                    <FormControlLabel
                        control={<Switch checked={transactionType === 'order'} onChange={() => onChangeType(transactionType)} />}
                        label={transactionType === 'order' ? 'Renter' : 'Lender'}
                    />
                </FormControl>

                <FormControl component="fieldset" variant="standard">
                    <FormLabel component="legend">Is item present in showroom</FormLabel>
                    <FormControlLabel
                        control={<Switch checked={isPresentInShowroom} onChange={() => onChangePresentInShowroom()} />}
                        label={isPresentInShowroom ? 'Is present in showroom' : 'Is not present in showroom'}
                    />
                </FormControl>

                <FormControl component="fieldset" variant="standard">
                    <FormLabel component="legend">Last transition</FormLabel>
                    <SimpleSelect
                        options={transitionSelectItems}
                        selectedValue={lastTransition}
                        onChange={(value) => onSetLastTransition(value as TransitionName)}
                    />
                </FormControl>

                <FormLabel component="legend" sx={{ mt: 2 }}>
                    Delivery dates
                </FormLabel>
                <DatePicker label="Renter delivery date" value={renterDeliveryDate} onChange={(d) => onSetTransactionDate(d, 'renterDeliveryDate')} />
                <DatePicker label="Renter return date" value={renterReturnDate} onChange={(d) => onSetTransactionDate(d, 'renterReturnDate')} />
                <DatePicker label="Lender dropoff date" value={lenderDropoffDate} onChange={(d) => onSetTransactionDate(d, 'lenderDropoffDate')} />
                <DatePicker label="Lender pickup date" value={lenderPickupDate} onChange={(d) => onSetTransactionDate(d, 'lenderPickupDate')} />

                {deliveryMethod === 'matkahuolto' && (
                    <>
                        <Typography variant="h6" sx={{ mt: 4, fontWeight: 'bold' }}>
                            Matkahuolto
                        </Typography>
                        <Button onClick={() => onInitDeliveryTracking('delivery')} disabled={isDeliveryTrackingActive} variant="contained">
                            Create activation code for delivery
                        </Button>
                        <Button onClick={() => onInitDeliveryTracking('return')} disabled={isReturnDeliveryTrackingActive} variant="contained">
                            Create activation code for return
                        </Button>

                        <FormControl component="fieldset" variant="standard">
                            <FormLabel component="legend">Toggle delivery tracking active state</FormLabel>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={deliveryTracking?.status === 'active'}
                                        onChange={() => toggleDeliveryTrackingActive('delivery')}
                                    />
                                }
                                label={deliveryTracking?.status === 'active' ? 'Active' : 'Inactive'}
                            />
                        </FormControl>

                        <FormControl component="fieldset" variant="standard">
                            <FormLabel component="legend">Toggle return tracking active state</FormLabel>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={returnDeliveryTracking?.status === 'active'}
                                        onChange={() => toggleDeliveryTrackingActive('return')}
                                    />
                                }
                                label={returnDeliveryTracking?.status === 'active' ? 'Active' : 'Inactive'}
                            />
                        </FormControl>

                        <FormControl component="fieldset" variant="standard" sx={{ mt: 2 }}>
                            <FormLabel component="legend">Set last event for delivery</FormLabel>
                            <SimpleSelect
                                options={mhSelectItems}
                                selectedValue={deliveryTrackingLastEvent}
                                onChange={(value) => onSetDeliveryTrackingLastEvent('delivery', value as MHStatusCode)}
                            />
                        </FormControl>

                        <FormControl component="fieldset" variant="standard">
                            <FormLabel component="legend">Set last event for return</FormLabel>
                            <SimpleSelect
                                options={mhSelectItems}
                                selectedValue={returnDeliveryTrackingLastEvent}
                                onChange={(value) => onSetDeliveryTrackingLastEvent('return', value as MHStatusCode)}
                            />
                        </FormControl>
                    </>
                )}
            </Box>
        </SwipeableDrawer>
    );
};
