import { Capacitor } from '@capacitor/core';
import { Box, Button } from '@mui/material';
import { PaymentRequestPaymentMethodEvent } from '@stripe/stripe-js';
import { AnimatePresence, easeInOut, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { AnimatedContainer } from '../../animations/components/AnimatedContainer';
import { swapVertical } from '../../animations/constants';
import { DragAccept } from '../../components/DragAccept/DragAccept';
import { PaymentRequestButton } from '../../components/PaymentRequestButton/PaymentRequestButton';
import { getAppIdentificationProvider } from '../../countryConfigs';
import useLocationParams from '../../hooks/useLocationParams';
import { Booking, BookingDraft } from '../../store/bookingReducer';
import { Money } from '../../transactions/types';
import { ExtendedListing } from '../../types/apiTypes';
import { useCurrentUser } from '../../user/hooks/useUser';
import { IdentityVerificationState } from '../IdentityVerification/IdentityVerification';
import { SignicatIdentityVerificationModal, StripeIdentityVerificationModal } from '../IdentityVerification/IdentityVerificationModal';
import { SignicatIdentityVerificationResultModal } from '../IdentityVerification/IdentityVerificationResultModal';
import { ApplePayBtnContainer, DesktopFooterContainer, MobileFooterContainer } from './CheckoutFooter.components';

interface CheckoutFooterProps {
    confirmed: boolean;
    isDesktop: boolean;
    paymentDisabled: boolean;
    booking: Booking | BookingDraft;
    listing: ExtendedListing | undefined | null;
    total: Money | undefined;
    minimized: boolean;
    startPayment: (ev?: PaymentRequestPaymentMethodEvent) => void;
    onClickGoBack: () => void;
    onAccept: () => void;
    setConfirmed: (val: boolean) => void;
}

export const CheckoutFooterContent: React.FC<CheckoutFooterProps & { isApplePay: boolean }> = ({
    confirmed,
    isDesktop,
    onClickGoBack,
    setConfirmed,
    paymentDisabled,
    onAccept,
    listing,
    total,
    isApplePay,
    minimized,
    booking,
    startPayment,
}) => {
    const { t } = useTranslation();

    const isWeb = Capacitor.getPlatform() === 'web';

    if (isDesktop) {
        return (
            <DesktopFooterContainer>
                {paymentDisabled ? (
                    <Button onClick={onClickGoBack} fullWidth sx={{ p: 2 }} size="large">
                        {t('goBack')}
                    </Button>
                ) : (
                    <>
                        {isWeb && isApplePay ? (
                            <ApplePayBtnContainer>
                                <div style={{ width: '100%' }}>
                                    {/** Apple pay requires the payment modal to be displayed using an authentic click event, custom swipe component such as DragAccept throw an IntegrationError.*/}
                                    <PaymentRequestButton booking={booking} listing={listing} total={total} startPayment={startPayment} />
                                </div>
                            </ApplePayBtnContainer>
                        ) : (
                            <Box sx={{ width: '100%' }}>
                                <Button onClick={onAccept} fullWidth variant="contained">
                                    {t('proceed')}
                                </Button>
                            </Box>
                        )}
                    </>
                )}
            </DesktopFooterContainer>
        );
    }

    return (
        <MobileFooterContainer animate={minimized ? 'minimized' : 'full'}>
            {paymentDisabled ? (
                <Button onClick={onClickGoBack} fullWidth sx={{ p: 2 }} size="large">
                    {t('goBack')}
                </Button>
            ) : (
                <>
                    <AnimatePresence mode="popLayout" initial={false}>
                        {minimized ? (
                            <AnimatedContainer
                                custom={{ dir: 'down', height: 60 }}
                                variants={swapVertical}
                                key="minimized"
                                style={{ height: '100%', display: 'flex', alignItems: 'flex-end', padding: '16px 0' }}
                                transition={{ type: 'spring', stiffness: 80, damping: 15 }}
                            >
                                <Button variant="contained" fullWidth sx={{ p: 0.5 }}>
                                    <motion.div
                                        animate={{ opacity: [0.3, 1, 0.3] }}
                                        transition={{ repeat: Infinity, duration: 2, ease: 'easeInOut' }}
                                    >
                                        {t('scrollToProceed')}
                                    </motion.div>
                                </Button>
                            </AnimatedContainer>
                        ) : (
                            <AnimatedContainer
                                variants={swapVertical}
                                custom={{ dir: 'up', height: 80 }}
                                key="full"
                                style={{ height: '100%' }}
                                transition={{ y: { type: 'spring', stiffness: 50, damping: 15 }, opacity: { ease: easeInOut, duration: 0.1 } }}
                            >
                                {isApplePay && isWeb ? (
                                    <ApplePayBtnContainer sx={{ p: 2 }}>
                                        <div style={{ width: '100%' }}>
                                            {/** Apple pay requires the payment modal to be displayed using an authentic click event, custom swipe component such as DragAccept throw an IntegrationError.*/}
                                            <PaymentRequestButton booking={booking} listing={listing} total={total} startPayment={startPayment} />
                                        </div>
                                    </ApplePayBtnContainer>
                                ) : (
                                    <DragAccept onAccept={onAccept} accepted={confirmed} setAccepted={setConfirmed} />
                                )}
                            </AnimatedContainer>
                        )}
                    </AnimatePresence>
                </>
            )}
        </MobileFooterContainer>
    );
};

export const CheckoutFooter: React.FC<CheckoutFooterProps> = (props) => {
    const { data: currentUser } = useCurrentUser();

    const isVerified = Boolean(currentUser?.profile.publicData.identityVerified);
    const isApplePay = props.booking.paymentMethod === 'apple_pay';
    const identificationProvider = getAppIdentificationProvider();

    if (!currentUser) {
        return null;
    }

    return (
        <>
            {isVerified && <CheckoutFooterContent {...props} isApplePay={isApplePay} />}
            {identificationProvider === 'signicat' && <SignicatIdentityVerificationModalMaybe isVerified={isVerified} />}
            {identificationProvider === 'stripe' && <StripeIdentityVerificationModal />}
        </>
    );
};

const SignicatIdentityVerificationModalMaybe: React.FC<{ isVerified: boolean }> = ({ isVerified }) => {
    const { identification } = useLocationParams();
    const { pathname } = useLocation();

    const [displayInfoModal, setDisplayInfoModal] = useState(Boolean(identification));

    const handleVerificationResultModalClose = () => {
        setDisplayInfoModal(false);
    };

    useEffect(() => {
        if (!!identification) {
            setDisplayInfoModal(true);
        }
    }, [identification]);

    return (
        <>
            {!displayInfoModal && !isVerified && <SignicatIdentityVerificationModal redirectUrl={pathname} />}

            <SignicatIdentityVerificationResultModal
                open={displayInfoModal}
                onClose={handleVerificationResultModalClose}
                redirectUrl={pathname}
                state={identification as IdentityVerificationState}
            />
        </>
    );
};
