import { useQuery } from '@tanstack/react-query';
import { getApiClient } from '../services/sharetribe/apiClients';
import { addDays, getDay, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { NullableDateLike, formatHoursAndMinutes, formatTime, parseHHMMTimeString } from '../helpers/dateAndTimeHelpers';
import { invariant } from '../helpers/commonHelpers';
import { CheckoutSection, DeliveryOption } from '../types/delivery';

export type DayOfWeek = 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday';

export const daysOfWeek: DayOfWeek[] = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

type OpeningHourDefinition = {
    startDate: DayOfWeek;
    endDate: DayOfWeek;
    openingHour: string;
    closingHour: string;
};

type DeliveryMethodConfiguration = {
    availabilityHours: OpeningHourDefinition[];
    leadTimeDays: number;
    startBufferDays: number;
    endBufferDays: number;
    minimumPrice?: number;
    requestBufferMinutes?: number;
};

export type OfficeConfiguration = {
    openingHours: OpeningHourDefinition[];
    woltConfiguration: DeliveryMethodConfiguration;
    faceToFaceConfiguration: DeliveryMethodConfiguration;
    showroomConfiguration: DeliveryMethodConfiguration;
};

type Buffer = {
    start: number;
    end: number;
    requestDeadlineMinutes: number;
    categoryExceptions: {
        exceptionFor: 'start' | 'end';
        category: string;
    };
};

export type ReturnMethod = { id: number; method: 'faceToFace' | 'showroom' | 'uber' };

export type DeliveryDetailsFormField = {
    type: 'text' | 'phone';
    controlName: string;
    label: string;
    required: boolean;
    pattern?: string;
    multiline?: boolean;
    rows?: number;
};
export type AvailabilityException = { startDate: string; endDate: string };

export interface DateRange {
    start: string;
    end: string;
}

export interface Lender {
    dropoff: DateDef;
    pickup: DateDef;
}

export interface DateDef {
    date: string;
    openingHour: OpeningHour;
}

export interface OpeningHour {
    id: number;
    openingHour: string;
    closingHour: string;
    startDate: DayOfWeek;
    endDate: DayOfWeek;
}

export interface Renter {
    delivery: DateDef;
    return: DateDef;
}

export type DeliveryDetailsForm = {
    formFields: DeliveryDetailsFormField[];
};

export type DeliveryOptionType = 'faceToFace' | 'showroom' | 'uber' | 'wolt' | 'matkahuolto';

export interface DateRange {
    start: string;
    end: string;
}

export interface Lender {
    dropoff: DateDef;
    pickup: DateDef;
}

export interface Renter {
    delivery: DateDef;
    return: DateDef;
}

export type DeliveryConfigurationParams = {
    bookingStart: string;
    bookingEnd: string;
    listingId: string;
};

export const fetchOfficeConfiguration = async () => {
    const { data } = await getApiClient('strapi').get<OfficeConfiguration>('/office-configuration');
    return data;
};

export const useOfficeConfiguration = () => {
    return useQuery(['office-configuration'], fetchOfficeConfiguration);
};

export const useDeliveryOptionsForListing = (params?: DeliveryConfigurationParams) => {
    const { bookingStart, bookingEnd, listingId } = params || {};
    const enabled = Boolean(bookingStart && bookingEnd && listingId);

    const getDeliveryOptions = async () => {
        if (!bookingStart || !bookingEnd) {
            return [];
        }

        const queryString = new URLSearchParams({ bookingStart, bookingEnd });
        const url = `/delivery-options/${listingId}?${queryString}`;
        const { data } = await getApiClient('strapi').get<DeliveryOption[]>(url);

        return data;
    };

    return useQuery(['delivery-options', { params }], getDeliveryOptions, { enabled });
};

export const useDeliveryOptions = () => {
    const getDeliveryOptions = async () => {
        const { data } = await getApiClient('strapi').get<DeliveryOption[]>('/delivery-options');
        return data;
    };

    return useQuery(['delivery-options'], getDeliveryOptions);
};

export const getFormattedOpeningHoursForReturnOrDeliveryDate = (
    deliveryMethod: string,
    deliveryOptions: DeliveryOption[],
    dateType: 'delivery' | 'return',
): string => {
    const deliveryMethodConfig = deliveryOptions.find((method) => method.type === deliveryMethod);

    if (!deliveryMethodConfig) {
        return '';
    }

    const openingHour = deliveryMethodConfig.renter[dateType]?.openingHour;

    if (!openingHour) {
        return '';
    }

    return `${formatTime(parseHHMMTimeString(openingHour.openingHour))} - ${formatTime(parseHHMMTimeString(openingHour.closingHour))}`;
};

const getOfficeOpenDays = (openingHours: OpeningHourDefinition[]) => {
    const openDays = new Set<number>();

    openingHours.forEach((openingHour) => {
        const startDayIndex = daysOfWeek.indexOf(openingHour.startDate);
        const endDayIndex = daysOfWeek.indexOf(openingHour.endDate);

        if (startDayIndex <= endDayIndex) {
            for (let i = startDayIndex; i <= endDayIndex; i++) {
                openDays.add(i);
            }
        } else {
            for (let i = startDayIndex; i <= 6; i++) {
                openDays.add(i);
            }
            for (let i = 0; i <= endDayIndex; i++) {
                openDays.add(i);
            }
        }
    });

    return openDays;
};

// How many days of week is the office open per week
export const calculateOfficeOpenDays = (openingHours: OpeningHourDefinition[]) => {
    let openDays = 0;

    openingHours.forEach((hours) => {
        const startDayIndex = daysOfWeek.indexOf(hours.startDate);
        const endDayIndex = daysOfWeek.indexOf(hours.endDate);

        // If the start day is after the end day, it means the period wraps around the end of the week
        if (startDayIndex > endDayIndex) {
            openDays += daysOfWeek.length - startDayIndex + (endDayIndex + 1);
        } else {
            openDays += endDayIndex - startDayIndex + 1;
        }
    });

    return openDays;
};

// How many days of week is the office closed per week
export const calculateOfficeClosedDays = (openingHours: OpeningHourDefinition[]) => {
    const closedDays = daysOfWeek.length - calculateOfficeOpenDays(openingHours);
    invariant(closedDays >= 0, 'Closed days should not be negative');
    return closedDays;
};

// Hour definition is a string in the format "HH:MM:SS"
const parseHourDefinition = (openingHour: string) => {
    const [hours, minutes, seconds] = openingHour.split(':').map(Number);
    return { hours, minutes, seconds };
};

const getOpeningHoursForDeliveryMethod = (officeConfiguration: OfficeConfiguration, deliveryMethod?: string) => {
    switch (deliveryMethod) {
        case 'wolt':
            return officeConfiguration.woltConfiguration.availabilityHours;
        case 'showroom':
            return officeConfiguration.showroomConfiguration.availabilityHours;
        case 'faceToFace':
            return officeConfiguration.faceToFaceConfiguration.availabilityHours;
        default:
            return officeConfiguration.openingHours;
    }
};

/**
 * Returns methods useful for getting information about the office opening hours and configuration.
 */
export const useOfficeConfigurationMethods = () => {
    const { status, data: officeConfiguration } = useOfficeConfiguration();
    const { t } = useTranslation();

    // Returns a string of the availability hours for the office (if forConfig is not provided) or for a specific delivery method in format "Mon-Tue 10:00-18:00, Wed 12:00-20:00"
    const getAvailabilityHoursString = useCallback(() => {
        if (!officeConfiguration) return '';

        let openingHours = officeConfiguration.openingHours;

        return openingHours
            .map((openingHour) => {
                if (openingHour.startDate === openingHour.endDate) {
                    return `${t(openingHour.startDate + 'Short')} ${formatHoursAndMinutes(openingHour.openingHour)} - ${formatHoursAndMinutes(
                        openingHour.closingHour,
                    )}`;
                }
                return `${t(openingHour.startDate + 'Short')} - ${t(openingHour.endDate + 'Short')} ${formatHoursAndMinutes(
                    openingHour.openingHour,
                )} - ${formatHoursAndMinutes(openingHour.closingHour)}`;
            })
            .join(', ');
    }, [t, officeConfiguration]);

    const getNextDateWithinOpeningHours = useCallback(
        (d: NullableDateLike) => {
            if (!officeConfiguration || !d) return null;

            const date = typeof d === 'string' ? parseISO(d) : d;

            const currentDayOfWeek = getDay(date);
            const openingDaysOfWeek = getOfficeOpenDays(officeConfiguration.openingHours);

            // If the current day of week is within the opening days, return the date
            if (openingDaysOfWeek.has(currentDayOfWeek)) {
                return date;
            }

            // If the current day of week is not within the opening days, find the next opening day
            let nextDate = addDays(date, 1);

            while (!openingDaysOfWeek.has(getDay(nextDate))) {
                nextDate = addDays(nextDate, 1);
            }

            return nextDate;
        },
        [officeConfiguration],
    );

    return {
        status,
        getAvailabilityHoursString,
        getNextDateWithinOpeningHours,
    };
};

/**
 * TODO: REMOVE and use useDeliveryMethods
 * Use this hook to get information about the delivery methods and their configuration.
 */
export const useDeliveryConfigurationMethods = (params: DeliveryConfigurationParams) => {
    const { status, data: deliveryMethods } = useDeliveryOptionsForListing(params);

    const isDeliveryMethodEnabled = useCallback(
        (deliveryMethod: string) => {
            if (!deliveryMethods) return false;

            const match = deliveryMethods.find((method) => method.type === deliveryMethod);
            return match?.enabled || false;
        },
        [deliveryMethods],
    );

    return {
        status,
        isDeliveryMethodEnabled,
        deliveryMethods,
    };
};

export type DeliveryConfigurationMethods = ReturnType<typeof useDeliveryConfigurationMethods>;
export type OfficeConfigurationMethods = ReturnType<typeof useOfficeConfigurationMethods>;
export type Methods = DeliveryConfigurationMethods & OfficeConfigurationMethods;
