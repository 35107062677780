import React from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Typography } from '@mui/material';
import { Transaction } from '../../../transactions/apiTypes';
import { formatDayMonthAndDate, formatHHMMTimeRange } from '../../../helpers/dateAndTimeHelpers';
import { deliveryTimeInterval, showroomAddressCity, showroomAddressStreet } from '../../../constants';
import { toZonedTime } from 'date-fns-tz';
import { ExpandMore, LocalShipping } from '@mui/icons-material';
import { formatOpeningHoursString } from './helpers';
import { capitalizeString } from '../../../helpers/commonHelpers';
import { DeliveryOption } from '../../../types/delivery';

const SectionLabel: React.FC<{ content: React.ReactNode }> = ({ content }) => (
    <Typography variant="body2" sx={{ fontWeight: 'bold', mt: 1, width: '100%' }}>
        {content}
    </Typography>
);

interface DeliveryDetailsProps {
    transactionType: 'sale' | 'order';
    transactionProtectedData: Transaction['data']['attributes']['protectedData'];
    deliveryOptions: DeliveryOption[];
    defaultExpanded?: boolean;
}

export const DeliveryInformationSection: React.FC<DeliveryDetailsProps> = (props) => {
    const { t } = useTranslation();
    const { transactionType, defaultExpanded = false } = props;
    const {
        deliveryDetailsStreet,
        deliveryDetailsCity,
        deliveryTime,
        deliveryMethod,
        renterReturnDate,
        returnMethod,
        renterDeliveryDate,
        renterReturnTimeFormatted,
    } = props.transactionProtectedData;

    const getAddressString = () => {
        return [deliveryDetailsStreet, deliveryDetailsCity].filter(Boolean).join(', ');
    };

    if (transactionType === 'sale') {
        return null;
    }

    const deliveryTimeString =
        deliveryTime && renterDeliveryDate
            ? `${formatDayMonthAndDate(toZonedTime(new Date(renterDeliveryDate), 'UTC'))}, ${formatHHMMTimeRange(deliveryTime, deliveryTimeInterval)}`
            : '';

    const resolvedDeliveryMethod = deliveryMethod ? deliveryMethod : 'faceToFace';

    const getReturnMethodString = () => {
        if (returnMethod === 'showroom') {
            return deliveryMethod === 'showroom' ? ` & ${t('dropoff')}` : ` & ${t('showroomDropoff')}`;
        }

        return '';
    };

    if (deliveryMethod === 'faceToFace') {
        return (
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '3px' }}>
                <Box sx={{ display: 'flex' }}>
                    <Typography variant="body2" fontWeight="bold">
                        {t('deliveryMethod')}
                    </Typography>
                </Box>
                <Typography variant="body2" sx={{ opacity: 0.75 }}>
                    {t(`${resolvedDeliveryMethod}Delivery`) + getReturnMethodString()}
                </Typography>
            </Box>
        );
    }

    if (deliveryMethod === 'matkahuolto') {
        return null;
    }

    return (
        <>
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '3px' }}>
                <Accordion
                    elevation={0}
                    defaultExpanded={defaultExpanded}
                    sx={{
                        margin: '0 !important',
                    }}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: 0,
                            minHeight: '0! important',
                            '&.Mui-expanded': {
                                margin: '0 !important',
                                minHeight: '0 !important',
                            },
                            '& > div': { margin: '0 !important' },
                            '&.MuiAccordionSummary-content': { margin: '0 !important', minHeight: '0 !important' },
                        }}
                    >
                        <Box>
                            <Box sx={{ display: 'flex' }}>
                                <LocalShipping fontSize="small" sx={{ mr: 1 }} />
                                <Typography variant="body2" fontWeight="bold">
                                    {t('deliveryInformation')}
                                </Typography>
                            </Box>
                            <Typography variant="body2" sx={{ opacity: 0.75 }}>
                                {t(`${resolvedDeliveryMethod}Delivery`) + getReturnMethodString()}
                            </Typography>
                        </Box>
                    </AccordionSummary>

                    <AccordionDetails sx={{ '&.MuiAccordionDetails-root': { padding: 0 } }}>
                        <Divider sx={{ my: '12px' }} />

                        {(resolvedDeliveryMethod === 'wolt' || resolvedDeliveryMethod === 'uber') && (
                            <>
                                <SectionLabel content={t('deliveryAddress')} />

                                <Typography variant="body2">{getAddressString()}</Typography>

                                <SectionLabel content={t('deliveryTime')} />
                                <Typography variant="body2">{capitalizeString(deliveryTimeString)}</Typography>
                            </>
                        )}

                        {resolvedDeliveryMethod === 'showroom' && (
                            <>
                                <>
                                    <SectionLabel content={t('showroomAddress')} />
                                    <Typography variant="body2">{`${showroomAddressStreet}, ${showroomAddressCity}`}</Typography>

                                    <SectionLabel content={t('pickupTime')} />
                                    <Typography variant="body2">
                                        {capitalizeString(formatOpeningHoursString(props.transactionProtectedData, 'renterDeliveryDate'))}
                                    </Typography>
                                </>
                            </>
                        )}

                        {renterReturnDate && deliveryMethod === 'wolt' && (
                            <>
                                <SectionLabel content={t('itemReturn')} />
                                <Typography variant="body2">
                                    {t('showroomItemReturnDescription', {
                                        renterReturnDate: formatOpeningHoursString(props.transactionProtectedData, 'renterReturnDate'),
                                    })}
                                </Typography>
                            </>
                        )}
                        {renterReturnDate && returnMethod === 'uber' && (
                            <>
                                <SectionLabel content={t('uberItemReturn')} />
                                <Typography variant="body2">{renterReturnTimeFormatted}</Typography>
                            </>
                        )}
                    </AccordionDetails>
                </Accordion>
            </Box>
            <Divider sx={{ width: '100%', margin: '20px 0' }} />
        </>
    );
};
