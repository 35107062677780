import React, { useCallback } from 'react';
import { List, ListItem, ListItemIcon, ListItemText, MenuItem, Select, Switch, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectCompactMode, selectHideUI, selectTheme, setTheme, toggleCompactMode, toggleHideUI } from '../../../store/settingsReducer';
import { ThemeEnum } from '../../../types/enums';
import { SettingsContainer } from '../UserProfile.components';
import { LanguageSelector } from './Language';
import { useUpdateProfile } from '../hooks/useUpdateProfile';
import { useCurrentUser } from '../../../user/hooks/useUser';
import { invariant, noop } from '../../../helpers/commonHelpers';
import { FI, US } from 'country-flag-icons/react/3x2';
import { useConfirm } from '../../../context/confirm';
import { useLogout } from '../../../hooks/auth/useLogout';
import { getAppConfig } from '../../../countryConfigs';
import { useHasPermission } from '../../../user/hooks/usePermissions';
import { getAppVersion } from '../../../helpers/version';

export const Settings: React.FC = () => {
    const { confirm } = useConfirm();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const currentTheme = useAppSelector(selectTheme);
    const compactMode = useAppSelector(selectCompactMode);
    const uiHidden = useAppSelector(selectHideUI);
    const { countryCode, languages } = getAppConfig();
    const canChangeCOD = useHasPermission('settings_change_country');

    const { data: user } = useCurrentUser();
    const { mutate: updateProfile } = useUpdateProfile(noop, false);
    const { mutate: logout } = useLogout();

    const isMarketingEnabled = Boolean(user?.profile?.privateData?.settings?.notifications?.marketing);

    const toggleDarkMode = useCallback(() => {
        const newTheme = currentTheme === ThemeEnum.DARK ? ThemeEnum.LIGHT : ThemeEnum.DARK;
        dispatch(setTheme(newTheme));
    }, [dispatch, currentTheme]);

    const toggleCompactModeListings = () => dispatch(toggleCompactMode());

    const toggleHideUIListings = () => dispatch(toggleHideUI());

    const toggleMarketingNotifications = () => {
        const payload = {
            privateData: {
                settings: {
                    notifications: {
                        marketing: !isMarketingEnabled,
                    },
                },
            },
        };

        updateProfile(payload);
    };

    const maybeChangeCountry = async (selectedCountry: unknown) => {
        if (!canChangeCOD) {
            return;
        }

        invariant(
            (typeof selectedCountry === 'string' && selectedCountry === 'FI') || selectedCountry === 'US',
            `Invalid country selected: ${selectedCountry}`,
        );

        confirm({
            title: t('changeCountryOfResidenceTitle'),
            message: t('changeCountryOfResidenceMessage'),
            confirmText: t('confirm'),
            cancelText: t('cancel'),
        })
            .then(() => {
                logout({ successToast: { type: 'success', msg: t('countryOfResidenceChangeSuccess') }, changedCountryOfResidence: selectedCountry });
            })
            .catch(noop);
    };

    const countryOptions = [
        { value: 'US', label: 'country-US', icon: <US /> },
        { value: 'FI', label: 'country-FI', icon: <FI /> },
    ];

    const buildVersion = getAppVersion();

    return (
        <SettingsContainer>
            <Typography sx={{ mt: 4, mb: 2, ml: 2, fontVariationSettings: "'wght' 500" }} variant="h6">
                {t('notifications')}
            </Typography>

            <List sx={{ width: '100%' }}>
                <ListItem>
                    <ListItemText id="settings-allow-marketing-switch" primary={t('allowMarketingPrimary')} />
                    <Switch
                        edge="end"
                        onChange={toggleMarketingNotifications}
                        checked={isMarketingEnabled}
                        inputProps={{
                            'aria-labelledby': 'settings-allow-marketing-switch',
                        }}
                    />
                </ListItem>
            </List>

            <Typography sx={{ mt: 4, mb: 2, ml: 2, fontVariationSettings: "'wght' 500" }} variant="h6">
                {t('preferences')}
            </Typography>

            <List sx={{ width: '100%' }}>
                {languages.length > 1 && (
                    <ListItem>
                        <ListItemText primary={t('language')} />
                        <LanguageSelector />
                    </ListItem>
                )}

                {canChangeCOD && (
                    <ListItem>
                        <ListItemText primary={t('countryOfResidence')} />
                        <Select
                            id="country-select"
                            labelId="country-select-label"
                            label={t('country')}
                            variant="standard"
                            value={countryCode}
                            disableUnderline
                            renderValue={(value) => {
                                const country = countryOptions.find((country) => country.value === value);
                                return country ? <ListItemText>{t(country.label)}</ListItemText> : null;
                            }}
                        >
                            {countryOptions.map((country) => (
                                <MenuItem
                                    key={country.value}
                                    value={country.value}
                                    onClick={() => {
                                        maybeChangeCountry(country.value);
                                    }}
                                >
                                    <ListItemIcon sx={{ height: 20, mr: 2 }}>{country.icon}</ListItemIcon>
                                    <ListItemText>{t(country.label)} </ListItemText>
                                </MenuItem>
                            ))}
                        </Select>
                    </ListItem>
                )}

                <ListItem>
                    <ListItemText id="settings-dark-mode-switch" primary={t('darkMode')} />
                    <Switch
                        edge="end"
                        onChange={toggleDarkMode}
                        checked={currentTheme === ThemeEnum.DARK}
                        inputProps={{
                            'aria-labelledby': 'settings-dark-mode-switch',
                        }}
                    />
                </ListItem>

                <ListItem>
                    <ListItemText id="settings-compact-mode-switch" primary={t('compactMode')} />
                    <Switch
                        edge="end"
                        onChange={toggleCompactModeListings}
                        checked={compactMode}
                        inputProps={{
                            'aria-labelledby': 'settings-compact-mode-switch',
                        }}
                    />
                </ListItem>

                <ListItem>
                    <ListItemText id="settings-hide-ui-switch" primary={t('hideUiPrimary')} secondary={t('hideUiSecondary')} />
                    <Switch
                        edge="end"
                        onChange={toggleHideUIListings}
                        checked={uiHidden}
                        inputProps={{
                            'aria-labelledby': 'settings-hide-ui-switch',
                        }}
                    />
                </ListItem>

                {buildVersion && (
                    <ListItem>
                        <ListItemText id="settings-build-version" primary={t('buildVersion')} secondary={buildVersion} />
                    </ListItem>
                )}
            </List>
        </SettingsContainer>
    );
};
