import { SwipeableDrawer } from '../../../components/SwipeableDrawer/SwipeableDrawer';
import { OwnTransaction, TransactionProtectedData } from '../../../transactions/apiTypes';
import { Trans, useTranslation } from 'react-i18next';
import { Box, ButtonBase, List, ListItem, ListItemIcon, ListItemText, Skeleton, Typography } from '@mui/material';
import { StyledLink } from '../../../components/Styled/Styled.components';
import { ArrowForwardIos, OpenInNew } from '@mui/icons-material';
import { formatDayMonthAndDate, formatFullDate } from '../../../helpers/dateAndTimeHelpers';
import { ContentLoader } from '../../../components/ContentLoader/ContentLoader';
import { FetchStatus, QueryStatus } from '@tanstack/react-query';
import { ParcelTracking } from '../../../parcel-tracking/types';
import React from 'react';
import {
    isInitialDeliveryParcelNotReceived,
    isRentalCompleteAndParcelReceived,
    isReturnParcelNotDroppedOff,
    TranslationParams,
} from '../TransactionCards/NextTransactionAction';
import { useHasPermission } from '../../../user/hooks/usePermissions';

const MHDeliveryTrackingSkeleton: React.FC = () => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', my: 2 }}>
            <Skeleton variant="text" width="70%" height={24} />
            <Skeleton variant="text" width="40%" height={20} />
            <Skeleton variant="text" width="40%" height={20} />
            <Skeleton variant="text" width="70%" height={24} sx={{ mt: 2 }} />
            <Skeleton variant="text" width="40%" height={20} />
            <Skeleton variant="text" width="40%" height={20} />
        </Box>
    );
};

export type MHDeliveryTrackingDrawerState = 'delivery-details' | 'return-details' | null;

interface MHDeliveryTrackingProps {
    translationParams: TranslationParams;
    transactionData: OwnTransaction;
    status: QueryStatus | FetchStatus;
    drawerState: MHDeliveryTrackingDrawerState;
    onSetDrawerState: (state: MHDeliveryTrackingDrawerState) => void;
}

interface DeliveryDetailsProps {
    transactionType: 'order' | 'sale';
    trackingData: ParcelTracking | undefined;
    protectedData: TransactionProtectedData;
}

const TrackingDetails: React.FC<DeliveryDetailsProps> = (props) => {
    const { transactionType, trackingData, protectedData } = props;
    const { t } = useTranslation();

    const trackingUrl = trackingData?.data?.events?.length ? trackingData?.data?.trackingUrl : undefined;
    const shipmentNumber = trackingData?.data?.shipmentNumber;
    const pickupPoint = trackingData?.data?.destination;
    const deliveryDate = trackingData?.deliveryType === 'delivery' ? protectedData.renterDeliveryDate : protectedData.lenderPickupDate;

    return (
        <List
            sx={{ p: 2, gap: 2, '.MuiListItemText-root': { mb: 2 }, textAlign: 'start', paddingBottom: 'calc(50px + env(safe-area-inset-bottom))' }}
        >
            <ListItemText
                primary={
                    <Typography variant="h6" fontWeight="bold">
                        {t('parcelTrackingCode')}
                    </Typography>
                }
                secondary={
                    <Typography variant="body2">
                        {trackingUrl ? (
                            <>
                                <b style={{ marginRight: '6px' }}>{t('trackingCode')}</b>
                                <StyledLink to={trackingUrl}>
                                    {shipmentNumber}
                                    {/** @ts-ignore */}
                                    <OpenInNew fontSize="10px" sx={{ ml: '4px' }} />
                                </StyledLink>
                                <br />
                                <br />
                                {t('matkahuoltoSmsNotification')}
                            </>
                        ) : (
                            t(`${transactionType}-noTrackingNumber`)
                        )}
                    </Typography>
                }
            />

            <ListItemText
                primary={
                    <Typography variant="h6" fontWeight="bold">
                        {t('pickupPoint')}
                    </Typography>
                }
                secondary={
                    <Box sx={{ color: 'text.primary' }}>
                        <Typography variant="body2">{pickupPoint?.Name} </Typography>
                        <Typography variant="body2">{pickupPoint?.StreetAddress}</Typography>
                        <Typography variant="body2">{`${String(pickupPoint?.PostalCode).padStart(5, '0')}, ${pickupPoint?.City}`}</Typography>
                    </Box>
                }
            />

            <ListItemText
                primary={
                    <Typography variant="h6" fontWeight="bold">
                        {t('deliveryDate')}
                    </Typography>
                }
                secondary={<Typography variant="body2">{t('latestBy', { date: formatDayMonthAndDate(deliveryDate) })}</Typography>}
            />
        </List>
    );
};

const DeliveryInstructions: React.FC<{
    transactionType: 'order' | 'sale';
    trackingData: ParcelTracking | undefined;
    protectedData: TransactionProtectedData;
}> = ({ trackingData, protectedData }) => {
    const { t } = useTranslation();

    const activationCode = trackingData?.data?.activationCode;
    const deliveryDate = trackingData?.deliveryType === 'delivery' ? protectedData.lenderDropoffDate : protectedData.renterReturnDate;

    return (
        <List
            sx={{
                alignItems: 'flex-start',
                paddingBottom: `calc(50px + env(safe-area-inset-bottom))`,
                '.MuiListItem-root': { alignItems: 'flex-start' },
                '.MuiListItemText-root': { padding: 0 },
                '.MuiListItemIcon-root': { my: '4px' },
            }}
        >
            <ListItem>
                <ListItemIcon>1.</ListItemIcon>
                <ListItemText>{t('matkahuoltoInstructionsPacking')}</ListItemText>
            </ListItem>

            <ListItem>
                <ListItemIcon>2.</ListItemIcon>
                <ListItemText>
                    <Trans i18nKey="matkahuoltoActivationCodeInstructions" components={{ b: <b /> }} />
                </ListItemText>
            </ListItem>

            <Box sx={{ p: 2, textAlign: 'center' }}>
                {!activationCode ? (
                    <Typography variant="body2" sx={{ p: 1, background: '#e8e8e8', borderRadius: '10px' }}>
                        {t('matkahuoltoActivationCodeSectionPendingActivation')}
                    </Typography>
                ) : (
                    <Typography variant="body2" sx={{ p: 1, background: '#e8e8e8', borderRadius: '10px' }}>
                        <Trans i18nKey="activationCode" components={{ b: <b /> }} values={{ code: activationCode }} />
                    </Typography>
                )}
            </Box>

            <ListItem>
                <ListItemIcon>3.</ListItemIcon>
                <ListItemText>
                    <Trans
                        i18nKey="matkahuoltoInstructionsDropOff"
                        components={{ b: <b /> }}
                        values={{ date: formatDayMonthAndDate(deliveryDate) }}
                    />
                </ListItemText>
            </ListItem>

            <ListItem>
                <ListItemIcon>4.</ListItemIcon>
                <ListItemText>
                    <Trans i18nKey="matkahuoltoInstructionsConfirmation" components={{ b: <b /> }} />
                </ListItemText>
            </ListItem>
        </List>
    );
};

interface DrawerButtonProps {
    onClick: () => void;
    label: string;
    content: React.ReactNode;
    readonly?: boolean;
}

const DrawerButton: React.FC<DrawerButtonProps> = ({ onClick, label, content, readonly }) => {
    const handleClick = () => {
        if (readonly) {
            return;
        }

        onClick();
    };

    return (
        <ButtonBase
            onClick={handleClick}
            sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', py: 1, ...(readonly && { pointerEvents: 'none' }) }}
        >
            <Box sx={{ textAlign: 'start' }}>
                <Typography variant="body1" fontWeight="bold">
                    {label}
                </Typography>
                <Typography variant="body2">{content}</Typography>
            </Box>

            {readonly ? <div></div> : <ArrowForwardIos fontSize="small" />}
        </ButtonBase>
    );
};

export const MHDeliveryTrackingDrawer: React.FC<MHDeliveryTrackingProps> = (props) => {
    const { translationParams, status, drawerState, onSetDrawerState } = props;
    const { type: transactionType, deliveryTracking, returnDeliveryTracking, isPresentInShowroom, transaction } = translationParams;

    const protectedData = transaction.protectedData;

    const { t } = useTranslation();

    const renderDeliveryDetails = () => {
        if (transactionType === 'order') {
            // Renter: Display tracking code for the initial delivery
            return <TrackingDetails transactionType={transactionType} trackingData={deliveryTracking} protectedData={protectedData} />;
        } else if (transactionType === 'sale') {
            // Lender: Display delivery instructions
            return <DeliveryInstructions transactionType={transactionType} trackingData={deliveryTracking} protectedData={protectedData} />;
        }
        return null;
    };

    const renderReturnDetails = () => {
        if (transactionType === 'order') {
            // Renter: Display return instructions
            return <DeliveryInstructions transactionType={transactionType} trackingData={returnDeliveryTracking} protectedData={protectedData} />;
        } else if (transactionType === 'sale') {
            // Lender: Display tracking code for the return delivery
            return <TrackingDetails transactionType={transactionType} trackingData={returnDeliveryTracking} protectedData={protectedData} />;
        }
        return null;
    };

    const drawerLabelByTransactionType = {
        'order-delivery': t('deliveryDetailsDrawerLabel'),
        'order-return': t('returnDetailsDrawerInstructionsLabel'),
        'sale-delivery': t('deliveryDetailsDrawerInstructionsLabel'),
        'sale-return': t('returnDetailsDrawerLabel'),
    };

    const buttonPropsByTransactionType = {
        'order-delivery': {
            label: t('deliveryDetailsDrawerLabel'),
            onClick: () => onSetDrawerState('delivery-details'),
            content: (
                <Trans
                    i18nKey={'deliveryDetailsDrawerContent'}
                    components={{ b: <b /> }}
                    values={{ date: formatDayMonthAndDate(protectedData.renterDeliveryDate) }}
                />
            ),
        },
        'order-return': {
            label: t('returnDetailsDrawerInstructionsLabel'),
            onClick: () => onSetDrawerState('return-details'),
            content: (
                <Trans
                    i18nKey="returnDetailsDrawerInstructionsContent"
                    components={{ b: <b /> }}
                    values={{ date: formatDayMonthAndDate(protectedData.renterReturnDate) }}
                />
            ),
        },
        'sale-delivery': {
            label: t('deliveryDetailsDrawerInstructionsLabel'),
            onClick: () => onSetDrawerState('delivery-details'),
            content: (
                <Trans
                    i18nKey={isPresentInShowroom ? 'deliveryDetailsDrawerContentShowroom' : 'deliveryDetailsDrawerContentSale'}
                    components={{ b: <b /> }}
                    values={{ date: formatDayMonthAndDate(protectedData.lenderDropoffDate) }}
                />
            ),
        },
        'sale-return': {
            label: t('returnDetailsDrawerLabel'),
            onClick: () => onSetDrawerState('return-details'),
            content: (
                <Trans
                    i18nKey={isPresentInShowroom ? 'returnDetailsDrawerContentShowroom' : 'returnDetailsDrawerContent'}
                    components={{ b: <b /> }}
                    values={{
                        date: `${formatDayMonthAndDate(protectedData.lenderPickupDate)}`,
                        openingHours: protectedData.openingHours?.lenderPickupDate,
                        pickupPointName: returnDeliveryTracking?.data?.destination?.Name,
                    }}
                />
            ),
        },
    };

    const getDrawerLabel = (deliveryType: 'delivery' | 'return') => {
        return drawerLabelByTransactionType[`${transactionType}-${deliveryType}`];
    };

    const getButtonProps = (deliveryType: 'delivery' | 'return') => {
        return buttonPropsByTransactionType[`${transactionType}-${deliveryType}`];
    };

    const canSeeDeliveryDetails = useHasPermission('view_mh_delivery_details');

    const showDeliveryTracking = isInitialDeliveryParcelNotReceived(deliveryTracking);
    const showReturnTracking = isReturnParcelNotDroppedOff(returnDeliveryTracking);

    const drawerButtonsReadonly =
        (isPresentInShowroom && transactionType === 'sale' && !canSeeDeliveryDetails) ||
        isRentalCompleteAndParcelReceived(transaction.lastTransition, returnDeliveryTracking);

    return (
        <ContentLoader status={status} skeleton={<MHDeliveryTrackingSkeleton />} style={{ display: 'block' }}>
            {showDeliveryTracking && <DrawerButton {...getButtonProps('delivery')} readonly={drawerButtonsReadonly} />}
            {showReturnTracking && <DrawerButton {...getButtonProps('return')} readonly={drawerButtonsReadonly} />}

            <SwipeableDrawer
                fillHeight={false}
                open={drawerState === 'delivery-details'}
                onClose={() => onSetDrawerState(null)}
                headerText={getDrawerLabel('delivery')}
            >
                {renderDeliveryDetails()}
            </SwipeableDrawer>

            <SwipeableDrawer
                fillHeight={false}
                open={drawerState === 'return-details'}
                onClose={() => onSetDrawerState(null)}
                headerText={getDrawerLabel('return')}
            >
                {renderReturnDetails()}
            </SwipeableDrawer>
        </ContentLoader>
    );
};
