import { ArrowBack, LightbulbOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { capitalize, Checkbox, FormControlLabel, FormHelperText, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { StaggerList, StaggerListElement } from '../../../animations/components/Stagger';
import { itemVariantsScale } from '../../../animations/constants';
import { User } from '../../../types/apiTypes';
import { DeliveryOption } from '../../../types/delivery';
import { StepBackButton, StepContent, StepContentContainer } from '../LenderOnboarding.components';
import { BaseStepProps } from '../LenderOnboarding.types';

export type FormValue = 'matkahuolto' | 'faceToFace' | 'showroomAndWolt' | 'uber';

interface DeliveryOptionsStepProps extends BaseStepProps {
    user: User;
    deliveryOptions: DeliveryOption[];
}

type FormValues = {
    deliveryOptions: FormValue[];
};

const getDefaultDeliveryOptions = (user: User, showroomAndWoltEnabled: boolean): FormValue[] => {
    const deliveryOptions: FormValue[] = [];
    const deliverySettings = user.profile.privateData.settings?.delivery;

    if (deliverySettings?.faceToFaceEnabled) {
        deliveryOptions.push('faceToFace');
    }

    if (deliverySettings?.matkahuoltoEnabled) {
        deliveryOptions.push('matkahuolto');
    }

    if (showroomAndWoltEnabled && (deliverySettings?.showroomEnabled || deliverySettings?.woltEnabled)) {
        deliveryOptions.push('showroomAndWolt');
    }

    if (deliverySettings?.uberEnabled) {
        deliveryOptions.push('uber');
    }

    return deliveryOptions;
};

const isShowroomAndWoltEnabled = (user: User) => {
    const selectedCity = user.profile.privateData.deliveryPreferences?.city || '';
    const allowedCities = ['helsinki', 'vantaa', 'kauniainen', 'espoo'];

    return allowedCities.includes(selectedCity.toLowerCase());
};

export const DeliveryOptionsStep: React.FC<DeliveryOptionsStepProps> = ({ onComplete, loading, onGoBack, user, deliveryOptions }) => {
    const { t } = useTranslation();
    const showroomAndWoltEnabled = isShowroomAndWoltEnabled(user);

    const methods = useForm<FormValues>({
        defaultValues: {
            deliveryOptions: getDefaultDeliveryOptions(user, showroomAndWoltEnabled),
        },
    });

    const {
        register,
        watch,
        setError,
        clearErrors,
        formState: { errors },
    } = methods;

    const handleSubmit = () => {
        const values = methods.getValues('deliveryOptions');

        if (!values || values.length === 0) {
            setError('deliveryOptions', {
                type: 'manual',
                message: 'At least one checkbox must be selected',
            });
        } else {
            clearErrors('deliveryOptions');
            methods.handleSubmit(onComplete)();
        }
    };

    const getAvailableDeliveryOptions = () => {
        const options = [
            {
                value: 'matkahuolto',
                disabled: false,
            },
            {
                value: 'faceToFace',
                disabled: false,
            },
            {
                value: 'showroomAndWolt',
                disabled: !showroomAndWoltEnabled,
                disabledText: t('lenderOnboardingDeliveryOptionsShowroomAndWoltDisabled'),
            },
            {
                value: 'uber',
                disabled: false,
            },
        ] as const;

        const filteredOptions = options.filter((option) =>
            deliveryOptions.some((deliveryOption) => {
                if (option.value === 'showroomAndWolt') {
                    return deliveryOption.type === 'showroom' || deliveryOption.type === 'wolt';
                }

                return deliveryOption.type === option.value;
            }),
        );
        return filteredOptions;
    };

    const availableDeliveryOptions = getAvailableDeliveryOptions();

    // Watch the checkbox array
    const selectedOptions = watch('deliveryOptions');

    return (
        <StepContent>
            <FormProvider {...methods}>
                <form>
                    <StepContentContainer>
                        <StaggerList
                            custom={{ stagger: 0.3, delay: 0.3 }}
                            style={{
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-evenly',
                                gap: '20px',
                                alignItems: 'start',
                                padding: '8px',
                                textAlign: 'start',
                            }}
                        >
                            <StaggerListElement
                                variants={itemVariantsScale}
                                transition={{ type: 'spring', damping: 9, stiffness: 40 }}
                                style={{ marginBottom: '16px' }}
                            >
                                <Typography variant="h6">{t('lenderOnboardingDeliveryOptionsTitle')}</Typography>
                            </StaggerListElement>

                            {availableDeliveryOptions.map((option, i) => (
                                <StaggerListElement key={option.value} transition={{ delay: 0.8 + (i + 1) * 0.3 }}>
                                    <FormControlLabel
                                        sx={{ width: '100%' }}
                                        componentsProps={{ typography: { fontWeight: 'bold' } }}
                                        label={t(`lenderOnboardingDeliveryOptions${capitalize(option.value)}`)}
                                        control={
                                            <Checkbox
                                                value={option.value}
                                                checked={selectedOptions.includes(option.value)}
                                                disabled={option.disabled}
                                                {...register('deliveryOptions')}
                                            />
                                        }
                                    />
                                    {option.disabled ? (
                                        <Typography variant="body2" sx={{ m: 0, opacity: 0.5 }}>
                                            {option.disabledText}
                                        </Typography>
                                    ) : (
                                        <Typography variant="body2" sx={{ m: 0 }}>
                                            {t(`lenderOnboarding${capitalize(option.value)}Description`)}
                                        </Typography>
                                    )}
                                </StaggerListElement>
                            ))}

                            <StaggerListElement style={{ display: 'flex', marginTop: '32px', gap: '8px' }} transition={{ delay: 2 }}>
                                <LightbulbOutlined />
                                <Typography variant="body2" sx={{ m: 0 }}>
                                    <Trans i18nKey="lenderOnboardingDeliveryOptionsTip" components={{ b: <b /> }} />
                                </Typography>
                            </StaggerListElement>
                        </StaggerList>
                    </StepContentContainer>
                </form>

                <StepBackButton onClick={onGoBack}>
                    <ArrowBack />
                </StepBackButton>
            </FormProvider>

            {!isEmpty(errors) && <FormHelperText error>{t('lenderOnboardingDeliveryOptionsPickAtLeastOne')}</FormHelperText>}
            <LoadingButton variant="contained" onClick={handleSubmit} loading={loading}>
                {t('continue')}
            </LoadingButton>
        </StepContent>
    );
};
