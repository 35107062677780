import React from 'react';
import { RouteObject } from 'react-router-dom';
import { RedirectToHome } from '../components/RedirectToHome/RedirectToHome';
import { ResponsivePage } from '../components/ResponsivePage/ResponsivePage';
import { RootContainerOutlet } from '../components/RootContainer/RootContainer';
import {
    listingsLoader,
    redirectIfBookingStateIncompatible,
    redirectIfDeletingAccount,
    redirectIfOnboardingComplete,
    redirectIfPaymentNotConfirmed,
    redirectIfLenderOnboardingIncomplete,
} from '../routeLoaders';
import { AddListingView, EditListingView } from '../views/AddListing/AddListing';
import { ArticleView } from '../views/Article/Article';
import { CheckoutPage } from '../views/Checkout/Checkout';
import { CheckoutCompleteView } from '../views/CheckoutComplete.tsx/CheckoutComplete';
import { HomeView } from '../views/Home/Home';
import { Listings } from '../views/Listings/Listings';
import { ChooseLoginProvider } from '../views/Login/ChooseLoginProviderView';
import { LoginLayout } from '../views/Login/LoginLayout';
import { EmailLoginView } from '../views/Login/components/EmailLoginView';
import { Messages } from '../views/Messages/Messages';
import { PasswordResetRequestView } from '../views/PasswordReset/PasswordResetRequestView';
import { PasswordResetView } from '../views/PasswordReset/PasswordResetView';
import { PrivacyPolicy } from '../views/PrivacyPolicy/PrivacyPolicy';
import SignUpView from '../views/SignUp/SignUp';
import { UserProfilePage } from '../views/UserProfile/UserProfile';
import { userProfileRoutes } from '../views/UserProfile/routes/routes';
import { UserView } from '../views/UserView/UserView';
import { VerificationPendingView } from '../views/VerifyEmail/VerificationPendingView';
import { VerifyEmailView } from '../views/VerifyEmail/VerifyEmailView';
import ViewListing, { ListingLayout } from '../views/ViewListing/ViewListing';
import { TransactionDetails } from '../views/Wardrobe/TransactionDetails/TransactionDetails';
import { WardrobeLayout, PersonalWardrobe, FavoritesWardrobe } from '../views/Wardrobe/Wardrobe';
import { getPathConfig } from './helpers';
import { RouteDefinition } from './types';
import { PasswordResetLayout } from '../views/PasswordReset/PasswordResetLayout';
import { ProtectedRoute } from './ProtectedRoute';
import { AuthType } from '../context/auth';
import { CreatePasswordView } from '../views/PasswordReset/CreatePasswordView';
import { SearchView } from '../views/Search/SearchView';
import { DynamicStrapiPage } from '../strapi/components/DynamicStrapiPage';
import { OnboardingView } from '../views/Onboarding/OnboardingView';
import { LenderOnboardingView } from '../views/Onboarding/LenderOnboardingView';

/**
 * Defines route structure and elements, and whether they are protected or not.
 */
export const routeDefinitions: RouteDefinition[] = [
    {
        index: true,
        element: <HomeView />,
    },
    {
        path: 'onboarding',
        element: <OnboardingView />,
    },
    {
        path: 'lender-onboarding',
        loader: redirectIfOnboardingComplete,
        element: <LenderOnboardingView />,
    },
    {
        path: 'lender-onboarding/:returnType',
        loader: redirectIfOnboardingComplete,
        element: <LenderOnboardingView />,
    },
    {
        path: 'search',
        element: <SearchView />,
    },
    {
        path: 'listings',
        element: <Listings />,
        loader: listingsLoader,
        shouldRevalidate: () => false,
    },
    {
        path: 'article/:id',
        element: <ArticleView />,
    },
    {
        path: 'add-listing',
        requiredAuth: [AuthType.LOGGED_IN, AuthType.EMAIL_VERIFIED],
        loader: redirectIfLenderOnboardingIncomplete,
        element: <AddListingView />,
    },
    {
        path: 'edit-listing/:id',
        requiredAuth: [AuthType.LOGGED_IN, AuthType.EMAIL_VERIFIED],
        element: <EditListingView />,
    },
    {
        path: 'wardrobe',
        requiredAuth: [AuthType.LOGGED_IN, AuthType.EMAIL_VERIFIED],
        element: <WardrobeLayout />,
        children: [
            { index: true, element: <PersonalWardrobe /> },
            { path: 'saved', element: <FavoritesWardrobe /> },
        ],
    },
    {
        path: 'transaction/:id',
        requiredAuth: [AuthType.LOGGED_IN, AuthType.EMAIL_VERIFIED],
        element: <TransactionDetails />,
    },
    {
        path: 'user/:id',
        element: <UserView />,
    },
    {
        path: 'listing/own/:id',
        requiredAuth: [AuthType.LOGGED_IN, AuthType.EMAIL_VERIFIED],
        element: <ViewListing own />,
    },
    {
        path: 'listing/:id',
        element: <ListingLayout />,
        children: [
            {
                index: true,
                element: <ViewListing />,
            },
            {
                path: 'checkout',
                requiredAuth: [AuthType.LOGGED_IN, AuthType.EMAIL_VERIFIED],
                element: <CheckoutPage />,
                loader: redirectIfBookingStateIncompatible,
            },
            {
                path: 'complete',
                requiredAuth: [AuthType.LOGGED_IN, AuthType.EMAIL_VERIFIED],
                element: <CheckoutCompleteView />,
                loader: redirectIfPaymentNotConfirmed,
            },
        ],
    },
    {
        path: 'login',
        element: <LoginLayout />,
        children: [
            {
                index: true,
                element: <ChooseLoginProvider />,
            },
            {
                path: 'email',
                element: <EmailLoginView />,
            },
        ],
    },
    {
        path: 'signup',
        element: <SignUpView />,
    },
    {
        path: 'privacy-policy',
        element: <PrivacyPolicy />,
    },
    {
        path: 'terms-of-service',
        element: <DynamicStrapiPage name="terms-of-service" />,
    },
    {
        path: 'renter-protection',
        element: <DynamicStrapiPage name="renter-protection" />,
    },
    {
        path: 'how-renting-works',
        element: <DynamicStrapiPage name="how-renting-works" />,
    },
    {
        path: 'fit-guarantee',
        element: <DynamicStrapiPage name="fit-guarantee" />,
    },
    {
        path: 'verification-pending',
        requiredAuth: [AuthType.LOGGED_IN],
        element: <VerificationPendingView />,
    },
    {
        path: 'verify-email',
        element: <VerifyEmailView />,
    },
    {
        path: 'reset-password',
        element: <PasswordResetLayout />,
        children: [
            {
                path: 'request',
                element: <PasswordResetRequestView />,
            },
            {
                path: 'reset',
                element: <PasswordResetView />,
                loader: redirectIfDeletingAccount,
            },
        ],
    },
    {
        path: 'create-password',
        element: <CreatePasswordView />,
    },
    {
        path: 'messages',
        requiredAuth: [AuthType.LOGGED_IN, AuthType.EMAIL_VERIFIED],
        element: <Messages />,
    },
    {
        path: 'profile',
        requiredAuth: [AuthType.LOGGED_IN, AuthType.EMAIL_VERIFIED],
        element: <UserProfilePage />,
        children: userProfileRoutes.map((route) => ({
            path: route.path,
            element: <>{route.component}</>,
            ...(route.loader && { loader: route.loader }),
        })),
    },
    {
        path: '*',
        element: <RedirectToHome />,
    },
];

/**
 * Creates route objects from route definitions. Applies path configuration to each route and wraps top level routes in ResponsivePage.
 * Protected routes are additionally wrapped in ProtectedRoute.
 */
export const createRoutesFromDefinition = (): RouteObject[] => {
    const createRouteElements = (def: RouteDefinition, rootPath?: string, isChildRoute?: boolean): RouteObject => {
        const { element, children, index, loader, path, requiredAuth, shouldRevalidate } = def;

        const baseRoute: RouteObject = {
            ...(index && { index: true }),
            ...(loader && { loader }),
            ...(shouldRevalidate && { shouldRevalidate }),
        };

        // Index routes' path is the same as the root path
        const getRoutePattern = () => {
            if (index) {
                return rootPath;
            }

            if (rootPath === '/') {
                return `/${path}`;
            }

            return `${rootPath}/${path}`;
        };

        const pattern = getRoutePattern();
        const configuration = getPathConfig(pattern);

        if (!index) {
            baseRoute.path = pattern;
        }

        if (element) {
            // Children of ResponsivePage should not be wrapped in ResponsivePage (duplicate padding and overflow)
            baseRoute.element = isChildRoute ? <>{element}</> : <ResponsivePage config={configuration}>{element}</ResponsivePage>;

            if (requiredAuth && requiredAuth.length) {
                baseRoute.element = <ProtectedRoute requiredAuth={requiredAuth}>{baseRoute.element}</ProtectedRoute>;
            }
        }

        if (children) {
            baseRoute.children = children.map((child) => createRouteElements(child, baseRoute.path || '/', true));
        }

        const route = {
            ...baseRoute,
            ...configuration,
        };

        return route;
    };

    const routes = [
        {
            element: <RootContainerOutlet />,
            children: routeDefinitions.map((def) => createRouteElements(def, '/')),
        },
    ];
    return routes;
};
