import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getApiClient } from '../../services/sharetribe/apiClients';
import { useAppSelector } from '../../store/hooks';
import { selectToken } from '../../store/userReducer';
import { User, UserSettings } from '../../types/apiTypes';

export const useUser = (userId: string | undefined): UseQueryResult<User, unknown> => {
    const getUser = async () => {
        if (!userId) {
            return null;
        }

        const { data } = await getApiClient('user').get<User>(userId);
        return data;
    };

    return useQuery(['user', { userId }], getUser);
};

export const getUserFn = async (token: string) => {
    if (!token) {
        return null;
    }

    const url = '/current';
    const { data } = await getApiClient('user').get<User>(url, {
        headers: {
            Authorization: token,
        },
    });

    return data;
};

export const useCurrentUser = (): UseQueryResult<User> => {
    const token = useAppSelector(selectToken);

    return useQuery(['current-user'], () => getUserFn(token), { refetchOnWindowFocus: 'always' });
};

export const useCurrentUserId = (): string | undefined => {
    const { data: user } = useCurrentUser();

    return user?.id;
};

export const useUserSettings = (): UserSettings => {
    const { data: user } = useCurrentUser();

    return user?.profile.privateData.settings;
};

export const useStripeCustomer = (): string | undefined => {
    const { data: user } = useCurrentUser();

    return user?.stripeCustomer?.stripeCustomerId;
};
