import { AvailabilityException, OpeningHour } from '../queries/useDeliveryTiming';
import { formatDayMonthAndDate, formatHoursAndMinutes } from './dateAndTimeHelpers';
import { TFunction } from 'i18next';
import { isWithinInterval, addMinutes, roundToNearestMinutes, endOfDay, parseISO, isSameDay, differenceInDays, set } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { DeliveryOption } from '../types/delivery';

const parseHourDefinition = (openingHour: string) => {
    const [hours, minutes, seconds] = openingHour.split(':').map(Number);
    return { hours, minutes, seconds };
};
export const getTransactionDate = (
    deliveryOptions: DeliveryOption[],
    deliveryMethod: string,
    date: 'renterDeliveryDate' | 'renterReturnDate' | 'lenderDropoffDate' | 'lenderPickupDate',
): Date | null => {
    const match = deliveryOptions.find((method) => method.type === deliveryMethod);

    if (!match) {
        return null;
    }

    if (date === 'renterDeliveryDate') {
        return new Date(match.renter.delivery.date);
    }

    if (date === 'renterReturnDate') {
        return new Date(match.renter.return.date);
    }

    if (date === 'lenderDropoffDate') {
        return new Date(match.lender.dropoff.date);
    }

    if (date === 'lenderPickupDate') {
        return new Date(match.lender.pickup.date);
    }

    return null;
};

export const getOpeningHourStringForDate = (
    deliveryOptions: DeliveryOption[],
    deliveryMethod: string,
    date: 'renterDeliveryDate' | 'renterReturnDate' | 'lenderDropoffDate' | 'lenderPickupDate',
    format: 'date' | 'date-and-time',
): string | null => {
    const match = deliveryOptions.find((method) => method.type === deliveryMethod);

    if (!match) {
        return null;
    }

    const formatOpeningHour = (openingHour: OpeningHour, d: Date) => {
        if (format === 'date') {
            return formatDayMonthAndDate(d);
        }

        return `${formatDayMonthAndDate(d)} ${formatHoursAndMinutes(openingHour.openingHour)} - ${formatHoursAndMinutes(openingHour.closingHour)}`;
    };

    if (date === 'renterDeliveryDate') {
        const d = new Date(match.renter.delivery.date);
        return formatOpeningHour(match.renter.delivery.openingHour, d);
    }

    if (date === 'renterReturnDate') {
        const d = new Date(match.renter.return.date);
        return formatOpeningHour(match.renter.return.openingHour, d);
    }

    if (date === 'lenderDropoffDate') {
        const d = new Date(match.lender.dropoff.date);
        return formatOpeningHour(match.lender.dropoff.openingHour, d);
    }

    if (date === 'lenderPickupDate') {
        const d = new Date(match.lender.pickup.date);
        return formatOpeningHour(match.lender.pickup.openingHour, d);
    }

    return null;
};

export const isDateInsideException = (date: Date, exception: AvailabilityException[]) => {
    return exception.some((ex) => {
        const startDate = new Date(ex.startDate);
        const endDate = new Date(ex.endDate);

        return date >= startDate && date <= endDate;
    });
};

export const isDeliveryMethodEnabled = (deliveryMethods: DeliveryOption[], deliveryMethod: string) => {
    return deliveryMethods.find((method) => method.type === deliveryMethod)?.enabled ?? false;
};

export const getPriceForDeliveryMethod = (deliveryMethods: DeliveryOption[], deliveryMethod: string) => {
    return deliveryMethods.find((method) => method.type === deliveryMethod)?.price ?? 0;
};

// Returns a string of the availability hours for the deliveryMethod in format "Mon-Tue 10:00-18:00, Wed 12:00-20:00"
export const getAvailabilityHoursString = (deliveryMethods: DeliveryOption[], deliveryMethod: string, t: TFunction) => {
    const match = deliveryMethods.find((method) => method.type === deliveryMethod);

    if (!match) {
        return '';
    }

    const availabilityHours = match.availability;

    return availabilityHours
        .map((availability) => {
            if (availability.startDate === availability.endDate) {
                return `${t(availability.startDate + 'Short')} ${formatHoursAndMinutes(availability.openingHour)} - ${formatHoursAndMinutes(
                    availability.closingHour,
                )}`;
            }
            return `${t(availability.startDate + 'Short')} - ${t(availability.endDate + 'Short')} ${formatHoursAndMinutes(
                availability.openingHour,
            )} - ${formatHoursAndMinutes(availability.closingHour)}`;
        })
        .join(', ');
};

export const getDeliveryInformation = (
    deliveryOptions: DeliveryOption[],
    deliveryMethod: string,
    bookingDate: string | undefined,
    type: 'delivery' | 'return',
) => {
    const match = deliveryOptions.find((method) => method.type === deliveryMethod);

    if (!match || !bookingDate || !match.buffer) {
        return null;
    }

    const now = new Date();

    const compareDate = type === 'delivery' ? new Date(match.renter.delivery.date) : new Date(match.renter.return.date);

    const requestBufferMinutes = match.buffer.requestDeadlineMinutes;
    const availabilityHour = match.renter.delivery.openingHour;
    const availabilityExceptions = match.availabilityExceptions;

    let min = set(compareDate, { ...parseHourDefinition(availabilityHour.openingHour) });
    const max = set(compareDate, { ...parseHourDefinition(availabilityHour.closingHour) });

    // Do not allow choosing delivery times that are less than now + requestBufferMinutes
    if (requestBufferMinutes && isWithinInterval(now, { start: min, end: max })) {
        const temp = addMinutes(now, requestBufferMinutes);
        min = roundToNearestMinutes(temp, { nearestTo: 30, roundingMethod: 'ceil' });
    }

    const localDeliveryDateUTC = compareDate.toISOString();

    // Booking date is in UTC, so we need to convert it to local time
    const bookingDateObj = endOfDay(toZonedTime(bookingDate, 'UTC'));
    const localDateObj = endOfDay(parseISO(localDeliveryDateUTC));

    const isDeliveryDateDifferent = !isSameDay(bookingDateObj, localDateObj);

    const daysDiff = isDeliveryDateDifferent ? Math.abs(differenceInDays(localDateObj, bookingDateObj)) : 0;

    return { min, max, isDeliveryDateDifferent, daysDiff, availabilityExceptions };
};

export type DeliveryInformation = ReturnType<typeof getDeliveryInformation>;

export const getOpeningHoursString = (date: Date | null, openingHour: OpeningHour) => {
    if (!date) {
        return null;
    }

    return `${formatDayMonthAndDate(date)} ${formatHoursAndMinutes(openingHour.openingHour)} - ${formatHoursAndMinutes(openingHour.closingHour)}`;
};
