import { Alert, AlertTitle, ButtonBase, Typography } from '@mui/material';
import { addDays, differenceInHours, isAfter, isBefore, isSameDay } from 'date-fns';
import { TFunction, TFunctionResult } from 'i18next';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { deliveryTimeInterval } from '../../../constants';
import { assertIsDefined, capitalizeEachWord } from '../../../helpers/commonHelpers';
import { formatDayMonthAndDate, formatHHMMTimeRange, formatMonthAndDate } from '../../../helpers/dateAndTimeHelpers';
import { getMatkahuoltoTransitStatus, isLatestMHEventEqual, isLatestMHEventEqualOrGreaterThan } from '../../../matkahuolto/helpers';
import { ParcelTracking } from '../../../parcel-tracking/types';
import { TransactionAttributes } from '../../../transactions/apiTypes';
import { TransitionName } from '../../../transactions/constants';
import { DeliveryOption } from '../../../types/delivery';
import { Nil } from '../../../types/types';
import { MHDeliveryTrackingDrawerState } from '../Matkahuolto/MHDeliveryTrackingDrawer';
import { StyledActivationCode } from '../Matkahuolto/StyledActivationCode';
import { StyledTrackingUrl } from '../Matkahuolto/StyledTrackingUrl';
import { formatOpeningHoursString } from './helpers';
import { buildMHRentalStatusKey } from './NextTransactionAction.helpers';

export type TranslationParams = {
    bookingStart: Date;
    bookingEnd: Date;
    deliveryOptions: DeliveryOption[];
    type: 'order' | 'sale';
    isPresentInShowroom: boolean | undefined;
    deliveryTracking: ParcelTracking | undefined;
    returnDeliveryTracking: ParcelTracking | undefined;
    transaction: TransactionAttributes;
    setDrawerState: (state: MHDeliveryTrackingDrawerState) => void;
};

type ParamDependantTranslation = (t: TFunction, params: TranslationParams) => TFunctionResult | React.ReactElement;

type NextStepConfig = {
    order: {
        text?: string | ParamDependantTranslation;
        alert?: {
            title?: string | ParamDependantTranslation;
            body?: string | ParamDependantTranslation;
        };
    };
    sale: {
        text?: string | ParamDependantTranslation;
        alert?: {
            title?: string | ParamDependantTranslation;
            body?: string | ParamDependantTranslation;
        };
    };
};

const getAlertTitle: ParamDependantTranslation = (t, params) => {
    const methodsWithDeliveryInstructions = ['wolt', 'showroom', 'uber', 'matkahuolto'];

    if (methodsWithDeliveryInstructions.includes(params.transaction.protectedData.deliveryMethod)) {
        return params.transaction.lastTransition === TransitionName.CONFIRM_PAYMENT ? t('deliveryInstructions') : t('nextStep');
    }
    return '';
};

type RentalStatus =
    | 'Upcoming'
    | 'StartsToday'
    | 'InProgress'
    | 'EndsToday'
    | 'Past'
    | 'NotAccepted'
    | 'NotAcceptedPresentInShowroom'
    | 'UpcomingPresentInShowroom';

const getRentalStatus = (
    start: Date,
    end: Date,
    type: 'sale' | 'order',
    presentInShowroom: boolean | undefined,
    lastTransition?: TransitionName,
): RentalStatus => {
    const now = new Date();
    if (lastTransition === TransitionName.CONFIRM_PAYMENT) {
        if (type === 'sale' && presentInShowroom) {
            return 'NotAcceptedPresentInShowroom';
        }
        return 'NotAccepted';
    }
    if (isBefore(now, start)) {
        if (type === 'sale' && presentInShowroom) {
            return 'UpcomingPresentInShowroom';
        }
        return 'Upcoming';
    }
    if (isSameDay(now, start)) {
        if (type === 'sale' && presentInShowroom) {
            return 'UpcomingPresentInShowroom';
        }
        return 'StartsToday';
    }
    if (isAfter(now, start) && isBefore(now, end)) {
        return 'InProgress';
    }
    if (isSameDay(now, end)) {
        return 'EndsToday';
    }

    return 'Past';
};

type Components = Record<string, any>;

export const isRentalCompleteButParcelNotReceived = (lastTransition: TransitionName, returnDeliveryTracking: ParcelTracking | undefined) => {
    const hasOwnerReceivedParcel = isLatestMHEventEqualOrGreaterThan(returnDeliveryTracking?.data?.events || [], '60');

    return lastTransition === TransitionName.COMPLETE && !hasOwnerReceivedParcel;
};

export const isRentalCompleteAndParcelReceived = (lastTransition: TransitionName, returnDeliveryTracking: ParcelTracking | undefined) => {
    const hasOwnerReceivedParcel = isLatestMHEventEqualOrGreaterThan(returnDeliveryTracking?.data?.events || [], '60');

    return lastTransition === TransitionName.COMPLETE && hasOwnerReceivedParcel;
};

export const isInitialDeliveryParcelReceived = (deliveryTracking: ParcelTracking | undefined) => {
    const hasRenterReceivedParcel = isLatestMHEventEqualOrGreaterThan(deliveryTracking?.data?.events || [], '60');

    return hasRenterReceivedParcel;
};

export const isInitialDeliveryParcelNotReceived = (deliveryTracking: ParcelTracking | undefined) => {
    return !isInitialDeliveryParcelReceived(deliveryTracking);
};

export const isReturnParcelNotDroppedOff = (returnDeliveryTracking: ParcelTracking | undefined) => {
    return returnDeliveryTracking?.status === 'pending-activation' || isLatestMHEventEqual(returnDeliveryTracking?.data?.events || [], '02');
};

const buildMHTranslations = (params: TranslationParams, type: 'sale' | 'order', components: Components) => {
    const { deliveryTracking, returnDeliveryTracking, transaction, isPresentInShowroom, setDrawerState } = params;
    const { protectedData, lastTransition } = transaction;
    const { renterDeliveryDate, renterReturnDate, deliveryMethod, lenderPickupDate, lenderDropoffDate } = protectedData;

    assertIsDefined(renterDeliveryDate, 'renterDeliveryDate should be defined');
    assertIsDefined(renterReturnDate, 'renterReturnDate should be defined');

    const trackingNumber = deliveryTracking?.data?.shipmentNumber;
    const trackingUrl = deliveryTracking?.data?.trackingUrl;
    const activationCode = deliveryTracking?.data?.activationCode;

    const getPickupPointDetails = () => {
        const pickupPoint = type === 'order' ? deliveryTracking?.data?.destination : returnDeliveryTracking?.data?.destination;

        if (!pickupPoint) return null;

        return {
            ...pickupPoint,
            PostalCode: `${String(pickupPoint.PostalCode).padStart(5, '0')}`,
        };
    };

    const translationValues: Record<string, any> = {
        trackingNumber,
        trackingUrl,
        d1: formatDayMonthAndDate(renterDeliveryDate),
        d2: formatDayMonthAndDate(lenderPickupDate),
        lenderPickupDate: formatDayMonthAndDate(lenderPickupDate),
        lenderDropoffDate: formatDayMonthAndDate(lenderDropoffDate),
        renterReturnDate: formatDayMonthAndDate(renterReturnDate),
        renterDeliveryDate: formatDayMonthAndDate(renterDeliveryDate),
        pickupPoint: getPickupPointDetails(),
    };

    components = {
        ...components,
        StyledTrackingUrl: <StyledTrackingUrl shipmentNumber={trackingNumber} trackingUrl={trackingUrl} transactionType={type} />,
        StyledActivationCode: <StyledActivationCode activationCode={activationCode} sx={{ py: 1 }} />,
        ReturnInstructionsButton: (
            <ButtonBase sx={{ textDecoration: 'underline', verticalAlign: 'baseline' }} onClick={() => setDrawerState('return-details')} />
        ),
        DeliveryInstructionsButton: (
            <ButtonBase sx={{ textDecoration: 'underline', verticalAlign: 'baseline' }} onClick={() => setDrawerState('delivery-details')} />
        ),
    };

    const rentalStatus = getRentalStatus(new Date(renterDeliveryDate), new Date(renterReturnDate), type, isPresentInShowroom, lastTransition);
    const party = type === 'order' ? 'Renter' : 'Lender';

    let i18nKey = `rentalStatus${party}${rentalStatus}${capitalizeEachWord(deliveryMethod)}`;

    const displayParcelTrackingForTransitions = [TransitionName.ACCEPT, TransitionName.OPERATOR_ACCEPT, TransitionName.COMPLETE];

    if (lastTransition && displayParcelTrackingForTransitions.includes(lastTransition)) {
        const initialDeliveryStatus = getMatkahuoltoTransitStatus(deliveryTracking);
        const returnDeliveryStatus = getMatkahuoltoTransitStatus(returnDeliveryTracking);

        i18nKey = buildMHRentalStatusKey(party, deliveryMethod, initialDeliveryStatus, returnDeliveryStatus, isPresentInShowroom);
    }

    return <Trans i18nKey={i18nKey} values={translationValues} components={components} />;
};

const buildFaceToFaceTranslations = (params: TranslationParams, type: 'sale' | 'order', components: Components) => {
    const { bookingStart, bookingEnd, transaction } = params;
    const deliveryMethod = transaction.protectedData.deliveryMethod;
    const lastTransition = transaction.lastTransition;

    const translationValues: Record<string, string | number | Nil> = {
        d1: formatMonthAndDate(bookingStart),
        d2: formatMonthAndDate(bookingEnd),
    };

    const rentalStatus = getRentalStatus(bookingStart, bookingEnd, type, params.isPresentInShowroom, lastTransition);

    const party = type === 'order' ? 'Renter' : 'Lender';
    const i18nKey = `rentalStatus${party}${rentalStatus}${capitalizeEachWord(deliveryMethod)}`;

    return <Trans i18nKey={i18nKey} values={translationValues} components={components} />;
};

const buildOtherDeliveryMethodTranslations = (params: TranslationParams, type: 'sale' | 'order', components: Components) => {
    const protectedData = params.transaction.protectedData;
    const lastTransition = params.transaction.lastTransition;
    const { renterDeliveryDate, renterReturnDate, lenderDropoffDate, deliveryMethod, deliveryTime } = protectedData;

    if (!renterDeliveryDate || !renterReturnDate) {
        return {
            i18nKey: '',
            components,
            translationValues: {},
        };
    }

    const formattedDeliveryTime = deliveryTime
        ? `${formatDayMonthAndDate(new Date(renterDeliveryDate))} - ${formatHHMMTimeRange(deliveryTime, deliveryTimeInterval)}`
        : null;

    const d1 = type === 'order' ? 'renterDeliveryDate' : lenderDropoffDate ? 'lenderDropoffDate' : 'renterDeliveryDate';
    const d2 = type === 'order' ? 'renterReturnDate' : 'lenderPickupDate';

    const translationValues: Record<string, string | number | Nil> = {
        d1: formatOpeningHoursString(protectedData, d1),
        d2: formatOpeningHoursString(protectedData, d2),
        deliveryTime: formattedDeliveryTime,
    };

    const rentalStatus = getRentalStatus(new Date(renterDeliveryDate), new Date(renterReturnDate), type, params.isPresentInShowroom, lastTransition);

    const party = type === 'order' ? 'Renter' : 'Lender';
    const i18nKey = `rentalStatus${party}${rentalStatus}${capitalizeEachWord(deliveryMethod)}`;

    return <Trans i18nKey={i18nKey} values={translationValues} components={components} />;
};

const getTranslationKey: ParamDependantTranslation = (t, params) => {
    const { transaction, type } = params;
    const { protectedData } = transaction;
    const { deliveryMethod } = protectedData;

    let components: Record<string, any> = {
        b: <b />,
        h3: <h3 style={{ margin: '8px 0' }} />,
        underline: <span style={{ textDecoration: 'underline' }} />,
        ul: <ul style={{ paddingInlineStart: '0' }} />,
        li: <li />,
    };

    if (deliveryMethod === 'matkahuolto') return buildMHTranslations(params, type, components);
    if (deliveryMethod === 'faceToFace') return buildFaceToFaceTranslations(params, type, components);
    else return buildOtherDeliveryMethodTranslations(params, type, components);
};

const getRenterConfirmPaymentTranslationKey: ParamDependantTranslation = (t, params) => {
    const { lastTransitionedAt } = params.transaction;
    const now = new Date();
    const deadlineForAcceptance = addDays(new Date(lastTransitionedAt), 2);
    const hoursDifference = differenceInHours(deadlineForAcceptance, now);

    if (hoursDifference < 0) {
        return '';
    }

    return (
        <Trans
            i18nKey="pendingLenderResponse"
            values={{ time: hoursDifference }}
            components={{ b: <b />, h3: <h3 />, underline: <span style={{ textDecoration: 'underline' }} /> }}
        />
    );
};

const getLenderConfirmPaymentTranslationKey: ParamDependantTranslation = (t, params) => {
    const { lastTransitionedAt } = params.transaction;
    const now = new Date();
    const deadlineForAcceptance = addDays(new Date(lastTransitionedAt), 2);
    const hoursDifference = differenceInHours(deadlineForAcceptance, now);

    if (hoursDifference < 0) {
        return '';
    }

    return (
        <Trans
            i18nKey="pendingResponse"
            values={{ time: hoursDifference }}
            components={{ b: <b />, underline: <span style={{ textDecoration: 'underline' }} /> }}
        />
    );
};

const nextStepConfigs: Partial<Record<TransitionName, NextStepConfig>> = {
    [TransitionName.ACCEPT]: {
        order: {
            alert: {
                title: 'nextStep',
                body: getTranslationKey,
            },
        },
        sale: {
            alert: {
                title: getAlertTitle,
                body: getTranslationKey,
            },
        },
    },
    [TransitionName.OPERATOR_ACCEPT]: {
        order: {
            alert: {
                title: 'nextStep',
                body: getTranslationKey,
            },
        },
        sale: {
            alert: {
                title: getAlertTitle,
                body: getTranslationKey,
            },
        },
    },
    [TransitionName.CONFIRM_PAYMENT]: {
        order: {
            text: getRenterConfirmPaymentTranslationKey,
            alert: {
                title: 'nextStep',
                body: getTranslationKey,
            },
        },
        sale: {
            text: getLenderConfirmPaymentTranslationKey,
            alert: {
                title: getAlertTitle,
                body: getTranslationKey,
            },
        },
    },
    [TransitionName.REQUEST_PAYMENT]: {
        order: {
            alert: {
                body: 'pendingPayment',
            },
        },
        sale: {
            alert: {
                body: 'pendingPayment',
            },
        },
    },
    [TransitionName.COMPLETE]: {
        order: {
            text: 'rentalCompleteRenter',
        },
        sale: {
            text: 'rentalCompleteLender',
        },
    },
    [TransitionName.REVIEW_1_BY_CUSTOMER]: {
        order: {
            text: 'rentalCompleteRenter',
        },
        sale: {
            text: 'rentalCompleteLender',
        },
    },
    [TransitionName.REVIEW_1_BY_PROVIDER]: {
        order: {
            text: 'rentalCompleteRenter',
        },
        sale: {
            text: 'rentalCompleteLender',
        },
    },
    [TransitionName.REVIEW_2_BY_CUSTOMER]: {
        order: {
            text: 'rentalCompleteRenter',
        },
        sale: {
            text: 'rentalCompleteLender',
        },
    },
    [TransitionName.REVIEW_2_BY_PROVIDER]: {
        order: {
            text: 'rentalCompleteRenter',
        },
        sale: {
            text: 'rentalCompleteLender',
        },
    },
};

interface NextTransactionActionProps {
    lastTransition: TransitionName;
    translationParams: TranslationParams;
}

export const NextTransactionAction: React.FC<NextTransactionActionProps> = ({ lastTransition, translationParams }) => {
    const { t } = useTranslation();

    const getNextStepConfig = () => {
        const deliveryMethod = translationParams.transaction.protectedData.deliveryMethod;
        const deliveryMethodsWithParcelTracking = ['matkahuolto'];
        const deliveryTracking = translationParams.returnDeliveryTracking;

        // Special case where the transaction which transitions automatically to "Complete" state, but the owner has not received the parcel yet.
        // In this case, show the alert with parcel tracking information that is shown in "Accept" state.
        if (deliveryMethodsWithParcelTracking.includes(deliveryMethod) && isRentalCompleteButParcelNotReceived(lastTransition, deliveryTracking)) {
            return nextStepConfigs[TransitionName.ACCEPT];
        }

        return nextStepConfigs[lastTransition];
    };

    const nextStepConfig = getNextStepConfig();

    if (!nextStepConfig) {
        return null;
    }

    const type = translationParams.type;
    const transaction = translationParams.transaction;
    const translationKeysForType = nextStepConfig[type];

    const resolveTranslationKey = (translationKey: string | ParamDependantTranslation | undefined) => {
        if (!translationKey) return null;

        let resolvedKey;

        if (typeof translationKey === 'function') {
            resolvedKey = translationKey(t, { ...translationParams, type, transaction }) as React.ReactNode;
        } else {
            resolvedKey = t(translationKey);
        }

        return resolvedKey;
    };

    const hideAlert = () => {
        return lastTransition === TransitionName.CONFIRM_PAYMENT && type === 'order';
    };

    const showAlert = !hideAlert();

    const resolvedBodyText = resolveTranslationKey(translationKeysForType.text);
    const resolvedAlertTitle = showAlert && resolveTranslationKey(translationKeysForType.alert?.title);
    const resolvedAlertBody = showAlert && resolveTranslationKey(translationKeysForType.alert?.body);

    const hasAlert = resolvedAlertTitle || resolvedAlertBody;

    return (
        <>
            {hasAlert && (
                <Alert severity="info" sx={{ borderRadius: '15px' }}>
                    {resolvedAlertTitle && <AlertTitle sx={{ fontWeight: 'bold' }}>{resolvedAlertTitle}</AlertTitle>}
                    {resolvedAlertBody && <Typography sx={{ fontSize: '0.8rem' }}>{resolvedAlertBody}</Typography>}
                </Alert>
            )}

            {resolvedBodyText && (
                <Typography variant="body2" sx={{ mt: 2 }}>
                    {resolvedBodyText}
                </Typography>
            )}
        </>
    );
};
