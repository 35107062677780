import { LoadingButton } from '@mui/lab';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../components/Modal/Modal';
import { StepContentContainer, StepContentFullScreen } from '../../components/NewUserOnboarding/UserOnboarding.components';
import { useSignicatIdentificationFlow } from '../../hooks/auth/signicat/useIdentification';
import { useBreakpoint } from '../../hooks/useBreakpoint';
import { IdentityVerification, IdentityVerificationState } from './IdentityVerification';

interface SignicatIdentityVerificationResultModalProps {
    open: boolean;
    onClose: () => void;
    redirectUrl: string;
    state?: IdentityVerificationState;
}

export const SignicatIdentityVerificationResultModal: React.FC<SignicatIdentityVerificationResultModalProps> = ({
    open,
    onClose,
    redirectUrl,
    state = 'initial',
}) => {
    const { t } = useTranslation();

    const redirectParams = {
        path: redirectUrl,
    };

    const { mutate: startRedirectFlow, isLoading } = useSignicatIdentificationFlow(redirectParams);

    const isDesktop = useBreakpoint('sm');

    let modalStyle: React.CSSProperties = {
        display: 'flex',
    };

    if (!isDesktop) {
        modalStyle = {
            ...modalStyle,
            margin: 0,
            borderRadius: 0,
        };
    }

    return (
        <Modal
            open={open}
            variant={isDesktop ? 'lg' : 'full'}
            animationVariant={isDesktop ? 'scale' : 'fadeUp'}
            onClose={onClose}
            modalStyle={modalStyle}
        >
            <StepContentFullScreen>
                <StepContentContainer>
                    <IdentityVerification identificationProvider="signicat" state={state} />
                </StepContentContainer>

                {(state === 'abort' || state === 'error') && (
                    <>
                        <LoadingButton variant="contained" onClick={() => startRedirectFlow()}>
                            {t('tryAgain')}
                        </LoadingButton>
                        <LoadingButton variant="text" onClick={onClose}>
                            {t('cancel')}
                        </LoadingButton>
                    </>
                )}

                {state === 'initial' && (
                    <>
                        <LoadingButton loading={isLoading} variant="contained" onClick={() => startRedirectFlow()}>
                            {t('verifyIdentityShort')}
                        </LoadingButton>
                        <LoadingButton variant="text" onClick={onClose}>
                            {t('cancel')}
                        </LoadingButton>
                    </>
                )}

                {state === 'success' && (
                    <>
                        <LoadingButton sx={{ paddingX: '80px' }} variant="contained" onClick={onClose}>
                            {t('continue')}
                        </LoadingButton>
                    </>
                )}
            </StepContentFullScreen>
        </Modal>
    );
};
