import { LoaderFunctionArgs, redirect } from 'react-router-dom';
import { selectBooking, selectHasCompletedPayment } from './store/bookingReducer';
import { setInitialState } from './store/listingsReducer';
import { store } from './store/store';
import { Preferences } from '@capacitor/preferences';
import { state2QueryString } from './helpers/urlHelpers';
import { getCurrentUser, getOrFetchCurrentUser, getQueryClientInstance } from './queryClient';
import { User } from './types/apiTypes';
import { selectToken } from './store/userReducer';

export const redirectIfBookingStateIncompatible = async ({ params }: LoaderFunctionArgs) => {
    const listingId = params.id as string;
    const booking = selectBooking(store.getState(), listingId);

    if (!booking) {
        throw redirect(`/listing/${params.id}`);
    }
    const { range } = booking;
    const { bookingStart, bookingEnd } = range;

    if (!bookingStart || !bookingEnd) {
        throw redirect(`/listing/${params.id}`);
    }

    return null;
};

export const redirectIfPaymentNotConfirmed = async ({ params }: LoaderFunctionArgs) => {
    const listingId = params.id as string;
    const hasCompletedPayment = selectHasCompletedPayment(store.getState(), listingId);
    if (!hasCompletedPayment) {
        throw redirect(`/listing/${params.id}`);
    }

    return null;
};

// Currently passwords are added to SSO users only when they are in the process of deleting their account.
export const redirectIfDeletingAccount = async ({ request }: LoaderFunctionArgs) => {
    const accountDeletionStatus = await Preferences.get({ key: 'accountDeletionStatus' });

    if (accountDeletionStatus.value) {
        const { search } = new URL(request.url);
        const redirectUrl = `/create-password${search}`;

        throw redirect(redirectUrl);
    }

    return null;
};

export const redirectIfLenderOnboardingIncomplete = async () => {
    const token = selectToken(store.getState());

    if (!token) {
        return null;
    }

    const user = await getOrFetchCurrentUser(token);

    if (user && !user.profile.privateData.lenderOnboardingComplete) {
        throw redirect('/lender-onboarding');
    }

    return null;
};

export const redirectIfOnboardingComplete = async () => {
    const token = selectToken(store.getState());

    if (!token) {
        return null;
    }

    const user = await getOrFetchCurrentUser(token);

    if (user && user.profile.privateData.lenderOnboardingComplete) {
        throw redirect('/');
    }

    return null;
};

export const listingsLoader = ({ request }: LoaderFunctionArgs) => {
    const { search } = new URL(request.url);

    if (!search) {
        const query = state2QueryString(store.getState().listings);
        throw redirect(`/listings${query}`);
    } else {
        store.dispatch(setInitialState(search));
    }

    return null;
};
