import { Chip, ChipProps, SxProps } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TransitionName } from '../../../transactions/constants';

interface LastTransitionBadgeProps {
    transition: TransitionName;
    sxProps?: SxProps;
    chipProps?: ChipProps;
}

type ChipConfig = {
    color: 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
    variant?: 'filled' | 'outlined';
};

const colorConfig: Record<TransitionName, ChipConfig> = {
    [TransitionName.ACCEPT]: {
        color: 'success',
        variant: 'outlined',
    },
    [TransitionName.OPERATOR_ACCEPT]: {
        color: 'success',
        variant: 'outlined',
    },
    [TransitionName.EXPIRE]: {
        color: 'info',
    },
    [TransitionName.COMPLETE]: {
        color: 'success',
    },
    [TransitionName.CONFIRM_PAYMENT]: {
        color: 'info',
    },
    [TransitionName.DECLINE]: {
        color: 'error',
        variant: 'outlined',
    },
    [TransitionName.CANCEL]: {
        color: 'error',
        variant: 'outlined',
    },
    [TransitionName.CUSTOMER_DECLINE_BEFORE_CONFIRM_PAYMENT]: {
        color: 'info',
        variant: 'outlined',
    },
    [TransitionName.CANCEL_NO_REFUND]: {
        color: 'error',
        variant: 'outlined',
    },
    [TransitionName.CUSTOMER_CANCEL]: {
        color: 'error',
        variant: 'outlined',
    },
    [TransitionName.CUSTOMER_CANCEL_AFTER_ACCEPT]: {
        color: 'info',
        variant: 'outlined',
    },
    [TransitionName.PROVIDER_CANCEL_AFTER_ACCEPT]: {
        color: 'info',
        variant: 'outlined',
    },
    [TransitionName.REQUEST_PAYMENT]: {
        color: 'info',
        variant: 'outlined',
    },
    [TransitionName.OPERATOR_DECLINE]: {
        color: 'error',
        variant: 'outlined',
    },
    [TransitionName.OPERATOR_DECLINE_NO_REFUND]: {
        color: 'error',
        variant: 'outlined',
    },
    [TransitionName.REVIEW_1_BY_CUSTOMER]: {
        color: 'success',
        variant: 'outlined',
    },
    [TransitionName.REVIEW_1_BY_PROVIDER]: {
        color: 'success',
        variant: 'outlined',
    },
    [TransitionName.REVIEW_2_BY_CUSTOMER]: {
        color: 'success',
    },
    [TransitionName.REVIEW_2_BY_PROVIDER]: {
        color: 'success',
    },
};

export const LastTransitionBadge: React.FC<LastTransitionBadgeProps> = ({ transition, sxProps, chipProps }) => {
    const { t } = useTranslation();
    const config = colorConfig[transition];

    return <Chip {...config} {...chipProps} sx={sxProps} label={t(transition)} />;
};
