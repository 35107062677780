import React from 'react';
import { useCurrentUser } from '../../user/hooks/useUser';
import { useAppDispatch } from '../../store/hooks';
import { setIsOnboarding } from '../../store/appStateReducer';
import { useSafeNavigate } from '../../hooks/useSafeNavigate';
import { LenderOnboarding } from '../../components/LenderOnboarding/LenderOnboarding';
import { DrawerProvider } from '../../context/drawer';
import { useParams } from 'react-router-dom';
import { useStripeAccount } from '../UserProfile/hooks/useStripeAccount';
import { useDeliveryOptions } from '../../queries/useDeliveryTiming';

export const LenderOnboardingView: React.FC = () => {
    const { data: user } = useCurrentUser();
    const { data: stripeAccountData, isInitialLoading: isStripeAccountLoading } = useStripeAccount();
    const { data: deliveryOptions, isInitialLoading: isDeliveryOptionsLoading } = useDeliveryOptions();

    const { returnType } = useParams();
    const dispatch = useAppDispatch();
    const navigate = useSafeNavigate();

    if (!user || !deliveryOptions || isStripeAccountLoading || isDeliveryOptionsLoading) {
        return null;
    }

    const handleFinishOnboarding = () => {
        dispatch(setIsOnboarding(false));

        const isExistingLender = user.stripeConnected;

        if (isExistingLender) {
            navigate('/', { replace: true });
        } else {
            navigate('/add-listing', { replace: true });
        }
    };

    return (
        <DrawerProvider>
            <LenderOnboarding
                onEnd={handleFinishOnboarding}
                user={user}
                stripeAccount={stripeAccountData}
                deliveryOptions={deliveryOptions}
                returnType={returnType}
            />
        </DrawerProvider>
    );
};
