import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import Heart from '../../../assets/images/onboarding/Heart.png';
import { User } from '../../../types/apiTypes';
import { StepContent, StepContentContainer } from '../UserOnboarding.components';
import { StaggerList, StaggerListElement } from '../../../animations/components/Stagger';

import { itemVariantsB } from '../../../animations/constants';
import { motion } from 'framer-motion';
import { BaseStepProps } from '../UserOnboarding.types';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';

interface StartStepProps extends BaseStepProps {
    user: User;
    existingUser: boolean;
}

export const StartStep: React.FC<StartStepProps> = ({ onComplete, loading, user }) => {
    const { t } = useTranslation();

    return (
        <StepContent>
            <StepContentContainer>
                <StaggerList
                    custom={{ stagger: 0.3 }}
                    style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}
                >
                    <StaggerListElement
                        custom={{ stagger: 0 }}
                        transition={{ delay: 0.5 }}
                        variants={itemVariantsB}
                        style={{ height: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                        <img src={Heart} height={60} />
                    </StaggerListElement>

                    <StaggerListElement transition={{ delay: 0.8 }}>
                        <Typography variant="h5" fontWeight="bold">
                            {t('firstStepTitle', { name: user.profile.firstName })}
                        </Typography>
                    </StaggerListElement>

                    <StaggerListElement transition={{ delay: 1.4 }} custom={0}>
                        <Typography variant="subtitle1" sx={{ marginBottom: '20px' }}>
                            {t('firstStepDescription')}
                        </Typography>
                        <Typography variant="subtitle1">{t('firstStepSubtitle')}</Typography>
                    </StaggerListElement>
                </StaggerList>
            </StepContentContainer>

            <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 2, type: 'spring', damping: 7, stiffness: 30 }}
            >
                <LoadingButton loading={loading} variant="contained" onClick={onComplete}>
                    {t('continue')}
                </LoadingButton>
            </motion.div>
        </StepContent>
    );
};
