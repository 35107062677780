import axios, { AxiosRequestConfig } from 'axios';
import i18next from 'i18next';
import { store } from '../../store/store';
import { Capacitor } from '@capacitor/core';
import { getQueryClientInstance } from '../../queryClient';
import { User } from '../../types/apiTypes';
import { getAppConfig } from '../../countryConfigs';
import { getAppVersion } from '../../helpers/version';

type Api =
    | 'user'
    | 'auth'
    | 'image'
    | 'listings'
    | 'transactions'
    | 'conversations'
    | 'messages'
    | 'tags'
    | 'notifications'
    | 'identification'
    | 'strapi'
    | 'delivery'
    | 'search'
    | 'stripe'
    | 'password-reset'
    | 'minimum-version'
    | 'credits'
    | 'referrals'
    | 'delivery'
    | 'location';

export const getApiClient = (api: Api, serviceUrl = '/api/', withCredentials = false) => {
    const createInstance = () => {
        const serverUrl = getAppConfig().serverUrl;
        const version = getAppVersion();

        const instance = axios.create({
            baseURL: `${serverUrl}${serviceUrl}${api}`,
            headers: {
                Accept: 'application/json',
                'x-api-key': process.env.REACT_APP_API_KEY || '',
                'accept-version': version,
                'x-platform': Capacitor.getPlatform(),
            },
            timeout: 10000,
            ...(withCredentials && { withCredentials: true }),
        });
        instance.interceptors.request.use((config: AxiosRequestConfig) => {
            const { token } = store.getState().user;
            const user = getQueryClientInstance().getQueryData<User>(['current-user']);
            const language = i18next.language || 'en';

            if (config && config.headers) {
                if (token) {
                    // eslint-disable-next-line no-param-reassign
                    config.headers.Authorization = token;
                }
                if (user) {
                    config.headers['x-user-id'] = user.id;
                    config.headers['x-user-email'] = user.email;
                }
                // eslint-disable-next-line no-param-reassign
                config.headers['app-language'] = language;
            }

            return config;
        });
        return instance;
    };

    return createInstance();
};
