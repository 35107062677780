import { capitalizeEachWord } from '../../../helpers/commonHelpers';
import { MHDeliveryStatus } from '../../../matkahuolto/helpers';

const isPendingActivation = (status: MHDeliveryStatus) => status === 'pending-activation';
const isPendingDropoff = (status: MHDeliveryStatus) => status === 'pending-dropoff';
const isPendingActivationOrDropoff = (status: MHDeliveryStatus) => isPendingActivation(status) || isPendingDropoff(status);
const isInTransit = (status: MHDeliveryStatus) => status === 'in-transit';
const isDelivered = (status: MHDeliveryStatus) => status === 'delivered';
const isCancelled = (status: MHDeliveryStatus) => status === 'cancelled';

export const buildMHRentalStatusKey = (
    party: 'Renter' | 'Lender',
    deliveryMethod: string,
    initialStatus: MHDeliveryStatus,
    returnStatus: MHDeliveryStatus,
    isPresentInShowroom: boolean | undefined,
): string => {
    const key = `rentalStatus${party}${capitalizeEachWord(deliveryMethod)}`;

    if (party === 'Renter') {
        if (isCancelled(initialStatus) || isCancelled(returnStatus)) return `${key}Cancelled`;
        if (isPendingActivation(initialStatus)) return `${key}PendingActivation`;
        if (isPendingDropoff(initialStatus) || isInTransit(initialStatus)) return `${key}DeliveryTracking`;
        if (isPendingActivationOrDropoff(returnStatus)) return `${key}ReturnInstructions`;
        if (isInTransit(returnStatus) || isDelivered(returnStatus)) return `${key}ReturnConfirmation`;
    }

    if (party === 'Lender') {
        if (isCancelled(initialStatus) || isCancelled(returnStatus)) return `${key}Cancelled`;
        if (isPendingActivationOrDropoff(initialStatus)) return `${key}DeliveryInstructions`;
        if (isInTransit(initialStatus)) return `${key}DeliveryTracking`;
        if (isPendingActivation(returnStatus)) return `${key}ReturnPendingActivation`;
        if (isPendingDropoff(returnStatus) || isInTransit(returnStatus)) return `${key}ReturnTracking`;
        if (isDelivered(returnStatus)) return `${key}RentalComplete`;

        // Special case: present in showroom but still ongoing
        if (!isCancelled(initialStatus) && !isDelivered(returnStatus) && isPresentInShowroom) {
            return `${key}PresentInShowroom`;
        }
    }

    return key;
};
