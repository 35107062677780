import React, { ChangeEvent, useEffect, useState } from 'react';
import { SwipeableDrawer } from '../../components/SwipeableDrawer/SwipeableDrawer';
import { Box, TextField, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { Check } from '@mui/icons-material';
import { analytics } from '../../analytics/events/helpers';
import { useOfficeConfigurationMethods } from '../../queries/useDeliveryTiming';
import { ContentLoader } from '../../components/ContentLoader/ContentLoader';
import { useHomeDeliveryPostalCodes } from '../../queries/useHomeDeliveryPostalCodes';
import { useCombinedQueryStatus } from '../../helpers/commonHelpers';

interface DeliveryOptionsInformationDrawerProps {
    open: boolean;
    onClose: () => void;
}

const PostalCodeAccepted: React.FC = () => {
    const { t } = useTranslation();
    return (
        <Box sx={{ display: 'flex' }}>
            <Check color="success" sx={{ mr: 1 }} />
            <Typography variant="caption">{t('postalCodeAccepted')}</Typography>
        </Box>
    );
};

const PostalCodeNotAccepted: React.FC = () => {
    const { t } = useTranslation();
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Typography variant="caption" textAlign="center" color="error">
                {t('postalCodeNotAccepted')}
            </Typography>
        </Box>
    );
};

export const DeliveryOptionsInformationDrawer: React.FC<DeliveryOptionsInformationDrawerProps> = ({ open, onClose }) => {
    const { getAvailabilityHoursString, status: officeConfigurationStatus } = useOfficeConfigurationMethods();
    const { data: woltDeliveryAcceptedPostalCodes, status: postalCodesStatus } = useHomeDeliveryPostalCodes();

    const status = useCombinedQueryStatus([officeConfigurationStatus, postalCodesStatus]);

    const [postalCode, setPostalCode] = useState('');

    const { t } = useTranslation();

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => setPostalCode(event.currentTarget.value);

    const postalCodeValid = (woltDeliveryAcceptedPostalCodes || []).includes(postalCode);

    useEffect(() => {
        if (postalCode.length === 5) {
            analytics.postalCodeCheck(postalCode, postalCodeValid);
        }
    }, [postalCode, postalCodeValid]);

    const getHelperText = () => {
        if (postalCodeValid) {
            return <PostalCodeAccepted />;
        }

        if (postalCode.length > 4) {
            return <PostalCodeNotAccepted />;
        }

        return '';
    };

    const getTranslationComponent = (i18nKey: string, deliveryMethod?: string) => {
        return (
            <Trans
                i18nKey={i18nKey}
                values={{ openingHours: getAvailabilityHoursString() }}
                components={{ b: <b />, h1: <h1 style={{ fontWeight: 100 }} />, h3: <h3 /> }}
            />
        );
    };

    return (
        <SwipeableDrawer open={open} onClose={onClose} fillHeight>
            <ContentLoader status={status}>
                <Box sx={{ p: 2 }}>
                    {getTranslationComponent('deliveryInformationDrawerHomeDelivery', 'wolt')}
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <TextField
                            sx={{ my: 2 }}
                            value={postalCode}
                            onChange={handleInputChange}
                            fullWidth
                            placeholder={t('postalCodePlaceholder')}
                        />
                        <Box sx={{ height: '40px' }}>{getHelperText()}</Box>
                    </Box>
                    <Box sx={{ mb: 8 }}>{getTranslationComponent('deliveryInformationDrawerParcelDelivery')}</Box>
                    <Box sx={{ mb: 8 }}>{getTranslationComponent('deliveryInformationDrawerShowroomDropoff')}</Box>
                    <Box sx={{ my: 8 }}>{getTranslationComponent('deliveryInformationDrawerfaceToFaceDelivery')}</Box>
                    <Box sx={{ my: 8 }}>{getTranslationComponent('deliveryInformationDrawerFAQ')}</Box>
                </Box>
            </ContentLoader>
        </SwipeableDrawer>
    );
};
