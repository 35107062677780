import { Edit, LocationOn } from '@mui/icons-material';
import { Box, CircularProgress, Divider, IconButton, Skeleton, Typography } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import _, { upperFirst } from 'lodash';
import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';

import { ProfileSection } from '../../components/ProfileSection/ProfileSection';
import { StyledHeader } from '../../components/Styled/Styled.components';
import { minimumRentalLength } from '../../constants';
import { formatPrice, getListingTitle } from '../../helpers/commonHelpers';
import { useSafeNavigate } from '../../hooks/useSafeNavigate';
import { useDeliveryOptions } from '../../queries/useDeliveryTiming';
import { ExtendedListing, Listing, ListingPreview } from '../../types/apiTypes';
import { ReactFCWithChildren } from '../../types/types';
import { DAYS_IN_MONTH, DAYS_IN_WEEK } from '../AddListing/PricingModelForm.helpers';
import { CancellationPolicySection } from './CancellationPolicySection';
import { DeliveryOptionsInformationDrawer } from './DeliveryOptionsInformationDrawer';
import { DeliveryOptionsSection } from './DeliveryOptionsSection';
import { HowRentingWorksSection } from './HowRentingWorks';
import { PublicDataSection } from './PublicDataSection';
import { RenterProtectionSection } from './RenterProtectionSection';
import { TryonSection } from './TryonSection';
import { ItemDescriptionText, ListingDataContainer } from './ViewListing.components';
import { isExtendedListing, isListingPreview } from './ViewListing.helpers';

const FadeInOutContainer: ReactFCWithChildren<{ style?: CSSProperties }> = ({ children, style }) => (
    <motion.div style={style} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.2 }}>
        {children}
    </motion.div>
);

interface ListingPublicDataSectionProps {
    listing: Listing | ExtendedListing | ListingPreview;
    showProfile?: boolean;
    setShowProfile?: (val: boolean) => void;
    isOwnListing: boolean;
}

export const ListingPublicDataSection: React.FC<ListingPublicDataSectionProps> = ({ listing, setShowProfile, showProfile, isOwnListing }) => {
    const [woltInfoDrawerOpen, setWoltInfoDrawerOpen] = React.useState(false);
    const { t } = useTranslation();
    const { description } = listing;
    const navigate = useSafeNavigate();
    const extendedListing = isExtendedListing(listing);
    const listingPreview = isListingPreview(listing);
    const { data: deliveryOptions } = useDeliveryOptions();

    const title = getListingTitle(listing, t);

    return (
        <ListingDataContainer>
            {extendedListing || listingPreview ? (
                <ProfileSection
                    allowEditing={!listingPreview}
                    userData={listing.author}
                    pictureSize="small"
                    listingId={extendedListing ? listing.id : undefined}
                />
            ) : (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <Skeleton variant="circular" height={40} width={40} />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Skeleton variant="text" width={100} />
                        <Skeleton variant="text" width={80} height={10} />
                    </div>
                </Box>
            )}

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '8px' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', maxWidth: isOwnListing ? '90%' : '100%' }}>
                    <StyledHeader variant="h6" sx={{ width: '100%', mt: 1 }}>
                        {title}
                    </StyledHeader>

                    <DeliveryOptionsInformationDrawer open={woltInfoDrawerOpen} onClose={() => setWoltInfoDrawerOpen(false)} />
                </Box>

                {isOwnListing && (
                    <IconButton
                        onClick={() => navigate(`/edit-listing/${_.get(listing, 'id', '')}`)}
                        sx={{ borderRadius: '10px', padding: '4px 8px' }}
                    >
                        <Edit />
                    </IconButton>
                )}
            </Box>

            {listing.publicData?.city && (
                <Box>
                    <Typography
                        variant="body2"
                        sx={{ display: 'flex', alignItems: 'center', marginLeft: '-6px', color: (t) => t.palette.svgIcon.default }}
                    >
                        <LocationOn sx={{ marginRight: '2px' }} />
                        {listing.publicData.presentInShowroom && <>ROBES Showroom, Helsinki</>}
                        {!listing.publicData.presentInShowroom && <>{upperFirst(listing.publicData.city)}</>}
                    </Typography>
                </Box>
            )}

            <AnimatePresence mode="wait">
                {extendedListing || listingPreview ? (
                    <FadeInOutContainer key="extended-data" style={{ marginTop: '16px' }}>
                        {listing.price && (
                            <div style={{ marginBottom: '10px' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography variant="subtitle2" fontWeight="bold" textTransform="capitalize">
                                        {t('day')}
                                    </Typography>
                                    <Typography>{formatPrice(listing.price.amount)}</Typography>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span style={{ display: 'flex' }}>
                                        <Typography variant="subtitle2" fontWeight="bold" textTransform="capitalize">
                                            {t('week')}
                                        </Typography>
                                        &nbsp;
                                        <Typography variant="subtitle2">{`(7 ${t('days')})`}</Typography>
                                    </span>
                                    <Typography>
                                        {listing.publicData?.pricing?.weekly
                                            ? formatPrice(listing.publicData.pricing.weekly)
                                            : formatPrice(listing.price.amount * DAYS_IN_WEEK)}
                                    </Typography>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span style={{ display: 'flex' }}>
                                        <Typography variant="subtitle2" fontWeight="bold" textTransform="capitalize">
                                            {t('month')}
                                        </Typography>
                                        &nbsp;
                                        <Typography variant="subtitle2">{`(28 ${t('days')})`}</Typography>
                                    </span>
                                    <Typography>
                                        {listing.publicData?.pricing?.monthly
                                            ? formatPrice(listing.publicData.pricing.monthly)
                                            : formatPrice(listing.price.amount * DAYS_IN_MONTH)}
                                    </Typography>
                                </div>
                                <Typography sx={{ opacity: 0.7 }} variant="caption">
                                    {t('minimumDaysRental', { amount: minimumRentalLength })}
                                </Typography>
                            </div>
                        )}

                        <Divider sx={{ my: 1 }} />

                        {extendedListing && (
                            <>
                                <TryonSection listing={listing} isOwnListing={isOwnListing} />
                                <DeliveryOptionsSection
                                    deliveryOptions={deliveryOptions || []}
                                    listing={listing}
                                    onClick={() => setWoltInfoDrawerOpen(true)}
                                />
                            </>
                        )}

                        <RenterProtectionSection />

                        <CancellationPolicySection />

                        <Divider sx={{ ...(description && { my: 1 }) }} />

                        <ItemDescriptionText>{description}</ItemDescriptionText>

                        {listing.publicData && (
                            <Box sx={{ mt: 4 }}>
                                <PublicDataSection publicData={listing.publicData} />
                            </Box>
                        )}

                        <Divider sx={{ mt: 2 }} />

                        {extendedListing && <HowRentingWorksSection listing={listing} />}
                    </FadeInOutContainer>
                ) : (
                    <FadeInOutContainer key="loader">
                        <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center' }}>
                            <CircularProgress size="2rem" />
                        </div>
                    </FadeInOutContainer>
                )}
            </AnimatePresence>
        </ListingDataContainer>
    );
};
