import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { getApiClient } from '../../../services/sharetribe/apiClients';

export const useCreateStripeCustomer = (opts: UseMutationOptions<string>) => {
    const createStripeCustomer = async () => {
        const { data } = await getApiClient('stripe').post<string>('/customer');
        return data;
    };

    return useMutation(createStripeCustomer, opts);
};
