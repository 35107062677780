import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { SuccessSVG } from '../../SuccessSvg/SuccessSvg';
import { StaggerList, StaggerListElement } from '../../../animations/components/Stagger';
import { StepContent, StepContentContainer } from '../LenderOnboarding.components';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { User } from '../../../types/apiTypes';

interface FinishStepProps {
    onComplete: () => void;
    user: User;
}

export const FinishStep: React.FC<FinishStepProps> = ({ user, onComplete }) => {
    const { t } = useTranslation();

    const isExistingLender = user.stripeConnected;

    return (
        <StepContent>
            <StepContentContainer>
                <StaggerList
                    custom={{ stagger: 0.7 }}
                    style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}
                >
                    <StaggerListElement>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 4, mt: 4 }}>
                            <SuccessSVG variant="sm" />
                        </Box>
                    </StaggerListElement>
                    <StaggerListElement>
                        <Typography variant="h5" fontWeight="bold">
                            {t('finishStepTitle')}
                        </Typography>
                    </StaggerListElement>

                    <StaggerListElement>
                        <Typography variant="subtitle1">{t('lenderOnboardingFinishBodyText')}</Typography>
                    </StaggerListElement>
                </StaggerList>
            </StepContentContainer>

            <motion.div initial={{ scale: 0 }} animate={{ scale: 1 }} transition={{ delay: 2 }}>
                <Button variant="contained" onClick={onComplete}>
                    {isExistingLender ? t('continue') : t('lenderOnboardingFinishButtonText')}
                </Button>
            </motion.div>
        </StepContent>
    );
};
