import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';
import { useQueryClient } from '@tanstack/react-query';
import { produce } from 'immer';
import { set } from 'lodash';
import React, { useState } from 'react';
import { getApiClient } from '../../services/sharetribe/apiClients';
import { dispatchCustomEvent } from '../../store/listeners';
import { DeliveryPreferences, User } from '../../types/apiTypes';
import { DeliveryOption } from '../../types/delivery';
import { StripeAccountDataWithStatus } from '../../views/UserProfile/hooks/useStripeAccount';
import { Step } from '../Stepper/Step';
import { Direction, Stepper } from '../Stepper/Stepper';
import { generateOnboardingSteps } from './LenderOnboarding.helpers';
import { OnboardingStep } from './LenderOnboarding.types';
import { DeliveryOptionsStep, FormValue } from './Steps/DeliveryOptions';
import { DeliveryPreferencesStep } from './Steps/DeliveryPreferences';
import { FinishStep } from './Steps/Finish';
import { NotificationPermissionsStep } from './Steps/NotificationPermissions';
import { PickupPointsStep } from './Steps/PickupPoints';
import { StartStep } from './Steps/Start';
import { StripeConnectStep } from './Steps/StripeConnect';

interface LenderOnboardingSteps {
    onEnd: () => void;
    user: User;
    stripeAccount: StripeAccountDataWithStatus | undefined;
    deliveryOptions: DeliveryOption[];
    returnType: string | undefined;
}

const getInitialStep = (steps: OnboardingStep[], returnType: string | undefined): number => {
    if (returnType === 'success' || returnType === 'error') {
        const idx = steps.indexOf('complete');
        return idx !== -1 ? idx : 0;
    }

    return 0;
};

export const LenderOnboarding: React.FC<LenderOnboardingSteps> = ({ onEnd, user, deliveryOptions, stripeAccount, returnType }) => {
    const [steps, setSteps] = useState<OnboardingStep[]>(() => generateOnboardingSteps(user, stripeAccount));
    const [loading, setLoading] = useState(false);
    const [activeStep, setActiveStep] = useState(getInitialStep(steps, returnType));
    const [direction, setDirection] = useState<Direction>();

    const queryClient = useQueryClient();

    const goForward = (increment = 1) => {
        setActiveStep(activeStep + increment);
        setDirection('right');
    };

    const goBack = () => {
        setActiveStep(activeStep - 1);
        setDirection('left');
    };

    const handleStepComplete = async (value: OnboardingStep) => {
        const isNative = Capacitor.isNativePlatform();

        if (isNative && value === 'pickupPoints') {
            const { receive } = await PushNotifications.checkPermissions();

            if (receive === 'granted') {
                // If notifications are already granted, skip the notification permissions step
                goForward(2);

                return;
            }
        }

        if (value === 'deliveryOptions') {
            const userQueryData = queryClient.getQueryData<User | undefined>(['current-user']) as User;

            // If Matkahuolto was not selected, skip the pickup points step
            if (!userQueryData.profile.privateData.settings?.delivery?.matkahuoltoEnabled) {
                const newSteps = steps.filter((step) => step !== 'pickupPoints');
                setSteps(newSteps);
            }
        }

        goForward();
    };

    const handleFinishOnboarding = async () => {
        await getApiClient('user').put('/', { privateData: { lenderOnboardingComplete: true } });

        queryClient.setQueryData<User | undefined>(['current-user'], (userData) => {
            if (!userData) {
                return undefined;
            }

            set(userData, 'profile.privateData.lenderOnboardingComplete', true);

            return userData;
        });

        onEnd();
    };

    const handleContactDetailsStepComplete = async (deliveryPreferences: DeliveryPreferences) => {
        setLoading(true);

        const handleUpdateUser = async () => {
            await getApiClient('user').put('/', { privateData: { deliveryPreferences } });

            queryClient.setQueryData<User | undefined>(['current-user'], (userData) => {
                if (!userData) {
                    return undefined;
                }

                set(userData, 'profile.privateData.deliveryPreferences', deliveryPreferences);

                return userData;
            });
        };

        await handleUpdateUser();

        setLoading(false);
        handleStepComplete('contactDetails');
    };

    const handleDeliveryOptionsStepComplete = async (formValues: { deliveryOptions: FormValue[] }) => {
        setLoading(true);

        const handleUpdateUser = async () => {
            const settings = user.profile.privateData.settings || {};

            const updatedSettings = produce(settings, (draft) => {
                draft.delivery = {
                    ...draft.delivery,
                    faceToFaceEnabled: false,
                    matkahuoltoEnabled: false,
                    showroomEnabled: false,
                    woltEnabled: false,
                    uberEnabled: false,
                };

                // Enable only the selected delivery options
                formValues.deliveryOptions.forEach((option) => {
                    if (draft.delivery) {
                        switch (option) {
                            case 'faceToFace':
                                draft.delivery.faceToFaceEnabled = true;
                                break;
                            case 'matkahuolto':
                                draft.delivery.matkahuoltoEnabled = true;
                                break;
                            case 'showroomAndWolt':
                                draft.delivery.showroomEnabled = true;
                                draft.delivery.woltEnabled = true;
                                break;
                            case 'uber':
                                draft.delivery.uberEnabled = true;
                                break;
                        }
                    }
                });
            });

            await getApiClient('user').put('/', { privateData: { settings: updatedSettings } });

            queryClient.setQueryData<User | undefined>(['current-user'], (userData) => {
                if (!userData) {
                    return undefined;
                }

                userData = produce(userData, (draft) => {
                    draft.profile.privateData.settings = updatedSettings;
                });

                console.log('Updated user', userData);

                return userData;
            });
        };

        await handleUpdateUser();

        setLoading(false);
        handleStepComplete('deliveryOptions');
    };

    // Request push notification permissions and update user settings
    const handleNotificationPermissionsStepComplete = (notificationsAllowed: boolean) => {
        if (notificationsAllowed) {
            dispatchCustomEvent('enabledPushNotifications');
        }

        handleStepComplete('notificationPermissions');
    };

    const handlePickupPointsStepComplete = async (pickupPoint: { MHPreferredPickupPointID: number }) => {
        const { MHPreferredPickupPointID } = pickupPoint;

        setLoading(true);

        const handleUpdateUser = async () => {
            const currentDeliveryPreferences = user.profile.privateData.deliveryPreferences;
            const updatedDeliveryPreferences = { ...currentDeliveryPreferences, MHPreferredPickupPointID };

            await getApiClient('user').put('/', { privateData: { deliveryPreferences: updatedDeliveryPreferences } });

            queryClient.setQueryData<User | undefined>(['current-user'], (userData) => {
                if (!userData) {
                    return undefined;
                }

                set(userData, 'profile.privateData.deliveryPreferences.MHPreferredPickupPointID', MHPreferredPickupPointID);

                return userData;
            });
        };

        await handleUpdateUser();
        setLoading(false);
        handleStepComplete('pickupPoints');
    };

    const hideIndicators = activeStep === steps.length - 1;

    return (
        <Stepper activeStep={activeStep} direction={direction} hideIndicators={hideIndicators}>
            {steps.map((step, i) => (
                <Step key={step}>
                    {step === 'start' && <StartStep user={user} onComplete={() => handleStepComplete('start')} />}

                    {step === 'contactDetails' && (
                        <DeliveryPreferencesStep
                            loading={loading}
                            hasPreviousStep={i > 0}
                            onComplete={handleContactDetailsStepComplete}
                            user={user}
                            onGoBack={goBack}
                        />
                    )}

                    {step === 'deliveryOptions' && (
                        <DeliveryOptionsStep
                            user={user}
                            loading={loading}
                            deliveryOptions={deliveryOptions}
                            onComplete={handleDeliveryOptionsStepComplete}
                            onGoBack={goBack}
                        />
                    )}

                    {step === 'pickupPoints' && (
                        <PickupPointsStep loading={loading} user={user} onComplete={handlePickupPointsStepComplete} onGoBack={goBack} />
                    )}

                    {step === 'notificationPermissions' && (
                        <NotificationPermissionsStep
                            user={user}
                            loading={loading}
                            onComplete={handleNotificationPermissionsStepComplete}
                            onGoBack={goBack}
                            hasPreviousStep={i > 0}
                        />
                    )}

                    {step === 'stripeConnect' && <StripeConnectStep onComplete={handleStepComplete} onGoBack={goBack} hasPreviousStep={i > 0} />}

                    {step === 'complete' && <FinishStep user={user} onComplete={handleFinishOnboarding} />}
                </Step>
            ))}
        </Stepper>
    );
};
