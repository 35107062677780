import { Box, Typography, Divider } from '@mui/material';
import { Control, Controller, FieldValues } from 'react-hook-form';
import React from 'react';
import { DatePicker, StaticDatePicker } from '@mui/x-date-pickers';
import { noop } from '../../../helpers/commonHelpers';
import { FormFieldDate } from '../FormRenderer.types';

interface DateFieldProps {
    field: FormFieldDate;
    control: Control<FieldValues, any>;
    closeDrawers: (drawerNames: string[]) => void;
}

const CustomActionBar: React.FC = () => <></>;

export const FormDateField: React.FC<DateFieldProps> = ({ field, control, closeDrawers }) => {
    const handleSelectDate = () => {
        if (field.parentControl && field.closeDrawerOnSelect) {
            return closeDrawers([field.parentControl]);
        } else {
            return noop;
        }
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} key={field.controlName}>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', overflowY: 'auto' }} onTouchStart={(ev) => ev.stopPropagation()}>
                <Controller
                    name={field.controlName}
                    control={control}
                    rules={{ ...field.registerOptions }}
                    render={({ field: fieldRenderer }) => (
                        <StaticDatePicker
                            {...fieldRenderer}
                            onChange={(val) => {
                                fieldRenderer.onChange(val);
                                handleSelectDate();
                            }}
                            slots={{ actionBar: CustomActionBar }}
                        />
                    )}
                />
            </Box>

            <Box sx={{ width: '100%', mt: 1 }}>
                {field.alert && <>{field.alert}</>}
                {field.description && <Typography variant="caption">{field.description}</Typography>}
            </Box>
        </Box>
    );
};
