import React, { useMemo } from 'react';
import { Box, FormHelperText, Skeleton, Typography } from '@mui/material';
import { StepBackButton, StepContent, StepContentContainer } from '../LenderOnboarding.components';
import { LoadingButton } from '@mui/lab';
import { Trans, useTranslation } from 'react-i18next';
import { BaseStepProps } from '../LenderOnboarding.types';
import { ArrowBack } from '@mui/icons-material';
import { User } from '../../../types/apiTypes';
import { FormProvider, useForm } from 'react-hook-form';
import { usePickupPoints } from '../../../queries/usePickupPoints';
import { ContentLoader } from '../../ContentLoader/ContentLoader';
import { FormRenderer } from '../../FormRenderer/FormRenderer';
import { renderSelectItem } from '../../../views/Checkout/LogisticsSection.helpers';
import { FormFieldSelect, SelectItem } from '../../FormRenderer/FormRenderer.types';
import { isEmpty } from 'lodash';

interface PickupPointsStepProps extends BaseStepProps {
    user: User;
}

type FormValues = {
    MHPreferredPickupPointID: number;
};

const PickupPointsSkeleton = () => (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '4px' }}>
        <Skeleton width="100%" height={90} variant="text" sx={{ mb: 2 }} />
        <Skeleton width="100%" height={56} variant="rectangular" />
        <Skeleton width="100%" height={56} variant="rectangular" />
        <Skeleton width="100%" height={56} variant="rectangular" />
        <Skeleton width="100%" height={56} variant="rectangular" />
        <Skeleton width="100%" height={56} variant="rectangular" />
    </Box>
);

export const PickupPointsStep: React.FC<PickupPointsStepProps> = ({ onComplete, loading, onGoBack, user }) => {
    const { t } = useTranslation();
    const { street, city, postalCode, MHPreferredPickupPointID } = user.profile.privateData.deliveryPreferences || {};
    const methods = useForm<FormValues>({ defaultValues: { MHPreferredPickupPointID } });
    const { data, status } = usePickupPoints({ street, city, postalCode });

    const {
        formState: { errors },
    } = methods;

    const handleSubmit = () => {
        methods.handleSubmit(onComplete)();
    };

    const resolvedFormFields: FormFieldSelect[] = useMemo(() => {
        if (!data) {
            return [];
        }

        const items: SelectItem[] = data.map((pickupPoint) => {
            return {
                type: 'item',
                value: `${pickupPoint.Id}`,
                title: `${pickupPoint.Name} (${pickupPoint.Distance} km)`,
                content: `${pickupPoint.StreetAddress}, ${String(pickupPoint.PostalCode).padStart(5, '0')} ${pickupPoint.City}`,
            };
        });

        return [
            {
                type: 'select',
                variant: 'radio',
                controlName: 'MHPreferredPickupPointID',
                label: '',
                closeDrawerOnSelect: true,
                renderItem: (item) => renderSelectItem(item, { renderPrefix: false, renderSuffix: false }),
                registerOptions: { required: true, shouldUnregister: true },
                items,
            },
        ];
    }, [data]);

    return (
        <StepContent>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit((data) => onComplete(data))}>
                    <StepContentContainer>
                        <Typography variant="h6" sx={{ mt: 4, textAlign: 'start', ml: 2 }}>
                            {t('lenderOnboardingPickupPointsTitle')}
                        </Typography>
                        <ContentLoader status={status} skeleton={<PickupPointsSkeleton />}>
                            <Typography variant="body2" sx={{ p: 2, textAlign: 'start', width: '100%', mb: 1 }}>
                                <Trans i18nKey="lenderOnboardingPickupPointsAddressDisclaimer" components={{ b: <b /> }} />
                            </Typography>

                            <FormRenderer formFields={resolvedFormFields} />
                        </ContentLoader>
                    </StepContentContainer>

                    <StepBackButton onClick={onGoBack}>
                        <ArrowBack />
                    </StepBackButton>
                </form>
            </FormProvider>
            {!isEmpty(errors) && <FormHelperText error>{t('lenderOnboardingPickupPointsPickAtLeastOne')}</FormHelperText>}
            <LoadingButton variant="contained" onClick={handleSubmit} loading={loading} sx={{ mt: 2 }}>
                {t('continue')}
            </LoadingButton>
        </StepContent>
    );
};
