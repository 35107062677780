import { Box, Fab, IconButton, styled } from '@mui/material';

export const StepContent = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexGrow: 1,
    alignItems: 'center',
    padding: '46px 16px 60px',
    overflowY: 'auto',
    '& .MuiTypography-root': { fontFamily: 'Inter Tight' },
}));

export const StepContentContainer = styled(Box)(() => ({
    width: '100%',
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '20px',
    textAlign: 'center',
}));

export const StepBackButton = styled(Fab)(({ theme }) => ({
    zIndex: 12,
    margin: '10px',
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '38px',
    height: '38px',
    background: theme.palette.background.paper,
    color: theme.palette.text.primary,
}));
