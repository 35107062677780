import { Box, Typography } from '@mui/material';
import React from 'react';
import { Listing } from '../../types/apiTypes';
import { useTranslation } from 'react-i18next';
import { getSizeLabel } from '../../helpers/commonHelpers';

export const PublicDataSection: React.FC<{ publicData: Listing['publicData'] }> = ({ publicData }) => {
    const { t } = useTranslation();

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                '& .MuiTypography-root': { fontFamily: 'Inter Tight' },
                '& h6': { textTransform: 'capitalize ' },
            }}
        >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="overline" sx={{ opacity: 0.7 }}>
                    {t('brand')}
                </Typography>
                <Typography variant="subtitle2">{publicData.brand}</Typography>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="overline" sx={{ opacity: 0.7 }}>
                    {t('category')}
                </Typography>
                <Typography variant="subtitle2">{t(publicData.category)}</Typography>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="overline" sx={{ opacity: 0.7 }}>
                    {t('size')}
                </Typography>
                <Typography variant="subtitle2">{getSizeLabel(publicData.size)}</Typography>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="overline" sx={{ opacity: 0.7 }}>
                    {t('color')}
                </Typography>
                <Typography variant="subtitle2">{t(publicData.color)}</Typography>
            </div>
        </Box>
    );
};
