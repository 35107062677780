import { User } from '../../types/apiTypes';
import { Capacitor } from '@capacitor/core';
import { OnboardingStep } from './LenderOnboarding.types';
import { StripeAccountDataWithStatus } from '../../views/UserProfile/hooks/useStripeAccount';
import { hasRequirements } from '../../views/UserProfile/Stripe/helpers';

export const generateOnboardingSteps = (user: User, stripeAccount: StripeAccountDataWithStatus | undefined): OnboardingStep[] => {
    const steps: OnboardingStep[] = ['start', 'contactDetails', 'deliveryOptions', 'pickupPoints'];

    if (Capacitor.isNativePlatform()) {
        steps.push('notificationPermissions');
    }

    if (!user.stripeConnected || (user.stripeConnected && hasRequirements(stripeAccount, ['currently_due', 'past_due']))) {
        steps.push('stripeConnect');
    }

    steps.push('complete');

    return steps;
};
