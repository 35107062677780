import { CircularProgress } from '@mui/material';
import React, { useEffect } from 'react';

export const RedirectToStripe: React.FC<{ redirectFn: () => void }> = ({ redirectFn }) => {
    useEffect(() => {
        redirectFn();
    }, [redirectFn]);

    return <CircularProgress />;
};
