import { Box, SxProps, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

export const StyledActivationCode: React.FC<{ sx?: SxProps; activationCode: string | undefined }> = ({ sx, activationCode }) => {
    return (
        <Box sx={{ textAlign: 'center', ...sx }}>
            {!activationCode ? (
                <Typography variant="body2" sx={{ p: 1, background: '#e8e8e8', borderRadius: '10px' }}>
                    <Trans i18nKey="'matkahuoltoActivationCodeSectionPendingActivation'" />
                </Typography>
            ) : (
                <Typography variant="body2" sx={{ p: 1, background: '#e8e8e8', borderRadius: '10px' }}>
                    <Trans i18nKey="activationCode" components={{ b: <b /> }} values={{ code: activationCode }} />
                </Typography>
            )}
        </Box>
    );
};
