import React from 'react';
import { Payments, Person, Settings as SettingsIcon, ContactMail, QuestionMark, Description, Engineering, Toll } from '@mui/icons-material';
import { ContactUs } from './ContactUs';
import { Menu } from './Menu';
import { PaymentsAndPayouts } from './PaymentsAndPayouts';
import { PersonalInformation } from './PersonalInformation';
import { Settings } from './Settings';
import { AccountDelete } from '../../AccountDelete/AccountDelete';
import { DynamicStrapiPage } from '../../../strapi/components/DynamicStrapiPage';
import { DeveloperSettings } from '../../DeveloperSettings/DeveloperSettings';
import { Credits } from './Credits';
import { CreditsBalance } from './CreditsBalance';
import { redirectIfLenderOnboardingIncomplete } from '../../../routeLoaders';
import { LoaderFunction } from 'react-router-dom';

type Route = {
    label: string;
    path: string;
    index?: boolean;
    component: JSX.Element;
    icon?: JSX.Element;
    suffix?: JSX.Element;
    hideLink?: boolean;
    loader?: LoaderFunction;
};

export const userProfileRoutes: Route[] = [
    {
        label: 'profile',
        index: true,
        path: '',
        component: <Menu />,
    },
    {
        label: 'personalInformation',
        path: 'personal-information',
        icon: <Person />,
        component: <PersonalInformation />,
    },
    {
        label: 'credits',
        path: 'credits',
        icon: <Toll />,
        suffix: <CreditsBalance />,
        component: <Credits />,
    },
    {
        label: 'paymentsAndPayouts',
        path: 'payments-and-payouts',
        icon: <Payments />,
        loader: redirectIfLenderOnboardingIncomplete,
        component: <PaymentsAndPayouts />,
    },
    {
        hideLink: true,
        label: 'paymentsAndPayouts',
        path: 'payments-and-payouts/:returnType',
        icon: <Payments />,
        loader: redirectIfLenderOnboardingIncomplete,
        component: <PaymentsAndPayouts />,
    },
    {
        label: 'settings',
        path: 'settings',
        icon: <SettingsIcon />,
        component: <Settings />,
    },
    {
        label: 'contactUs',
        path: 'contact-us',
        icon: <ContactMail />,
        component: <ContactUs />,
    },
    {
        label: 'howItWorks',
        path: '/how-renting-works', // Note: this is a top level route
        icon: <QuestionMark />,
        component: <DynamicStrapiPage name="how-renting-works" />,
    },
    {
        label: 'termsOfService',
        path: '/terms-of-service',
        icon: <Description />,
        component: <DynamicStrapiPage name="terms-of-service" />,
    },
    {
        label: 'deleteAccount',
        path: 'delete-account',
        component: <AccountDelete />,
        hideLink: true,
    },
    {
        label: 'Developer settings',
        path: 'dev',
        icon: <Engineering />,
        component: <DeveloperSettings />,
        hideLink: true,
    },
];

export const routeLinks = userProfileRoutes
    .filter((route) => route.path !== '' && !route.hideLink)
    .map((route) => ({ path: route.path, label: route.label, icon: route.icon, ...(route.suffix && { suffix: route.suffix }) }));
