import React, { useEffect, useId, useState } from 'react';
import { SwipeableDrawer, Box, IconButton, Typography, ButtonBase, SxProps } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { assertIsDefined } from '../../helpers/commonHelpers';
import { useHashNavigation } from '../../hooks/useHashNavigation';
import { useBreakpoint } from '../../hooks/useBreakpoint';

export interface SwipeableFormDrawerProps {
    id: string;
    label?: string;
    buttonLabel: React.ReactNode;
    buttonPrefix?: React.ReactNode;
    buttonSuffix?: React.ReactNode;
    renderValue?: () => React.ReactNode;
    children: React.ReactNode;
    sx?: SxProps;
    isDesktop?: boolean;
    open?: boolean;
    onClose?: () => void;
    onClick?: () => void;
}

const SwipeableFormDrawer: React.FC<SwipeableFormDrawerProps> = ({
    id,
    label,
    buttonLabel,
    buttonPrefix,
    buttonSuffix,
    renderValue,
    children,
    open: controlledOpen,
    onClose,
    onClick,
    sx,
    isDesktop = false,
}) => {
    // Internal state for uncontrolled mode
    const [internalOpen, setInternalOpen] = useState(false);

    // Determine if the component is controlled
    const isControlled = controlledOpen !== undefined;

    // Use internal state if uncontrolled, otherwise use the controlled state
    const open = isControlled ? controlledOpen : internalOpen;

    useHashNavigation(open, id, onClose);

    const toggleDrawer = (isOpen: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (event?.type === 'keydown' && ((event as React.KeyboardEvent)?.key === 'Tab' || (event as React.KeyboardEvent)?.key === 'Shift')) {
            return;
        }

        if (isControlled && !isOpen) {
            assertIsDefined(onClose, 'onClose must be defined when using SwipeableFormDrawer in controlled mode');

            onClose();
        } else {
            setInternalOpen(isOpen);
        }
    };

    const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (isControlled) {
            assertIsDefined(onClick, 'onClick must be defined when using SwipeableFormDrawer in controlled mode');
            onClick();
        } else {
            toggleDrawer(true)(event);
        }
    };

    useEffect(() => {
        if (isControlled && controlledOpen === false && internalOpen) {
            setInternalOpen(false);
        }
    }, [controlledOpen, isControlled, internalOpen]);

    const isSmBreakpoint = useBreakpoint('sm');
    const anchor = isSmBreakpoint ? 'right' : 'bottom';
    const RenderValueComponent = renderValue ? () => <>{renderValue()}</> : () => <></>;

    return (
        <>
            <ButtonBase
                onClick={handleButtonClick}
                sx={{
                    display: 'grid',
                    gridTemplateColumns: 'auto 1fr auto',
                    alignItems: 'center',
                    px: '6px',
                    height: 'auto',
                    gap: '10px',
                }}
            >
                {buttonPrefix}
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <Typography variant="body1" fontWeight="bold">
                        {buttonLabel}
                    </Typography>
                    {RenderValueComponent && <RenderValueComponent />}
                </Box>
                {buttonSuffix}
            </ButtonBase>

            <SwipeableDrawer
                disableSwipeToOpen
                swipeAreaWidth={0}
                disableDiscovery
                anchor={anchor}
                open={open}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
                PaperProps={{ style: { borderTopLeftRadius: '10px', borderTopRightRadius: '10px', maxWidth: '500px' } }}
            >
                <Box sx={{ position: 'sticky', top: 0, bgcolor: 'background.paper', zIndex: 1100 }}>
                    {!isDesktop && <Box sx={{ height: '4px', bgcolor: 'grey.300', borderRadius: '2px', my: 1, mx: 'auto', width: '30px' }} />}
                    <Box sx={{ display: 'grid', gridAutoColumns: '1fr 4fr 1fr', gridAutoFlow: 'column' }}>
                        <div />
                        <Typography variant="h6" component="div" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {label}
                        </Typography>
                        <IconButton onClick={toggleDrawer(false)} edge="start" size="large">
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
                <Box sx={{ overflowY: 'auto', bgcolor: 'background.paper', ...sx }}>{children}</Box>
            </SwipeableDrawer>
        </>
    );
};

export default SwipeableFormDrawer;
