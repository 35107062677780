import { TFunction } from 'i18next';
import { Trans } from 'react-i18next';
import { woltDeliveryAcceptedPostalCodes } from '../../constants';
import { postalCodeValidation } from '../../helpers/regex';
import { CheckoutSection } from '../../types/delivery';

const getMatkahuoltoCheckoutSections = (t: TFunction): CheckoutSection[] => [
    {
        component: 'common.divider',
    },
    {
        component: 'delivery.delivery-details-form',
        label: t('deliveryDetailsLabel'),
        description: t('deliveryDetailsDescription'),
        fieldName: 'deliveryDetails',
        rememberForLater: true,
        formFields: [
            {
                controlName: 'street',
                label: t('deliveryDetails.streetAddressLabel'),
                type: 'text',
                required: true,
                multiline: false,
                summary: 'show',
            },
            {
                controlName: 'city',
                label: t('deliveryDetails.cityLabel'),
                type: 'text',
                required: true,
                multiline: false,
                summary: 'show',
            },
            {
                controlName: 'postalCode',
                label: t('deliveryDetails.postalCodeLabel'),
                type: 'text',
                required: true,
                multiline: false,
                pattern: postalCodeValidation,
                summary: 'show',
            },
            {
                controlName: 'email',
                label: t('deliveryDetails.emailLabel'),
                type: 'text',
                required: true,
                multiline: false,
                summary: 'hide',
            },
            {
                controlName: 'phone',
                label: t('deliveryDetails.phoneNumberLabel'),
                type: 'phone',
                required: true,
                summary: 'standalone',
            },
        ],
        sectionIcon: {
            icon: 'house',
        },
    },
    {
        component: 'common.condition-block',
        conditions: [
            {
                value: 'deliveryDetails.street',
                exists: true,
            },
            {
                value: 'deliveryDetails.postalCode',
                exists: true,
            },
            {
                value: 'deliveryDetails.phone',
                exists: true,
            },
        ],
    },
    {
        component: 'common.divider',
    },
    {
        component: 'delivery.pickup-point-selector',
        provider: 'matkahuolto',
        label: t('pickupPointLabel'),
        description: t('pickupPointDescription'),
        sectionIcon: {
            icon: 'location',
        },
    },
    {
        component: 'common.condition-block',
        conditions: [
            {
                value: 'pickupPoint',
                exists: true,
            },
        ],
    },
    {
        component: 'common.divider',
    },
    {
        component: 'delivery.selected-info-section',
        label: t('deliveryDateLabel'),
        type: 'delivery',
        format: 'date',
        sectionIcon: {
            icon: 'clock',
        },
    },
    {
        component: 'common.divider',
    },
    {
        component: 'delivery.selected-info-section',
        label: t('returnDateLabel'),
        type: 'return',
        format: 'date',
        prefix: t('returnDatePrefix'),
        sectionIcon: {
            icon: 'arrow-back',
        },
    },
];

const getShowroomCheckoutSections = (t: TFunction): CheckoutSection[] => [
    {
        component: 'common.divider',
    },
    {
        component: 'delivery.info-section',
        label: t('showroomAddress'),
        description: 'Fabianinkatu 4, 00130, Helsinki',
        sectionIcon: {
            icon: 'house',
        },
    },
    {
        component: 'common.divider',
    },
    {
        component: 'delivery.selected-info-section',
        label: t('timeOfPickupLabel'),
        type: 'delivery',
        format: 'date',
        sectionIcon: {
            icon: 'clock',
        },
    },
    {
        component: 'common.divider',
    },
    {
        component: 'delivery.selected-info-section',
        label: t('timeOfReturnLabel'),
        type: 'return',
        format: 'date',
        sectionIcon: {
            icon: 'arrow-back',
        },
    },
];

const getWoltCheckoutSections = (t: TFunction): CheckoutSection[] => [
    {
        component: 'common.divider',
    },
    {
        component: 'delivery.delivery-details-form',
        label: 'Delivery Details',
        description: 'Enter delivery details',
        fieldName: 'deliveryDetails',
        rememberForLater: true,
        formFields: [
            {
                controlName: 'street',
                label: 'Street Address',
                type: 'text',
                required: true,
                multiline: false,
                summary: 'show',
            },
            {
                controlName: 'postalCode',
                label: t('deliveryDetails.postalCodeLabel'),
                type: 'text',
                required: true,
                multiline: false,
                pattern: postalCodeValidation,
                validate: (value: string) => {
                    const result = woltDeliveryAcceptedPostalCodes.includes(value) ? true : t('woltDeliveryNotAcceptedPostalCode');
                    return result;
                },
                summary: 'show',
            },
            {
                controlName: 'city',
                label: t('deliveryDetails.cityLabel'),
                type: 'text',
                required: true,
                multiline: false,
                summary: 'show',
            },
            {
                controlName: 'additionalInformation',
                label: t('instructionsForTheCourier'),
                type: 'text',
                required: false,
                multiline: true,
                rows: 2,
                summary: 'hide',
            },
            {
                controlName: 'phone',
                label: 'Phone number',
                type: 'phone',
                required: true,
                summary: 'standalone',
            },
        ],
        sectionIcon: {
            icon: 'house',
        },
    },
    {
        component: 'common.condition-block',
        conditions: [
            {
                value: 'deliveryDetails.street',
                exists: true,
            },
            {
                value: 'deliveryDetails.postalCode',
                exists: true,
            },
            {
                value: 'deliveryDetails.city',
                exists: true,
            },
        ],
    },
    {
        component: 'common.divider',
    },
    {
        component: 'delivery.date-time-form-section',
        label: t('deliveryTime'),
        description: t('selectDeliveryTime'),
        sectionIcon: {
            icon: 'clock',
        },
        formField: {
            controlName: 'deliveryTime',
            required: true,
            type: 'time',
            displayTimeRangeMinutes: 30,
            alertCompareDate: 'renterDeliveryDate',
            summary: 'hide',
        },
    },
    {
        component: 'common.condition-block',
        conditions: [
            {
                value: 'deliveryTime',
                exists: true,
            },
        ],
    },
    {
        component: 'common.section-title',
        text: t('returnSectionTitle'),
        variant: 'h6',
        bold: true,
    },
    {
        component: 'delivery.return-method-selector',
        label: t('returnMethod'),
        description: t('selectReturnMethod'),
        controlName: 'returnMethod',
        sectionIcon: {
            icon: 'arrow-back',
        },
        selectItems: [
            {
                type: 'item',
                value: 'showroom',
                title: t('showroomDropoffTitle'),
                text: t('showroomDropoffDescription'),
                price: 0,
                severity: 'info',
                prefix: {
                    icon: 'building',
                },
            },
            {
                type: 'alert',
                content: <Trans i18nKey="moreOptionsComingSoonAlert" components={{ h3: <h3 style={{ marginTop: '0px' }} />, p: <p /> }} />,
            },
        ],
    },
    {
        component: 'common.condition-block',
        conditions: [
            {
                value: 'returnMethod',
                exists: true,
            },
        ],
    },
    {
        component: 'common.divider',
    },
    {
        component: 'delivery.info-section',
        label: t('returnAddress'),
        description: 'Fabianinkatu 4, 00130, Helsinki',
        sectionIcon: {
            icon: 'house',
        },
    },
    {
        component: 'common.divider',
    },
    {
        component: 'delivery.selected-info-section',
        label: t('timeOfReturnLabel'),
        type: 'return',
        format: 'date-and-time',
        sectionIcon: {
            icon: 'clock',
        },
    },
];

const getFaceToFaceCheckoutSections = (t: TFunction): CheckoutSection[] => [];

export const getCheckoutSectionsForDeliveryMethod = (t: TFunction, deliveryMethod: string): CheckoutSection[] => {
    switch (deliveryMethod) {
        case 'matkahuolto':
            return getMatkahuoltoCheckoutSections(t);
        case 'showroom':
            return getShowroomCheckoutSections(t);
        case 'wolt':
            return getWoltCheckoutSections(t);
        case 'faceToFace':
            return getFaceToFaceCheckoutSections(t);
        default:
            return [];
    }
};
