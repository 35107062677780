import React, { useEffect, useState } from 'react';
import { Preferences } from '@capacitor/preferences';
import { Box, MenuItem, Select, InputLabel, FormControl, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getAppCountryCode, handleChangeCountryOfResidence, tryResolveUserCountry } from '../../countryConfigs';
import { CircleFlag } from 'react-circle-flags';
import { DrawerSelect } from '../DrawerSelect/DrawerSelect';

export const CountrySelector: React.FC = () => {
    const [country, setCountry] = useState<string | null>(getAppCountryCode());
    const [loading, setLoading] = useState(true);

    const { t } = useTranslation();

    const countries = [
        {
            value: 'US',
            label: t('country-US'),
            icon: <CircleFlag countryCode="us" height="20" />,
        },
        { value: 'FI', label: t('country-FI'), icon: <CircleFlag countryCode="fi" height="20" /> },
    ];

    const saveCountry = async (selectedCountry: string) => {
        await handleChangeCountryOfResidence(selectedCountry);
        setCountry(selectedCountry);
    };

    const initAppConfig = async () => {
        const { value } = await Preferences.get({ key: 'country' });

        let selectedCountry = value;

        if (selectedCountry) {
            await saveCountry(selectedCountry);
        } else {
            selectedCountry = await tryResolveUserCountry();

            if (selectedCountry) {
                await saveCountry(selectedCountry);
            }
        }

        setLoading(false);
    };

    useEffect(() => {
        initAppConfig();
    }, []);

    if (loading) {
        return null;
    }

    return (
        <FormControl size="small">
            <DrawerSelect
                label={t('chooseYourLocation')}
                selectedValue={country as string}
                onSelect={saveCountry}
                options={countries}
                hideSearch
                fillHeight={false}
                ModalStyle={{ paddingBottom: '15px' }}
                buttonSx={{ background: 'none', padding: 0 }}
                renderSelectedValue={() => {
                    const selectedCountry = countries.find((c) => c.value === country);
                    return selectedCountry ? (
                        <Box display="flex" gap={2} alignItems="center">
                            <ListItemIcon sx={{ minWidth: 20, height: 20 }}>{selectedCountry.icon}</ListItemIcon>
                            <ListItemText sx={{ '.MuiTypography-root': { fontSize: '13px' } }}>{t(selectedCountry.label)}</ListItemText>
                        </Box>
                    ) : null;
                }}
            />
        </FormControl>
    );

    return (
        <FormControl size="small">
            <Select
                variant="standard"
                onChange={(e) => saveCountry(e.target.value as string)}
                MenuProps={{ PaperProps: { sx: { maxHeight: '300px' } } }}
                value={country}
                disableUnderline
                renderValue={(value) => {
                    const country = countries.find((country) => country.value === value);
                    return country ? (
                        <Box display="flex" gap={2} alignItems="center">
                            <ListItemIcon sx={{ minWidth: 20, height: 20 }}>{country.icon}</ListItemIcon>
                            <ListItemText sx={{ '.MuiTypography-root': { fontSize: '13px' } }}>{t(country.label)}</ListItemText>
                        </Box>
                    ) : null;
                }}
            >
                <MenuItem disabled>{t('changeLocation')}</MenuItem>
                {countries.map((country) => (
                    <MenuItem key={country.value} value={country.value}>
                        <ListItemIcon sx={{ height: 20, mr: 2 }}>{country.icon}</ListItemIcon>
                        <ListItemText>{t(country.label)} </ListItemText>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );

    return (
        <Box height="30px">
            <FormControl sx={{ width: '85px' }}>
                <InputLabel id="country-select-label">{t('country')}</InputLabel>
                <Select
                    value={country}
                    id="country-select"
                    labelId="country-select-label"
                    label={t('country')}
                    renderValue={(value) => {
                        const country = countries.find((country) => country.value === value);
                        return country ? (
                            <Box display="flex" gap={2} alignItems="center">
                                <ListItemIcon sx={{ height: 20 }}>{country.icon}</ListItemIcon>
                            </Box>
                        ) : null;
                    }}
                    onChange={(e) => saveCountry(e.target.value as string)}
                >
                    {countries.map((country) => (
                        <MenuItem key={country.value} value={country.value}>
                            <ListItemIcon sx={{ height: 20, mr: 2 }}>{country.icon}</ListItemIcon>
                            <ListItemText>{t(country.label)} </ListItemText>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
};
