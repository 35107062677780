import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getApiClient } from '../services/sharetribe/apiClients';

interface UsePickupPointsArgs {
    street?: string;
    city?: string;
    postalCode?: string;
}

export interface PickupPoint {
    SeqNumber: number;
    Id: number;
    Type: string;
    Name: string;
    StreetAddress: string;
    PostalCode: number;
    City: string;
    Country: string;
    MhOffice: string;
    ShopId: string;
    Distance: string;
}

export const getPickupPointDetailsById = (pickupPoints: PickupPoint[], id: number | string) => {
    return pickupPoints.find((point) => point.Id === Number(id));
};

export const usePickupPoints = (args: UsePickupPointsArgs | null): UseQueryResult<PickupPoint[]> => {
    const getPickupPoints = async (): Promise<PickupPoint[]> => {
        if (!args) {
            return [];
        }

        const urlParams = new URLSearchParams(args as Record<string, string>).toString();
        const { data } = await getApiClient('delivery').get<PickupPoint[]>(`/pickup-points?${urlParams}`);
        return data;
    };

    return useQuery(['pickup-points', args], getPickupPoints);
};
